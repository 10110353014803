import { ReactNode } from 'react';

import Btn from './Btn';
import { Modal, ModalFooter, ModalTitle } from './Modal';

type CustomModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  title: ReactNode | string;
  rightButtonLabel?: string;
  hideDefaultFooter?: boolean;
  customleftFooterComponent?: ReactNode;
  customRightFooterComponent?: ReactNode;
  children?: ReactNode;
  className?: string;
  onSubmit?: VoidFunction;
  isSubmitting?: boolean;
  contentClassName?: string;
};

export default (props: CustomModalProps) => {
  const {
    isShowModal: open,
    handleCloseModal: onClose,
    title,
    rightButtonLabel = 'Save',
    customRightFooterComponent,
    customleftFooterComponent,
    hideDefaultFooter = false,
    className,
    children,
    onSubmit,
    isSubmitting,
  } = props;

  return (
    <Modal open={open} onClose={onClose} className={className}>
      <ModalTitle>{title}</ModalTitle>
      {children}
      {!hideDefaultFooter && (
        <ModalFooter className="justify-items-center sm:justify-between">
          <div>
            {customRightFooterComponent ? (
              customRightFooterComponent
            ) : (
              <Btn onClick={onSubmit} variant="primary" isLoading={isSubmitting}>
                {rightButtonLabel}
              </Btn>
            )}
          </div>
          <div>{customleftFooterComponent}</div>
        </ModalFooter>
      )}
    </Modal>
  );
};
