import { localPoint } from '@vx/event';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear } from '@vx/scale';
import { Bar } from '@vx/shape';
import { useTooltip, useTooltipInPortal } from '@vx/tooltip';
import moment, { Moment } from 'moment';
import React from 'react';

import styles from './PracticeChart.module.scss';

import { DateUtils } from 'common/utils/date-utils';

type TTimechartDatum = {
  day: number;
  seconds: number;
};
type TChartProps = {
  values: number[];
  maxValue?: number;
};

const Chart = (props: TChartProps) => {
  const { values, maxValue = 3600 } = props;
  const { showTooltip, tooltipData, tooltipLeft, tooltipTop, tooltipOpen, hideTooltip } = useTooltip();

  const handleMouseOverBar = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
    datum: TTimechartDatum,
    date: string,
  ) => {
    const coords = localPoint(event);
    if (coords === null) return;
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: (
        <div style={{ textAlign: 'center' }}>
          {datum.seconds < 60 ? `${datum.seconds}s` : DateUtils.countdown()(datum.seconds)}
          <div>{date}</div>
        </div>
      ),
    });
  };

  const startDay = moment().subtract(30, 'days');

  let data = new Array(30).fill(null).map((_, i) => ({ day: i, seconds: 0 }));
  let inactive = true;

  if (values.length > 0) {
    data = values.map((h, i) => ({ day: i, seconds: h }));
    inactive = values.reduce((accumulator, value) => accumulator + value, 0) === 0;
  }

  const width = 215;
  const height = 30;

  const x = (d: TTimechartDatum) => d.day;
  const y = (d: TTimechartDatum) => d.seconds;

  const xScale = scaleBand().domain(data.map(x)).range([0, width]).paddingInner(2).paddingOuter(2);

  const yScale = scaleLinear()
    .range([height, 0])
    .domain([0, maxValue ? maxValue : Math.max(...data.map(y))])
    .clamp(true);

  const smalestHoursValue = 1;
  const smallestBarHeight = 4;

  const dateGraph = (from: Moment, num: number): string => {
    return from.clone().add(num, 'days').format('MMM Do');
  };

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  return (
    <div className={styles.chart}>
      <svg ref={containerRef} style={{ width, height }}>
        {/*<rect width={width} height={height} />*/}
        <Group>
          {data.map((d, i) => {
            let barHeight = height - (yScale(y(d)) as number);

            const isHoursNull = d.seconds === 0;
            if (inactive) barHeight = smallestBarHeight;
            else if (isHoursNull) barHeight = height - (yScale(smalestHoursValue) as number);

            return (
              <Bar
                key={i}
                width={5}
                height={Math.max(smallestBarHeight, barHeight)}
                x={xScale(x(d))}
                y={Math.min(height - smallestBarHeight, yScale(y(d)) as number)}
                fill={isHoursNull ? '#cccccc' : '#2264F2'}
                rx={2}
                ry={2}
                onMouseOver={e => d.seconds > 0 && handleMouseOverBar(e, d, dateGraph(startDay, i))}
                onMouseOut={hideTooltip}
              />
            );
          })}
        </Group>
      </svg>

      {tooltipOpen && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <span>{tooltipData as React.ReactNode}</span>
        </TooltipInPortal>
      )}
    </div>
  );
};

export default Chart;
