import { ReactNode, useEffect, useState } from 'react';

import styles from './ConfirmModal.module.scss';

import Btn from 'components/Btn';
import CustomModal from 'components/CustomModal';

type ConfirmModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  title: string;
  submitAction?: VoidFunction;
  className?: string;
  children?: ReactNode;
};

const ConfirmModal = (props: ConfirmModalProps) => {
  // eslint-disable-next-line
  const { isShowModal, handleCloseModal, title, submitAction, className, children } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [isShowModal]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (submitAction) {
      await submitAction();
      handleCloseModal();
    }
    setIsLoading(false);
  };

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={handleCloseModal}
      title={title || 'Delete student'}
      customleftFooterComponent={
        <Btn disabled={isLoading} onClick={() => handleCloseModal()} variant="outline-secondary">
          Cancel
        </Btn>
      }
      customRightFooterComponent={
        <Btn btnClassName="ms-2" isLoading={isLoading} variant="primary" onClick={() => handleSubmit()}>
          Yes
        </Btn>
      }
      className={styles['custom-modal-mb']}
    >
      <div className={styles['confirm-modal-body']}>{children}</div>
    </CustomModal>
  );
};

export default ConfirmModal;
