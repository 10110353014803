import { default as cs } from 'classnames';
import { Form, FormGroupProps, FormLabelProps } from 'react-bootstrap';
import { TagsInput, TagsInputProps } from 'react-tag-input-component';

import styles from './CustomTagInput.module.scss';

type CustomTagInputProps = {
  inputClassName?: string;
  labelClassName?: string;
  labelProps?: FormLabelProps;
  inputProps?: TagsInputProps;
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
};

const CustomTagInput = (props: CustomTagInputProps) => {
  const { className, inputClassName, inputProps, groupProps, error } = props;

  return (
    <Form.Group className={cs(styles['input-field-group'], className)} {...groupProps}>
      <div className={cs(styles['tag-input-wrapper'], error ? styles['is-invalid'] : '')}>
        <TagsInput
          {...inputProps}
          classNames={{
            input: cs(styles['input-field-input'], inputClassName),
          }}
        />
      </div>
      <Form.Control.Feedback type="invalid" className={error ? 'd-block' : ''}>
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default CustomTagInput;
