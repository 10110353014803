export const MODIFY_STEPS = 'USERGUIDE/MODIFY_STEPS';
export const HIDE_USERGUIDE = 'ASSIGNMENT/HIDE_USERGUIDE';

export const hideUserGuide = () => ({
  type: HIDE_USERGUIDE,
});

export const modifySteps = (data: string[]) => ({
  type: MODIFY_STEPS,
  completedSteps: data,
});
