import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector, useStore } from 'react-redux';

import type { AppDispatch, AppStore, RootState } from '../store';

export { default as useAPI } from './useAPI';
export { default as useForceUpdate } from './useForceUpdate';
export { default as useToggle } from './useToggle';
export { default as useCurrentPath } from './useCurrentPath';
export { default as useStudentByUserID } from './useStudentByUserID';

// https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;
