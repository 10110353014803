import { useQuery } from '@tanstack/react-query';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { HiChevronLeft, HiChevronRight, HiPlus } from 'react-icons/hi';
import { connect } from 'react-redux';

import { useAppSelector } from '../../hooks';

import styles from './Billing.module.scss';
import InvoiceModal from './InvoiceModal/InvoiceModal';
import InvoicesTable from './InvoicesTable';
import { EarningsFilterByPeriod } from './components';

import { fetchStudents } from 'actions/StudentActions';
import Btn from 'components/Btn';
import { BarChart } from 'components/Chart';
import { Contact, Invoice } from 'types';

type BillingProps = {
  fetchStudents: () => void;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface EarningsFilter {
  period?: string;
  freq?: string;
  date?: string;
}

const fetchEarnings = (params: EarningsFilter) => {
  const { getJson } = useAppSelector(state => state.Api.allApis);
  return useQuery({
    queryKey: ['billing', 'earnings', params],
    queryFn: () => getJson('/study/billing/earnings/', params),
  });
};

const Billing = (props: BillingProps) => {
  const students = useAppSelector(state => state.student.items as Record<string, Contact>);
  const { fetchStudents } = props;
  const [invoiceSelected, setInvoiceSelected] = useState<Invoice | undefined>(undefined);
  const [earningsFilter, setEarningsFilter] = useState<EarningsFilter>({ period: '1m', freq: 'd' });
  const { data: earnings, isFetching: isLoadingChart } = fetchEarnings(earningsFilter);

  const getDataChart = useMemo(() => {
    const items = earnings?.items || [];
    const labels = Object.keys(items) // YYYY-MM-DD or YYYY-MM-DD/YYYY-MM-DD
      .map(i => i.split('/'))
      .map(i => i.map(i => dayjs(i).format('DD/MM/YY')))
      .map(i => i.join(' - '));
    return {
      labels,
      dataset: Object.values(items as Record<'amount', number>[]).map(v => v.amount),
    };
  }, [earnings]);

  const [isShowInvoiceModal, setIsShowInvoiceModal] = useState<boolean>(false);

  const itemsSummaryLeft = [
    {
      id: 'paid',
      label: 'Paid',
      value: earnings?.summary.paid.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      id: 'unpaid',
      label: 'Unpaid',
      value: earnings?.summary.unpaid.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
  ];

  const itemsSummaryRight = [
    {
      id: 'students',
      label: 'Students',
      value: Object.values(students).filter(i => i.status !== 'archived').length,
    },
    {
      id: 'earned_this_month',
      label: 'Earned This month',
      value: earnings?.summary.month.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      id: 'earned_this_year',
      label: 'Earned This year',
      value: earnings?.summary.year.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
  ];

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const handleSetInvoiceSelected = async (invoice: Invoice | undefined) => {
    setInvoiceSelected(invoice);
    setIsShowInvoiceModal(true);
  };

  const previous = async () => {
    const newCurrentMonth = moment(earningsFilter.date || Date.now()).add(-1, 'months');
    setEarningsFilter({
      date: newCurrentMonth.format('YYYY-MM-DD'),
      period: '1m',
      freq: 'd',
    });
  };

  const next = async () => {
    const newCurrentMonth = moment(earningsFilter.date || Date.now()).add(1, 'months');
    if (moment().isAfter(newCurrentMonth)) {
      setEarningsFilter({
        date: newCurrentMonth.format('YYYY-MM-DD'),
        period: '1m',
        freq: 'd',
      });
    }
  };

  return (
    <div className={styles['billing-container']}>
      <div className={styles['summary-wrapper']}>
        <div className={styles['summary-header']}>
          <div className={styles['summary-header__left']}>Summary</div>
          <div className={styles['summary-header__right']}>
            <div className={styles['toolbar-wrapper']}>
              <div className={styles['toolbar-icon']} onClick={previous}>
                <HiChevronLeft size={26} />
              </div>
              <div className={styles['toolbar-label']}>
                {moment(earningsFilter.date || Date.now()).format('MMMM, YYYY')}
              </div>
              <div className={styles['toolbar-icon']} onClick={next}>
                <HiChevronRight size={26} />
              </div>
            </div>
            <div>
              <EarningsFilterByPeriod onChange={setEarningsFilter} />
            </div>
            <Btn
              variant="primary"
              btnClassName={styles['new-invoice-btn']}
              onClick={() => {
                setInvoiceSelected(undefined);
                setIsShowInvoiceModal(true);
              }}
            >
              <HiPlus size={20} className={styles['plus-icon']} /> Create Invoice
            </Btn>
          </div>
        </div>
        <Row className={styles['summary-overview']}>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5} className={styles['col-left-wrapper']}>
            <Row className={styles['summary-overview__left']}>
              {itemsSummaryLeft.map(item => (
                <Col className={styles['summary-item']} xs={6} key={item.id}>
                  <p className={styles['summary-item__label']}>{item.label}</p>
                  <p className={styles['summary-item__value']}>{item.value}</p>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7} className={styles['col-right-wrapper']}>
            <Row className={styles['summary-overview__right']}>
              {itemsSummaryRight.slice(0, 2).map(item => (
                <Col className={styles['summary-item']} xs={6} sm={6} md={6} lg={4} xl={4} xxl={4} key={item.id}>
                  <p className={styles['summary-item__label']}>{item.label}</p>
                  <p className={styles['summary-item__value']}>{item.value}</p>
                </Col>
              ))}
              <Col className={styles['summary-item']} xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                <p className={styles['summary-item__label']}>{itemsSummaryRight[2].label}</p>
                <p className={styles['summary-item__value']}>{itemsSummaryRight[2].value}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles['summary-chart']}>
          {isLoadingChart ? (
            <div className={styles['spinner-wrapper']}>
              <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
            </div>
          ) : (
            <BarChart
              labels={getDataChart.labels}
              dataset={getDataChart.dataset}
              className={styles['chart']}
              prefix="$"
            />
          )}
        </div>
      </div>
      <InvoicesTable handleSetInvoiceSelected={handleSetInvoiceSelected} />
      <InvoiceModal
        invoice={invoiceSelected}
        show={isShowInvoiceModal}
        onHide={() => {
          setIsShowInvoiceModal(false);
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchStudents: () => dispatch(fetchStudents()),
});

export default connect(null, mapDispatchToProps)(Billing);
