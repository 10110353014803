import { default as cs } from 'classnames';
import { MouseEvent, SyntheticEvent, forwardRef, useMemo } from 'react';
import { Form, FormGroupProps, FormLabelProps } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { MdOutlineClear } from 'react-icons/md';

import styles from './TimeInput.module.scss';

type CustomInputProps = {
  label?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  value?: string;
  error?: string;
};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(({ value, onClick, label, error }, ref) => {
  const randomId = useMemo(() => (Math.random() + 1).toString(36).substring(2), []);

  const handleOnFocus = () => {
    const element = document.getElementById(randomId) as HTMLInputElement | null;
    element?.classList?.add(styles['has-value']);
  };
  const handleOnBlur = () => {
    if (!value) {
      const element = document.getElementById(randomId) as HTMLInputElement | null;
      element?.classList?.remove(styles['has-value']);
    }
  };
  return (
    <>
      <Form.Control
        className={cs(styles['input-field-input'], !!value ? styles['has-value'] : '')}
        isInvalid={!!error}
        onClick={onClick}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={ref}
        id={randomId}
        value={value}
        readOnly
      />
      {label && (
        <label className={cs(styles['input-field-label'])} htmlFor={randomId}>
          {label}
        </label>
      )}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  );
});

type TimeInputProps = {
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
  label?: string;
  labelProps?: FormLabelProps;
  labelClassName?: string;
  value: string | number;
  onChange: (date: Date | null, event: SyntheticEvent<any, Event> | undefined) => void;
};

const TimeInput = (props: TimeInputProps) => {
  const { className, groupProps, error, label, value, onChange } = props;

  return (
    <Form.Group className={cs(styles['input-field-group'], className)} {...groupProps}>
      <DatePicker
        selected={(value && new Date(value)) || null}
        placeholderText=""
        onChange={onChange}
        showPopperArrow={false}
        customInput={<CustomInput label={label} error={error} />}
        calendarClassName={styles['calendar']}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm aa"
      />
      {!error &&
        (!value ? (
          <HiOutlineChevronDown size={14} className={styles['chevron-down-icon']} />
        ) : (
          <MdOutlineClear
            size={14}
            className={styles['chevron-down-icon']}
            onClick={e => {
              onChange(null, e);
            }}
          />
        ))}
    </Form.Group>
  );
};

export default TimeInput;
