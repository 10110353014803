import styles from './AssignmentsComponent.module.scss';

type AssignmentsComponentProps = {};

// eslint-disable-next-line
const AssignmentsComponent = (props: AssignmentsComponentProps) => {
  return (
    <div className={styles['assignments-wrapper']}>
      <p className={styles['assignments-header']}>Assignments</p>
      <div className={styles['assignments-body']}>
        <div className={styles['assignments-no-item']}>
          <p className={styles['no-item__title']}>No assignment added</p>
          <p className={styles['no-item__desc']}>
            When you add a student, they will receive an email asking them to download the Practice app.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssignmentsComponent;
