import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchStudents } from '../../../actions/StudentActions';
import { getTeacher } from '../../../actions/UserActions';
import { getOrgs } from '../organizationsSlice';

import OrganizationsForm from './OrganizationsForm';

export default ({ show, onHide, org, orgDispatch }) => {
  const url = '/studio/organizations';
  const dispatch = useDispatch();
  const { allApis } = useSelector(({ Api }) => Api);

  const handleCreate = values => {
    allApis.postJson(`${url}/`, values).then(org => {
      onHide();
      if (orgDispatch) {
        orgDispatch({ type: 'create', org });
      } else {
        dispatch(getOrgs());
      }
      dispatch(fetchStudents());
      dispatch(getTeacher());
    });
  };

  const handleDelete = org => {
    allApis.delJson(`${url}/${org.id}/`).then(() => {
      dispatch(getOrgs());
      dispatch(fetchStudents());
      dispatch(getTeacher());
      onHide();
    });
  };

  const handleUpdate = ({ id, name, address }) => {
    allApis.patchJson(`${url}/${id}/`, { name, address }).then(org => {
      if (orgDispatch) {
        dispatch({ type: 'update', org });
      } else {
        dispatch(getOrgs());
      }
      onHide();
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div className="modal-title">{org?.id ? 'Update' : 'Create'} organization</div>
      </Modal.Header>
      <Modal.Body className="p-4">
        <OrganizationsForm initialValues={org} onSubmit={org?.id ? handleUpdate : handleCreate} />
      </Modal.Body>
      <Modal.Footer className="text-right">
        {org?.id && (
          <button type="button" className="btn btn-danger" onClick={() => handleDelete(org)}>
            Delete
          </button>
        )}
        <button type="submit" form="organization-modal-form" className="btn btn-primary">
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
