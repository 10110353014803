import { ChevronLeftIcon, EllipsisHorizontalIcon, PlusIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import type { SingleValue } from 'react-select';
import Select from 'react-select';

import { createGroup, deleteGroup, updateGroup } from '../actions/GroupActions';
import { updateStudent } from '../actions/StudentActions';
import { GTAG_CREATE_GROUP, GTAG_DELETE_GROUP } from '../constants/gtag';
import { useAppDispatch, useAppSelector } from '../hooks';
import type { Contact, GroupType, OptionsType } from '../types';
import { gtag } from '../utils';

import Avatar from './Avatar';
import Btn from './Btn';
import DropdownSelect from './DropdownSelect';
import InputFloatingLabel from './InputFloatingLabel';
import { Modal, ModalBody, ModalFooter, ModalTitle } from './Modal';

type ListGroupModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  groups: GroupType[];
  students: Contact[];
  removeGroup: (id: number) => void;
};

const GroupFormContactItem = ({ contact, group }: { contact: Contact; group: GroupType }) => {
  const dispatch = useAppDispatch();
  const [busy, setBusy] = useState(false);

  const remove = () => {
    if (busy) return;
    setBusy(true);
    dispatch(
      updateStudent(contact.id, {
        groups: contact.groups?.filter(url => url != group.url),
      }),
    ).finally(() => setBusy(false));
  };

  return (
    <div className={classNames('px-2 flex items-center justify-between', { 'opacity-30': busy })}>
      <div className="flex items-center gap-2">
        <div key={contact.id} className="inline-block !size-6 rounded-full h-auto">
          <Avatar
            size={24}
            letter={contact.name?.substring(0, 1) || ''}
            url={contact.student?.picture}
            className="!size-full"
          />
        </div>
        {contact.name}
      </div>
      <XMarkIcon
        className={classNames('cursor-pointer size-5 text-dark/50 hover:text-dark/20', { 'animate-spin': busy })}
        onClick={remove}
      />
    </div>
  );
};

const GroupForm = ({ group, hide }: { group?: GroupType; hide: () => void }) => {
  const contacts = useAppSelector<Contact[]>(state => state.student.items);
  const dispatch = useAppDispatch();
  const { handleSubmit, register, reset } = useForm<GroupType>();
  const [busy, setBusy] = useState(false);
  const [isAddingStudent, setIsAddingStudent] = useState(false);
  const members = Object.values(contacts).filter(c => c.groups?.includes(group?.url));

  const onSubmit = async (data: GroupType) => {
    setBusy(true);

    if (group) {
      dispatch(updateGroup(data)).then(() => {
        hide();
        reset();
        setBusy(false);
      });
    } else {
      dispatch(createGroup(data)).then(() => {
        hide();
        reset();
        setBusy(false);
        gtag(GTAG_CREATE_GROUP);
      });
    }
  };

  const addStudent = (option: SingleValue<OptionsType>) => {
    const contact = contacts[Number(option?.value)];
    const groups = contact.groups ?? [];
    setIsAddingStudent(true);
    dispatch(updateStudent(contact.id, { groups: [...groups, group?.url] })).finally(() => {
      setIsAddingStudent(false);
    });
  };

  const options = Object.values(contacts)
    .filter(contact => !members.includes(contact))
    .map(contact => ({
      label: contact.name,
      value: contact.id,
    }));

  return (
    (group && (
      <>
        <ModalTitle>
          <span className="flex gap-x-2.5 items-center">
            <ChevronLeftIcon className="size-5 text-gray-600 cursor-pointer hover:text-dark" onClick={hide} />
            Edit group
          </span>
        </ModalTitle>
        <ModalBody className="flex flex-col gap-x-6 gap-y-3.5 py-3.5">
          <form id="group-form" className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-col gap-y-3.5">
              <input {...register('id')} type="hidden" value={group.id} />
              <InputFloatingLabel
                {...register('name', { required: true })}
                defaultValue={group.name}
                disabled={busy}
                label="Group name"
              />
              <Select
                placeholder={(options.length && 'Add students') || 'No more students'}
                isDisabled={isAddingStudent || !options.length}
                isLoading={isAddingStudent}
                options={options}
                value={null}
                onChange={addStudent}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                classNames={{
                  control: () =>
                    'border-greyBorder h-11 rounded-md focus-within:border-primary focus-within:ring-1 focus-within:ring-primary hover:border-greyBorder',
                  placeholder: () => 'text-greySecondary',
                }}
              />
            </div>
          </form>
        </ModalBody>
        {Boolean(members.length) && (
          <div className="px-6 !py-5 bg-grey flex flex-col gap-3.5">
            <span className="font-semibold">Students in the group</span>
            {members.map(contact => (
              <GroupFormContactItem key={contact.id} contact={contact} group={group} />
            ))}
          </div>
        )}
        <ModalFooter className="justify-between">
          <Btn isLoading={busy} variant="primary" form="group-form" type="submit">
            Save Changes
          </Btn>
          <Btn disabled={busy} variant="light" onClick={hide}>
            Cancel
          </Btn>
        </ModalFooter>
      </>
    )) || (
      <form className="flex justify-between !gap-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grow">
          <InputFloatingLabel {...register('name', { required: true })} disabled={busy} label="Enter a group name" />
        </div>
        <div className="flex !gap-3 flex-row-reverse">
          <Btn isLoading={busy} variant="primary" className="px-5" type="submit">
            Save
          </Btn>
          <Btn disabled={busy} variant="light" onClick={hide}>
            Cancel
          </Btn>
        </div>
      </form>
    )
  );
};

const ListGroupModal = (props: ListGroupModalProps) => {
  const { isShowModal: open, handleCloseModal: onClose, groups, students, removeGroup } = props;
  const [group, setGroup] = useState<GroupType>();
  const [showForm, setShowForm] = useState(false);

  const dropdownOptions = [
    {
      label: 'Edit group',
      action: (id: number) => {
        const group = groups.find(g => g.id == id);
        setGroup(group);
      },
    },
    {
      label: 'Delete group',
      action: (id: number) => {
        removeGroup(id);
        gtag(GTAG_DELETE_GROUP);
      },
    },
  ];

  const members = Object.fromEntries(
    groups.map(group => {
      const contacts = students.filter((s: Contact) => s.groups?.includes(group.url));
      return [group.id, contacts];
    }),
  );

  return (
    <Modal open={open} onClose={onClose}>
      {(group && <GroupForm group={group} hide={() => setGroup(undefined)} />) || (
        <>
          <ModalTitle>Manage Groups</ModalTitle>
          <ModalBody className="flex flex-col gap-6">
            {(groups.length &&
              groups.map(item => (
                <div key={item.id} className="flex justify-between items-center h-8 font-medium whitespace-nowrap">
                  <div className="text-base pe-4 truncate">{item.name}</div>
                  <div className="flex gap-1 items-center">
                    <div className="flex -space-x-1 overflow-hidden">
                      {members[item.id].slice(0, 3).map(contact => (
                        <div key={contact.id} className="inline-block !size-6 rounded-full ring-2 ring-white h-auto">
                          <Avatar
                            size={32}
                            letter={contact.name?.substring(0, 1) || ''}
                            url={contact.student?.picture}
                            className="!size-full"
                          />
                        </div>
                      ))}
                      {(members[item.id].slice(3).length && (
                        <div className="inline-block !size-6 rounded-full ring-2 ring-white h-auto">
                          <Avatar size={32} letter={`+${members[item.id].slice(3).length}`} className="!size-full" />
                        </div>
                      )) ||
                        null}
                    </div>
                    <div className="font-sm me-2 text-greySecondary">{members[item.id].length} members</div>
                    <DropdownSelect
                      className="cursor-pointer !shadow hover:bg-grey active:!shadow-none size-7 rounded flex justify-center items-center"
                      id={item.id}
                      customToggleComponent={<EllipsisHorizontalIcon className="size-6" />}
                      options={dropdownOptions}
                    />
                  </div>
                </div>
              ))) || (
              <div className="flex flex-col text-center gap-4">
                <UsersIcon className="text-primary size-11 self-center" />
                <div className="flex flex-col gap-1">
                  <span className="text-base font-semibold">You don't have any groups yet</span>
                  <span className="text-[0.8125rem] text-gray-600">
                    Keep students organized by adding them to groups (ex “Strings” or “Beginners”. Groups enable
                    filtering, messaging, and assigning homework to all students in a group at once.
                  </span>
                </div>
              </div>
            )}
            {(showForm && <GroupForm hide={() => setShowForm(false)} />) || (
              <div className={classNames('flex', { 'justify-center': !groups.length })}>
                <Btn variant="outline" onClick={() => setShowForm(true)}>
                  <PlusIcon />
                  Add group
                </Btn>
              </div>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  students: Object.values(state.student.items) as Contact[],
});

const mapDispatchToProps = (dispatch: any) => ({
  removeGroup: (id: number) => dispatch(deleteGroup(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListGroupModal);
