import { useEffect, useState } from 'react';

import { useAppSelector } from '../../hooks';

interface Link {
  href: string;
  method: string;
  rel: string;
}

interface MerchantResponse {
  id: string;
  status: boolean;
  message: string;
}

export default () => {
  const { getJson } = useAppSelector(state => state.Api.allApis);
  const [merchant, setMerchant] = useState<string>();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    getJson('/billing/paypal/merchant/').then((resp: MerchantResponse) => {
      setMerchant(resp.id);
    });
  }, [getJson]);

  useEffect(() => {
    if (merchant === null) {
      getJson('/billing/paypal/links/').then((resp: Record<'links', Link[]>) => {
        setUrl(resp.links.find(link => link.rel === 'action_url')?.href);
      });
    }
  }, [getJson, merchant]);

  if (merchant) {
    return 'You can accept payments via PayPal.';
  }

  if (!url) {
    return 'Loading...';
  }

  return (
    <>
      <p>
        <a data-paypal-button="true" href={`${url}&displayMode=minibrowser`} target="PPFrame">
          Sign in for PayPal
        </a>
      </p>
      <p className="text-secondary">To receive payments electronically, please connect your Paypal account.</p>
      <script src="https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js" />
    </>
  );
};
