import { FETCH_ASSIGNMENTS, FETCH_ASSIGNMENTS_FAIL, FETCH_ASSIGNMENTS_SUCCESS } from '../actions/AssignmentActions';

import { TReduxAction } from 'types';

const initialState = {
  isFetching: false,
  items: [],
};

export default (state = initialState, action: TReduxAction) => {
  switch (action.type) {
    case FETCH_ASSIGNMENTS: {
      return { ...state, isFetching: true };
    }

    case FETCH_ASSIGNMENTS_FAIL: {
      return { ...state, isFetching: false };
    }

    case FETCH_ASSIGNMENTS_SUCCESS: {
      const assignments = action.payload.data.results;

      return { ...state, items: assignments, isFetching: false };
    }

    default:
      return state;
  }
};
