import { default as cs } from 'classnames';
import {
  TaskSquare as AssignmentsIcon,
  MoneySend as BillingIcon,
  Calendar as CalendarIcon,
  Category2 as HomeIcon,
  LogoutCurve as LogoutIcon,
  ArchiveBook as NotesIcon,
  Setting as SettingsIcon,
  Profile2User as StudentsIcon,
} from 'iconsax-react';
import { HTMLProps, MouseEvent, forwardRef } from 'react';
import { Dropdown, Offcanvas } from 'react-bootstrap';
import { HiOutlineMenu } from 'react-icons/hi';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import Breadcrumbs from '../breadcrumbs';

import styles from './Header.module.scss';

import { logOut } from 'actions/AuthActions';
import { useToggle } from 'hooks';
import useCurrentPath from 'hooks/useCurrentPath';
import musopenIcon from 'theme/img/musopen-icon.png';
import organizationIcon from 'theme/img/organization-icon.png';
import practiceIcon from 'theme/img/practice-icon.png';
import studioIcon from 'theme/img/studio-icon.png';

type CustomToggleProps = HTMLProps<HTMLDivElement> & {
  avatar: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
};

const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(({ onClick, avatar }, ref) => (
  <div
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    className={styles['avatar']}
    style={{ backgroundImage: `url(${avatar})` }}
  ></div>
));

type CustomDropDownItemProps = {
  label?: string;
  subLabel?: string;
  picture?: string;
  type?: string;
  route: string;
  link: string;
};

const CustomDropDownItem = (props: CustomDropDownItemProps) => {
  const { picture, label, subLabel, type = '', route, link } = props;
  if (type === 'divider') {
    return <Dropdown.Divider />;
  }

  const ChildComponent = (
    <>
      <img src={picture} className={styles['dropdown-item-picture']} />
      <div className={styles['dropdown-labels']}>
        <div className={styles['main-label']}>{label}</div>
        {subLabel && <div className={styles['sub-label']}>{subLabel}</div>}
      </div>
    </>
  );

  return (
    <Dropdown.Item eventKey="4" className={styles['dropdown-item']} as="div">
      {type === 'route' ? (
        <Link to={route} className={styles['dropdown-item-link']}>
          {ChildComponent}
        </Link>
      ) : type === 'link' ? (
        <a href={link} target="_blank" className={styles['dropdown-item-link']}>
          {ChildComponent}
        </a>
      ) : (
        ''
      )}
    </Dropdown.Item>
  );
};

type HeaderProps = {
  user: any;
  dispatch: Dispatch;
};

const Header = (props: HeaderProps) => {
  const { user, dispatch } = props;
  const { route } = useCurrentPath();
  const [show, toggleShow] = useToggle();
  const navigate = useNavigate();

  const dropdownItems = [
    {
      label: 'Studio',
      subLabel: 'Manage your students',
      picture: studioIcon,
      type: 'route',
      route: '/home',
      link: '',
    },
    {
      label: 'Musopen',
      subLabel: 'Free music and sheet music',
      picture: musopenIcon,
      type: 'link',
      route: '',
      link: 'https://musopen.org/',
    },
    {
      label: 'Practice',
      subLabel: 'Track practice time',
      picture: practiceIcon,
      type: 'link',
      route: '',
      link: 'https://musopen.org/apps/practice/',
    },
    {
      label: '',
      subLabel: '',
      picture: '',
      type: 'divider',
      route: '',
      link: '',
    },
    {
      label: 'My Profile',
      subLabel: '',
      picture: user?.picture || '',
      type: 'route',
      route: '/settings',
      link: '',
    },
    {
      label: 'Organizations',
      subLabel: '',
      picture: organizationIcon,
      type: 'route',
      route: '/settings/organizations',
      link: '',
    },
  ];

  const handleLogout = async () => {
    dispatch(logOut());
  };

  const items = [
    {
      title: 'Home',
      route: '/home',
      matchingRoutes: ['/home'],
      icon: HomeIcon,
    },
    {
      title: 'Students',
      route: '/students',
      matchingRoutes: ['/students', '/students/:id'],
      icon: StudentsIcon,
    },
    /*
    {
      title: 'Messages',
      route: '/messages',
      matchingRoutes: ['/messages'],
      icon: MessagesIcon,
    },
     */
    {
      title: 'Assignments',
      route: '/assignments',
      matchingRoutes: ['/assignments'],
      icon: AssignmentsIcon,
    },
    {
      title: 'Notes',
      route: '/notes',
      matchingRoutes: ['/notes'],
      icon: NotesIcon,
    },
    {
      title: 'Billing',
      route: '/billing',
      matchingRoutes: ['/billing'],
      icon: BillingIcon,
    },
    {
      title: 'Calendar',
      route: '/calendar',
      matchingRoutes: ['/calendar'],
      icon: CalendarIcon,
    },
    {
      title: 'Settings',
      route: '/settings',
      matchingRoutes: ['/settings'],
      icon: SettingsIcon,
      asLink: true,
    },
    {
      title: 'Log out',
      matchingRoutes: [''],
      icon: LogoutIcon,
      asLink: false,
      action: () => handleLogout(),
    },
  ];

  return (
    <div className={styles['header']}>
      <div className={styles['left-wrapper']}>
        <HiOutlineMenu size={20} className={styles['icon-menu']} onClick={toggleShow} />
        <Offcanvas show={show} onHide={toggleShow}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={styles['menu-mb-items']}>
              {items.map(({ icon: Icon, ...item }, index) => (
                <Link
                  key={index}
                  to={item.route || ''}
                  className={cs(
                    styles['item-link-wrapper'],
                    item.matchingRoutes.includes(route.path) ? styles['active'] : '',
                  )}
                  onClick={toggleShow}
                >
                  <div className={styles['icon-wrapper']}>
                    <Icon variant={item.matchingRoutes.includes(route.path) ? 'Bold' : 'Linear'} />
                  </div>
                  <div className={cs(styles['item'], item.matchingRoutes.includes(route.path) ? styles['active'] : '')}>
                    {item.title}
                  </div>
                </Link>
              ))}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        {/* <LeftArrowSVG className={styles['left-arrow-icon']} /> */}

        <div className="text-base font-semibold">
          <Breadcrumbs />
        </div>
      </div>
      <div className={styles['right-wrapper']}>
        <div
          className={styles['noti-wrapper']}
          onClick={() => {
            navigate('/feed/');
          }}
        >
          {/* <BellIcon className={styles['noti-icon']} /> */}
          {/* <div className={styles['badge']}>9</div> */}
        </div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-avatar" avatar={user?.picture}></Dropdown.Toggle>
          <Dropdown.Menu className={styles['dropdown-menu']}>
            {dropdownItems.map((item, index) => (
              <CustomDropDownItem key={index} {...item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  updates: state.updates,
  user: state.user,
});

export default connect(mapStateToProps)(Header);
