import { default as cs } from 'classnames';
import { EventProps } from 'react-big-calendar';
import { FaUserPlus } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';

import styles from './EventWrapperCalendar.module.scss';

import { LessonEvent } from 'types';

const EventWrapperCalendar = (props: EventProps<LessonEvent>) => {
  const { event } = props;
  return (
    <div className={cs(styles['wrapper-event'])}>
      {event?.isGroup && <HiUserGroup size={14} className={styles['event__icon-group']} />}
      {event?.isRequest && <FaUserPlus size={14} className={styles['event__icon-request']} />}
      {event?.title}
    </div>
  );
};

export default EventWrapperCalendar;
