import { LessonEvent, TReduxPayload } from 'types';

export const FETCH_TEACHER_SCHEDULE = 'SCHEDULE/FETCH_TEACHER_SCHEDULE';
export const FETCH_TEACHER_SCHEDULE_SUCCESS = 'SCHEDULE/FETCH_TEACHER_SCHEDULE_SUCCESS';
export const FETCH_TEACHER_SCHEDULE_FAIL = 'SCHEDULE/FETCH_TEACHER_SCHEDULE_FAIL';

export const UPDATE_TEACHER_SCHEDULE = 'SCHEDULE/UPDATE_TEACHER_SCHEDULE';
export const UPDATE_TEACHER_SCHEDULE_SUCCESS = 'SCHEDULE/UPDATE_TEACHER_SCHEDULE_SUCCESS';
export const UPDATE_TEACHER_SCHEDULE_FAIL = 'SCHEDULE/UPDATE_TEACHER_SCHEDULE_FAIL';

export const CHANGE_TEACHER_SCHEDULE = 'SCHEDULE/CHANGE_TEACHER_SCHEDULE';
export const CHANGE_EVENT_TYPE = 'SCHEDULE/CHANGE_EVENT_TYPE';

export const FETCH_LESSONS = 'SCHEDULE/FETCH_LESSONS';
export const FETCH_LESSONS_SUCCESS = 'SCHEDULE/FETCH_LESSONS_SUCCESS';
export const FETCH_LESSONS_FAIL = 'SCHEDULE/FETCH_LESSONS_FAIL';

export const UPDATE_LESSON = 'SCHEDULE/UPDATE_LESSON';
export const UPDATE_LESSON_SUCCESS = 'SCHEDULE/UPDATE_LESSON_SUCCESS';
export const UPDATE_LESSON_FAIL = 'SCHEDULE/UPDATE_LESSON_FAIL';

export const CREATE_LESSON = 'SCHEDULE/CREATE_LESSON';
export const CREATE_LESSON_SUCCESS = 'SCHEDULE/CREATE_LESSON_SUCCESS';
export const CREATE_LESSON_FAIL = 'SCHEDULE/CREATE_LESSON_FAIL';

export const CHANGE_LESSON = 'SCHEDULE/CHANGE_LESSON';

export const FETCH_TEACHER_LESSONS = 'SCHEDULE/FETCH_TEACHER_SCHEDULE';
export const FETCH_TEACHER_LESSONS_SUCCESS = 'SCHEDULE/FETCH_TEACHER_SCHEDULE_SUCCESS';
export const FETCH_TEACHER_LESSONS_FAIL = 'SCHEDULE/FETCH_TEACHER_SCHEDULE_FAIL';

export const fetchTeacherSchedule = () => ({
  types: [FETCH_TEACHER_SCHEDULE, FETCH_TEACHER_SCHEDULE_SUCCESS, FETCH_TEACHER_SCHEDULE_FAIL],
  payload: {
    request: {
      method: 'GET',
      url: '/teacher-week-schedule/',
    },
  },
});

export const updateTeacherSchedule = (id: number, data: TReduxPayload) => ({
  types: [UPDATE_TEACHER_SCHEDULE, UPDATE_TEACHER_SCHEDULE_SUCCESS, UPDATE_TEACHER_SCHEDULE_FAIL],
  payload: {
    request: {
      method: 'PATCH',
      url: `/teacher-week-schedule/${id}/`,
      data,
    },
  },
});

export const changeTeacherSchedule = (data: TReduxPayload) => ({
  type: CHANGE_TEACHER_SCHEDULE,
  data,
});

export const changeEventType = (data: TReduxPayload) => ({
  type: CHANGE_EVENT_TYPE,
  data,
});

export const fetchLessons = (params: Record<string, string> = {}) => ({
  type: FETCH_LESSONS,
  payload: {
    request: {
      method: 'GET',
      url: '/lessons/',
      params: { embed: 'student', limit: 100, ...params },
    },
  },
});

export const updateLesson = (id: string | number | undefined, data: LessonEvent) => ({
  types: [UPDATE_LESSON, UPDATE_LESSON_SUCCESS, UPDATE_LESSON_FAIL],
  payload: {
    request: {
      method: 'PATCH',
      url: `/lessons/${id}/`,
      data,
    },
  },
});

export const createLesson = (data: LessonEvent) => ({
  types: [CREATE_LESSON, CREATE_LESSON_SUCCESS, CREATE_LESSON_FAIL],
  payload: {
    request: {
      method: 'POST',
      url: '/lessons/',
      data,
    },
  },
});

export const changeLesson = (data: LessonEvent) => ({
  type: CHANGE_LESSON,
  data,
});

export const fetchTeacherLessons = (id: string) => ({
  types: [FETCH_TEACHER_LESSONS, FETCH_TEACHER_LESSONS_SUCCESS, FETCH_TEACHER_LESSONS_FAIL],
  payload: {
    request: {
      method: 'GET',
      url: `/lessons/calendar/?teacher=${id}/`,
    },
  },
});
