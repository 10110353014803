import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useAppSelector } from '../../../hooks';

import { Contact, Invoice } from 'types';

yup.setLocale({
  mixed: {
    required: 'Required', // unify an error message
  },
});

const schema = yup.object().shape({
  contact: yup.string().required(),
  date: yup.string().required(),
  description: yup.string(),
  amount: yup.number().required().min(0, 'Amount must be positive').typeError('Required'),
  payer: yup.string().oneOf(['student', 'parent']),
  payer_email: yup.string().when('payer', {
    is: 'parent',
    then: schema => schema.email('Invalid email').required(),
    otherwise: schema => schema.optional(),
  }),
  send: yup.boolean().default(false),
});

type FormData = yup.InferType<typeof schema>;

interface InvoiceFormPropsType {
  invoice?: Invoice;
  setModalSubmitting: Dispatch<SetStateAction<boolean>>;
  onHide: () => void;
}

export default ({ invoice, setModalSubmitting, onHide }: InvoiceFormPropsType) => {
  const queryClient = useQueryClient();
  const api = useAppSelector(state => state.Api.allApis);
  const students = useAppSelector(state => state.student.items as Record<string, Contact>);
  const contacts = Object.values(students).map(s => ({ label: s.name, value: s.url }));

  const mutation = useMutation({
    mutationFn: ({ id, data }: { id?: number; data: FormData }) => {
      if (id) {
        return api.patchJson(`/study/billing/invoices/${id}/`, data);
      } else {
        return api.postJson('/study/billing/invoices/', data);
      }
    },
    onMutate: () => {
      setModalSubmitting(true);
    },
    onError: () => {
      toast.error('An error occurred while processing your request.');
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['billing'], refetchType: 'all' });
    },
    onSettled: () => {
      setModalSubmitting(false);
      onHide();
    },
  });

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: invoice && schema.cast({ ...invoice, status: 'UNPAID' }),
  });

  const onSubmit = async (data: FormData) => {
    const student = Object.values(students).find(s => s.url == data.contact);
    mutation.mutate({
      id: invoice?.id,
      data: {
        ...data,
        ...(data.payer == 'student' && { payer_email: student?.email }),
      },
    });
  };

  return (
    <Form id="invoice-form" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Student*:
        </Form.Label>
        <Col sm={8}>
          <Select
            {...register('contact')}
            value={contacts.find(c => c.value == watch('contact'))}
            options={contacts}
            placeholder="Start typing student..."
            onChange={(option: SingleValue<any>) => {
              setValue('contact', option?.value);
            }}
          />
          {errors.contact && <Form.Text className="text-danger">{errors.contact.message}</Form.Text>}
        </Col>
      </FormGroup>

      <FormGroup as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Date*:
        </Form.Label>
        <Col sm={8}>
          <Form.Control {...register('date')} type="date" />
          {errors.date && <Form.Text className="text-danger">{errors.date.message}</Form.Text>}
        </Col>
      </FormGroup>

      <FormGroup as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Description:
        </Form.Label>
        <Col sm={8}>
          <Form.Control {...register('description')} as="textarea" rows={3} />
        </Col>
      </FormGroup>

      <FormGroup as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Amount*:
        </Form.Label>
        <Col sm={8}>
          <Form.Control {...register('amount')} type="number" />
          {errors.amount && <Form.Text className="text-danger">{errors.amount.message}</Form.Text>}
        </Col>
      </FormGroup>

      <FormGroup as={Row} className="mb-3">
        <Form.Label column sm={4}>
          Send invoice to:
        </Form.Label>
        <Col sm={8}>
          <div>
            <Form.Check {...register('payer')} type="radio" value="student" defaultChecked={true} label="Student" />
          </div>
          <div>
            <Form.Check {...register('payer')} type="radio" value="parent" label="Parent" />
            {watch('payer') === 'parent' && (
              <div className="mt-3">
                <Form.Control {...register('payer_email')} type="email" placeholder="Parent's email address..." />
                {errors.payer_email && <Form.Text className="text-danger">{errors.payer_email.message}</Form.Text>}
              </div>
            )}
          </div>
        </Col>
      </FormGroup>

      <FormGroup>
        <Col xs={12}>
          <Controller
            name="send"
            control={control}
            render={({ field }) => (
              <Form.Check {...field} type="checkbox" value="send" label="Send payment request immediately" />
            )}
          />
        </Col>
      </FormGroup>
    </Form>
  );
};
