import { Breadcrumb } from 'react-bootstrap';
import { Link, UIMatch, useMatches } from 'react-router-dom';

interface Handle {
  crumb: (data: any) => React.ReactNode;
}

export default () => {
  const matches = useMatches() as UIMatch<unknown, Handle>[];
  const crumbs = matches
    .filter(match => !!match.handle?.crumb)
    .map(match => ({
      label: match.handle.crumb!(match.data),
      href: match.pathname,
    }));

  return (
    <Breadcrumb bsPrefix="breadcrumb m-0">
      {crumbs.map(({ label, href }, index) => (
        <Breadcrumb.Item
          active={index == crumbs.length - 1}
          linkAs={Link}
          linkProps={{
            className: 'no-underline text-body',
            to: href,
          }}
          key={href}
        >
          {label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
