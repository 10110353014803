import * as Sentry from '@sentry/browser';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import packageJson from '../package.json';

import Router from './router';
import { persistor, queryClient, store } from './store';
import Loader from './utils/loader';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense>
          <Loader>
            <Router />
          </Loader>
        </Suspense>
      </PersistGate>
    </Provider>
  </QueryClientProvider>
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7fe1bb56570b48448a5fd1eee0ef4595@o309968.ingest.sentry.io/5201799',
    beforeSend(event, hint) {
      const exception: any = hint?.originalException;
      if (exception && exception?.error) {
        if (
          typeof exception?.error?.response === 'undefined' ||
          [0, 401, 429].includes(exception?.error?.response?.status)
        ) {
          return null;
        }
      }
      return event;
    },
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    release: packageJson.name + '@' + packageJson.version,
  });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as Element);

root.render(<App />);
