import { yupResolver } from '@hookform/resolvers/yup';
import { Form, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Logo from '../components/logo';
import { config } from '../utils/config';

import { setToken } from 'actions/AuthActions';

const schema = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  password1: yup.string().required(),
  password2: yup.string().required(),
});

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, register, formState, setValue, watch, setError } = useForm({ resolver: yupResolver(schema) });

  watch((value, { name }) => {
    if (name == 'password1') setValue('password2', value.password1 ?? '');
  });

  return (
    <>
      <header className="absolute w-full py-3 md:py-6 z-10">
        <div className="container flex items-center">
          <a href="" className="no-underline">
            <Logo />
          </a>
        </div>
      </header>
      <main>
        <section className="h-screen min-h-[610px] relative">
          <div className="container h-full flex flex-col justify-center">
            <div className="md:max-w-[calc(50%-16px)] w-full flex justify-center">
              <div className="text-[13px] w-full sm:!w-[400px]">
                <h3 className="text-2xl text-black font-medium mb-1.5">Create a Adagio account</h3>
                <p className="text-greySecondary leading-5">
                  Already have an account?{' '}
                  <a href="signin" className="text-primary font-medium">
                    Log in
                  </a>
                </p>
                <Form
                  action={config.api_endpoint + '/auth/registration/'}
                  onSuccess={({ response }) => {
                    response.json().then(data => {
                      dispatch(setToken(data.token));
                      navigate('/');
                    });
                  }}
                  onError={({ response }) => {
                    response?.json().then((data: string[][]) => {
                      Object.entries(data).map(([name, messages]) => {
                        messages.map(message => {
                          setError(name as keyof typeof schema.fields, { type: 'server', message });
                        });
                      });
                    });
                  }}
                  className="mt-8 text-greySecondary flex flex-col gap-3"
                  control={control}
                >
                  <div className="flex gap-2 max-[576px]:flex-col">
                    <div className="flex flex-col gap-1.5 flex-1">
                      <label htmlFor="firstName">First name</label>
                      <input
                        {...register('first_name')}
                        type="text"
                        id="firstName"
                        placeholder="First name"
                        className="w-full h-11 px-3.5 rounded-md border border-greyBorder outline-none placeholder:text-gray-500 text-gray-900"
                      />
                    </div>
                    <div className="flex flex-col gap-1.5 flex-1">
                      <label htmlFor="lastName">Last name</label>
                      <input
                        {...register('last_name')}
                        type="text"
                        id="lasttName"
                        placeholder="Last name"
                        className="w-full h-11 px-3.5 rounded-md border border-greyBorder outline-none placeholder:text-gray-500 text-gray-900"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <label htmlFor="email">Email address</label>
                    <input
                      {...register('email')}
                      type="email"
                      id="email"
                      placeholder="Email address"
                      className="w-full h-11 px-3.5 rounded-md border border-greyBorder outline-none placeholder:text-gray-500 text-gray-900"
                    />
                    {formState.errors.email && <span className="text-red-500">{formState.errors.email.message}</span>}
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <label htmlFor="password">Password</label>
                    <input
                      {...register('password1', { min: 6 })}
                      type="password"
                      id="password"
                      placeholder="••••••••"
                      className="w-full h-11 px-3.5 rounded-md border border-greyBorder outline-none placeholder:text-gray-500 text-gray-900"
                    />
                    {formState.errors.password1 && (
                      <span className="text-red-500">{formState.errors.password1.message}</span>
                    )}
                    <input {...register('password2')} type="hidden" />
                  </div>
                  <button
                    type="submit"
                    className="h-10 rounded-[40px] py-2.5 px-4 text-center mt-5 bg-primary text-white disabled:bg-opacity-50"
                    disabled={!formState.isValid || formState.isSubmitting}
                  >
                    {formState.isSubmitting ? 'Signing up...' : 'Sign up'}
                  </button>
                </Form>
                <p className="text-center text-gray-600 leading-5 mt-3">
                  By creating an account, you agree that you have read and accepted our{' '}
                  <a href="https://musopen.org/page/tos/" className="font-medium underline">
                    Terms of Use
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="intro-hero-image bg-intro-hero bg-center bg-cover bg-no-repeat max-w-[50%] absolute right-0 top-0 w-full h-full hidden md:!block"></div>
        </section>
      </main>
    </>
  );
};
