import { CheckIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { Modal } from '../../components/Modal';

import DashboardImg from './img/dashboard.webp';
import PhoneScreenshot1 from './img/phone-screenshot-1-1.webp';
import PhoneScreenshot2 from './img/phone-screenshot-2-2.webp';
import PhoneScreenshot3 from './img/phone-screenshot-3-3.webp';
import Promotion1 from './img/promotion/1.webp';
import Promotion2 from './img/promotion/2.webp';
import Promotion3 from './img/promotion/3.webp';
import Promotion4 from './img/promotion/4.webp';
import Testimonials1 from './img/testimonials/1.webp';
import Testimonials2 from './img/testimonials/2.webp';
import Testimonials3 from './img/testimonials/3.webp';
import Testimonials4 from './img/testimonials/4.webp';
import Testimonials5 from './img/testimonials/5.webp';
import Testimonials6 from './img/testimonials/6.webp';
import Testimonials7 from './img/testimonials/7.webp';
import Testimonials8 from './img/testimonials/8.webp';
import Testimonials9 from './img/testimonials/9.webp';

import './index.css';

const Slider = () => {
  const [active, setActive] = useState<number>(0);
  const slides = [
    { label: 'Practice', img: DashboardImg },
    { label: 'Learn', img: 'https://placehold.co/1440x920?text=Learn' },
    { label: 'Homework', img: 'https://placehold.co/1440x920?text=Homework' },
    { label: 'Billing', img: 'https://placehold.co/1440x920?text=Billing' },
    { label: 'Calendar', img: 'https://placehold.co/1440x920?text=Calendar' },
    { label: 'Communicate', img: 'https://placehold.co/1440x920?text=Communicate' },
    { label: 'Organizations', img: 'https://placehold.co/1440x920?text=Organizations' },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(a => ++a % slides.length);
    }, 5000);
    return () => clearTimeout(timer);
  }, [active, slides.length]);

  return (
    <div className="max-w-[1560px] mx-auto relative">
      <div className="flex items-center justify-start lg:justify-center pt-11 md:mt-[84px] max-lg:px-4 pb-8 md:pb-9 gap-4 text-sm md:text-lg font-medium text-dark overflow-x-scroll scroll-smooth snap-x snap-mandatory no-scrollbar whitespace-nowrap">
        {slides.map((slide, i) => (
          <span key={i} onClick={() => setActive(i)} className="sliderNavItem relative cursor-pointer">
            <span
              className={classNames('opacity-60 px-4 py-2.5 inline-block hover:opacity-100', {
                'opacity-100': i == active,
              })}
            >
              {slide.label}
            </span>
            <span className="absolute w-full h-1 rounded-full bg-[#2851A9] bg-opacity-10 left-0 -bottom-1">
              {i == active && <span className="bg-primary h-full block rounded-full w-0 animate-progress" />}
            </span>
          </span>
        ))}
      </div>
      <ol className="flex overflow-hidden no-scrollbar pt-[1px] stack-images max-md:pb-4 relative max-lg:px-4">
        {slides.map((slide, i) => (
          <li
            key={i}
            className={classNames('z-10 relative flex-grow-0 flex-shrink-0 basis-full outline-none', {
              hidden: i != active,
            })}
          >
            <div className="max-w-[1280px] max-h-[760px] mx-auto relative rounded-md md:rounded-3xl bg-white shadow-slider border border-[#0E3F7E] border-opacity-10 overflow-hidden">
              <img src={slide.img} alt="image" className="mx-auto w-full" />
            </div>
          </li>
        ))}
      </ol>
      <div className="absolute w-full h-[200px] bottom-0 left-0 bg-gradient-slider z-10 max-md:hidden"></div>
    </div>
  );
};

export default () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <main>
      <section className="bg-hero-section max-md:bg-hero-position bg-right-top bg-no-repeat text-gray-600 pt-[76px] md:pt-36 text-center relative">
        <div className="container">
          <div className="max-w-[930px] mx-auto max-md:pt-9">
            <h1 className="font-Jakarta text-[32px] md:text-[86px] leading-10 md:leading-[86px] font-medium mb-4 md:mb-6 max-md:px-3 max-sm:px-0">
              Make music teaching easy with Adagio
            </h1>
            <p className="text-base md:text-lg">
              Manage your teaching workflow by adding students, scheduling lessons and performances,{' '}
              <br className="hidden sm:block" /> assigning homework, and handling billing all in one streamlined
              platform
            </p>
            <div className="mt-6 gap-2 md:gap-4 flex justify-center max-md:flex-col max-md:items-center">
              <a href="signup" className="py-4 px-8 font-medium text-base bg-primary text-white rounded-[40px]">
                Get started for free
              </a>
              <a
                role="button"
                className="py-4 px-8 font-medium text-base bg-[#3066B0] bg-opacity-10 text-gray-800 rounded-[40px] flex items-center gap-2"
                onClick={() => setShowModal(true)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.7817 8.78296C10.4498 8.55666 10 8.79436 10 9.19607V14.8039C10 15.2056 10.4498 15.4433 10.7817 15.217L14.8941 12.4131C15.1852 12.2146 15.1852 11.7854 14.8941 11.5869L10.7817 8.78296Z"
                  />
                </svg>
                Watch demo
              </a>
            </div>
          </div>
        </div>

        <Slider />
      </section>

      <section className="py-20 sm:py-36">
        <div className="container">
          <div className="max-w-[844px] text-dark">
            <h2 className="font-Jakarta text-[28px] sm:text-[48px] leading-9 sm:leading-[56px] mb-3 sm:mb-4 font-medium tracking-tight">
              Turn lesson management <br className="hidden sm:block" /> into a melody with Adagio
            </h2>
            <p className="text-sm sm:text-lg">
              You don’t have to switch solutions to benefit from our user-friendly software suite. Bring your{' '}
              <br className="hidden sm:block" /> current tools with you, and we’ll connect your data via API—it’s our
              behind-the-scenes magic.
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-7 mt-9 lg:mt-[84px]">
            <div>
              <div className="bg-secondary rounded-[10px] h-[420px] sm:h-[460px] overflow-hidden relative">
                <img src={PhoneScreenshot1} alt="image" loading="lazy" className="mx-auto" />
                <div className="absolute w-full h-[100px] bottom-0 left-0 bg-fade-gradient-medium"></div>
              </div>
              <div className="py-[14px] text-base text-dark">
                <p className="font-medium mb-1">Track student practice time</p>
                <p className="opacity-60 text-sm sm:text-base">
                  See the practice time for an entire <br /> class at a glance.
                </p>
              </div>
            </div>
            <div>
              <div className="bg-secondary rounded-[10px] h-[420px] sm:h-[460px] pt-[30px] overflow-hidden relative">
                <img src={PhoneScreenshot2} alt="image" loading="lazy" className="mx-auto" />
                <div className="absolute w-full h-[100px] bottom-0 left-0 bg-fade-gradient-medium"></div>
              </div>
              <div className="py-[14px] text-base text-dark">
                <p className="font-medium mb-1">A calendar for you and your students</p>
                <p className="opacity-60 text-sm sm:text-base">
                  Automate scheduling, cancellations, and <br /> calendar management.
                </p>
              </div>
            </div>
            <div>
              <div className="bg-secondary rounded-[10px] h-[420px] sm:h-[460px] overflow-hidden relative">
                <img src={PhoneScreenshot3} alt="image" loading="lazy" className="mx-auto" />
                <div className="absolute w-full h-[100px] bottom-0 left-0 bg-fade-gradient-medium"></div>
              </div>
              <div className="py-[14px] text-base text-dark">
                <p className="font-medium mb-1">Automate billing for lessons</p>
                <p className="opacity-60 text-sm sm:text-base">
                  Invoices, payment tracking, reminders and <br /> online payment options.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-[1560px] mx-auto py-[84px] rounded-3xl bg-fade-gradient">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-7">
            <div className="text-dark max-md:mb-[84px]">
              <h2 className="font-Jakarta text-[28px] sm:text-[48px] leading-9 sm:leading-[56px]  mb-3 sm:mb-4 font-medium tracking-tight">
                Your membership, Your way
              </h2>
              <p className="text-sm sm:text-lg">
                We are a registered 501(c)3 non-profit based in California. 100% of your monthly subscription is tax
                deductible.
              </p>
            </div>
            <div className="bg-white shadow-primary rounded-[16px]">
              <div className="p-6 border-b border-b-[#07101F] border-opacity-10">
                <p className="text-2xl font-Jakarta font-semibold mb-2">Free Plan</p>
                <p className="text-base">Perfect for getting started with essential tools.</p>
                <div className="mt-[60px]">
                  <p className="text-gray-600 mb-4">
                    <span className="font-Jakarta text-dark text-[40px] leading-[40px] font-semibold mr-1.5">$0</span>{' '}
                    USD/mo
                  </p>
                  <a
                    href="signup"
                    className="py-3 px-6 font-medium text-base text-white bg-primary rounded-[40px] w-full inline-block text-center"
                  >
                    Get started
                  </a>
                </div>
              </div>
              <ul className="flex flex-col gap-2 p-6 text-sm">
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Track practice time</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Calendar</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Lesson scheduler</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Assign homework</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Set goals</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>See class feed</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Manage billing</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Free website</span>
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-primary rounded-[16px]">
              <div className="p-6 border-b border-b-[#07101F] border-opacity-10">
                <p className="text-2xl font-Jakarta font-semibold mb-2">Premium plan</p>
                <p className="text-base">Perfect for getting started with essential tools.</p>

                <div className="mt-[60px]">
                  <p className="text-gray-600 mb-4">
                    <span className="font-Jakarta text-dark text-[40px] leading-[40px] font-semibold mr-1.5">$5</span>{' '}
                    USD/mo
                  </p>
                  <a
                    href="signup"
                    className="py-3 px-6 font-medium text-base text-white bg-primary rounded-[40px] w-full inline-block text-center"
                  >
                    Get started
                  </a>
                </div>
              </div>
              <ul className="flex flex-col gap-2 p-6 text-sm">
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>All Free Plan features plus:</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Manage billing</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Free website</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Automated billing and invoices</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Custom domain support</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Newsletters</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Listen to audio from practice sessions</span>
                </li>
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check stroke-primary" />
                  <span>Free Musopen Pro membership</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-16 sm:py-36 hidden md:block">
        <div className="container">
          <div className="max-w-[844px] text-dark">
            <h2 className="font-Jakarta text-[28px] sm:text-[48px] leading-9 sm:leading-[56px] mb-3 sm:mb-4 font-medium tracking-tight">
              Teach, inspire, and grow alongside 10,000+ music educators.
            </h2>
            <p className="text-sm sm:text-lg">
              A platform designed to help you focus on what matters most—teaching and <br className="hidden sm:block" />{' '}
              inspiring your students—while it handles organization, tracking, and simplification.
            </p>
          </div>
          <div className="my-[84px] columns-3 gap-x-4 space-y-4 mx-auto w-full relative after:absolute after:h-36 after:w-full after:bottom-0 after:left-0">
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>I appreciate the robust lesson tracking and progress reporting features.</p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials1} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Christopher Brown</p>
                  <p className="text-gray-600">Piano Instructor</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                This platform has been a game-changer for my teaching business. The user-friendly interface makes
                scheduling lessons and managing my calendar a breeze.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials2} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Emily Thompson</p>
                  <p className="text-gray-600">Violin Instructor</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                As a working musician and part-time music teacher, finding a platform that caters to my unique needs and
                busy schedule was a challenge – until I discovered this incredible solution. The flexibility it offers
                is unparalleled. I can easily adjust my availability and schedule lessons at times that work best for me
                and my students, ensuring a perfect work-life balance.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials3} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Joseph Thomas</p>
                  <p className="text-gray-600">Trumpet Instructor</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                As a seasoned music teacher with over 20 years of experience, I've seen my fair share of teaching tools
                and platforms come and go. However, this platform stands out as one of the most comprehensive and
                user-friendly solutions I've ever encountered.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials4} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Olivia Martinez</p>
                  <p className="text-gray-600">Voice Instructor</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                I love the ability to offer both in-person and online lessons through this platform. It has expanded my
                reach and allowed me to connect with students from all over the world.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials5} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">William Davis</p>
                  <p className="text-gray-600">Guitar Instructor</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                I've tried other teaching platforms in the past, but none have matched the user-friendly design and
                comprehensive features offered here.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials6} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Amelia Gonzalez</p>
                  <p className="text-gray-600">Bass Guitar</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                The video conferencing tool is top-notch. The high-quality audio and video make virtual lessons feel
                just like being in the same room as my students.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials7} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Ava Rodriguez</p>
                  <p className="text-gray-600">Piano Instructor</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                The built-in resource sharing feature is fantastic. I can easily share sheet music, exercises, and other
                learning materials with my students, streamlining the learning process.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials8} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Michael Smith</p>
                  <p className="text-gray-600">Drums Instructor</p>
                </div>
              </div>
            </div>
            <div className="bg-secondary rounded-2xl py-4 px-5 text-gray-700 border border-gray-200 text-sm flex flex-col gap-4">
              <p>
                The ability to set up recurring lessons and send automatic reminders has been a lifesaver for me. It
                helps ensure my students stay on track and never miss a lesson. It has opened up new revenue streams and
                allowed me to reach a wider audience.
              </p>
              <div className="flex gap-2.5 items-center">
                <div className="w-[38px] h-[38px] rounded-full overflow-hidden">
                  <img src={Testimonials9} loading="lazy" alt="image" />
                </div>
                <div>
                  <p className="font-medium text-dark">Mia Hernandez</p>
                  <p className="text-gray-600">Cello Instructor</p>
                </div>
              </div>
            </div>
            <div className="absolute w-full h-52 bottom-0 left-0 bg-gradient-slider z-10" />
          </div>
          <div className="text-center">
            <span className="inline-block py-2.5 px-4 text-gray-800 bg-gray-100 rounded-[40px] text-sm">
              More than 400 satisfied users
            </span>
          </div>
        </div>
      </section>

      <section className="max-w-[1560px] mx-auto mb-11 rounded-3xl bg-promotion-section bg-no-repeat bg-left-top overflow-hidden max-md:mx-4">
        <div className="max-md:py-20 max-md:px-8 md:pl-20 lg:pl-[140px]">
          <div className="h-full flex flex-col md:flex-row md:items-center gap-12 lg:gap-24">
            <div className="max-w-[530px] sm:py-11">
              <h2 className="text-dark text-[28px] sm:text-5xl leading-9 sm:leading-[56px] font-Jakarta font-medium mb-6 tracking-tight">
                Join 10,000+ Music <br /> Educators Inspiring the Next Generation of Musicians
              </h2>
              <p className="text-gray-600 text-base sm:text-lg mb-6 sm:mb-11">
                Get started for free. Upgrade whenever you wanted to deep dive into the best of Adagio.
              </p>
              <a
                href="signup"
                className="py-4 px-8 font-medium text-base text-white bg-primary rounded-[40px] inline-block text-center"
              >
                Get started for free
              </a>
            </div>
            <div className="max-h-[672px] overflow-hidden hidden md:flex gap-3 sm:gap-[30px] flex-1">
              <div className="flex gap-3 sm:gap-5 flex-col -translate-y-[145px]">
                <div className="w-[410px] h-[410px] rounded-3xl overflow-hidden flex-shrink-0">
                  <img src={Promotion1} loading="lazy" alt="image" />
                </div>
                <div className="w-[410px] h-[410px] rounded-3xl overflow-hidden flex-shrink-0">
                  <img src={Promotion2} loading="lazy" alt="image" />
                </div>
              </div>
              <div className="hidden lg:flex gap-3 sm:gap-[30px] flex-col -translate-y-[37px]">
                <div className="w-[410px] h-[410px] rounded-3xl overflow-hidden flex-shrink-0">
                  <img src={Promotion3} loading="lazy" alt="image" />
                </div>
                <div className="w-[410px] h-[410px] rounded-3xl overflow-hidden flex-shrink-0">
                  <img src={Promotion4} loading="lazy" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="aspect-video size-full min-w-3xl z-20">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/cGINBVsGBIA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </main>
  );
};
