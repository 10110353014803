import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PracticeComponent from './AnalyticsComponent/PracticeComponent';

import { Contact } from 'types';

export default () => {
  let { id } = useParams();
  const students: Contact[] = useSelector((state: any) => state.student.items);
  const contact = students[Number(id)];

  return (
    <div className="my-4 mx-auto" style={{ width: 'calc(100% - 152px)' }}>
      <PracticeComponent isPagination={true} contact={contact} />
    </div>
  );
};
