import { Circle } from 'rc-progress';
import { useCallback, useContext, useEffect, useState } from 'react';
import { TfiMoreAlt } from 'react-icons/tfi';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './UserInfoComponent.module.scss';

import Avatar from 'components/Avatar';
import CreateAssignmentModal from 'components/CreateAssignmentModal';
import DropdownSelect from 'components/DropdownSelect';
import PageSection from 'components/PageSection';
import SendMessageModal from 'components/SendMessageModal';
import { AssignmentsDispatchContext } from 'features/assignments';
import EditStudentModal from 'pages/Students/EditStudentModal';
import { ReactComponent as CheckIcon } from 'theme/svg/check.svg';
import { ReactComponent as EditIcon } from 'theme/svg/edit.svg';
import { ReactComponent as EmailIcon } from 'theme/svg/email.svg';
import { ReactComponent as UserLightIcon } from 'theme/svg/user-light.svg';
import { Contact, PracticesProgress } from 'types';

type UserInfoComponentProps = {
  allApis: any; // TODO: add type
  contact?: Contact;
};

const UserInfoComponent = (props: UserInfoComponentProps) => {
  const navigate = useNavigate();
  const [isShowSendMessageModal, setIsShowSendMessageModal] = useState<boolean>(false);
  const [isShowCreateAssignmentModal, setIsShowCreateAssignmentModal] = useState<boolean>(false);
  const [practicesProgress, setPracticesProgress] = useState<PracticesProgress>();
  const [isShowEditModal, setIsShowEditModal] = useState(false);

  /* eslint-disable */
  let { id } = useParams() as { id: string };
  /* eslint-enable */

  const {
    allApis: { getJson },
    contact,
  } = props;

  const dispatch = useContext(AssignmentsDispatchContext);

  const getPracticeProgress = useCallback(async () => {
    if (contact?.student) {
      const resp = await getJson(`/practices/progress/?student=${contact?.student?.id}`);
      setPracticesProgress(resp);
    }
  }, [getJson, contact]);

  useEffect(() => {
    getPracticeProgress();
  }, [contact, getPracticeProgress]);

  const openSendMessageModal = () => {
    setIsShowSendMessageModal(true);
  };

  const openCreateAssignmentModal = () => {
    setIsShowCreateAssignmentModal(true);
  };

  const options = [
    {
      icon: <EmailIcon className={styles['icon']} />,
      title: 'Message student',
      action: openSendMessageModal,
    },
    {
      icon: <UserLightIcon className={styles['icon']} />,
      title: 'Message parents',
    },
    {
      icon: <CheckIcon className={styles['icon']} />,
      title: 'Assign homework',
      action: openCreateAssignmentModal,
    },
    {
      icon: <EditIcon className={styles['icon']} />,
      title: 'Add note',
      action: () => navigate('/notes', { state: { student: contact } }),
    },
  ];

  const dropdownOptions = [{ label: 'Edit student', action: () => setIsShowEditModal(true) }];

  return (
    <PageSection className={styles['user-info']} wrapperClassName={styles['user-info-mb']}>
      <div className={styles['cover-area']}>
        <div className={styles['more-icon']}>
          <DropdownSelect
            customToggleComponent={
              <div className={styles['right-wrapper']}>
                <TfiMoreAlt className={styles['more-icon']} />
              </div>
            }
            options={dropdownOptions}
          />
        </div>
        <div className={styles['avatar-area']}>
          <Avatar
            size={84}
            letter={contact?.name?.substring(0, 1) || ''}
            url={contact?.student?.picture}
            className={styles['student-avatar']}
          />
          <Circle
            className={styles['progress-circle']}
            percent={parseInt(practicesProgress?.level?.progress.replace('%', '') || '0')}
            strokeWidth={4}
            trailWidth={4}
            strokeColor="#FF9400"
            trailColor="#eff2f5"
          />
          <div className={styles['progress-badge']}>{practicesProgress?.level?.name}</div>
        </div>
        <div className={styles['name']}>{contact?.name}</div>
        <div className={styles['email']}>{contact?.email}</div>
      </div>
      <div className={styles['options-area']}>
        {options?.map((item, index) => (
          <div key={index} className={styles['option-item']} onClick={item.action}>
            <div>{item?.icon}</div>
            <div className={styles['option-title']}>{item?.title}</div>
          </div>
        ))}
      </div>

      <SendMessageModal
        isShowModal={isShowSendMessageModal}
        handleCloseModal={() => setIsShowSendMessageModal(false)}
        contact={contact}
      />
      <CreateAssignmentModal
        isShowModal={isShowCreateAssignmentModal}
        contact={contact}
        handleCloseModal={() => setIsShowCreateAssignmentModal(false)}
        handleSubmitModal={() => dispatch({ type: 'reset' })}
      />

      <EditStudentModal
        isShowModal={isShowEditModal}
        handleCloseModal={() => setIsShowEditModal(false)}
        contact={contact}
      />
    </PageSection>
  );
};

/* eslint-disable */
const mapStateToProps = (state: any) => ({});
/* eslint-enable */

export default connect(mapStateToProps)(UserInfoComponent);
