import _ from 'lodash';
import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { IoMdClose, IoMdFunnel } from 'react-icons/io';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import GroupsModal from '../../components/GroupsModal';

import AddStudentModal from './AddStudentModal';
import StudentItem from './StudentItem';
import styles from './Students.module.scss';

import { fetchGroups } from 'actions/GroupActions';
import { deleteStudent, updateStudent } from 'actions/StudentActions';
import Btn from 'components/Btn';
import CheckboxCustom from 'components/CheckboxCustom';
import ConfirmModal from 'components/ConfirmModal';
import DropdownFilterGroups from 'components/DropdownFilterGroups';
import DropdownSelect from 'components/DropdownSelect';
import FilterStudentsMobile from 'components/FilterStudentsMobile';
import InputField from 'components/InputField';
import STUDENT_NOTIFICATIONS from 'data/notifications/student';
import { GroupsList } from 'features/organizations/components';
import { setActiveGroup } from 'features/organizations/organizationsSlice';
import { useToggle } from 'hooks';
import useCheck from 'hooks/useCheck';
import { ReactComponent as AddUserIcon } from 'theme/svg/add-user.svg';
import { Contact, GroupType, OptionsType, User } from 'types';

type StudentsProps = {
  students?: Contact[];
  isFetchingStudent: boolean;
  user?: User;
  allApis: any;
  fetchGroups: () => void;
  groups: GroupType[];
  handleUpdateStudent: (id: number, groups: string[]) => void;
  deleteStudent: (id: number) => void;
  activeClass: string;
  resetActiveClass: () => void;
};

const Students = (props: StudentsProps) => {
  const {
    students = [],
    isFetchingStudent,
    fetchGroups,
    groups,
    handleUpdateStudent,
    allApis: { getJson },
    deleteStudent,
    user,
    activeClass,
    resetActiveClass,
  } = props;

  const cookie = document.cookie.split(';').some(c => c.trim().startsWith('amscallout='));
  const [isHideBanner, setIsHideBanner] = useState(cookie);
  const [isShowAddStudentModal, setIsShowAddStudentModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [showModalGroups, setShowModalGroups] = useToggle();
  const [isBulkAdd, setIsBulkAdd] = useState(false);
  const [isLoadingPractice, setIsLoadingPractice] = useState(true);
  const [practiceTimeChart, setPracticeTimeChart] = useState<{ [key: string]: number[] }>({});
  const [search, setSearch] = useState('');
  const [filterGroup, setFilterGroup] = useState<string | number>('');
  const [show, toggleShow] = useToggle();
  const [sortingType, setSortingType] = useState<string>();

  const handleGetStudentPractice = useCallback(async () => {
    const created_min = moment().subtract(30, 'days');
    const created_max = moment();
    const studentss = students.filter(i => i.status !== 'archived' && !!i.student);
    let studentIds = _.flatMap(studentss, contact => contact?.student?.id);

    const studentParams = studentIds.reduce((prev, curr) => {
      if (prev) return prev.concat(`&student=${curr}`);
      return `student=${curr}`;
    }, '');

    const studentsQueryString = studentParams ? studentParams + '&' : '';
    try {
      const queryString = [
        studentsQueryString,
        `created_min=${encodeURIComponent(created_min.utc().format())}`,
        `created_max=${encodeURIComponent(created_max.utc().format())}`,
      ];
      let result = await getJson('/practices/timechart/?' + queryString.join('&'));

      if (result) {
        const timeCharts: { [key: string]: number[] } = {};

        for (const [key, value] of Object.entries(result?.datasets) as any) {
          const timeChartKey = key.split('/')[3];
          timeCharts[timeChartKey] = value?.values || [];
        }
        setPracticeTimeChart(timeCharts);
      }
    } catch (error) {}

    setIsLoadingPractice(false);
  }, [getJson, students]);

  let filteredStudents = useMemo(() => {
    return students.filter(
      i =>
        (i?.name?.includes(search) || i?.email?.includes(search)) &&
        (!filterGroup || (filterGroup && i.groups?.includes(filterGroup))) &&
        i.status !== 'archived',
    );
  }, [students, search, filterGroup]);

  const optionsSorting = [
    {
      value: 'activity',
      action: () => {
        // from the student with the most recent activity to the most inactive one
        setSortingType('Activity');
        filteredStudents.sort((a: Contact, b: Contact): number => {
          const aTimeCharts = practiceTimeChart?.[String(a.student?.id)] || new Array(30).fill(0);
          const bTimeCharts = practiceTimeChart?.[String(b.student?.id)] || new Array(30).fill(0);

          return aTimeCharts.reduceRight((acc, val, i) => acc || bTimeCharts[i] - val, 0);
        });
      },
      label: 'Activity',
    },
    {
      value: 'name',
      action: () => {
        setSortingType('Name');
        filteredStudents.sort((a: Contact, b: Contact): number => a.name.localeCompare(b.name));
      },
      label: 'Name',
    },
  ];

  useEffect(() => {
    if (students?.length > 0 && !isFetchingStudent) {
      handleGetStudentPractice();
      optionsSorting[0].action();
    } else {
      setIsLoadingPractice(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students, isFetchingStudent, handleGetStudentPractice]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const optionsGroup = useMemo(
    () => [
      {
        label: 'All groups',
        value: '',
      },
      ...groups?.map(group => ({
        id: group.id,
        label: group.name,
        value: group.url || '',
      })),
    ],
    [groups],
  );

  const hideBanner = () => {
    setIsHideBanner(true);
    document.cookie = `amscallout=1; max-age=${3650 * 24 * 3600}; path=/`;
  };

  const { checkIsChecked, handleToggleCheck, handleToggleCheckAll, checkedItems, handleClearCheck } = useCheck({
    items: filteredStudents,
    key: 'id',
  });

  useEffect(() => {
    if (document.getElementById('check-box-custom')) {
      const elCheckbox = document.getElementById('check-box-custom') as HTMLInputElement;
      if (checkedItems.length) {
        if (checkedItems.length < filteredStudents.length) {
          elCheckbox.indeterminate = true;
          elCheckbox.checked = false;
        } else {
          elCheckbox.indeterminate = false;
          elCheckbox.checked = true;
        }
      } else {
        elCheckbox.indeterminate = false;
        elCheckbox.checked = false;
      }
    }
  }, [checkedItems, filteredStudents.length]);

  const handleDeleteStudent = async () => {
    const checkContactError = [];
    for (let i = 0; i < checkedItems.length; i++) {
      const contact: Contact = checkedItems[i];
      try {
        await deleteStudent(contact.id);
      } catch (error) {
        checkContactError.push(contact);
      }
    }
    handleClearCheck();
    if (!checkContactError.length) toast.success(STUDENT_NOTIFICATIONS.delete.success);
    else toast.error(STUDENT_NOTIFICATIONS.delete.error);
  };

  const onEditGroup = () => {
    setShowModalGroups();
  };

  const onSelect = (option: OptionsType) => {
    setFilterGroup(option.value);
  };

  // eslint-disable-next-line
  const onClickCheckboxCustom = (e?: ChangeEvent<HTMLInputElement>) => {
    handleToggleCheckAll();
  };

  if (user?.memberships?.length && user.memberships[0].role === 'admin') {
    if (activeClass) {
      filteredStudents = filteredStudents.filter(c => c.groups?.includes(activeClass));
    } else {
      return <GroupsList />;
    }
  }

  return (
    <div className={styles['student-wrapper']}>
      <AddStudentModal
        setIsBulkAdd={(value: boolean) => setIsBulkAdd(value)}
        isShowModal={isShowAddStudentModal}
        handleCloseModal={() => setIsShowAddStudentModal(false)}
        isBulkAdd={isBulkAdd}
        initialValues={{ groups: activeClass ? [activeClass] : [] }}
      />

      <ConfirmModal
        isShowModal={isShowDeleteModal}
        title="Delete students"
        submitAction={handleDeleteStudent}
        handleCloseModal={() => setIsShowDeleteModal(false)}
      >
        Are you sure you want to delete these students:{' '}
        {checkedItems.map((contact: Contact) => contact.name).join(', ')} ?
      </ConfirmModal>

      {showModalGroups && (
        <GroupsModal isShowModal={showModalGroups} handleCloseModal={setShowModalGroups} groups={groups} />
      )}

      <div className={styles['header']}>
        <div className={styles['left-wrapper']}>
          {!checkedItems.length ? (
            <InputField
              className={styles['search-input']}
              label="Search"
              size="small"
              inputProps={{ value: search, onChange: e => setSearch(e.target.value) }}
            />
          ) : (
            <div className={styles['check-box-custom-wrapper']}>
              <CheckboxCustom id="check-box-custom" onChange={e => onClickCheckboxCustom(e)} />
            </div>
          )}
        </div>
        <div className={styles['right-wrapper']}>
          {!checkedItems.length ? (
            <>
              <Btn
                variant="link"
                btnClassName={styles['add-btn']}
                onClick={() => {
                  setIsShowAddStudentModal(true);
                  setIsBulkAdd(false);
                }}
              >
                <AddUserIcon className={styles['btn-icon']} /> Add students
              </Btn>

              {!!activeClass && (
                <span className="mx-4 cursor-pointer" style={{ fontSize: '14px' }} onClick={resetActiveClass}>
                  <span className="material-icons-outlined">arrow_back</span>
                  &nbsp; Class list
                </span>
              )}

              <div>
                <Btn variant="outline-secondary" btnClassName={styles['filter-btn']} onClick={toggleShow}>
                  Filter <IoMdFunnel size={18} className={styles['btn-icon-filter']} />
                </Btn>
                <FilterStudentsMobile
                  show={show}
                  toggleShow={toggleShow}
                  onEditGroup={onEditGroup}
                  optionsGroup={optionsGroup}
                  onSelect={onSelect}
                />
              </div>

              <div className={styles['group-dropdown-wrapper']}>
                <DropdownFilterGroups
                  label={optionsGroup.find(option => option.value === filterGroup)?.label}
                  options={optionsGroup}
                  className={styles['group-dropdown']}
                  onEditGroup={onEditGroup}
                  onSelect={onSelect}
                />
                <DropdownSelect
                  icon={<>Sort by {sortingType ? <b>{sortingType}</b> : ''}</>}
                  options={optionsSorting}
                />
              </div>
            </>
          ) : (
            <Btn variant="link" btnClassName={styles['remove-btn']} onClick={() => setIsShowDeleteModal(true)}>
              Remove
            </Btn>
          )}
        </div>
      </div>
      <div className={styles['body']}>
        <div className={styles['student-table']}>
          {isLoadingPractice || isFetchingStudent ? (
            <div className={styles['spinner-wrapper']}>
              <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
            </div>
          ) : (
            <>
              {filteredStudents?.map(item => (
                <StudentItem
                  key={item.id}
                  contact={item}
                  timeChart={practiceTimeChart?.[String(item?.student?.id)]}
                  groups={groups}
                  handleUpdateStudent={handleUpdateStudent}
                  fetchGroups={fetchGroups}
                  handleToggleCheck={handleToggleCheck}
                  checkIsChecked={checkIsChecked}
                />
              ))}
            </>
          )}
        </div>
        {!isHideBanner && (
          <div className={styles['add-more-banner']}>
            <div className={styles['add-more-text']}>
              Studio is better with more students,{' '}
              <span
                className={styles['add-more-action']}
                onClick={() => {
                  setIsShowAddStudentModal(true);
                  setIsBulkAdd(true);
                }}
              >
                add multiple students at once.
              </span>
            </div>
            <IoMdClose className={styles['icon']} size={22} onClick={hideBanner} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user as User,
  students: Object.values(state.student.items) as Contact[],
  isFetchingStudent: state.student.isFetching as boolean,
  allApis: state.Api.allApis,
  groups: Object.values(state.group.items) as GroupType[],
  activeClass: state.organizations.activeGroup,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetActiveClass: () => dispatch(setActiveGroup()),
  fetchGroups: () => dispatch(fetchGroups()),
  handleUpdateStudent: (id: number, groups: string[]) => dispatch(updateStudent(id, { groups })),
  deleteStudent: (id: number) => dispatch(deleteStudent(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Students);
