export const SET_TOKEN = 'AUTH/SET_TOKEN';

export const UPDATE_ALL_APIS = 'AUTH/UPDATE_ALL_APIS';
export const UPDATE_EXPIRES_TIME = 'AUTH/UPDATE_EXPIRES_TIME';

export const LOGOUT = 'AUTH/LOGOUT';
export const LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'AUTH/LOGOUT_FAIL';

export const RESET_TOKEN = 'AUTH/RESET_TOKEN';

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  payload: { data: { token } },
});

export const logOut = () => ({
  type: LOGOUT,
  payload: {
    request: {
      method: 'POST',
      url: '/auth/logout/',
      withCredentials: 'same-origin',
    },
  },
});

export type TResetTokenAction = { type: typeof RESET_TOKEN };

export const resetToken = (): TResetTokenAction => ({ type: RESET_TOKEN });
