export default (props: any) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.25 5.25L3.75 10.75L1 8"
      stroke="#2264F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0016 5.25L9.50156 10.75L8.03906 9.2875"
      stroke="#2264F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
