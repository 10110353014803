export const config = {
  api_endpoint: process.env.REACT_APP_API,
  host: process.env.REACT_APP_MUSOPEN,
  places_api_key: process.env.REACT_APP_PLACES_API_KEY,
  public_url: process.env.PUBLIC_URL,
};

export const REGISTRATION_TYPES: any = {
  free: 'This event is free',
  custom_rate: 'Set a per person rate of this event',
  default_rate: "Use the student's default rate",
};

export const EVENT_TYPES = {
  event: {
    id: 'event',
    name: 'Event',
  },
  group_lesson: {
    id: 'group_lesson',
    name: 'Group Lesson',
  },
  lesson: {
    id: 'lesson',
    name: 'Lesson',
  },
  personal_appointment: {
    id: 'personal_appointment',
    name: 'Personal Appointment',
  },
};
