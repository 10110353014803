import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import styles from './LessonsComponent.module.scss';

import { useGetLesson } from 'common/api/apis';
import PageSection from 'components/PageSection';
import DotsHorizontalSVG from 'theme/svg/DotsHorizontalSVG';
import { Contact, Lesson, User } from 'types';
type LessonsComponentProps = {
  user: User;
  contact?: Contact;
};

const renderDateObject = (timestamp?: string): Date => moment(timestamp, 'YYYY-MM-DDTHH:mm:ssZZ').toDate();
const renderShortMonth = (date: Date) => {
  let formatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
  return formatter.format(date);
};
const renderPrettyDate = (timestamp?: string): string =>
  moment(timestamp, 'YYYY-MM-DDTHH:mm:ssZZ').format('YYYY-MM-DD');

const LessonsComponent = (props: LessonsComponentProps) => {
  const { user, contact } = props;
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [fetchLesson, { isPending: loading }] = useGetLesson({});
  const [isFinishedLoadApi, setIsFinishedLoadApi] = useState<boolean>(false);
  const [showAllLessons, setShowAllLessons] = useState<boolean>(false);
  const getAllLesson = useCallback(async () => {
    if (!user.id || !contact?.student) {
      return;
    }
    let resp = await fetchLesson({
      teacher: user.id,
      students: contact?.student.id,
    });
    if (resp) {
      setLessons([...resp.results].reverse());
    }
    setIsFinishedLoadApi(true);
  }, [fetchLesson, contact, user]);

  useEffect(() => {
    if (contact && !contact.student) {
      setIsFinishedLoadApi(true);
    }

    if (!isFinishedLoadApi) {
      getAllLesson();
    }
  }, [contact, getAllLesson, isFinishedLoadApi]);

  return (
    <PageSection className="mb-4" title="Lessons" wrapperClassName={styles['lessons-wrapper']}>
      {loading || !isFinishedLoadApi ? (
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      ) : (
        <>
          <div className={styles['table-wrapper']}>
            <table>
              <thead>
                <tr>
                  <th className={styles['header-1']}></th>
                  <th className={styles['header-2']}></th>
                </tr>
              </thead>
              <tbody>
                {lessons.length === 0 ? (
                  <tr>
                    <td colSpan={2}>
                      <div className={styles['no-lesson']}>No lessons yet.</div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {lessons.slice(0, showAllLessons ? lessons.length : 5).map((item: Lesson, id: number) => (
                      <tr key={id}>
                        <td>
                          <div className={styles['wrapper-colume-1']}>
                            <div className={styles['colume-1--left']}>
                              <div className={styles['day']}>{renderDateObject(item.start).getDate()}</div>
                              <div className={styles['month']}>{renderShortMonth(renderDateObject(item.start))}</div>
                            </div>
                            <div className={styles['colume-1--right']}>
                              <div className={styles['title']}>{item.summary}</div>
                              <div className={styles['date']}>
                                <span className={styles['time-label']}>Start date: </span>
                                {renderPrettyDate(item.start)}
                              </div>
                              <div className={styles['date']}>
                                <span className={styles['time-label']}>End date: </span>
                                {renderPrettyDate(item.end)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={styles['colume-2']}>
                            <DotsHorizontalSVG className={styles['icon']} />
                          </div>
                        </td>
                      </tr>
                    ))}
                    {!showAllLessons && (
                      <tr>
                        <td colSpan={4}>
                          <div
                            className="text-primary cursor-pointer w-fit hover:opacity-80"
                            onClick={() => setShowAllLessons(true)}
                          >
                            See all
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </PageSection>
  );
};

/* eslint-disable */
const mapStateToProps = (state: any) => ({});
/* eslint-enable */

export default connect(mapStateToProps)(LessonsComponent);
