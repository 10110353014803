import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { default as cs } from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { TfiExport } from 'react-icons/tfi';
import { toast } from 'react-toastify';

import { useSearchInvoices } from '../../../common/api/apis';
import { useAppSelector } from '../../../hooks';

import styles from './InvoicesTable.module.scss';

import Btn from 'components/Btn';
import DropdownSelect from 'components/DropdownSelect';
import InputField from 'components/InputField';
import DotsHorizontalSVG from 'theme/svg/DotsHorizontalSVG';
import { Contact, Invoice } from 'types';

type ActionBilling = {
  label: string;
  action: (id: number) => void;
};

type InvoicesTableProps = {
  handleSetInvoiceSelected: (invoice: Invoice | undefined) => void;
};

type StatusLabelType = {
  status: string;
};

const fetchInvoices = (params: object | undefined) => {
  const { getJson } = useAppSelector(state => state.Api.allApis);
  return useQuery({
    queryKey: ['billing', 'invoices', params],
    queryFn: () => getJson('/study/billing/invoices/', params),
  });
};

const useInvoiceMutation = () => {
  const queryClient = useQueryClient();
  const api = useAppSelector(state => state.Api.allApis);
  return useMutation({
    mutationFn: ({ id, action }: { id: number; action: 'cancel' | 'paid' | 'unpaid' | 'send' }) => {
      if (action === 'cancel') {
        return api.postJson(`/study/billing/invoices/${id}/cancel/`);
      } else if (action === 'paid') {
        return api.postJson(`/study/billing/invoices/${id}/payments/`);
      } else if (action === 'unpaid') {
        return api.delJson(`/study/billing/invoices/${id}/payments/`);
      } else if (action === 'send') {
        return api.postJson(`/study/billing/invoices/${id}/send/`);
      }
    },
    onError: () => {
      toast.error('An error occurred while processing your request.');
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['billing'], refetchType: 'all' });
    },
  });
};

const InvoicesTable = (props: InvoicesTableProps) => {
  const students = useAppSelector(state => state.student.items) as Record<string, Contact>;
  const mutation = useInvoiceMutation();
  const { handleSetInvoiceSelected } = props;
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [getSearchInvoices, {}] = useSearchInvoices({ searchBody: {}, searchParams: {} });
  const [filters, setFilters] = useState<Record<string, string>>();
  const [params, setParams] = useState<object>();
  const { data, isLoading } = fetchInvoices({ ...params, ordering: '-date' });

  useEffect(() => {
    // any filters change resets pagination params
    setParams(filters);
  }, [filters]);

  const headers = [
    {
      label: 'Name',
      class: 'header-name',
    },
    {
      label: 'Description',
      class: 'header-description',
    },
    {
      label: 'Date',
      class: 'header-date',
    },
    {
      label: 'Amount',
      class: 'header-amount',
    },
    {
      label: 'Status',
      class: 'header-status',
    },
    {
      label: 'Preview',
      class: 'header-preview',
    },
    {
      label: '',
      class: 'header-dots',
    },
  ];

  const StatusLabel = ({ status }: StatusLabelType) => {
    const className = `colume-status-${status.toLowerCase()}`;
    return <div className={cs(styles[className], styles['colume-status-wrapper'])}>{_.capitalize(status)}</div>;
  };

  const appendData = async (search: string[][] | Record<string | number, string | number>, data: any[] = []) => {
    const body: any = {};
    return getSearchInvoices(body, search).then((response: any) => {
      data.push(
        ...response.items?.map((item: any) =>
          JSON.stringify([
            moment(item?.detail?.invoice_date).format('MM/DD/YYYY'),
            item?.primary_recipients?.[0]?.billing_info.name?.full_name,
            item?.detail?.note,
            item?.amount?.value,
            item?.status,
          ]).slice(1, -1),
        ),
      );

      const link = response.links.find((l: any) => l.rel === 'next');
      if (link) {
        let link_search = new URL(link.href).search;
        let searchParams = Object.fromEntries(new URLSearchParams(link_search));
        return appendData(searchParams, data);
      }

      return data;
    });
  };

  const exportDataCsv = async () => {
    setIsExporting(true);
    await appendData({ page_size: 100 }).then((data: any) => {
      const csv = data.join('\r\n');
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      setIsExporting(false);
    });
  };

  const optionsStatusInvoice = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Unpaid',
      value: 'UNPAID',
    },
    {
      label: 'Paid',
      value: 'PAID',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
  ];

  const actionEditInvoice = async (id: number) => {
    let invoice = data.results.find((invoice: Invoice) => invoice.id === id);
    handleSetInvoiceSelected(invoice);
  };

  const dropdownActionsBilling: ActionBilling[] = [
    {
      label: 'Edit',
      action: actionEditInvoice,
    },
    {
      label: 'Cancel',
      action: id => mutation.mutate({ id, action: 'cancel' }),
    },
    {
      label: 'Mark Paid',
      action: id => mutation.mutate({ id, action: 'paid' }),
    },
    {
      label: 'Mark Unpaid',
      action: id => mutation.mutate({ id, action: 'unpaid' }),
    },
    {
      label: 'Send',
      action: id => mutation.mutate({ id, action: 'send' }),
    },
  ];

  const getDropdownActionBilling = (status: string): ActionBilling[] => {
    if (status.toLowerCase() === 'unpaid') {
      return [
        dropdownActionsBilling[0],
        dropdownActionsBilling[1],
        dropdownActionsBilling[2],
        dropdownActionsBilling[4],
      ];
    }

    if (status.toLowerCase() === 'cancelled') {
      return [dropdownActionsBilling[0], dropdownActionsBilling[2], dropdownActionsBilling[3]];
    }

    if (status.toLowerCase() === 'paid') {
      return [dropdownActionsBilling[0], dropdownActionsBilling[1], dropdownActionsBilling[3]];
    }

    return [dropdownActionsBilling[0]];
  };

  const handleFilters = (filter: Record<string, string>) => {
    setFilters(orig => ({ ...orig, ...filter }));
  };

  const setParamsFromURL = (value: string) => {
    const url = new URL(value);
    setParams(Object.fromEntries(url.searchParams.entries()));
  };

  return (
    <div className={styles['invoices-wrapper']}>
      <div className={styles['invoices-header']}>
        <div className={styles['invoices-header__left']}>Invoices</div>
        <div className={styles['invoices-header__right']}>
          <InputField
            inputClassName={styles['search-input']}
            label="Search"
            size="small"
            inputProps={{ value: filters?.search, onChange: e => handleFilters({ search: e.target.value }) }}
          />

          <div className={styles['export-wrapper']}>
            <Btn
              variant="outline-primary"
              btnClassName={`${styles['export-btn']} hidden`}
              isLoading={isExporting}
              onClick={exportDataCsv}
            >
              <TfiExport size={13} className={styles['export-btn-icon']} />
              Export
            </Btn>
            <DropdownSelect
              label={optionsStatusInvoice.find((o: any) => o.value === filters?.status)?.label || 'All'}
              value={filters?.status}
              options={optionsStatusInvoice}
              onChange={(status: string) => handleFilters({ status })}
            />
          </div>
        </div>
      </div>
      <div className={styles['table-invoices-wrapper']}>
        {isLoading ? (
          <div className={styles['spinner-wrapper']}>
            <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                {headers.map(header => (
                  <th className={styles[`${header.class}`]} key={header.class}>
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>

            {data?.results.length ? (
              <tbody>
                {data.results.map((invoice: Invoice) => {
                  const student = Object.values(students).find(s => s.url == invoice.contact);
                  return (
                    <tr key={invoice.id}>
                      <td>
                        <div className={styles['colume-info']}>{student?.name}</div>
                        {invoice.payer_email}
                      </td>
                      <td>
                        <div className={styles['colume-note']}>{invoice.description}</div>
                      </td>
                      <td>
                        <div className={styles['colume-date']}>{moment(invoice.date).format('ll')}</div>
                      </td>
                      <td>
                        <div className={styles['colume-money']}>${invoice.amount}</div>
                      </td>
                      <td>
                        <StatusLabel status={invoice.status} />
                      </td>
                      <td>
                        <div className={styles['colume-preview']}>
                          <a href={`/invoice/${invoice.uuid}`} className="btn btn-primary" target="_blank">
                            Preview
                          </a>
                        </div>
                      </td>
                      <td>
                        {getDropdownActionBilling(invoice.status).length > 0 && (
                          <DropdownSelect
                            id={invoice.id}
                            customToggleComponent={
                              <div className={styles['colume-dots']}>
                                <DotsHorizontalSVG className={styles['icon']} />
                              </div>
                            }
                            options={getDropdownActionBilling(invoice.status)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr className="text-center text-muted" style={{ height: 300 }}>
                  <td colSpan={7}>No invoices yet</td>
                </tr>
              </tbody>
            )}
          </table>
        )}
      </div>
      {data?.count > data?.results.length && (
        <div className="flex justify-between items-center py-4">
          <Btn
            className={cs({ invisible: !data.previous })}
            onClick={() => setParamsFromURL(data.previous)}
            variant="secondary"
          >
            ← Previous
          </Btn>
          <Btn
            className={cs({ invisible: !data.next })}
            onClick={() => setParamsFromURL(data.next)}
            variant="secondary"
          >
            Next →
          </Btn>
        </div>
      )}
    </div>
  );
};

export default InvoicesTable;
