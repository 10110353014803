import moment from 'moment';
import { TfiMoreAlt } from 'react-icons/tfi';

import styles from './MessageArea.module.scss';

import Avatar from 'components/Avatar';
import InputField from 'components/InputField';
import CalendarSVG from 'theme/svg/CalendarSVG';
import ChecksSVG from 'theme/svg/ChecksSVG';
import PaperClipSVG from 'theme/svg/PaperClipSVG';
import TrashSVG from 'theme/svg/TrashSVG';
import { Contact, User } from 'types';

const fakeDate = [
  {
    messages: ['Hi', 'How are you doing?'],
    studentId: 518258,
    sendingTime: moment().add(-2, 'months'),
    isSeen: true,
  },
  {
    messages: ['I am doing good!'],
    studentId: 2,
    sendingTime: moment().add(-1, 'months'),
  },
  {
    messages: ['I scheduled lesson October 6 at 4:52 PM'],
    studentId: 518258,
    sendingTime: moment().add(-1, 'months').add(1, 'days'),
    isSeen: true,
  },
  {
    messages: ['Thank you'],
    studentId: 2,
    sendingTime: moment().add(-1, 'months').add(3, 'days'),
    isSeen: true,
  },
  {
    messages: ['Thanks, Much appreciated!'],
    studentId: 518258,
    sendingTime: moment(),
  },
  {
    messages: ['I am doing good!'],
    studentId: 2,
    sendingTime: moment().add(-1, 'months'),
  },
  {
    messages: ['I scheduled lesson October 6 at 4:52 PM'],
    studentId: 518258,
    sendingTime: moment().add(-1, 'months').add(1, 'days'),
    isSeen: true,
  },
  {
    messages: ['Thank you'],
    studentId: 2,
    sendingTime: moment().add(-1, 'months').add(3, 'days'),
  },
  {
    messages: ['Thanks, Much appreciated!'],
    studentId: 518258,
    sendingTime: moment(),
    isSeen: true,
  },
  {
    messages: ['I am doing good!'],
    studentId: 2,
    sendingTime: moment().add(-1, 'months'),
  },
  {
    messages: ['I scheduled lesson October 6 at 4:52 PM'],
    studentId: 518258,
    sendingTime: moment().add(-1, 'months').add(1, 'days'),
  },
  {
    messages: ['Thank you'],
    studentId: 2,
    sendingTime: moment().add(-20, 'days'),
  },
  {
    messages: ['Thanks, Much appreciated!'],
    studentId: 518258,
    sendingTime: moment(),
  },
];

type MessageAreaProps = {
  studentSelected?: Contact;
  user: User;
};

const MessageArea = (props: MessageAreaProps) => {
  const { studentSelected, user } = props;

  return (
    <div className={styles['message-area-wrapper']}>
      <div className={styles['message-area-header']}>
        <div className={styles['header-info']}>
          <div className={styles['avatar']}>
            <Avatar
              size={40}
              letter={studentSelected?.name?.substring(0, 1) || ''}
              url={studentSelected?.student?.picture}
            />
          </div>
          <div className={styles['name']}>{studentSelected?.name}</div>
        </div>
        <div className={styles['header-action']}>
          <TrashSVG className={styles['trash-icon']} />
          <TfiMoreAlt className={styles['more-icon']} />
        </div>
      </div>
      <div className={styles['message-area-body']}>
        <div className={styles['messages-wrapper']}>
          {fakeDate.map((message, id) => {
            return (
              <div
                key={id}
                className={
                  user.id === message.studentId ? styles['message-sent-wrapper'] : styles['message-received-wrapper']
                }
              >
                {message.messages.map((mess, i) => (
                  <p
                    key={i}
                    className={user.id === message.studentId ? styles['message-sent'] : styles['message-received']}
                  >
                    {mess}
                  </p>
                ))}
                <div className={styles['message-time']}>
                  {message?.isSeen && <ChecksSVG />}
                  {moment(message.sendingTime).fromNow()}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles['message-area-footer']}>
        <div className={styles['input-wrapper']}>
          <InputField
            inputClassName={styles['input']}
            size="small"
            inputProps={{ rows: 1, as: 'textarea', placeholder: 'Message Russell' }}
          />
          <div className={styles['action']}>
            <PaperClipSVG className={styles['action-icon-paper']} />
            <CalendarSVG className={styles['action-icon-calendar']} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageArea;
