import { yupResolver } from '@hookform/resolvers/yup';
import { default as cs } from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { GoDot } from 'react-icons/go';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import * as yup from 'yup';

import { GTAG_CALENDAR_CREATE_EVENT, GTAG_CALENDAR_EDIT_EVENT } from '../../../constants/gtag';
import { useAppSelector } from '../../../hooks';

import styles from './ModalLesson.module.scss';

import CustomModal from 'components/CustomModal';
import DateInput from 'components/DateInput';
import DropdownSelect from 'components/DropdownSelect';
import InputField from 'components/InputField';
import TimeInput from 'components/TimeInput';
import { Contact, LessonEvent } from 'types';
import { gtag } from 'utils';
import { EVENT_TYPES, REGISTRATION_TYPES } from 'utils/config';

type ModalLessonProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  dispatch: Dispatch;
  allApis: any; // TODO: add type
  displayModalLesson: string;
  fetchLessons: () => void;
  updateCalendarEvents: VoidFunction;
  createLesson: (data: LessonEvent) => void;
  updateLesson: (id: string | number | undefined, data: LessonEvent) => void;
  initializeDataModal?: LessonEvent;
};

const ModalLesson = (props: ModalLessonProps) => {
  const {
    isShowModal,
    handleCloseModal,
    displayModalLesson,
    fetchLessons,
    updateCalendarEvents,
    createLesson,
    initializeDataModal,
    updateLesson,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const students = Object.values(useAppSelector(state => state.student.items as Record<string, Contact>));

  const schema = yup
    .object({
      summary: yup.string().required('Title is required'),
      fee: yup.string().default(''),
      student: yup.string(),
      date: yup.string().required('Date is required'),
      event_type: yup.string().required('Event type is required'),
      start: yup.string().required('Start time is required'),
      end: yup.string().required('End time is required'),
      description: yup.string(),
      location: yup.string(),
    })
    .shape({});

  const formMethods = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  useEffect(() => {
    if (initializeDataModal) {
      setValue('summary', initializeDataModal?.summary || '');
      setValue('fee', initializeDataModal?.fee?.toString() || '');
      setValue('student', initializeDataModal?.student || '');
      setValue('location', initializeDataModal?.location || '');
      setValue('description', initializeDataModal?.description || '');
      setValue('event_type', initializeDataModal?.event_type || '');
      setValue('date', `${initializeDataModal?.date || ''}`);
      setValue('start', `${initializeDataModal?.start || ''}`);
      setValue('end', `${initializeDataModal?.end || ''}`);
    }
  }, [initializeDataModal, setValue]);

  type TAddLessonValues = yup.InferType<typeof schema>;

  const onSubmit = async (data: TAddLessonValues) => {
    setIsLoading(true);
    const isNew = displayModalLesson === 'New';
    const registration =
      (!isNew && Object.keys(REGISTRATION_TYPES).find((key: string) => key === initializeDataModal?.registration)) ||
      '';

    let sdate = moment(data.date);
    let edate = moment(data.date);
    sdate.set({
      h: +moment(data.start).format('HH'),
      m: +moment(data.start).format('mm'),
    });
    edate.set({
      h: +moment(data.end).format('HH'),
      m: +moment(data.end).format('mm'),
    });

    const description = data.description || '';
    const eventType = data.event_type || 'lesson';

    const _data: LessonEvent = isNew
      ? {
          summary: data.summary || '',
          start: sdate.toDate(),
          end: edate.toDate(),
          status: 'pending',
          contacts: (data.student && [data.student]) || [],
          location: data.location,
          description: description,
          event_type: eventType,
        }
      : {
          summary: data.summary || '',
          start: sdate.toDate(),
          end: edate.toDate(),
          contacts: (data.student && [data.student]) || [],
          location: data.location,
          description: description,
          event_type: eventType,
          visibility: initializeDataModal?.visibility || '',
          registration: registration,
          recurrence_end: initializeDataModal?.recurrence_end,
          recurrence_type: initializeDataModal?.recurrence_type || '',
        };
    if (data.fee !== '') _data['fee'] = Number(data.fee);
    if (initializeDataModal?.reschedule === 'cancelled') {
      _data.status = initializeDataModal?.reschedule;
    }
    const lessonId = !isNew ? initializeDataModal?.url?.split('/')[5] : '';
    try {
      if (isNew) {
        await createLesson(_data);
        gtag(GTAG_CALENDAR_CREATE_EVENT);
      } else {
        await updateLesson(lessonId, _data);
        gtag(GTAG_CALENDAR_EDIT_EVENT);
      }
      setIsLoading(false);
      await handleCloseModal();
      await fetchLessons();
      await updateCalendarEvents();
    } catch (error: any) {
      setIsLoading(false);
      const defaultErrors = ['Error: ', 'Time already reserved or teacher cannot start at this time!'];
      const errors: any = error.error.response.data;
      const messages: any = Object.values(errors);
      toast.error(errors ? messages?.[0]?.[0] : defaultErrors);
    }
  };

  const handleSelectCategory = (category: string) => {
    setValue('event_type', category);
  };

  const optionsCategory = [
    {
      value: EVENT_TYPES['lesson'].id,
      action: () => {
        handleSelectCategory(EVENT_TYPES['lesson'].id);
      },
      label: EVENT_TYPES['lesson'].name,
      icon: <GoDot size={14} className={`${styles['icon-dot']} ${styles['icon-dot--blue']}`} />,
    },
    {
      value: EVENT_TYPES['event'].id,
      action: () => {
        handleSelectCategory(EVENT_TYPES['event'].id);
      },
      label: EVENT_TYPES['event'].name,
      icon: <GoDot size={14} className={`${styles['icon-dot']} ${styles['icon-dot--green']}`} />,
    },
    {
      value: EVENT_TYPES['personal_appointment'].id,
      action: () => {
        handleSelectCategory(EVENT_TYPES['personal_appointment'].id);
      },
      label: EVENT_TYPES['personal_appointment'].name,
      icon: <GoDot size={14} className={`${styles['icon-dot']} ${styles['icon-dot--red']}`} />,
    },
  ];

  const optionsStudent = students.map(student => ({
    action: () => {
      setValue('student', student.url);
      setValue('fee', student.cost_per_lesson || '');
    },
    label: student.name,
  }));

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={() => {
        handleCloseModal();
        reset();
        setIsLoading(false);
      }}
      title={`${displayModalLesson} event`}
      className={cs(styles['add-lesson-modal'], styles['custom-modal-mb'])}
      contentClassName={styles['content-modal-mb']}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading || isSubmitting}
      rightButtonLabel={displayModalLesson === 'New' ? 'Create new event' : 'Save event'}
    >
      <div className={styles['add-lesson-modal-body']}>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles['section']}>
              <InputField
                size="small"
                inputProps={{ ...register('summary'), placeholder: 'Title e.g Piano lessons', value: watch('summary') }}
                error={errors.summary?.message}
              />
            </div>
            <div className={styles['section']}>
              <DropdownSelect
                label={students.find(s => s.url === watch('student'))?.name || 'Add student'}
                className={styles['category-dropdown']}
                classNameToggle={styles['category-dropdown-toggle']}
                options={optionsStudent}
                error={errors.student?.message}
                onClear={() => setValue('student', '')}
                isClearable={true}
                required={false}
              />
            </div>
            {watch('event_type') === EVENT_TYPES['lesson'].id && (
              <div className={styles['section']}>
                <InputField
                  label="Lesson price"
                  value={watch('fee') || ''}
                  inputProps={{ ...register('fee'), type: 'number' }}
                  error={errors.fee?.message}
                />
              </div>
            )}
            <Row className={styles['section']}>
              <Col xs={6}>
                <DateInput
                  label="Select date"
                  value={watch('date') || ''}
                  onChange={date => {
                    setValue('date', (date && moment(date).format('MM/DD/YYYY')) || '');
                  }}
                  error={errors.date?.message}
                />
              </Col>
              <Col xs={6}>
                <DropdownSelect
                  label={
                    optionsCategory.find((student: any) => student.value === watch('event_type'))?.label || 'Category'
                  }
                  icon={optionsCategory.find((student: any) => student.value === watch('event_type'))?.icon}
                  className={styles['category-dropdown']}
                  classNameToggle={styles['category-dropdown-toggle']}
                  options={optionsCategory}
                  value={optionsCategory.find((student: any) => student.value === watch('event_type'))?.value}
                  error={errors.event_type?.message}
                  onClear={() => setValue('event_type', '')}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <TimeInput
                  label="Start"
                  value={watch('start') || ''}
                  onChange={date => {
                    setValue('start', (date && moment(date).format('MM/DD/YYYY HH:mm')) || '');
                  }}
                  error={errors.start?.message}
                />
              </Col>
              <Col xs={6}>
                <TimeInput
                  label="End"
                  value={watch('end') || ''}
                  onChange={date => {
                    setValue('end', (date && moment(date).format('MM/DD/YYYY HH:mm')) || '');
                  }}
                  error={errors.end?.message}
                />
              </Col>
            </Row>
            <div className={styles['section']}>
              <InputField
                label="Description"
                inputProps={{ ...register('description'), rows: 4, as: 'textarea', value: watch('description') }}
                error={errors.description?.message}
              />
            </div>
            <div>
              <InputField
                label="Location"
                inputProps={{ ...register('location'), value: watch('location') }}
                error={errors.location?.message}
              />
            </div>
          </Form>
        </FormProvider>
      </div>
    </CustomModal>
  );
};

export default ModalLesson;
