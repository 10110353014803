import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { MultiValue, OptionProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';

import styles from './AsyncStudentSelect.module.scss';

import Avatar from 'components/Avatar';
import { Contact, OptionsType } from 'types';

type AsyncStudentSelectProps = {
  value: MultiValue<OptionsType>;
  onChange: (students: MultiValue<OptionsType>) => void;
  placeholder?: string;
  error?: string;
};

const AsyncStudentSelect = (props: AsyncStudentSelectProps) => {
  const { value, onChange, placeholder, error } = props;
  const getJson = useSelector((store: any) => store.Api.allApis.getJson);
  const options = (inputValue: string, callback: any) => {
    const params = {
      limit: 10,
      embed: 'student',
      search: inputValue,
    };

    getJson(`/contacts`, params)
      .then((data: any) => {
        return data.results.map(({ name, email, url }: Contact) => ({
          label: name,
          value: url,
          email: email,
        }));
      })
      .then((opts: any) => callback(opts));
  };

  const OptionComponent = (props: OptionProps<OptionsType>) => {
    const { data } = props;
    return (
      <components.Option {...props} className={styles['wrapper-item-option']}>
        <Avatar
          size={31}
          letter={data?.label?.substring(0, 1) || ''}
          url={data?.student?.picture}
          className={styles['student-avatar']}
        />
        <p className={styles['student-name']}>{data.label}</p>
        <p className={styles['student-email']}>{data.email}</p>
      </components.Option>
    );
  };

  return (
    <div className={styles['student-select-wrapper']}>
      <AsyncSelect
        className="async-select"
        name="contacts"
        value={value}
        onChange={onChange}
        cacheOptions
        defaultOptions
        isClearable
        isMulti
        loadOptions={debounce(options)}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Option: OptionComponent,
        }}
        styles={{
          multiValue: base => ({
            ...base,
            backgroundColor: '#E9F0FE',
            color: '#2264F2',
            borderRadius: '30px',
            padding: '4px',
          }),
          multiValueLabel: base => ({
            ...base,
            color: '#2264F2',
          }),
          multiValueRemove: base => ({
            ...base,
            ':hover': {
              backgroundColor: '#E9F0FE',
            },
          }),
        }}
      />
      <div className={styles['error-feedback']}>{error}</div>
    </div>
  );
};

export default AsyncStudentSelect;
