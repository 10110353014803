import type { QueryClient } from '@tanstack/query-core';

import { config } from '../../utils/config';
import { mapValues } from '../utils/utils';

import { delJson } from './method/del-json';
import { getJson } from './method/get-json';
import { patchJson, patchMultipartPayloadJson } from './method/patch-json';
import { postJson } from './method/post-json';
import { putJson } from './method/put-json';
import { putMultipartPayloadJson } from './method/put-multipart-json';

import { TFetcherData, TFetcherMethod } from 'types';

export const createFetcher = (
  authToken: string,
  onUnauthorized: () => void,
  queryClient: QueryClient,
): Record<string, TFetcherMethod> => {
  const interceptor = (method: TFetcherMethod, url: string, data: TFetcherData) => {
    return method(`${config.api_endpoint}${url}`, { data, authToken });
  };

  const parseJsonResp = (resp: Response) => {
    if (resp && resp.status === 401) {
      onUnauthorized();
      return new Promise(() => {}); // Empty promise
    } else if (resp && resp.status === 404) {
      return Promise.resolve('Api not found.');
    } else if (resp && resp.status === 400) {
      return Promise.reject(resp.json()); //return error
    } else if (resp && resp.status === 204) {
      return Promise.resolve();
    } else {
      try {
        return resp.json();
      } catch (e) {
        return Promise.resolve();
      }
    }
  };

  const methods = mapValues(
    { getJson, postJson, patchJson, patchMultipartPayloadJson, putJson, putMultipartPayloadJson, delJson },
    (method: TFetcherMethod) => (url: string, data: TFetcherData) => interceptor(method, url, data).then(parseJsonResp),
  );

  methods.getJson = (url: string, data: TFetcherData) => {
    return queryClient.fetchQuery({
      queryKey: [url, data],
      queryFn: () => interceptor(getJson, url, data).then(parseJsonResp),
    });
  };

  return methods;
};
