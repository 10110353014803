import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Facebook, Youtube } from 'iconsax-react';
import { useState } from 'react';

import Logo from './components/logo';

import './template.css';

const TemplateAnonymous = ({ children }: { children: React.ReactNode }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const items = {
    Home: '',
    Features: 'features',
    Pricing: 'pricing',
  };

  return (
    <>
      <header
        className={classNames('absolute w-full py-0.5 md:py-1.5 z-20 text-dark', { 'max-md:mobile': mobileMenuOpen })}
      >
        <div className="container flex items-center py-4">
          <a href="" className="no-underline">
            <Logo />
          </a>

          <nav className="md:ml-10 text-2xl md:text-sm hidden items-start flex-col h-full font-medium md:inline-block">
            {Object.entries(items).map(([text, href]) => (
              <a key={text} href={href} className="py-3 md:py-2 md:mx-3">
                {text}
              </a>
            ))}

            <span className="mt-auto w-full font-medium text-base text-center flex md:hidden flex-col gap-2 pt-4 pb-6 border-t border-t-gray-200">
              <a href="signin" className="inline-block p-3 rounded-full border border-gray-200">
                Sign in
              </a>
              <a href="signup" className="inline-block p-3 text-white bg-primary rounded-full">
                Get started for free
              </a>
            </span>
          </nav>

          <div className="ml-auto flex gap-2 items-center z-10">
            <a href="signin" className="py-2.5 px-5 font-medium text-sm rounded-full hidden md:inline-block">
              Sign in
            </a>
            <a href="signup" className="py-2.5 px-5 font-medium text-sm text-white bg-primary rounded-full">
              Get started
            </a>

            <button type="button" onClick={() => setMobileMenuOpen(value => !value)} className="inline-block md:hidden">
              {(mobileMenuOpen && <XMarkIcon className="size-6" />) || <Bars3Icon className="size-6" />}
            </button>
          </div>
        </div>
      </header>
      {children}
      <footer className="pt-[64px] md:pt-[84px] max-md:border-t max-md:border-t-gray-200">
        <div className="container">
          <div className="flex lg:mb-[144px] flex-col lg:flex-row">
            <div className="text-gray-800 text-sm md:text-base max-w-[550px]">
              <a href="" className="inline-block mb-4 md:mb-6 no-underline">
                <Logo />
              </a>
              <p>
                Manage your teaching workflow by adding students, scheduling lessons and performances, assigning
                homework, and handling billing all in one streamlined platform
              </p>
            </div>

            <div className="grid grid-cols-2 my-[64px] md:my-12 lg:my-0 lg:grid-cols-2 gap-2 md:gap-7 text-sm md:text-base lg:ml-auto">
              <div className="w-[190px]">
                <p className="text-gray-500">Product</p>
                <ul className="text-gray-900 flex flex-col gap-3 mt-3">
                  <li>
                    <a href="contact-us">Contact Us</a>
                  </li>
                  <li>
                    <a href="signup">Sign up</a>
                  </li>
                  <li>
                    <a href="signin">Login</a>
                  </li>
                  <li>
                    <a href="https://musopen.org/about/" target="_blank">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="https://musopen.org/faq/" target="_blank">
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>

              <div className="w-[190px]">
                <p className="text-gray-500">Legal</p>
                <ul className="text-gray-900 flex flex-col gap-3 mt-3">
                  <li>
                    <a href="https://musopen.org/page/tos/" target="_blank">
                      Terms of Use
                    </a>
                  </li>
                  <li>
                    <a href="https://musopen.org/page/privacy/" target="_blank">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="border-t border-t-gray-200 py-4 md:py-6 text-sm text-gray-600 flex max-md:flex-col max-md:gap-4 md:items-center justify-between">
            <p>All rights reserved © {new Date().getFullYear()} Adagio, Inc.</p>
            <ul className="gap-5 flex">
              <li>
                <a href="https://www.youtube.com/@adagio-dot-org/" target="_blank">
                  <Youtube color="#596579" variant="Bold" />
                </a>
              </li>
              <li>
                <a href="https://bsky.app/profile/adagio-org.bsky.social" target="_blank">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0012 11.4535C11.0788 9.53897 8.5657 5.97133 6.22962 4.21155C4.54403 2.9421 1.8125 1.95953 1.8125 5.08555C1.8125 5.70961 2.1691 10.3302 2.37861 11.0803C3.10583 13.688 5.75648 14.3531 8.11421 13.9507C3.99288 14.6543 2.94472 16.985 5.20884 19.3157C9.50879 23.7423 11.3892 18.2051 11.8707 16.7863C11.9598 16.5243 12.0012 16.4027 12.0012 16.5103C12.0012 16.4027 12.0426 16.5243 12.1317 16.7863C12.6132 18.2051 14.4936 23.7423 18.7936 19.3157C21.0577 16.985 20.0095 14.6543 15.8882 13.9507C18.2459 14.3531 20.8966 13.688 21.6238 11.0803C21.8333 10.3302 22.1899 5.70961 22.1899 5.08555C22.1899 1.95953 19.4587 2.9421 17.7728 4.21155C15.4367 5.97133 12.9236 9.53897 12.0012 11.4535Z"
                      fill="#596579"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/musopenorg" target="_blank">
                  <Facebook color="#596579" variant="Bold" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ({ children, isAuthenticated }: { children: React.ReactNode; isAuthenticated: Boolean }) => {
  if (!isAuthenticated) {
    return <TemplateAnonymous>{children}</TemplateAnonymous>;
  }

  return null;
};
