import { default as cs } from 'classnames';
import {
  TaskSquare as AssignmentsIcon,
  MoneySend as BillingIcon,
  Calendar as CalendarIcon,
  ClipboardText as FeedIcon,
  MessageQuestion as HelpIcon,
  Category2 as HomeIcon,
  LogoutCurve as LogoutIcon,
  ArchiveBook as NotesIcon,
  Setting as SettingsIcon,
  Profile2User as StudentsIcon,
} from 'iconsax-react';
import { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import ClassSwitch from '../ClassSwitch';
import Logo from '../logo';

import styles from './Sidebar.module.scss';

import { logOut } from 'actions/AuthActions';
import UserGuide from 'components/UserGuide';
import useCurrentPath from 'hooks/useCurrentPath';

type SidebarProps = {
  children: ReactNode;
  dispatch: Dispatch;
};
const Sidebar = (props: SidebarProps) => {
  const { children, dispatch } = props;
  const { route } = useCurrentPath();

  const handleLogout = async () => {
    dispatch(logOut());
  };

  const items = [
    {
      title: 'Home',
      route: '/home',
      matchingRoutes: ['/home'],
      icon: HomeIcon,
    },
    {
      title: 'Feed',
      route: '/feed',
      matchingRoutes: ['/feed'],
      icon: FeedIcon,
    },
    {
      title: 'Students',
      route: '/students',
      matchingRoutes: ['/students', '/students/:id'],
      icon: StudentsIcon,
    },
    // TODO: Comment out to develop message features
    // {
    //   title: 'Messages',
    //   route: '/messages',
    //   matchingRoutes: ['/messages'],
    //   icon: MessagesIcon,
    // },
    {
      title: 'Calendar',
      route: '/calendar',
      matchingRoutes: ['/calendar'],
      icon: CalendarIcon,
    },
    {
      title: 'Billing',
      route: '/billing',
      matchingRoutes: ['/billing'],
      icon: BillingIcon,
    },
    {
      title: 'Notes',
      route: '/notes',
      matchingRoutes: ['/notes'],
      icon: NotesIcon,
    },
    {
      title: 'Assignments',
      route: '/assignments',
      matchingRoutes: ['/assignments'],
      icon: AssignmentsIcon,
    },
  ];

  const footerItems = [
    {
      title: 'Settings',
      route: '/settings',
      matchingRoutes: ['/settings'],
      icon: SettingsIcon,
      asLink: true,
    },
    {
      title: 'Help',
      route: '/help',
      matchingRoutes: ['/help/:slug?'],
      icon: HelpIcon,
      asLink: true,
    },
    {
      title: 'Log out',
      matchingRoutes: [''],
      icon: LogoutIcon,
      asLink: false,
      action: () => handleLogout(),
    },
  ];

  return (
    <div className={styles['main-layout']}>
      <div className={styles['side-bar']}>
        <div className={styles['side-bar-body']}>
          {/* Bootstrap overrides 'px-3' class, so can't use it*/}
          <div className="mb-4 ps-3 pe-3">
            <a href="" className="no-underline">
              <Logo />
            </a>
          </div>
          <div className="mb-4">
            <ClassSwitch />
          </div>
          <div className={styles['side-bar-items']}>
            {items.map(({ icon: Icon, ...item }, index) => (
              <Link
                key={index}
                to={item.route}
                className={cs(
                  styles['item-link-wrapper'],
                  item.matchingRoutes.includes(route.path) ? styles['active'] : '',
                )}
              >
                <div className={styles['icon-wrapper']}>
                  <Icon variant={item.matchingRoutes.includes(route.path) ? 'Bold' : 'Linear'} />
                </div>
                <div className={cs(styles['item'], item.matchingRoutes.includes(route.path) ? styles['active'] : '')}>
                  {item.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className={styles['side-bar-footer']}>
          <div className={styles['side-bar-items']}>
            {footerItems.map(({ icon: Icon, ...item }, index) => (
              <Fragment key={index}>
                {item.asLink ? (
                  <Link
                    to={item?.route || ''}
                    className={cs(
                      styles['item-link-wrapper'],
                      item?.matchingRoutes.includes(route.path) ? styles['active'] : '',
                    )}
                  >
                    <div className={styles['icon-wrapper']}>
                      <Icon variant={item.matchingRoutes.includes(route.path) ? 'Bold' : 'Linear'} />
                    </div>
                    <div
                      className={cs(styles['item'], item.matchingRoutes.includes(route.path) ? styles['active'] : '')}
                    >
                      {item.title}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={cs(
                      styles['item-link-wrapper'],
                      item.matchingRoutes.includes(route.path) ? styles['active'] : '',
                    )}
                    onClick={() => (item?.action ? item?.action() : null)}
                  >
                    <div className={styles['icon-wrapper']}>
                      <Icon />
                    </div>
                    <div
                      className={cs(styles['item'], item.matchingRoutes.includes(route.path) ? styles['active'] : '')}
                    >
                      {item.title}
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className={styles['main-area']}>{children}</div>
      <UserGuide />
    </div>
  );
};

export default connect(null)(Sidebar);
