import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { MultiValue, OptionProps, SingleValue, components } from 'react-select';
import AsyncSelect from 'react-select/async';

import styles from './AsyncMusicSelect.module.scss';

import Avatar from 'components/Avatar';
import { MusicType, OptionsType } from 'types';

type AsyncMusicSelectProps = {
  value: SingleValue<OptionsType>;
  onChange: (piece: MultiValue<OptionsType> | SingleValue<OptionsType>) => void;
  placeholder?: string;
  error?: string;
};

const AsyncMusicSelect = (props: AsyncMusicSelectProps) => {
  const { value, onChange, placeholder, error } = props;
  const getJson = useSelector((store: any) => store.Api.allApis.getJson);
  const options = (inputValue: string, callback: any) => {
    const params = {
      limit: 10,
      ordering: '-practitioners.count',
      search: inputValue,
    };

    getJson(`/music`, params)
      .then((data: any) => {
        return data.results.map(({ title, url }: MusicType) => ({
          label: title,
          value: url,
        }));
      })
      .then((opts: any) => callback(opts));
  };

  // eslint-disable-next-line
  const OptionComponent = (props: OptionProps<OptionsType>) => {
    const { data } = props;
    return (
      <components.Option {...props} className={styles['wrapper-item-option']}>
        <Avatar
          size={31}
          letter={data?.label?.substring(0, 1) || ''}
          url={data?.student?.picture}
          className={styles['student-avatar']}
        />
        <p className={styles['student-name']}>{data.label}</p>
        <p className={styles['student-email']}>{data.email}</p>
      </components.Option>
    );
  };

  return (
    <div className={styles['music-select-wrapper']}>
      <AsyncSelect
        className="async-select"
        name="music"
        value={value?.value ? value : null}
        onChange={onChange}
        cacheOptions
        defaultOptions
        isClearable
        loadOptions={debounce(options)}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          // Option: OptionComponent,
        }}
        styles={{
          multiValueLabel: base => ({
            ...base,
            color: '#2264F2',
          }),
          multiValueRemove: base => ({
            ...base,
            ':hover': {
              backgroundColor: '#E9F0FE',
            },
          }),
        }}
      />
      <div className={styles['error-feedback']}>{error}</div>
    </div>
  );
};

export default AsyncMusicSelect;
