export const ASSIGNMENT_TYPE = {
  text: 'text',
  audio: 'audio',
  video: 'video',
};

export const ASSIGNMENT_TYPE_OPTIONS = [
  {
    label: 'Assignment',
    value: ASSIGNMENT_TYPE.text,
  },
  {
    label: 'Watch video or listen to audio',
    value: ASSIGNMENT_TYPE.video,
  },
  {
    label: 'Audio Recording',
    value: ASSIGNMENT_TYPE.audio,
  },
];

export const REDUX_AXIOS_ERROR_SUFFIX = '_FAIL';
export const REDUX_AXIOS_SUCCESS_SUFFIX = '_SUCCESS';
