import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrgs, setActiveGroup } from '../organizationsSlice';

import OrganizationsModal from './OrganizationsModal';

export default () => {
  const dispatch = useDispatch();
  const { orgs } = useSelector(state => state.organizations);
  const [modal, setModal] = useState();

  useEffect(() => {
    dispatch(getOrgs());
  }, [dispatch]);

  const handleClick = value => {
    dispatch(setActiveGroup(value));
  };

  // eslint-disable-next-line no-console
  console.log(orgs);
  if (!orgs || !orgs.length) {
    return (
      <div className="py-5 text-center">
        <div className="spinner-border big" />
      </div>
    );
  }

  return (
    <div className="py-4 px-5">
      <div className="px-4">
        <span className="float-end cursor-pointer">
          <span onClick={() => setModal(true)} className="material-icons-outlined">
            edit
          </span>
        </span>
        <h2 className="page-header">{orgs[0].name}</h2>
        <ul className="p-0 m-0">
          {orgs[0].groups.map(group => (
            <li key={group.url} className="d-flex justify-content-between align-items-center border-bottom py-3">
              <span type="button" onClick={() => handleClick(group.url)}>
                <span className="material-icons-outlined" style={{ marginRight: '.5em' }}>
                  group
                </span>
                {group.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <OrganizationsModal show={!!modal} onHide={() => setModal()} org={orgs[0]} />
    </div>
  );
};
