import { UPDATE_ALL_APIS } from '../actions/AuthActions';

import { TReduxAction } from 'types';

const initialState = {
  allApis: null,
};

export default (state = initialState, action: TReduxAction) => {
  switch (action.type) {
    case UPDATE_ALL_APIS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
