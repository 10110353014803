/* eslint-disable import/no-extraneous-dependencies */
import { APIProvider } from '@vis.gl/react-google-maps';
import moment, { MomentInput } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Calendar as CalendarContainer, SlotInfo, ToolbarProps, Views, momentLocalizer } from 'react-big-calendar';
import { Offcanvas, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RRule, RRuleSet } from 'rrule';

import styles from './Calendar.module.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import DrawerLesson from './DrawerLesson/DrawerLesson';
import EventWrapperCalendar from './EventWrapperCalendar';
import ToolbarCalendar from './ToolbarCalendar';

import {
  changeLesson,
  changeTeacherSchedule,
  createLesson,
  fetchLessons,
  fetchTeacherSchedule,
  updateLesson,
  updateTeacherSchedule,
} from 'actions/ScheduleActions';
import { fetchStudents } from 'actions/StudentActions';
import { CalendarType, Contact, Lesson, LessonEvent, ScheduleType } from 'types';
import { REGISTRATION_TYPES } from 'utils/config';

type CalendarProps = {
  allApis: any;
  calendar: CalendarType;
  schedule: ScheduleType;
  lessons: { results: Lesson[] };
  eventType: string;
  students: Contact[];
  isFetchingSchedule: boolean;
  isFetchingLessons: boolean;
  fetchTeacherSchedule: () => void;
  fetchLessons: () => void;
  fetchStudents: any;
  createLesson: (data: LessonEvent) => void;
  updateLesson: (id: string | number | undefined, data: LessonEvent) => void;
};

const TIME_60MIN = 60;

const Calendar = (props: CalendarProps) => {
  const {
    lessons,
    schedule,
    fetchTeacherSchedule,
    fetchLessons,
    fetchStudents,
    calendar,
    isFetchingLessons,
    isFetchingSchedule,
    students,
    allApis,
    createLesson,
    eventType,
    updateLesson,
  } = props;

  const [scrollToDate, setScrollToDate] = useState<any>();
  const [studentsNotInContacts, setStudentsNotInContacts] = useState<any>([]);
  const [initializeDataModal, setInitializeDataModal] = useState<LessonEvent>();
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const localizer = momentLocalizer(moment);
  const getDays = () => ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const getUnitOfTime = useCallback(
    () => ({
      value: schedule.time_increment,
      unit: 'minutes',
    }),
    [schedule.time_increment],
  );

  const getLessonEvents = (lessons: any[]) => {
    const data: any[] = [];

    const getLessonTitle = (lesson: any): string => {
      if (lesson.summary) {
        return lesson.summary;
      }

      let title = 'Lesson';
      if (lesson.student) {
        const name = lesson.student_contact_name || lesson.student.first_name + ' ' + lesson.student.last_name;
        title += `: ${name}`;
      }

      return title;
    };

    lessons.forEach((lesson: any) => {
      if (lesson.status === 'pending' || lesson.status === 'approved') {
        const name = lesson?.student_contact_name || lesson?.student?.first_name + ' ' + lesson?.student?.last_name;
        data.push({
          url: lesson.url,
          title: (lesson && getLessonTitle(lesson)) || '',
          start: new Date(lesson?.start),
          end: new Date(lesson?.end),
          allDay: false,
          contacts: lesson.contacts,
          fee: lesson.fee,
          desc: lesson.description,
          userUrl: lesson.students && lesson.students[0],
          location: lesson.location,
          auto_invoicing: lesson.auto_invoicing,
          summary: lesson.summary,
          description: lesson.description,
          event_type: lesson.event_type,
          registration: lesson.registration,
          visibility: lesson.visibility,
          status: lesson.status,
          name: name,
          recurrence_end: lesson.recurrence_end,
          recurrence_type: lesson.recurrence_type,
        });
      }
    });

    return data;
  };

  const getAvailableTimeEvents = useCallback(
    (schedule: any) => {
      const data = [];

      const rruleSet = new RRuleSet();
      const startDate = moment().subtract(1, 'week').startOf('week');

      getDays().forEach((day, index) => {
        if (index !== 0) startDate.add(1, 'day');

        if (schedule[day] === true) {
          const start = moment(schedule[day + '_start'], 'HH:mm:ss');
          const end = moment(schedule[day + '_end'], 'HH:mm:ss');

          rruleSet.rrule(
            new RRule({
              freq: RRule.WEEKLY,
              count: 3,
              dtstart: startDate.clone().hours(start.hours()).minutes(start.minutes()).toDate(),
            }),
          );

          rruleSet.rrule(
            new RRule({
              freq: RRule.WEEKLY,
              count: 3,
              dtstart: startDate.clone().hours(end.hours()).minutes(end.minutes()).toDate(),
            }),
          );
        }
      });

      // every 2 items is a pair (start time and end time of a period)
      const timePeriods = rruleSet.all();

      for (let index = 0; index < timePeriods.length; index += 2) {
        const unitOfTime = getUnitOfTime();

        let start = moment(timePeriods[index]);
        let end = moment(timePeriods[index + 1]);

        do {
          let e = null;
          const s = start.clone();

          if (unitOfTime.value === TIME_60MIN && s.minutes() !== 0) {
            start.minutes(0);
          }

          start.add(unitOfTime.value as any, unitOfTime.unit);

          if (start.isAfter(end)) {
            e = end.clone();
          } else {
            e = start.clone();
          }

          data.push({
            title: 'Available',
            status: 'available',
            start: s.toDate(),
            end: e.toDate(),
            allDay: false,
          });
        } while (start.isBefore(end));
      }

      return data;
    },
    [getUnitOfTime],
  );

  const updateCalendarEvents = useCallback(async () => {
    const selected = moment();
    const scheduled_key = selected.format('dddd').toLowerCase() + '_start';
    const start = moment(schedule[scheduled_key as keyof ScheduleType] as MomentInput, 'HH:mm:ss');
    setScrollToDate(selected.hours(start.hours()).minutes(start.minutes()).toDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule.url]);

  const fetchDataCalendar = useCallback(async () => {
    await fetchLessons();
    await fetchTeacherSchedule();
    await fetchStudents();
    await updateCalendarEvents();
  }, [fetchLessons, fetchTeacherSchedule, updateCalendarEvents, fetchStudents]);

  useEffect(() => {
    fetchDataCalendar();
  }, [fetchDataCalendar]);

  const unit = getUnitOfTime().value || 60;
  const step = 30;

  const eventStyleGetter = (event: any) => {
    if (event.event_type === 'lesson') {
      return {
        style: {
          backgroundColor: event.status === 'pending' ? '#E9F0FE' : '#2264F2',
          borderColor: '#2264F2',
          color: event.status === 'pending' ? '#2264F2' : '#ffffff',
          zIndex: '11',
        },
      };
    } else if (event.event_type === 'event') {
      return {
        style: {
          backgroundColor: event.status === 'pending' ? '#bee8c3' : '#4BA356',
          borderColor: '#4BA356',
          color: event.status === 'pending' ? '#4BA356' : '#FFFFFF',
          zIndex: '11',
        },
      };
    } else if (event.event_type === 'personal_appointment') {
      return {
        style: {
          backgroundColor: event.status === 'pending' ? '#ffc4ab' : '#C83C00',
          borderColor: '#C83C00',
          color: event.status === 'pending' ? '#C83C00' : '#FFFFFF',
          zIndex: '11',
        },
      };
    }
  };

  const dayPropGetter = () => ({
    className: 'calendar-timeslot-group-wrapper',
  });

  const handleSelectEvent = (event: LessonEvent) => {
    setIsOpenDrawer(true);
    if (typeof event === 'undefined') {
      const currDate = new Date();
      const unitOfTime = getUnitOfTime();
      setInitializeDataModal({
        date: currDate,
        start: currDate,
        end: moment(currDate).add(unitOfTime.value, 'm').toDate(),
        event_type: 'lesson',
      });
      // openLessonModal(true);
      // setShowModalLessonDateInput(true);
    } else {
      setIsOpenDrawer(true);
      const student = Object.values(students).filter(
        (item: any) => item.student !== null && item.student.url === event.userUrl,
      );

      if (student.length === 0 && event.userUrl) {
        setStudentsNotInContacts([]);
        // setStudentsNotInContacts({ id: event.userUrl, name: event.desc, userUrl: event.userUrl });
      }

      const reg = Object.keys(REGISTRATION_TYPES).find(key => key === event.registration);

      setInitializeDataModal({
        status: event.status,
        date: new Date(event.start),
        start: new Date(event.start),
        end: new Date(event.end),
        event_type: event.event_type,
        url: event.url,
        student: event.userUrl,
        contacts: event.contacts,
        summary: event.summary,
        description: event.description,
        auto_invoicing: event.auto_invoicing,
        location: event.location,
        registration: reg || '',
        recurrence_type: event.recurrence_type,
        recurrence_end: event.recurrence_end,
        visibility: event.visibility,
        name: event.name,
        ...(event.fee && { fee: event.fee }),
      });
    }
  };

  const wrapperToolBar = (props: ToolbarProps) => {
    return <ToolbarCalendar {...props} handleSelectEvent={handleSelectEvent} />;
  };

  const handleSelectSlot = ({ start, end }: SlotInfo) => {
    setInitializeDataModal({
      date: start,
      start: start,
      end: end,
      event_type: 'lesson',
    });

    // setShowModalLessonDateInput(true);
    setIsOpenDrawer(true);
  };

  const handleCloseModal = () => {
    setIsOpenDrawer(false);
  };

  const studentsParsed = Object.values(students)
    .filter((item: Contact) => item.student !== null)
    .map((item: Contact) => ({
      id: item.id,
      name: item.name,
      url: item.url,
      userUrl: item.student?.url,
      cost: item.cost_per_lesson,
    }));

  const costsPerLesson = Object.values(students)
    .filter((item: Contact) => item.cost_per_lesson !== null)
    .map((item: Contact) => ({ cost: item.cost_per_lesson }));

  const isRender = () => {
    const data: any = {};
    if (lessons.results !== undefined) {
      const lessonEventsResult = getLessonEvents(lessons.results);
      const lessonEventsResultFiltered = lessonEventsResult.filter(lesson => {
        return eventType === 'all' ? lesson : lesson.event_type === eventType;
      });
      data.lessonEventsResult = [...lessonEventsResultFiltered];
    }

    if (Object.keys(schedule).length > 0) {
      const availableTimeEventsResult = getAvailableTimeEvents(schedule);
      data.availableTimeEventsResult = [...availableTimeEventsResult];
    }

    const getEvents =
      calendar.showAvailableTime === true
        ? [...data.availableTimeEventsResult, ...data.lessonEventsResult]
        : data.lessonEventsResult;

    return (
      <CalendarContainer
        selectable
        localizer={localizer}
        dayLayoutAlgorithm="overlap"
        events={getEvents}
        defaultView={Views.WEEK}
        step={step}
        timeslots={unit / step}
        showMultiDayTimes
        scrollToTime={scrollToDate}
        className={styles['rbc-calendar']}
        eventPropGetter={eventStyleGetter as any}
        dayPropGetter={dayPropGetter as any}
        components={{
          toolbar: wrapperToolBar,
          event: EventWrapperCalendar,
        }}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        popup={true}
      />
    );
  };

  const closeDrawer = () => {
    setIsOpenDrawer(false);
  };

  return (
    <div className={styles['container-calendar']}>
      {isFetchingLessons || isFetchingSchedule ? (
        <div className={styles['spinner-wrapper']}>
          <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
        </div>
      ) : (
        isRender()
      )}

      <Offcanvas
        placement="end"
        backdrop={false}
        show={isOpenDrawer}
        onHide={closeDrawer}
        className={styles['container-drawer-lesson']}
      >
        <APIProvider
          apiKey={process.env.REACT_APP_PLACES_API_KEY as string}
          solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
        >
          <DrawerLesson
            costsPerLesson={costsPerLesson}
            contacts={[...studentsParsed, ...studentsNotInContacts]}
            isShowModal={isOpenDrawer}
            handleCloseModal={handleCloseModal}
            allApis={allApis}
            updateCalendarEvents={updateCalendarEvents}
            createLesson={createLesson}
            initializeDataModal={initializeDataModal}
            updateLesson={updateLesson}
          />
        </APIProvider>
      </Offcanvas>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  allApis: state.Api.allApis,
  calendar: state.calendar,
  eventType: state.schedule.eventType,
  schedule: state.schedule.teacherSchedule,
  lessons: state.schedule.lessons,
  students: state.student.items,
  isFetchingSchedule: state.schedule.isFetchingSchedule,
  isFetchingLessons: state.schedule.isFetchingLessons,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTeacherSchedule: () => dispatch(fetchTeacherSchedule()),
  changeTeacherSchedule: (data: any) => dispatch(changeTeacherSchedule(data)),
  updateTeacherSchedule: (id: number, data: any) => dispatch(updateTeacherSchedule(id, data)),
  fetchLessons: () => dispatch(fetchLessons()),
  fetchStudents: () => dispatch(fetchStudents()),
  createLesson: (data: LessonEvent) => dispatch(createLesson(data)),
  updateLesson: (id: string | number | undefined, data: LessonEvent) => dispatch(updateLesson(id, data)),
  changeLesson: (data: any) => dispatch(changeLesson(data)),
  dispatch: (action: any) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
