import { lazy, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { fetchStudents } from './actions/StudentActions';
import { getClasses, getTeacher } from './actions/UserActions';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { useAppDispatch, useAppSelector } from './hooks';
import Template from './template';

const Styling = lazy(() => import('./components/Styling'));

const LayoutAnonymous = () => {
  return (
    <Template isAuthenticated={false}>
      <Outlet />
    </Template>
  );
};

const LayoutAuthenticated = () => {
  const classes = useAppSelector(state => state.classes);
  const dispatch = useAppDispatch();

  dispatch(getTeacher());
  dispatch(getClasses());

  useEffect(() => {
    if (!classes) return;
    dispatch(fetchStudents({ embed: 'student,assignments', _: Date.now() }));
  }, [classes, dispatch]);

  return (
    <>
      <Styling />
      <Sidebar>
        <Header />
        <div style={{ minHeight: 800, flex: '1 0 auto' }}>
          <Outlet />
        </div>

        {(() => {
          return (
            <ToastContainer
              className="toast-container-custom"
              position="bottom-right"
              autoClose={4000}
              hideProgressBar={true}
              rtl={false}
            />
          );
        })()}
      </Sidebar>
    </>
  );
};

export default ({ isAuthenticated }: { isAuthenticated: Boolean }) => {
  return isAuthenticated ? <LayoutAuthenticated /> : <LayoutAnonymous />;
};
