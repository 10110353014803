import { default as cs } from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { AiOutlinePause, AiOutlineWarning } from 'react-icons/ai';
import { HiPlay } from 'react-icons/hi2';
import { SlTrophy } from 'react-icons/sl';
import WaveSurfer from 'wavesurfer.js';

import styles from './Body.module.scss';

import { useToggle } from 'hooks';
import { FeedType } from 'types';

type BodyProps = {
  data: FeedType;
};

const Body = ({ data }: BodyProps) => {
  const { metadata, attachments, id } = data;
  const [waveForm, setWaveForm] = useState<any>(null);
  const [playWaveForm, togglePlayWaveForm] = useToggle();
  // eslint-disable-next-line
  const [isLoadedTrack, setIsLoadedTrack] = useState<boolean>(true);

  useEffect(() => {
    const track = document.getElementById(`${id}-track`) as HTMLMediaElement;
    if (track) {
      const waveform = WaveSurfer.create({
        barWidth: 3,
        barGap: 2,
        barHeight: 1,
        cursorWidth: 2,
        container: `#waveform-${id}`,
        height: 80,
        url: track.src,
        progressColor: '#2264F2',
        waveColor: '#C4C4C4',
        cursorColor: '#2264F2',
      });
      setWaveForm(waveform);
      waveform.on('finish', () => {
        togglePlayWaveForm();
      });
    }
  }, [id, togglePlayWaveForm]);

  const handlePlayWaveForm = () => {
    if (waveForm) {
      waveForm.playPause();
      togglePlayWaveForm();
    }
  };

  const WarningPopover = React.forwardRef(({ popper, children, show: _, ...props }: any, ref) => {
    return (
      <Popover ref={ref} body {...props}>
        {children}
      </Popover>
    );
  });

  return (
    <div className={styles['wrapper-body']}>
      {metadata.level && (
        <div className={styles['card-practice-level']}>
          <SlTrophy size={18} className={styles['cup-icon']} /> <p>Reached Level {metadata.level}</p>
        </div>
      )}
      <p className={styles['body-title']}>{metadata.title}</p>
      <div className={styles['body-content']}>
        <div className={styles['content__time']}>
          <p className={styles['title']}>Time Practiced</p>
          <p className={styles['content']}>
            {moment
              .utc(moment.duration(metadata.duration, 's').asMilliseconds())
              .format('HH:mm:ss')
              .replace(/^00:/, '')}
          </p>
        </div>
        <div className={styles['line']} />
        <div className={styles['content__composer']}>
          <p className={styles['title']}>Composer</p>
          <p className={styles['content']}>{metadata?.composer}</p>
        </div>
      </div>
      {attachments && (
        <div className={styles['card-attachments']}>
          {Object.entries(attachments).map(([key, values]) => (
            <div key={key}>
              {values.map((obj, vkey) => {
                const props = {
                  src: obj?.url,
                  className: styles['attachment-item'],
                };

                return (
                  <div key={vkey}>
                    <div hidden={key !== 'audio'} style={{ position: 'relative' }}>
                      <div id={`waveform-${id}`} className={styles['wrapper-audio']} />
                      <div className={styles['wrapper-controls']}>
                        {!playWaveForm ? (
                          <HiPlay
                            size={26}
                            className={cs(
                              styles['controls-icon'],
                              !isLoadedTrack ? styles['controls-icon__disabled'] : '',
                            )}
                            onClick={() => isLoadedTrack && handlePlayWaveForm()}
                          />
                        ) : (
                          <AiOutlinePause
                            size={26}
                            className={cs(
                              styles['controls-icon'],
                              !isLoadedTrack ? styles['controls-icon__disabled'] : '',
                            )}
                            onClick={() => isLoadedTrack && handlePlayWaveForm()}
                          />
                        )}
                      </div>
                      {!isLoadedTrack && (
                        <OverlayTrigger
                          trigger="hover"
                          overlay={<WarningPopover id="popover-contained">Audio preview not available</WarningPopover>}
                        >
                          <div className={styles['icon-warning']}>
                            <AiOutlineWarning size={18} />
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                    {key === 'audio' && <audio controls {...props} id={`${id}-track`} hidden={key === 'audio'} />}
                    {key === 'images' && <img {...props} />}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Body;
