import { default as cs } from 'classnames';
import { BaseEmoji, Picker } from 'emoji-mart';
import moment from 'moment';
import React, { HTMLProps, MouseEvent, forwardRef, useEffect, useState } from 'react';
import { Dropdown, Form, Spinner } from 'react-bootstrap';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { HiOutlineFaceSmile } from 'react-icons/hi2';
import { useDispatch } from 'react-redux';

import { GTAG_POST_COMMENT } from '../../../../../constants/gtag';

import styles from './Footer.module.scss';

import Avatar from 'components/Avatar';
import InputField from 'components/InputField';
import { createComment, likePost, unlikePost } from 'features/updates/updatesSlice';
import CommentSVG from 'theme/svg/CommentSVG';
import SendCommentSVG from 'theme/svg/SendCommentSVG';
import { FeedType, User } from 'types';
import 'emoji-mart/css/emoji-mart.css';
import { gtag } from 'utils';

type CustomToggleProps = HTMLProps<HTMLDivElement> & {
  onClick: (e: MouseEvent<HTMLElement>) => void;
};

type FooterProps = {
  data: FeedType;
  user?: User;
  keyTab: string;
};

const Footer = ({ data, user, keyTab }: FooterProps) => {
  const { comments, likes, id } = data;
  const [textComment, setTextComment] = useState<string>('');
  const [isSubmittingComment, setIsSubmittingComment] = useState<boolean>(false);
  const isLiked = likes?.find(like => like.user.id === user?.id);
  const [visibleComments, setVisibleComments] = useState<number>(0);
  const dispatch = useDispatch();

  const scrollToBottom = (id_element: string) => {
    const element = document.getElementById(id_element);
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  useEffect(() => {
    if (id) {
      scrollToBottom(`${id}`);
    }
  }, [id, keyTab, comments]);

  const handleReaction = () => {
    const action = (isLiked && unlikePost) || likePost;
    dispatch(action(id));
  };

  const handleSubmit = async () => {
    if (textComment) {
      setIsSubmittingComment(true);
      try {
        const response = await dispatch(createComment(id, textComment));
        if (response) {
          const payload: any = response.payload;
          setTextComment('');
          comments[comments.length] = payload?.data;
          setVisibleComments(visibleComments + 1);
          gtag(GTAG_POST_COMMENT);
        }
      } catch (e) {}
      setIsSubmittingComment(false);
      scrollToBottom(`${id}`);
    }
  };

  const handleEmojiPickup = (emoji: BaseEmoji) => {
    setTextComment(textComment + emoji.native);
    const inputEl = document.getElementById('input-field' + id.toString());
    const emojiEl = document.getElementById('dropdown-emoji');
    if (inputEl) inputEl?.focus();
    if (emojiEl) emojiEl.click();
  };

  const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(({ onClick }, ref) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <HiOutlineFaceSmile size={22} className={styles['input-icon-emoji']} />
    </div>
  ));

  return (
    <div className={styles['wrapper-footer']}>
      <div className={styles['card-react']}>
        <div className={cs(styles['react-item'])} onClick={handleReaction}>
          <div className={isLiked && styles['react-item__active']}>
            {isLiked ? (
              <AiFillHeart size={20} className={styles['icon-heart__active']} />
            ) : (
              <AiOutlineHeart size={20} className={styles['icon-heart']} />
            )}
            {likes?.length}
          </div>
        </div>
        <div className={styles['line']} />
        <div className={styles['react-item']} onClick={() => setVisibleComments(visibleComments ? 0 : 3)}>
          <div>
            <CommentSVG className={styles['icon-comment']} />
            {comments.length}
          </div>
        </div>
      </div>
      {visibleComments > 0 && (
        <>
          <div className={styles['card-comment']} id={id.toString()}>
            {visibleComments < comments.length && (
              <button onClick={() => setVisibleComments(visibleComments + 3)} className="px-3 pt-3 mb-1 fw-semibold">
                See more comments
              </button>
            )}

            {comments
              .slice(visibleComments * -1)
              .toReversed()
              .map((comment, index) => {
                const { datetime, message } = comment;
                return (
                  <div key={index} className={styles['wrapper-comment']}>
                    <div className={styles['comment__avatar']}>
                      <Avatar
                        url={comment?.user.picture}
                        size={32}
                        letter={comment?.user?.full_name?.substring(0, 1)}
                      />
                    </div>
                    <div className={styles['comment__content']}>
                      <div>
                        <span className={styles['content__name']}>{comment?.user.full_name}</span>

                        <span className={styles['content__time']}> &#183; {moment(datetime).fromNow()}</span>
                      </div>
                      <p className={styles['content__message']}>{message}</p>
                    </div>
                  </div>
                );
              })}

            {visibleComments >= comments.length && comments.length > 3 && (
              <button onClick={() => setVisibleComments(3)} className="px-3 pt-3 mt-1 fw-semibold">
                See less comments
              </button>
            )}
          </div>

          <div className={styles['card-input']}>
            <div className={styles['input__avatar']}>
              <Avatar url={user?.picture} size={32} letter={user?.first_name?.substring(0, 1) || ''} />
            </div>
            <div className={styles['comment-input-wrapper']}>
              <Form
                className={styles['comment-input']}
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <InputField
                  inputClassName={styles['comment-input-field']}
                  size="small"
                  value={textComment}
                  autoComplete="off"
                  inputProps={{
                    id: 'input-field' + id.toString(),
                    placeholder: 'Write a comment...',
                    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      const value = e.target?.value;
                      setTextComment(value);
                    },
                  }}
                />
              </Form>

              <Dropdown align="start">
                <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                <Dropdown.Menu className={styles['dropdown-menu']}>
                  <Picker onSelect={handleEmojiPickup} title="Pick your emoji" emoji="point_up" />
                  <Dropdown.Item hidden={true} id="dropdown-emoji"></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {!isSubmittingComment ? (
                <SendCommentSVG className={styles['input-icon-send']} onClick={handleSubmit} />
              ) : (
                <Spinner animation="border" role="status" size="sm" className={styles['comment-input-spiner']} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
