import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import styles from './styles.module.scss';

import AsyncMusicSelect from 'components/AsyncMusicSelect';
import Btn from 'components/Btn';
import CustomModal from 'components/CustomModal';
import DateInput from 'components/DateInput';
import InputField from 'components/InputField';
import NOTIFICATIONS from 'data/notifications/goal';
import { createGoal, deleteGoal, updateGoal } from 'features/students/studentsSlice';
import { Contact, IGoal } from 'types';

export const validIntervalUnits = [
  {
    label: 'Per Week',
    value: 'week',
  },
  {
    label: 'Per Day',
    value: 'day',
  },
];

type GoalModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  handleSubmitModal?: VoidFunction;
  className?: string;
  goalEdit?: IGoal;
  contact?: Contact;
};

const GoalModal = (props: GoalModalProps) => {
  const { isShowModal, handleCloseModal, goalEdit, handleSubmitModal, contact } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    id: yup.string(),
    duration: yup.string().required(),
    interval: yup.string().required(),
    end_date: yup.string().nullable(),
    music: yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    }),
  });

  const defaultValues = {
    id: '',
    name: '',
    duration: '',
    interval: validIntervalUnits[0].value,
    music: { label: '', value: '' },
    end_date: null,
  };

  const formMethods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    setIsLoading(false);
  }, [isShowModal]);

  useEffect(() => {
    if (goalEdit) {
      setValue('id', goalEdit?.id.toString());
      setValue('name', goalEdit?.name);
      setValue('duration', (goalEdit.duration / 60).toString());
      setValue('interval', goalEdit.interval);
      setValue('end_date', goalEdit.end_date);
      if (goalEdit.piece) {
        setValue('music', {
          value: goalEdit.piece.music,
          label: goalEdit.piece.title,
        });
      }
    }
  }, [goalEdit, setValue]);

  const onCloseModal = (isSubmitted: boolean = false) => {
    handleCloseModal();
    reset(defaultValues);
    if (isSubmitted && handleSubmitModal) {
      handleSubmitModal();
    }
  };

  type TGoalValues = yup.InferType<typeof schema>;

  const prepareDataBeforeSubmit = (values: TGoalValues) => {
    return {
      ...values,
      duration: values.duration ? parseInt(values.duration) * 60 : null,
      music: values.music && values.music.value,
      contact: contact?.url,
    };
  };

  const onSubmit = async (values: TGoalValues) => {
    setIsLoading(true);
    const data = prepareDataBeforeSubmit(values);

    // Create goal
    if (!goalEdit) {
      await Promise.all([dispatch(createGoal(data, { embed: 'piece' }))])
        .then(() => {
          toast.success(NOTIFICATIONS.create.success);
        })
        .catch(() => {
          toast.error(NOTIFICATIONS.create.error);
        })
        .finally(() => {
          setIsLoading(false);
          onCloseModal(true);
        });
    } else {
      await Promise.all([dispatch(updateGoal(goalEdit.id, data, { embed: 'piece' }))])
        .then(() => {
          toast.success(NOTIFICATIONS.update.success);
        })
        .catch(() => {
          toast.error(NOTIFICATIONS.update.error);
        })
        .finally(() => {
          setIsLoading(false);
          onCloseModal(true);
        });
    }
  };

  const handleDelete = async () => {
    if (!goalEdit?.id) {
      return;
    }
    setIsLoading(true);
    await Promise.all([dispatch(deleteGoal(goalEdit.id))])
      .then(() => {
        toast.success(NOTIFICATIONS.delete.success);
      })
      .catch(() => {
        toast.error(NOTIFICATIONS.delete.error);
      })
      .finally(() => {
        setIsLoading(false);
        onCloseModal(true);
      });
  };

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={onCloseModal}
      title={`${goalEdit?.id ? 'Edit' : ''} Goal`}
      customleftFooterComponent={
        goalEdit?.id ? (
          <>
            <Btn disabled={isLoading} onClick={() => handleDelete()} variant="danger">
              Delete
            </Btn>
          </>
        ) : (
          <>
            <Btn disabled={isLoading} onClick={() => onCloseModal()} variant="outline-secondary">
              Back
            </Btn>
          </>
        )
      }
      customRightFooterComponent={
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Btn isLoading={isLoading} variant="primary" type="submit">
              {goalEdit?.id ? 'Update ' : 'Create '}Goal
            </Btn>
          </Form>
        </FormProvider>
      }
      className={styles['custom-modal-mb']}
      contentClassName={styles['content-modal-mb']}
    >
      <div className={styles['goal-modal-body']}>
        <InputField
          label="Name"
          inputProps={{
            ...register('name'),
          }}
          value={watch('name')}
          error={errors.name?.message}
          autoComplete="off"
        />
        <Row>
          <Col xs={6}>
            <InputField
              label="Amount of time"
              inputProps={{
                ...register('duration'),
                type: 'text',
              }}
              value={watch('duration')}
              unit={'minute'}
            />
          </Col>
          <Col xs={6}>
            <Form.Select
              className="form-control mb-3"
              defaultValue={validIntervalUnits[0].value}
              {...register('interval')}
            >
              {validIntervalUnits.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <AsyncMusicSelect
          value={
            watch('music') && {
              label: watch('music').label as string,
              value: watch('music').value as string,
            }
          }
          onChange={(music: any) => {
            setValue('music', {
              label: music?.label,
              value: music?.value,
            });
          }}
          placeholder="Select Piece ..."
        />
        <Row>
          <Col xs={12}>
            <DateInput
              value={watch('end_date') as string}
              error={errors.end_date?.message}
              onChange={value => {
                if (value) setValue('end_date', moment(value).format('YYYY-MM-DD'));
                else setValue('end_date', null);
              }}
            />
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default GoalModal;
