/* eslint-disable import/no-extraneous-dependencies */
import {
  ArrowPathRoundedSquareIcon,
  Bars3BottomLeftIcon,
  CalendarDaysIcon,
  ChevronDownIcon,
  MapPinIcon,
  Squares2X2Icon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { startCase } from 'lodash';
import { useEffect, useRef } from 'react';
import { CloseButton, Dropdown, Form, InputGroup, Stack } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import calStyles from '../../../components/DateInput/DateInput.module.scss';
import categories from '../../../constants/categories';
import reccurence from '../../../constants/reccurence';
import { Contact, LessonEvent } from '../../../types';
import { REGISTRATION_TYPES } from '../../../utils/config';

import ChargeLesson from './ChargeLesson/ChargeLesson';
import styles from './DrawerLesson.module.scss';

import { fetchLessons } from 'actions/ScheduleActions';
import Avatar from 'components/Avatar';
import { gtag } from 'utils';

type DrawerLessonProps = {
  contacts: Contact[];
  costsPerLesson: any;
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  allApis: any; // TODO: add type
  updateCalendarEvents: VoidFunction;
  createLesson: (data: LessonEvent) => void;
  updateLesson: (id: string | number | undefined, data: LessonEvent) => void;
  initializeDataModal?: LessonEvent;
};

const DrawerLesson = (props: DrawerLessonProps) => {
  const {
    contacts,
    costsPerLesson,
    handleCloseModal,
    updateCalendarEvents,
    createLesson,
    initializeDataModal,
    updateLesson,
  } = props;
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>();
  const places = useMapsLibrary('places');

  const schema = yup
    .object({
      summary: yup.string(),
      date: yup.date().required(),
      start: yup.date().required(),
      end: yup.date().required(),
      reccuring_event: yup.string(),
      contacts: yup.mixed(),
      event_type: yup.string(),
      location: yup.string(),
      description: yup.string(),
      fee: yup.number(),
      auto_invoicing: yup.boolean(),
      default_rate: yup.boolean(),
    })
    .shape({});

  const formMethods = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      ...initializeDataModal,
      reccuring_event: initializeDataModal?.recurrence_type,
      contacts: initializeDataModal?.contacts ?? [],
    },
  });

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    setValue,
    register,
    watch,
  } = formMethods;
  const { ref: descriptionRef, ...descriptionProps } = register('description');
  const { ref: locationRef, ...locationProps } = register('location');

  const isNew = !initializeDataModal?.url;

  const updateContacts = (val: any) => {
    const items = watch('contacts');
    if (items.includes(val)) {
      items.pop(val);
    } else {
      items.push(val);
    }
    setValue('contacts', items);
  };

  type TAddLessonValues = yup.InferType<typeof schema>;
  const onSubmit = async (values: TAddLessonValues) => {
    const registration =
      (!isNew && Object.keys(REGISTRATION_TYPES).find((key: string) => key === initializeDataModal?.registration)) ||
      '';

    const start = new Date(
      values.date.getFullYear(),
      values.date.getMonth(),
      values.date.getDate(),
      values.start.getHours(),
      values.start.getMinutes(),
    );
    const end = new Date(
      values.date.getFullYear(),
      values.date.getMonth(),
      values.date.getDate(),
      values.end.getHours(),
      values.end.getMinutes(),
    );
    const data: LessonEvent = isNew
      ? {
          summary: values.summary,
          start: start,
          end: end,
          status: 'pending',
          contacts: values.contacts,
          fee: Number(values.fee),
          auto_invoicing: values.auto_invoicing,
          recurrence_type: values.reccuring_event,
          location: values.location,
          description: values.description,
          event_type: values.event_type,
        }
      : {
          summary: values.summary || '',
          start: start,
          end: end,
          contacts: values.contacts,
          auto_invoicing: values.auto_invoicing,
          fee: Number(values.fee),
          location: values.location,
          recurrence_type: values?.reccuring_event,
          description: values.description,
          event_type: values.event_type,
          visibility: initializeDataModal?.visibility || '',
          registration: registration,
          recurrence_end: initializeDataModal?.recurrence_end,
        };
    const lessonId = !isNew ? initializeDataModal?.url?.split('/')[5] : '';
    try {
      if (isNew) {
        await createLesson(data);
        gtag('event_creation');
      } else {
        await updateLesson(lessonId, data);
        gtag('event_update');
      }
      await handleCloseModal();
      await dispatch(fetchLessons({ _: new Date().getTime().toString() }));
      await updateCalendarEvents();
    } catch (error: any) {
      const defaultErrors = ['Error: ', 'Time already reserved or teacher cannot start at this time!'];
      const errors: any = error.error.response.data;
      const messages: any = Object.values(errors);
      toast.error(errors ? messages?.[0]?.[0] : defaultErrors);
    }
  };

  const categoryOptions = [
    {
      label: startCase(categories.CATEGORY_EVENT),
      value: categories.CATEGORY_EVENT,
      color: '#4ba356',
    },
    {
      label: startCase(categories.CATEGORY_LESSON),
      value: categories.CATEGORY_LESSON,
      color: '#c83c00',
    },
    {
      label: startCase(categories.CATEGORY_PERSONAL_APPOINTMENT),
      value: categories.CATEGORY_PERSONAL_APPOINTMENT,
      color: '#2264f2',
    },
  ];

  const reccurenceOptions = [
    {
      label: startCase(reccurence.RECCURENCE_DAILY),
      value: reccurence.RECCURENCE_DAILY,
    },
    {
      label: startCase(reccurence.RECCURENCE_WEEKLY),
      value: reccurence.RECCURENCE_WEEKLY,
    },
  ];

  const guestOptions = contacts
    ?.map(c => ({ label: c.name, value: c.url }))
    .filter(c => !watch('contacts').includes(c.value));

  const chargeOptions = costsPerLesson?.map(({ cost }: { cost: string }) => ({
    label: cost,
    value: cost,
  }));

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    };

    const autocomplete = new places.Autocomplete(inputRef.current, options);
    autocomplete.addListener('place_changed', () => {
      setValue('location', autocomplete.getPlace().formatted_address, { shouldDirty: true });
    });
  }, [places, setValue]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <InputGroup className="w-100">
              <Form.Control placeholder="Title e.g Piano lessons" {...register('summary')} className="border-0" />
              <InputGroup.Text className="bg-transparent p-0 border-0 text-secondary">
                <CloseButton onClick={handleCloseModal} />
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className={styles.body}>
            <Stack gap={3} className={styles.stack}>
              <Stack gap={0} direction="horizontal">
                <CalendarDaysIcon className="text-secondary" style={{ minWidth: '1rem' }} />
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Select date"
                      onChange={field.onChange}
                      selected={field.value}
                      className="form-control border-0"
                      calendarClassName={calStyles['calendar']}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="start"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Start time"
                      onChange={field.onChange}
                      selected={field.value}
                      className="form-control border-0 text-center"
                      calendarClassName={calStyles['calendar']}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  )}
                />
                <span>-</span>
                <Controller
                  control={control}
                  name="end"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="End time"
                      onChange={field.onChange}
                      selected={field.value}
                      className="form-control border-0 text-center"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  )}
                />
              </Stack>
              <InputGroup className="w-100">
                <InputGroup.Text className="bg-transparent p-0 border-0 text-secondary">
                  <ArrowPathRoundedSquareIcon />
                </InputGroup.Text>
                <Dropdown onSelect={val => setValue('reccuring_event', val ?? undefined)}>
                  <Dropdown.Toggle className="border-0 form-control text-start text-capitalize" variant="transparent">
                    {watch('reccuring_event') || <span className="text-secondary">Does not repeat</span>}
                    &nbsp;
                    <ChevronDownIcon className="text-secondary" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {reccurenceOptions.map((option: any, index: number) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={option.value}
                        active={watch('reccuring_event') == option.value}
                      >
                        <span style={{ fontWeight: 500 }}>{option.label}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
              <InputGroup className="w-100">
                <InputGroup.Text className="bg-transparent p-0 border-0 text-secondary">
                  <UsersIcon />
                </InputGroup.Text>
                <Dropdown onSelect={updateContacts}>
                  <Dropdown.Toggle className="border-0 form-control text-start text-capitalize" variant="transparent">
                    <span className={watch('contacts').length ? '' : 'text-secondary'}>Add guests</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {guestOptions.map((option: any, index: number) => (
                      <Dropdown.Item key={index} eventKey={option.value}>
                        <span style={{ fontWeight: 500 }}>{option.label}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="ps-3 d-flex gap-2" style={{ flexBasis: '100%' }}>
                  {watch('contacts').map((value: string) => {
                    const contact = contacts.find(c => c.url === value);
                    return (
                      contact && (
                        <span className="p-2 rounded-pill bg-light d-flex gap-2 align-items-center" key={value}>
                          <Avatar size={16} letter={contact.name.substring(0, 1)} className="avatar" />
                          {contact.name}
                          <XMarkIcon
                            className="ms-2 cursor-pointer text-secondary"
                            onClick={() => updateContacts(value)}
                          />
                        </span>
                      )
                    );
                  })}
                </div>
              </InputGroup>
              <InputGroup className="w-100">
                <InputGroup.Text className="bg-transparent p-0 border-0 text-secondary">
                  <Squares2X2Icon />
                </InputGroup.Text>
                <Dropdown onSelect={val => setValue('event_type', val ?? undefined)}>
                  <Dropdown.Toggle className="border-0 form-control text-start text-capitalize" variant="transparent">
                    {watch('event_type') || <span className="text-secondary">Select Category</span>}
                    &nbsp;
                    <ChevronDownIcon className="text-secondary" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {categoryOptions.map((option: any, index: number) => (
                      <Dropdown.Item key={index} eventKey={option.value} active={watch('event_type') == option.value}>
                        <span style={{ fontWeight: 500 }}>
                          <span className="me-2" style={{ color: option.color }}>
                            &#9679;
                          </span>
                          {option.label}
                        </span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
              <InputGroup className="w-100">
                <InputGroup.Text className="bg-transparent p-0 border-0 text-secondary">
                  <MapPinIcon />
                </InputGroup.Text>
                <Form.Control
                  {...locationProps}
                  placeholder="Add location"
                  className="border-0"
                  ref={(node: any) => {
                    locationRef(node);
                    inputRef.current = node;
                  }}
                />
              </InputGroup>
            </Stack>
            <ChargeLesson
              value={{
                fee: watch('fee'),
                auto_invoicing: watch('auto_invoicing'),
                default_rate: watch('default_rate'),
              }}
              onChange={val => {
                Object.entries(val).map((v: any) => setValue(v[0], v[1]));
              }}
              options={chargeOptions}
            />

            <Stack className={styles.stack}>
              <InputGroup className="w-100">
                <InputGroup.Text className="bg-transparent py-2 px-0 border-0 text-secondary d-block">
                  <Bars3BottomLeftIcon />
                </InputGroup.Text>
                <Form.Control
                  {...descriptionProps}
                  as="textarea"
                  placeholder="Add description"
                  className="border-0"
                  ref={(node: any) => {
                    descriptionRef(node);
                    watch('description');
                    if (node) {
                      node.style.height = 'auto';
                      node.style.height = node.scrollHeight + 'px';
                    }
                  }}
                />
              </InputGroup>
            </Stack>

            <div className={styles.footer}>
              <button type="submit" className="btn btn-primary" disabled={!isDirty || !isValid}>
                {isNew ? 'Create' : 'Update'} event
              </button>
            </div>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default DrawerLesson;
