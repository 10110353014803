import { useQuery } from '@tanstack/react-query';
import { default as cs } from 'classnames';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks';

import styles from './InvoicesComponent.module.scss';

import PageSection from 'components/PageSection';

export default () => {
  const { id } = useParams();
  const { getJson } = useAppSelector(state => state.Api.allApis);
  const { data, isLoading } = useQuery({
    queryKey: ['student', id, 'invoices'],
    queryFn: () => getJson(`/contacts/${id}/invoices/`),
  });

  const navigate = useNavigate();

  return (
    <PageSection className="mb-4" title="Invoices" wrapperClassName={styles['invoices-wrapper']}>
      {isLoading ? (
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      ) : (
        <>
          <div className={styles['table-wrapper']}>
            <table>
              <thead>
                <tr>
                  <th className={styles['header-1']}></th>
                  <th className={styles['header-2']}></th>
                  <th className={styles['header-3']}></th>
                  <th className={styles['header-4']}></th>
                </tr>
              </thead>
              <tbody>
                {data.results?.length === 0 ? (
                  <tr>
                    <td colSpan={4}>
                      <div className={styles['no-invoice']}>No invoices yet.</div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {data.results?.map((item: any, id: number) => (
                      <tr key={id}>
                        <td>
                          <div className={styles['colume-sub-1']}>{item.date}</div>
                        </td>
                        <td>
                          <div className={styles['colume-1']}>{item.description}</div>
                        </td>
                        <td>
                          <div className={styles['colume-2']}>
                            <p className={styles['colume-2-item']}>${item.amount}</p>
                            <p
                              className={cs(
                                styles[`${item.status.toLowerCase() === 'paid' ? 'colume-3--active' : 'colume-3'}`],
                                styles['colume-2-item-mb'],
                              )}
                            >
                              {item.status}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div
                            className={cs(
                              styles[`${item.status.toLowerCase() === 'paid' ? 'colume-3--active' : 'colume-3'}`],
                              styles['wrapper-colume-3'],
                            )}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td>
                          <div className={styles['colume-4']}></div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                <tr>
                  <td>
                    <div
                      className={styles['btn-load-more']}
                      onClick={() => {
                        navigate('/billing/');
                      }}
                    >
                      See all
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </PageSection>
  );
};
