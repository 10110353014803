import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../../hooks';

import styles from './BillingComponent.module.scss';

import { Contact } from 'types';

type BillingComponentProps = {
  students: Contact[];
};

const BillingComponent = (props: BillingComponentProps) => {
  const { students } = props;
  const { getJson } = useAppSelector(state => state.Api.allApis);
  const { data } = useQuery({
    queryKey: ['billing', 'earnings'],
    queryFn: () => getJson('/study/billing/earnings/'),
  });
  const studentss = students.filter(i => i.status !== 'archived');
  const billingElements = [
    {
      id: 'students',
      label: 'Students',
      value: studentss.length,
    },
    {
      id: 'earned_this_month',
      label: 'Earned This month',
      value: data?.summary.month.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      id: 'earned_this_year',
      label: 'Earned This year',
      value: data?.summary.year.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
  ];
  return (
    <div className={styles['billing-wrapper']}>
      <div className={styles['billing-header']}>
        <p className={styles['title']}>Billing</p>
        <Link className={styles['btn-view-billing']} to={'/billing'}>
          View billing history
        </Link>
      </div>
      <div className={styles['billing-body']}>
        {billingElements.map((item, index) => (
          <div key={index} className={styles['billing-item']}>
            <p className={styles['label']}>{item.label}</p>
            <p className={styles['value']}>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BillingComponent;
