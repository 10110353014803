import Select, { MultiValue } from 'react-select';

import styles from './MultiSelect.module.scss';

import { OptionsType } from 'types';

type MultiSelectProps = {
  value: MultiValue<OptionsType>;
  onChange: (values: MultiValue<OptionsType>) => void;
  placeholder?: string;
  options: OptionsType[];
};

const MultiSelect = (props: MultiSelectProps) => {
  const { value, onChange, placeholder, options } = props;

  return (
    <div className={styles['multi-select-wrapper']}>
      <Select
        className="async-select"
        name="Select..."
        value={value}
        onChange={onChange}
        isClearable
        isMulti
        options={options}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          multiValue: base => ({
            ...base,
            backgroundColor: '#E9F0FE',
            color: '#2264F2',
            borderRadius: '30px',
            padding: '4px',
          }),
          multiValueLabel: base => ({
            ...base,
            color: '#2264F2',
          }),
          multiValueRemove: base => ({
            ...base,
            ':hover': {
              backgroundColor: '#E9F0FE',
            },
          }),
        }}
      />
    </div>
  );
};

export default MultiSelect;
