import { useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

const routes = [
  { path: '/home', label: 'Home' },
  { path: '/feed', label: 'Feed' },
  { path: '/students', label: 'Students' },
  { path: '/students/:id', label: 'Students' },
  { path: '/assignments', label: 'Assignments' },
  { path: '/billing', label: 'Billing' },
  { path: '/notes', label: 'Notes' },
  { path: '/calendar', label: 'Calendar' },
  { path: '/settings', label: 'Settings' },
  { path: '/settings/organizations', label: 'Organizations' },
  { path: '/messages', label: 'Messages' },
  { path: '/help/:slug?', label: 'Help' },
];

const useCurrentPath = () => {
  const location = useLocation();

  const matchedRoutes: any = matchRoutes(routes, location.pathname);

  const label = useMemo(() => {
    return routes.find(i => i.path === matchedRoutes?.[0].route.path)?.label;
  }, [matchedRoutes]);

  return { currentPath: matchedRoutes?.[0].pathname, label, route: matchedRoutes?.[0]?.route || '' };
};

export default useCurrentPath;
