import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { GrFormClose } from 'react-icons/gr';
import { MdEditNote, MdOutlineDeleteForever } from 'react-icons/md';
import { TfiMoreAlt } from 'react-icons/tfi';

import { GTAG_ADD_NOTE } from '../../../constants/gtag';

import styles from './StudentNoteDetail.module.scss';

import Avatar from 'components/Avatar';
import Btn from 'components/Btn';
import ConfirmModal from 'components/ConfirmModal';
import DropdownSelect from 'components/DropdownSelect';
import InputField from 'components/InputField';
import { useToggle } from 'hooks';
import { Contact, NotesType, User } from 'types';
import { gtag } from 'utils';

type StudentNoteDetailProps = {
  studentSelected: Contact;
  user: User;
  students: Contact[];
  notes: NotesType;
  deleteNote: (id: number) => void;
  updateNote: (id: number, data: { description: string }) => void;
  addNote: (data: { student?: string; description: string }) => void;
};

type NoteEditFormProps = {
  noteId: number;
  initialValue: string;
};

const StudentNoteDetail = (props: StudentNoteDetailProps) => {
  const { studentSelected, user, notes, deleteNote, updateNote, addNote } = props;
  const [editNoteId, setEditNoteId] = useState<number | null>(null);
  const [deleteNoteId, setDeleteNoteId] = useState<number>();
  const [isShowModalDelete, setIsShowModalDelete] = useToggle();
  const [isLoadingSubmitEdit, setIsLoadingSubmitEdit] = useToggle();
  const [isLoadingSubmitAdd, setIsLoadingSubmitAdd] = useToggle();

  const contact =
    studentSelected.id !== -1
      ? studentSelected
      : ({
          ...studentSelected,
          student: { picture: user.picture },
          name: `${user.first_name} ${user.last_name}`,
        } as Contact);

  const name = contact.name || contact.email;

  const notesFiltered = notes.data.filter(note => note.contact === ((contact && contact.url) || null));

  const notesGrouped = _.groupBy(notesFiltered, note => moment(note.created).startOf('month').format('MMMM YYYY'));

  const handleEdit = async (noteId: number, description: string) => {
    try {
      setIsLoadingSubmitEdit();
      const data = { description };
      await updateNote(noteId, data);
      setEditNoteId(null);
      setIsLoadingSubmitEdit();
    } catch (e) {
      setIsLoadingSubmitEdit();
    }
  };

  const handleAdd = async (description: string) => {
    try {
      setIsLoadingSubmitAdd();
      const data = {
        contact: contact.url,
        description,
      };
      await addNote(data);
      gtag(GTAG_ADD_NOTE);
      setIsLoadingSubmitAdd();
    } catch (e) {
      setIsLoadingSubmitAdd();
    }
  };

  const handleDelete = (id: number) => {
    setDeleteNoteId(id);
    setIsShowModalDelete();
  };

  const onDelete = async () => {
    if (deleteNoteId) await deleteNote(deleteNoteId);
  };

  const dropdownOptions = [
    {
      label: 'Edit Note',
      action: (id: number) => setEditNoteId(id),
      icon: <MdEditNote size={20} className={styles['icon-options']} />,
    },
    {
      label: 'Delete Note',
      action: (id: number) => handleDelete(id),
      icon: <MdOutlineDeleteForever size={20} className={styles['icon-options']} />,
    },
  ];

  const NoteEditForm = (props: NoteEditFormProps) => {
    const { noteId, initialValue } = props;
    const [value, setValue] = useState<string>(initialValue);

    return (
      <Form
        className={styles['note-edit-wrapper']}
        onSubmit={() => (noteId ? handleEdit(noteId, value) : handleAdd(value))}
      >
        <InputField
          value={value}
          size="small"
          className={styles['note-edit-field']}
          inputProps={{ placeholder: 'Type new note message...', onChange: e => setValue(e.target.value) }}
        />
        <Btn
          variant="primary"
          className={styles['note-edit-btn']}
          isLoading={noteId ? isLoadingSubmitEdit : isLoadingSubmitAdd}
          onClick={() => (noteId ? handleEdit(noteId, value) : handleAdd(value))}
          type="submit"
        >
          {noteId ? 'Save' : 'Add'}
        </Btn>
        {!!noteId && <GrFormClose size={34} className={styles['note-edit-icon']} onClick={() => setEditNoteId(null)} />}
      </Form>
    );
  };

  return (
    <div className={styles['note-detail-wrapper']}>
      {contact && (
        <div className={styles['note-detail-header']}>
          <div className={styles['note-detail-header__avatar']}>
            <Avatar url={contact.student && contact.student.picture} size={72} letter={name?.[0] || ''} />
          </div>
          <p className={styles['note-detail-header__name']}>{name}</p>
        </div>
      )}
      <div className="mb-4">
        <NoteEditForm noteId={0} initialValue="" />
      </div>
      <div className={styles['note-detail-body']}>
        {Object.entries(notesGrouped).map(([month, monthNotes]) => (
          <div className={styles['note-detail-body__item']} key={month}>
            <p className={styles['item-month']}>{month}</p>
            {monthNotes.map(note => (
              <div className={styles['item-content']} key={note.url}>
                {(editNoteId === note.id && <NoteEditForm noteId={note.id} initialValue={note.description} />) || (
                  <>
                    <div className={styles['icon-more']}>
                      <DropdownSelect id={note.id} customToggleComponent={<TfiMoreAlt />} options={dropdownOptions} />
                    </div>
                    <div className={styles['content-description']}>{note.description}</div>
                    <div className={styles['content-created']}>{moment(note.created).format('LT - MMM D, YYYY')}</div>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <ConfirmModal
        isShowModal={isShowModalDelete}
        handleCloseModal={setIsShowModalDelete}
        title={'Delete Note'}
        submitAction={onDelete}
      >
        Are you sure you want to delete this note?
      </ConfirmModal>
    </div>
  );
};

export default StudentNoteDetail;
