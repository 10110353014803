import { PauseIcon, PlayIcon } from '@heroicons/react/24/outline';
import { default as cs } from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from './PracticeComponent.module.scss';

import { useGetPracticeByStudent } from 'common/api/apis';
import { DateUtils } from 'common/utils/date-utils';
import Btn from 'components/Btn';
import PageSection from 'components/PageSection';
import { Contact } from 'types';

interface IPiecePractice {
  folder: string | null;
  goals: string[];
  id: number;
  is_archived: boolean;
  music: any;
  practice_category: number | null;
  practiced_time: number;
  practices: string[];
  title: string;
  url: string;
}

interface IPracticesHistory {
  assignment: any;
  created: string;
  duration: number;
  id: number;
  modified: string;
  notes: string;
  picture: string;
  piece: IPiecePractice;
  practice_category: number | null;
  recording: string;
  recordings: string[];
  url: string;
}

type PracticeComponentProps = {
  contact?: Contact;
  isPagination?: boolean;
};

export default (props: PracticeComponentProps) => {
  const [practicesHistory, setPracticesHistory] = useState<IPracticesHistory[]>([]);
  const { contact, isPagination } = props;
  const [next, setNext] = useState<string>();
  const [audioSrc, setAudioSrc] = useState<string>();

  const defaultPracticeQueryParams = useMemo(
    () => ({
      embed: 'piece,music',
      fields: 'composer',
      ordering: '-created',
      limit: '10',
    }),
    [],
  );

  const [getPracticeByStudent, { isPending: loading }] = useGetPracticeByStudent({
    student_id: contact?.student?.id.toString(),
    next: next,
    query_params: defaultPracticeQueryParams,
  });

  const getPracticesHistory = useCallback(
    async (pagination?: string) => {
      let resp = await getPracticeByStudent({
        student_id: contact?.student.id,
        query_params: defaultPracticeQueryParams,
        next: pagination,
      });
      if (resp) {
        if (pagination) {
          setPracticesHistory([...practicesHistory, ...resp.results]);
        } else {
          setPracticesHistory(resp.results);
        }
        setNext(resp.next);
      }
    },
    [contact, defaultPracticeQueryParams, getPracticeByStudent, practicesHistory],
  );

  const handlePagination = () => {
    getPracticesHistory(next);
  };

  useEffect(() => {
    if (contact?.student && loading === null) {
      getPracticesHistory();
    }
  }, [contact, loading, getPracticesHistory]);

  const renderTimePractice = (practice: IPracticesHistory) => {
    const practiceTime = Math.floor(practice.duration / 60);
    const level = (value: number) => {
      if (value <= 20) return 'low';
      if (value <= 40) return 'medium';
      return 'high';
    };

    return <div className={styles[`time-${level(practiceTime)}`]}>{DateUtils.countdown()(practice.duration)}</div>;
  };

  return (
    <>
      <audio autoPlay={true} src={audioSrc} />
      <PageSection
        className="mb-4"
        title={'Practice History'}
        wrapperClassName={cs(styles['practice-wrapper'], styles['page-section-practice-mb'])}
      >
        {loading ? (
          <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
        ) : (
          <>
            <div className={styles['table-wrapper']}>
              <table>
                <thead>
                  <tr>
                    <th className={styles['header-1']}>Piece name</th>
                    <th className={styles['header-2']}>Date</th>
                    <th className={styles['header-3']}>Time</th>
                    <th className={styles['header-4']}></th>
                  </tr>
                </thead>
                <tbody>
                  {practicesHistory?.length === 0 ? (
                    <tr>
                      <td colSpan={4}>
                        <div className={styles['no-practice']}>
                          {(loading && 'Loading...') || 'No practice history yet'}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {practicesHistory?.map((item, id) => (
                        <tr key={id}>
                          <td>
                            {item?.piece ? (
                              <>
                                <div className={styles['colume-1']}>{item?.piece.title}</div>
                                <div className={styles['colume-sub-1']}>{item?.piece?.music?.composer}</div>
                              </>
                            ) : (
                              <div className={styles['colume-1']}>-</div>
                            )}
                          </td>
                          <td>
                            <div className={styles['colume-2']}>
                              {item.created ? moment(item.created).format('ll') : '-'}
                            </div>
                          </td>
                          <td>
                            <div className={styles['colume-3']}>{renderTimePractice(item)}</div>
                          </td>
                          <td>
                            {item.recording && (
                              <div className="flex items-center justify-center">
                                {(audioSrc === item.recording && (
                                  <PauseIcon
                                    className="cursor-pointer size-6 text-primary"
                                    onClick={() => setAudioSrc('')}
                                  />
                                )) || (
                                  <PlayIcon
                                    className="cursor-pointer size-6 text-primary"
                                    onClick={() => setAudioSrc(item.recording)}
                                  />
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                      {!isPagination && (
                        <tr>
                          <td>
                            <Link className={styles['btn-load-more']} to={`/students/${contact?.id}/practice-history`}>
                              See all
                            </Link>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className={styles['table-wrapper-mb']}>
              {practicesHistory?.length === 0 ? (
                <div className={styles['no-practice']}>{(loading && 'Loading...') || 'No practice history yet'}</div>
              ) : (
                <>
                  {practicesHistory?.map((item, id) => (
                    <div key={id} className={styles['practice-item']}>
                      <div className={styles['practice-item-header']}>
                        {item?.piece ? (
                          <div>
                            <p className={styles['header-title']}>{item?.piece.title}</p>
                            <p className={styles['header-sub-title']}>{item?.piece?.music?.composer}</p>
                          </div>
                        ) : (
                          <div className={styles['header-title']}>-</div>
                        )}
                        {item.recording && (
                          <div className="flex items-center justify-center">
                            {(audioSrc === item.recording && (
                              <PauseIcon
                                className="cursor-pointer size-6 text-primary"
                                onClick={() => setAudioSrc('')}
                              />
                            )) || (
                              <PlayIcon
                                className="cursor-pointer size-6 text-primary"
                                onClick={() => setAudioSrc(item.recording)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className={styles['wrapper-field']}>
                        <p className={styles['field__label']}>Date</p>
                        <p className={styles['field__value-date']}>
                          {item.created ? moment(item.created).format('ll') : '-'}
                        </p>
                      </div>
                      <div className={styles['wrapper-field']}>
                        <p className={styles['field__label']}>Practice time</p>
                        <div className={styles['field__value-time']}>{renderTimePractice(item)}</div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {!isPagination && (
                <>
                  <Link className={styles['btn-load-more-mb']} to={`/students/${contact?.id}/practice-history`}>
                    See all
                  </Link>
                </>
              )}
            </div>
            {next && isPagination && (
              <div className="text-center p-3">
                <Btn variant="primary" onClick={handlePagination}>
                  Load More
                </Btn>
              </div>
            )}
          </>
        )}
      </PageSection>
    </>
  );
};
