import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';
import Form from 'react-bootstrap/Form';

import styles from './Radio.module.scss';

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
  id?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  className?: string;
  label?: string;
}

const Radio = ({ disabled = false, defaultChecked, className, id, checked, onChange, label }: RadioProps): any => {
  return (
    <>
      <Form.Check
        type="checkbox"
        id={id}
        className={`${styles['radio']} ${className}`}
        checked={checked}
        onChange={onChange}
        defaultChecked={defaultChecked}
        label={label}
        disabled={disabled}
      />
    </>
  );
};

export default Radio;
