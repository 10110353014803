import { lazy } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { useAppSelector } from './hooks';
import Layout from './layout';
import Landing from './pages/landing';
import Pricing from './pages/pricing';
import SignIn from './pages/signin';
import SignUp from './pages/signup';

import { Organizations } from 'features';
import { Assignments, Billing, Calendar, Feeds, Home, Notes, Page404, Practices, Settings, Students } from 'pages';
import PostDetails from 'pages/PostDetails/PostDetails';
import toc from 'pages/help/TOC.json';

const Help = lazy(() => import('pages/help'));
const Teacher = lazy(() => import('features/teachers/Teacher'));

export default () => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
  const router = createBrowserRouter(
    [
      {
        path: 'help',
        element: (isAuthenticated && <Layout isAuthenticated={true} />) || null,
        handle: { crumb: () => 'Help' },
        children: [
          {
            index: true,
            element: <Help />,
          },
          {
            path: ':slug',
            element: <Help />,
            loader: async ({ params }) => {
              const { slug } = params;
              return Object.values(toc)
                .flat()
                .find(c => c.slug === slug)?.title;
            },
            handle: { crumb: (title: string) => title },
          },
        ],
      },
      {
        path: '/invoice/:uuid',
        lazy: () => import('./pages/invoice-by-uuid'),
      },
      {
        path: '/teachers/:username',
        element: <Teacher />,
      },
      isAuthenticated
        ? {
            element: <Layout isAuthenticated={isAuthenticated} />,
            children: [
              {
                index: true,
                element: <Navigate to="/home" replace={true} />,
              },
              {
                path: '/home',
                element: <Home />,
                handle: { crumb: () => 'Home' },
              },
              {
                path: '/settings',
                element: <Settings />,
                handle: { crumb: () => 'Settings' },
              },
              {
                path: '/settings/organizations',
                element: <Organizations />,
                handle: { crumb: () => 'Organizations' },
              },
              {
                path: '/students',
                handle: { crumb: () => 'Students' },
                children: [
                  {
                    index: true,
                    element: <Students />,
                  },
                  {
                    path: ':id',
                    lazy: () => import('./routes/students-id'),
                  },
                  {
                    path: ':id/practice-history',
                    element: <Practices />,
                    handle: { crumb: () => 'Practice History' },
                  },
                ],
              },
              {
                path: '/post/:id',
                element: <PostDetails />,
              },
              {
                path: '/billing',
                element: <Billing />,
                handle: { crumb: () => 'Billing' },
              },
              {
                path: '/calendar',
                element: <Calendar />,
                handle: { crumb: () => 'Calendar' },
              },
              {
                path: '/notes',
                element: <Notes />,
                handle: { crumb: () => 'Notes' },
              },
              {
                path: '/assignments',
                element: <Assignments />,
                handle: { crumb: () => 'Assignments' },
              },
              {
                path: '/feed',
                element: <Feeds />,
                handle: { crumb: () => 'Feed' },
              },
              {
                path: '*',
                element: <Page404 />,
              },
            ],
          }
        : {
            children: [
              {
                element: <Layout isAuthenticated={false} />,
                children: [
                  {
                    index: true,
                    element: <Landing />,
                  },
                  {
                    path: 'contact-us',
                    lazy: () => import('./pages/contact-us'),
                  },
                  {
                    path: 'features',
                    lazy: () => import('./pages/features'),
                  },
                  {
                    path: '/pricing',
                    element: <Pricing />,
                  },
                ],
              },
              {
                path: '/signin',
                element: <SignIn />,
              },
              {
                path: '/signup',
                element: <SignUp />,
              },
              {
                path: '*',
                element: <Navigate to="/" replace={true} />,
              },
            ],
          },
    ],
    { basename: process.env.PUBLIC_URL },
  );

  return <RouterProvider router={router} />;
};
