import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import organizations from '../features/organizations/organizationsSlice';
import updates from '../features/updates/updatesSlice';

import Api from './AllApisReducer';
import assignment from './AssignmentReducer';
import auth from './AuthReducer';
import calendar from './CalendarReducer';
import classes from './ClassReducer';
import group from './GroupReducer';
import notes from './NotesReducer';
import practice from './PracticeReducer';
import schedule from './ScheduleReducer';
import student from './StudentReducer';
import userGuide from './UserGuideReducer';
import user from './UserReducer';

const appReducer = combineReducers({
  form: formReducer,
  notes,
  Api,
  auth,
  calendar,
  classes,
  user,
  group,
  student,
  assignment,
  practice,
  schedule,
  updates,
  userGuide,
  organizations,
});

export default appReducer;
