import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { AiFillSetting } from 'react-icons/ai';

import styles from './FilterStudentsMobile.module.scss';

import Btn from 'components/Btn';
import Radio from 'components/Radio';
import { OptionsType } from 'types';

type FilterStudentsMobileProps = {
  show: boolean;
  toggleShow: () => void;
  onSelect?: (data: OptionsType) => void;
  onEditGroup?: () => void;
  optionsGroup: OptionsType[];
};

const FilterStudentsMobile = (props: FilterStudentsMobileProps) => {
  const { show, toggleShow, onEditGroup, optionsGroup, onSelect } = props;
  const [groupSelected, setGroupSelected] = useState<OptionsType>({
    value: '',
    label: '',
  });

  const handleSelectGroup = (option: OptionsType) => {
    if (groupSelected?.value === option.value)
      setGroupSelected({
        value: '',
        label: '',
      });
    else setGroupSelected(option);
  };

  const handleApplyFilter = () => {
    if (onSelect) onSelect(groupSelected);
    toggleShow();
  };

  return (
    <div>
      <Offcanvas show={show} placement="bottom" onHide={toggleShow}>
        <Offcanvas.Body className={styles['filter-students-mb']}>
          <div className={styles['filter-item']}>
            <p className={styles['title']}>Sort by</p>
          </div>
          <div className={styles['wrapper-item-groups']}></div>
          <div className={styles['filter-item']}>
            <p className={styles['title']}>Sort by Groups</p>
          </div>
          <div className={styles['wrapper-item-groups']}>
            {optionsGroup?.map(option => {
              return (
                <div
                  className={styles['filter-item__group']}
                  onClick={() => handleSelectGroup(option)}
                  key={option.value}
                >
                  <p className={styles['group__label']}>{option.label}</p>
                  <Radio checked={groupSelected?.value === option.value} readOnly />
                </div>
              );
            })}
          </div>
          <div className={styles['filter-item-bottom']}>
            <div onClick={onEditGroup} className={styles['item-handle-group']}>
              <AiFillSetting size={22} className={styles['icon']} />
              <span className={styles['text']}>Edit group</span>
            </div>
          </div>
          <div className={styles['button-apply-wrapper']}>
            <Btn variant="primary" className={styles['button-apply']} onClick={handleApplyFilter}>
              Apply
            </Btn>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default FilterStudentsMobile;
