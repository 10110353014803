import { default as cs } from 'classnames';
import { useState } from 'react';
import { Col, Row, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLoaderData, useLocation } from 'react-router-dom';

import AnalyticsComponent from './AnalyticsComponent';
import FeedComponent from './FeedComponent';
import styles from './StudentDetails.module.scss';
import UserInfoComponent from './UserInfoComponent';

import { AssignmentsProvider } from 'features/assignments';
import { Contact, User } from 'types';

type StudentDetailsProps = {
  allApis: any; // TODO: add type
  user: User;
};

const StudentDetails = (props: StudentDetailsProps) => {
  const location = useLocation();
  const [keyTab, setKeyTab] = useState<string>(location.state?.feed ? 'feed' : 'analytics');
  const { allApis, user } = props;

  const classTabActive = (tab: string) => (keyTab === tab ? styles['tab-item--active'] : '');

  const contact = useLoaderData() as Contact;

  return (
    <AssignmentsProvider>
      <div className={styles['student-details-container']}>
        <Row>
          <Col
            xs={{ span: 12, order: 'last' }}
            sm={{ span: 12, order: 'last' }}
            md={{ span: 12, order: 'last' }}
            lg={{ span: 8, order: 'first' }}
            xl={{ span: 8, order: 'first' }}
          >
            <Tab.Container activeKey={keyTab}>
              <div className={styles['wrapper-tab']}>
                <p className={cs(styles['tab-item'], classTabActive('feed'))} onClick={() => setKeyTab('feed')}>
                  Feed
                </p>
                <p
                  className={cs(styles['tab-item'], classTabActive('analytics'))}
                  onClick={() => setKeyTab('analytics')}
                >
                  Analytics
                </p>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="feed">
                  <Row>
                    <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={10}>
                      <FeedComponent allApis={allApis} contact={contact} user={user} keyTab={keyTab} />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={1}></Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="analytics">
                  <AnalyticsComponent contact={contact} user={user} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
          <Col
            xs={{ span: 12, order: 'first' }}
            sm={{ span: 12, order: 'first' }}
            md={{ span: 12, order: 'first' }}
            lg={{ span: 4, order: 'last' }}
            xl={{ span: 4, order: 'last' }}
          >
            <UserInfoComponent allApis={allApis} contact={contact} />
          </Col>
        </Row>
      </div>
    </AssignmentsProvider>
  );
};

const mapStateToProps = (state: any) => ({
  allApis: state.Api.allApis,
  user: state.user as User,
});

export default connect(mapStateToProps)(StudentDetails);
