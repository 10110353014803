import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Form, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Logo from '../components/logo';
import { config } from '../utils/config';

import { setToken } from 'actions/AuthActions';

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, register, formState } = useForm({ resolver: yupResolver(schema) });

  // left just for successful passing tests
  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.slice(1);
      const entries = hash.split('&').map(i => i.split('='));
      const values = Object.fromEntries(entries);
      dispatch(setToken(values.access_token));
      navigate('/');
    }
  }, [dispatch, navigate]);

  return (
    <>
      <header className="absolute w-full py-3 md:py-6 z-10">
        <div className="container flex items-center">
          <a href="" className="no-underline">
            <Logo />
          </a>
        </div>
      </header>
      <main>
        <section className="h-screen min-h-[610px] relative">
          <div className="container h-full flex flex-col justify-center">
            <div className="md:max-w-[calc(50%-16px)] w-full flex justify-center">
              <div className="text-[13px] w-full sm:!w-[400px]">
                <h3 className="text-2xl text-black font-medium mb-1.5">Log in</h3>
                <p className="text-greySecondary leading-5">
                  Don't have an account yet?{' '}
                  <a href="signup" className="text-primary font-medium">
                    Signup
                  </a>
                </p>
                <Form
                  action={config.api_endpoint + '/auth/login/'}
                  onSuccess={({ response }) => {
                    response.json().then(data => {
                      dispatch(setToken(data.token));
                      navigate('/');
                    });
                  }}
                  className="mt-8 text-greySecondary flex flex-col gap-3"
                  control={control}
                >
                  <div className="flex flex-col gap-1.5">
                    <label htmlFor="email">Email address</label>
                    <input
                      {...register('email')}
                      type="email"
                      placeholder="Email address"
                      className="w-full h-11 px-3.5 rounded-md border border-greyBorder outline-none placeholder:text-gray-500 text-gray-900"
                    />
                    <span className="text-red hidden">This value should be a valid email.</span>
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <label htmlFor="password">Password</label>
                    <input
                      {...register('password')}
                      type="password"
                      placeholder="••••••••"
                      className="w-full h-11 px-3.5 rounded-md border border-greyBorder outline-none placeholder:text-gray-500 text-gray-900"
                    />
                  </div>
                  {formState.errors.root && <p className="text-red-500">Unable to log in with provided credentials.</p>}
                  <button
                    type="submit"
                    className="h-10 rounded-[40px] py-2.5 px-4 text-center mt-5 bg-primary text-white disabled:bg-opacity-50"
                    disabled={!formState.isValid || formState.isSubmitting}
                  >
                    {formState.isSubmitting ? 'Logging in...' : 'Log in'}
                  </button>
                </Form>
              </div>
            </div>
          </div>
          <div className="intro-hero-image bg-intro-hero bg-center bg-cover bg-no-repeat max-w-[50%] absolute right-0 top-0 w-full h-full hidden md:!block"></div>
        </section>
      </main>
    </>
  );
};
