import { UseApiReturnType, useApi } from './use-api';

export type SearchInvoiceBodyType = {
  recipient_email?: string;
};

export const useGenericApi = (buildConfig: Function, props: any): UseApiReturnType => {
  const [makeRequest, { data, error, isPending }] = useApi(buildConfig(props));
  const sendRequest = (props: any) => makeRequest(buildConfig(props));
  return [sendRequest, { data, error, isPending }];
};

export type useSearchInvoicesPropsType = {
  searchBody: SearchInvoiceBodyType;
  searchParams: string[][] | Record<string, string>;
};

export const useSearchInvoices = (props: useSearchInvoicesPropsType): UseApiReturnType => {
  const buildConfig = (props: useSearchInvoicesPropsType) => {
    let { searchBody, searchParams } = props;
    searchBody = searchBody ? searchBody : {};
    searchParams = searchParams ? searchParams : {};
    return {
      method: 'post',
      endpoint: '/billing/paypal/invoices/search/',
      params: searchParams,
      body: searchBody,
    };
  };
  return useGenericApi(buildConfig, props);
};

export type useGetAssignmentByStudentPropsType = {
  contact: number | undefined;
  query_params?: string[][] | Record<string, string>;
};

export const useGetAssignmentByStudent = (props: useGetAssignmentByStudentPropsType): UseApiReturnType => {
  const buildConfig = (props: useGetAssignmentByStudentPropsType) => {
    let { contact, query_params } = props;
    query_params = query_params ? query_params : {};
    return {
      endpoint: '/assignments/',
      params: { ...query_params, contact },
    };
  };

  return useGenericApi(buildConfig, props);
};

export type useGetPracticeByStudentPropsType = {
  student_id?: string;
  next?: string;
  query_params?: string[][] | Record<string, string>;
};

export const useGetPracticeByStudent = (props: useGetPracticeByStudentPropsType): UseApiReturnType => {
  const buildConfig = (props: useGetPracticeByStudentPropsType) => {
    let { student_id, next, query_params } = props;
    let endpoint = '/practices/';
    if (!next) {
      query_params = query_params ? query_params : {};
      if (student_id) {
        query_params = { ...query_params, student: student_id };
      }
    } else {
      let urlObj = new URL(next);
      query_params = Object.fromEntries(urlObj.searchParams);
    }
    return {
      endpoint: endpoint,
      params: query_params,
    };
  };

  return useGenericApi(buildConfig, props);
};

export type useGetGoalByStudentPropsType = {
  contact: number | undefined;
  query_params?: string[][] | Record<string, string>;
};

export const useGetGoalByStudent = (props: useGetGoalByStudentPropsType): UseApiReturnType => {
  const buildConfig = (props: useGetGoalByStudentPropsType) => {
    let { contact, query_params } = props;
    query_params = query_params ? query_params : {};
    return {
      endpoint: '/teacher/goals/',
      params: { ...query_params, contact },
    };
  };

  return useGenericApi(buildConfig, props);
};

export type useGetContactPropsType = {
  query_params?: string[][] | Record<string, string>;
};

export const useGetContact = (props: useGetContactPropsType): UseApiReturnType => {
  const buildConfig = (props: useGetContactPropsType) => {
    let { query_params } = props;
    query_params = query_params ? query_params : {};
    return {
      endpoint: '/practices/timechart/',
      params: query_params,
    };
  };

  return useGenericApi(buildConfig, props);
};

type useGetPostPropTypes = {
  post_id: string;
};

export const useGetPost = (props: useGetPostPropTypes): UseApiReturnType => {
  const buildConfig = (props: useGetPostPropTypes) => {
    if (props) {
      let { post_id } = props;
      return {
        endpoint: `/posts/${post_id}`,
        params: {
          embed: 'likes,comments',
        },
      };
    }
  };

  return useGenericApi(buildConfig, props);
};

export type useGetLessonPropsType = {
  teacher?: string;
  students?: string;
};

export const useGetLesson = (props: useGetLessonPropsType): UseApiReturnType => {
  const buildConfig = (props: useGetLessonPropsType) => {
    return {
      endpoint: '/lessons/',
      params: props,
    };
  };

  return useGenericApi(buildConfig, props);
};

export type useGetFeedPropsType = {
  user_id?: string;
  next?: string;
  query_params?: string[][] | Record<string, string>;
};

export const useGetFeed = (props: useGetFeedPropsType): UseApiReturnType => {
  const buildConfig = (props: useGetFeedPropsType) => {
    let { user_id, next, query_params } = props;
    let default_params = { embed: 'likes,comments' };
    let endpoint = '/posts/students/';
    if (!next) {
      query_params = query_params ? { ...default_params, ...query_params } : default_params;
      if (user_id) {
        query_params['user'] = user_id;
      }
    } else {
      let urlObj = new URL(next);
      query_params = Object.fromEntries(urlObj.searchParams);
    }
    return {
      endpoint: endpoint,
      params: query_params,
    };
  };

  return useGenericApi(buildConfig, props);
};
