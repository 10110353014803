import { useState } from 'react';

import Btn from '../../../components/Btn';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '../../../components/Modal';

import InvoiceForm from './InvoiceForm';
import styles from './InvoiceModal.module.scss';

import { Invoice } from 'types';

interface InvoiceModalPropsType {
  invoice?: Invoice;
  show: boolean;
  onHide: () => void;
}

const InvoiceModal = (props: InvoiceModalPropsType) => {
  const { invoice, show, onHide } = props;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  if (!show) return null;

  return (
    <Modal
      open={true}
      onClose={onHide}
      title={invoice ? 'Update Invoice' : 'Create Invoice'}
      className={styles['modal-container']}
    >
      <ModalTitle>Invoice</ModalTitle>
      <ModalBody>
        <InvoiceForm invoice={invoice} setModalSubmitting={setIsSubmitting} onHide={onHide} />
      </ModalBody>
      <ModalFooter>
        <Btn type="submit" form="invoice-form" className="min-w-28" isLoading={isSubmitting}>
          Save
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default InvoiceModal;
