export const GTAG_ADD_STUDENT = 'Add Student';
export const GTAG_ADD_MULTIPLE_STUDENTS = 'Add Multiple Students';
export const GTAG_CREATE_ASSIGNMENT = 'Create Assignment';
export const GTAG_EDIT_ASSIGNMENT = 'Edit Assignment';
export const GTAG_DELETE_ASSIGNMENT = 'Delete Assignment';
export const GTAG_ADD_NOTE = 'Add Note';
export const GTAG_CREATE_GROUP = 'Create Group';
export const GTAG_ADD_STUDENT_TO_GROUP = 'Add Student to Group';
export const GTAG_DELETE_GROUP = 'Delete Group';
export const GTAG_CALENDAR_CREATE_EVENT = 'Calendar: Create Event';
export const GTAG_CALENDAR_EDIT_EVENT = 'Calendar: Edit Event';
export const GTAG_BILLING_CREATE_INVOICE = 'Billing: Create Invoice';
export const GTAG_POST_COMMENT = 'Post Comment';
export const GTAG_MESSAGE_STUDENT = 'Message Student';
