/* eslint-disable */
export default (props: any) => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83464 15.3582H9.16797L12.8763 17.8249C13.4263 18.1916 14.168 17.7999 14.168 17.1332V15.3582C16.668 15.3582 18.3346 13.6916 18.3346 11.1916V6.19157C18.3346 3.69157 16.668 2.0249 14.168 2.0249H5.83464C3.33464 2.0249 1.66797 3.69157 1.66797 6.19157V11.1916C1.66797 13.6916 3.33464 15.3582 5.83464 15.3582Z"
      stroke="#596579"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
