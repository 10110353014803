import { TFetcherMethodKwargs } from 'types';

export const getJson = (url: string, { data, authToken }: TFetcherMethodKwargs) => {
  const headers = {
    'Content-Type': 'application/json',
    ...(authToken && { Authorization: `JWT ${authToken}` }),
  };

  const uri = new URL(url);

  if (!uri.search) {
    if (!uri.pathname.endsWith('/')) {
      uri.pathname += '/';
    }

    uri.search = new URLSearchParams(data).toString();
  }

  return fetch(uri.toString(), { headers });
};
