import * as Sentry from '@sentry/browser';
import React from 'react';
import { toast } from 'react-toastify';

type TErrorBoundaryProps = {
  children: JSX.Element;
};
type TErrorBoundaryStates = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<TErrorBoundaryProps, TErrorBoundaryStates> {
  constructor(props: TErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      toast(
        <div>
          <div className="_toast-header">Oops! Something went wrong.</div>
          <div className="_toast-content">
            We have been notified about this issue and we will take a look at it shortly.
          </div>
        </div>,
      );
    }

    return children;
  }
}
