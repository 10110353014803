import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { connect } from 'react-redux';

import styles from './Feeds.module.scss';

import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import FeedComponent from 'pages/StudentDetails/FeedComponent/FeedComponent';
import { Contact, User } from 'types';

type FeedProps = {
  allApis: any;
  user: User;
  students: Contact[];
};
type DropDownOptionType = {
  label: string;
  value: string;
};

const Feeds = (props: FeedProps) => {
  const { allApis, user, students } = props;
  const [studentId, setStudentId] = useState<string>('');
  const [contact, setContact] = useState<Contact | null>(null);
  const [action, setAction] = useState<string | null>();
  const studentFilterOptions = useMemo<DropDownOptionType[]>(
    () => [
      {
        label: 'All students',
        value: '',
      },
      ...students
        .filter((student: Contact) => student.student?.id != null)
        .map((student: Contact) => ({
          label: student.name,
          value: student.id.toString(),
        })),
    ],
    [students],
  );

  const actionFilterOptions = [
    {
      label: 'All updates',
      value: '',
    },
    {
      label: 'Practice sessions',
      value: 'practice session',
    },
    {
      label: 'Assignments',
      value: 'assignment completion',
    },
  ];

  const refreshContact = useCallback(
    (status: string) => {
      let matchContact = students.filter((student: Contact) => student.id.toString() == status);
      if (matchContact.length == 0) {
        setContact(null);
      } else {
        setContact(matchContact[0]);
      }
    },
    [students],
  );
  const handleStudentFilterChange = (id: string) => {
    setStudentId(id);
  };

  const handleActionFilterChange = (action: string) => {
    setAction(action);
  };

  useEffect(() => {
    refreshContact(studentId);
  }, [studentId, contact, refreshContact]);

  return (
    <>
      <div className={styles['feeds-wrapper']}>
        <div className={styles['feeds-header']}>
          <div className={styles['feeds-header__left']}></div>
          <div className={styles['feeds-header__right']}>
            <Stack gap={2} direction="horizontal">
              <DropdownSelect
                label={
                  studentFilterOptions.find((option: DropDownOptionType) => option.value === studentId)?.label || 'All'
                }
                value={contact?.id.toString() || ''}
                options={studentFilterOptions}
                onChange={handleStudentFilterChange}
              />
              <DropdownSelect
                label={
                  actionFilterOptions.find((option: DropDownOptionType) => option.value === action)?.label ||
                  'All updates'
                }
                value={contact?.id.toString() || ''}
                options={actionFilterOptions}
                onChange={handleActionFilterChange}
              />
            </Stack>
          </div>
        </div>
        <FeedComponent allApis={allApis} user={user} keyTab="feed" contact={contact} action={action} />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  allApis: state.Api.allApis,
  user: state.user as User,
  students: Object.values(state.student.items) as Contact[],
});

export default connect(mapStateToProps)(Feeds);
