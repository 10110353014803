import { FormSelect } from 'react-bootstrap';

export const EarningsFilterByPeriod = ({ onChange }: { onChange: Function }) => {
  const options = [
    {
      label: 'Current month',
      value: { period: '1m', freq: 'd' },
    },
    {
      label: 'Last 3 months',
      value: { period: '3m', freq: 'w' },
    },
    {
      label: 'Last 6 months',
      value: { period: '6m', freq: 'm' },
    },
    {
      label: 'Last year',
      value: { period: '12m', freq: 'm' },
    },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    options.map(o => {
      if (o.label == e.target.value) {
        onChange(o.value);
      }
    });
  };

  return (
    <FormSelect defaultValue={options[0].label} onChange={handleChange}>
      {options.map(o => (
        <option key={o.label}>{o.label}</option>
      ))}
    </FormSelect>
  );
};
