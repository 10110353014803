export const mapValues = <T>(obj: Record<string, T>, fn: (method: T, methodName: string) => T) => {
  const ret: Record<string, T> = {};
  for (const k in obj) {
    ret[k] = fn(obj[k], k);
  }
  return ret;
};

export const getYoutubeId = (value: string | undefined) => {
  const re =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return (value?.match(re) && RegExp.$1) || null;
};
