import { SUCCESS_SUFFIX } from 'redux-axios-middleware';

const GET_ORGS = 'dashboard/getOrgs';
const UPDATE = 'dashboard/update';

const initialState = {
  orgs: null,
  activeGroup: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGS: {
      return { ...state, orgs: null };
    }

    case GET_ORGS + SUCCESS_SUFFIX: {
      return {
        ...state,
        orgs: action.payload.data.results,
      };
    }

    case UPDATE: {
      return {
        ...state,
        ...action.data,
      };
    }

    default:
      return state;
  }
};

export const setActiveGroup = activeGroup => ({
  type: UPDATE,
  data: { activeGroup },
});

export const getOrgs = () => ({
  type: GET_ORGS,
  payload: {
    request: {
      method: 'GET',
      url: '/studio/organizations/',
      params: { embed: 'groups' },
    },
  },
});
