import { TReduxPayload } from 'types';
export const FETCH_TIMECHARTS_FOR_STUDENTS = 'PRACTICES/FETCH_TIMECHARTS_FOR_STUDENTS';
export const FETCH_TIMECHARTS_FOR_STUDENTS_SUCCESS = 'PRACTICES/FETCH_TIMECHARTS_FOR_STUDENTS_SUCCESS';
export const FETCH_TIMECHARTS_FOR_STUDENTS_FAIL = 'PRACTICES/FETCH_TIMECHARTS_FOR_STUDENTS_FAIL';

export const fetchTimechartsForStudents = (params: TReduxPayload) => ({
  types: [FETCH_TIMECHARTS_FOR_STUDENTS, FETCH_TIMECHARTS_FOR_STUDENTS_SUCCESS, FETCH_TIMECHARTS_FOR_STUDENTS_FAIL],
  payload: {
    request: {
      method: 'GET',
      url: `/practices/timechart/?${params}`,
    },
  },
});
