import { default as cs } from 'classnames';
import { ChangeEvent, useState } from 'react';

import { GTAG_ADD_STUDENT_TO_GROUP } from '../../../constants/gtag';
import { gtag } from '../../../utils';

import styles from './AddGroupModal.module.scss';

import Checkbox from 'components/Checkbox';
import CustomModal from 'components/CustomModal';
import { useToggle } from 'hooks';
import { Contact, GroupType } from 'types';

type AddGroupModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  groups: GroupType[];
  contact: Contact;
  handleUpdateStudent: (id: number, groups: string[]) => void;
  fetchGroups: () => void;
};

const AddGroupModal = (props: AddGroupModalProps) => {
  const { isShowModal, handleCloseModal, groups, contact, handleUpdateStudent, fetchGroups } = props;
  const [isLoadingSubmit, setIsLoadingSubmit] = useToggle();
  const [listGroupChecked, setListGroupChecked] = useState<string[]>([...(contact.groups as string[])]);
  const handleCheckbox = (checked: boolean, group: GroupType) => {
    const newGroups = listGroupChecked.filter(gr => gr !== group.url);
    if (checked) {
      setListGroupChecked([...newGroups, group.url] as string[]);
    } else {
      setListGroupChecked([...newGroups] as string[]);
    }
  };

  const onUpdateStudent = async () => {
    setIsLoadingSubmit();
    await handleUpdateStudent(contact.id, listGroupChecked);
    gtag(GTAG_ADD_STUDENT_TO_GROUP);
    setIsLoadingSubmit();
    await fetchGroups();
    handleCloseModal();
  };

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={handleCloseModal}
      title="Add to group"
      className={cs(styles['add-group-modal'], styles['custom-modal-mb'])}
      isSubmitting={isLoadingSubmit}
      onSubmit={onUpdateStudent}
      contentClassName={styles['content-modal-mb']}
    >
      <div className={styles['add-group-modal-body']}>
        {groups.map((item: GroupType) => (
          <div key={item.id} className={styles['group-item']}>
            <div className={styles['left-wrapper']}>
              <Checkbox
                defaultChecked={!!contact.groups?.find((group: string) => group === `${item.url}`)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckbox(e.target.checked, item)}
              />
            </div>
            <div className={styles['right-wrapper']}>
              <div className={styles['name']}>{item.name}</div>
            </div>
          </div>
        ))}
      </div>
    </CustomModal>
  );
};

export default AddGroupModal;
