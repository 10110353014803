import { yupResolver } from '@hookform/resolvers/yup';
import { default as cs } from 'classnames';
import { map } from 'lodash';
import moment from 'moment';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { TbSubtask } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { GTAG_CREATE_ASSIGNMENT, GTAG_DELETE_ASSIGNMENT, GTAG_EDIT_ASSIGNMENT } from '../../constants/gtag';

import styles from './CreateAssignmentModal.module.scss';

import { getYoutubeId } from 'common/utils/utils';
import AsyncMusicSelect from 'components/AsyncMusicSelect';
import AsyncStudentSelect from 'components/AsyncStudentSelect';
import Btn from 'components/Btn';
import CustomModal from 'components/CustomModal';
import DateInput from 'components/DateInput';
import DropdownSelect from 'components/DropdownSelect';
import InputField from 'components/InputField';
import { ASSIGNMENT_TYPE, ASSIGNMENT_TYPE_OPTIONS } from 'data/common';
import ASSIGNMENT_NOTIFICATIONS from 'data/notifications/assignment';
import TASK_NOTIFICATIONS from 'data/notifications/task';
import { useAssignment } from 'pages/Assignments/Assignments';
import { ReactComponent as ClockPlusIcon } from 'theme/svg/clock-plus.svg';
import { ReactComponent as LinkIcon } from 'theme/svg/link.svg';
import { ReactComponent as MusicIcon } from 'theme/svg/music.svg';
import { ReactComponent as YoutubeIcon } from 'theme/svg/youtube.svg';
import { AssignmentType, AssignmentsStudentType, Contact, OptionsType, SubtaskType, TaskType } from 'types';
import { gtag } from 'utils';

type CreateAssignmentModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  handleSubmitModal?: VoidFunction;
  className?: string;
  assignmentEdit?: TaskType;
  contact?: Contact;
};

export type AssignmentItem = {
  id: string;
  icon: ReactNode;
  title: string;
  childComponent: ReactNode;
  type?: string[];
};

type TSubtaskState = {
  index: number;
  id?: number;
  value: string;
};

export type TSubtask = {
  id?: number;
  name: string;
};

export const validDurationUnits = [
  {
    label: 'hours',
    value: 'hours',
  },
  {
    label: 'minutes',
    value: 'minutes',
  },
];

const CreateAssignmentModal = (props: CreateAssignmentModalProps) => {
  const { isShowModal, handleCloseModal, assignmentEdit, handleSubmitModal, contact } = props;
  const { students } = useAssignment();
  const allApis = useSelector((state: any) => state.Api.allApis);
  const [isLoading, setIsLoading] = useState(false);
  const [activeAssignments, setActiveAssignments] = useState<AssignmentItem[]>([]);
  const [studentsSelected, setStudentsSelected] = useState<MultiValue<OptionsType>>([]);
  const [subtaskList, setSubtaskList] = useState<TSubtaskState[]>([]);

  const schema = yup.object().shape({
    name: yup.string().required('Name is required').default(''),
    contacts: yup.array().of(yup.string()).required('Students is required').default([]),
    id: yup.string().default(''),
    description: yup.string().default(''),
    durationUnit: yup.string().default(validDurationUnits[0].value),
    duration: yup.string().default(''),
    music: yup.object().shape({
      label: yup.string().default(''),
      value: yup.string().default(''),
    }),
    attachment: yup.string().url('Invalid URL format').default(''),
    due_date: yup.string().required().default(''),
    student: yup.string().default(''),
    subtasks: yup.array().of(yup.string()).default([]),
    task_type: yup.string().required().default(ASSIGNMENT_TYPE.text),
  });

  const formMethods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = formMethods;

  const initData = useCallback(
    (task_type = ASSIGNMENT_TYPE.text) => {
      setValue('task_type', task_type);
      setValue('durationUnit', validDurationUnits[0].value);
    },
    [setValue],
  );

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    setActiveAssignments([]);
    // setSubtaskList([]);
    const task_type = watch('task_type');
    reset();
    initData(task_type);
  }, [initData, watch, reset]);

  useEffect(() => {
    setIsLoading(false);
  }, [isShowModal]);

  useEffect(() => {
    if (contact) {
      const studentOptions = [
        {
          label: contact.name,
          email: contact.email,
          value: contact.url,
        },
      ];
      setStudentsSelected(studentOptions);
      setValue('contacts', [contact.url]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, setValue]);

  useEffect(() => {
    if (assignmentEdit?.task_type === ASSIGNMENT_TYPE.text) {
      setValue('id', assignmentEdit?.id.toString());
      setValue('task_type', assignmentEdit?.task_type);
      setValue('name', assignmentEdit?.name);
      setValue('contacts', assignmentEdit.contacts);
      setValue('description', assignmentEdit?.description);
      setValue('due_date', assignmentEdit?.due_date);
      const currentStudents = students.filter((student: Contact) =>
        assignmentEdit.contacts.includes(student.url as string),
      );
      const studentOptions = currentStudents.map((student: Contact) => ({
        label: student.name,
        email: student.email,
        value: student.url,
      })) as MultiValue<OptionsType>;
      setStudentsSelected(studentOptions);
      const currentTask = currentStudents[0].assignments.filter(
        assignment => assignment.task.id === assignmentEdit?.id,
      )[0].task;
      setSubtaskList(
        currentTask.subtasks.map((subtask: SubtaskType, index: number) => ({
          id: subtask.id,
          index: index,
          value: subtask.name,
        })),
      );
    }
  }, [assignmentEdit, students, setValue]);

  const onCloseModal = (isSubmitted: boolean = false) => {
    handleCloseModal();
    reset(schema.getDefault());
    setActiveAssignments([]);
    setSubtaskList([]);
    setValue('task_type', ASSIGNMENT_TYPE.text);
    if (isSubmitted && handleSubmitModal) {
      handleSubmitModal();
    }
  };

  const handleSubtasks = (taskId: string, subtasks: TSubtask[]) => {
    const URL = `/tasks/${taskId}/subtasks/`;
    const res = subtasks
      .filter(({ name }) => name.trim().length)
      .map(async ({ id, name }) => {
        const data = id
          ? await allApis.patchJson(`${URL + id}/`, { name: name.trim() })
          : await allApis.postJson(URL, { name: name.trim() });
        return data;
      });
    return res;
  };

  type TAssignmentValues = yup.InferType<typeof schema>;

  const prepareDataBeforeSubmit = (values: TAssignmentValues) => {
    const durationMultiplier = (values.durationUnit === 'hours' && 3600) || 60;

    return {
      ...values,
      due_date: (values.due_date && moment(values.due_date).format()) || null,
      duration: values.duration ? Number(values.duration) * durationMultiplier : null,
      music: values.music && values.music.value,
      subtasks: subtaskList.map(item => ({
        id: item.id,
        name: item.value,
      })),
    };
  };

  const onSubmit = async (values: TAssignmentValues) => {
    setIsLoading(true);
    const data = prepareDataBeforeSubmit(values);

    // Init default fetch is post to create task
    let fetchPromiseMethod = allApis.postJson;
    let fetchPromiseProps: any[] = ['/tasks/', data];
    let action = 'create';
    let currentStudents: string[] = [];
    let deleteAssignments: AssignmentType[] = [];

    // Set fetch patch if edit assignment
    if (assignmentEdit?.id) {
      fetchPromiseMethod = allApis.patchJson;
      fetchPromiseProps = [`/tasks/${assignmentEdit?.id}/`, data];
      action = 'update';
      currentStudents = assignmentEdit.contacts;

      // Figure out which assignment to remove
      let deleleStudents: (string | undefined)[] = currentStudents.filter(
        student => !data.contacts.includes(student as string),
      );
      deleteAssignments = students
        .filter(item => deleleStudents.includes(item.url))
        .reduce(
          (ret: AssignmentType[], item: AssignmentsStudentType) => [
            ...ret,
            ...item.assignments.filter(assignment => assignment.task.id === assignmentEdit.id),
          ],
          [],
        );

      // Set fetch delete if all student remove from task
      if (data.contacts.length == 0) {
        fetchPromiseMethod = allApis.delJson;
        fetchPromiseProps = [`/tasks/${assignmentEdit?.id}/`];
        deleteAssignments = [];
        setValue('subtasks', []);
        action = 'remove';
      }
    }

    // Create promise to add new students
    const updateStudents = data.contacts.filter(student => !currentStudents.includes(student as string));

    // Main promise execution
    await fetchPromiseMethod(...fetchPromiseProps).then((response: any) => {
      let promiseList = [
        ...updateStudents.map(contact => allApis.postJson('/assignments/', { task: response.url, contact })),
        ...deleteAssignments.map(assignment => allApis.delJson(`/assignments/${assignment.id}`)),
      ];
      if (response?.id) {
        promiseList.concat([...handleSubtasks(response.id, data.subtasks)]);
      }
      let templateAction = action as keyof typeof ASSIGNMENT_NOTIFICATIONS;
      Promise.all(promiseList)
        .then(() => {
          toast.success(ASSIGNMENT_NOTIFICATIONS[templateAction].success);
          switch (templateAction) {
            case 'create':
              gtag(GTAG_CREATE_ASSIGNMENT);
              break;
            case 'delete':
              gtag(GTAG_DELETE_ASSIGNMENT);
              break;
            case 'update':
              gtag(GTAG_EDIT_ASSIGNMENT);
              break;
          }
        })
        .catch(() => {
          toast.error(ASSIGNMENT_NOTIFICATIONS[templateAction].error);
        })
        .finally(() => {
          setIsLoading(false);
          onCloseModal(true);
        });
    });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await allApis.delJson(`/tasks/${assignmentEdit?.id}`).then(() => {
      toast.success(ASSIGNMENT_NOTIFICATIONS.delete.success);
      setIsLoading(false);
      onCloseModal(true);
    });
  };

  const handleToggleActiveAssignment = (assignment: AssignmentItem) => {
    setActiveAssignments(prev => {
      if (prev.find(i => i.id === assignment.id) || assignment.id === 'subtask') {
        return [...prev.filter(i => i.id !== assignment.id)];
      } else {
        return [...prev, assignment];
      }
    });

    if (assignment.id === 'subtask') {
      setSubtaskList([
        ...subtaskList,
        {
          index: subtaskList.length + 1,
          value: '',
        },
      ]);
    }
  };

  const removeSubtask = async (index: number) => {
    // Trigger actual delete existing subtask if id exist
    let matchSubtask = subtaskList.filter(subtask => subtask.index == index)[0];
    let isUpdateSubtaskList = true;
    if (matchSubtask.id && assignmentEdit?.id) {
      setIsLoading(true);
      await allApis
        .delJson(`/tasks/${assignmentEdit?.id}/subtasks/${matchSubtask.id}`)
        .then(() => {
          toast.success(TASK_NOTIFICATIONS.delete.success);
          if (handleSubmitModal) {
            handleSubmitModal();
          }
        })
        .catch(() => {
          toast.error(TASK_NOTIFICATIONS.delete.error);
          isUpdateSubtaskList = false;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // Update subtask list on frontend
    if (isUpdateSubtaskList) {
      setSubtaskList(prev => {
        return [...prev.filter(subtask => subtask.index !== index)];
      });
    }
  };

  const fieldList = [
    {
      id: 'link',
      icon: <LinkIcon />,
      title: 'Links',
      childComponent: (
        <InputField
          label="Link"
          inputProps={{ ...register('attachment') }}
          error={errors?.attachment?.message}
          value={watch('attachment')}
        />
      ),
      type: [ASSIGNMENT_TYPE.audio],
    },
    {
      id: 'time',
      icon: <ClockPlusIcon />,
      title: 'Time',
      childComponent: (
        <Row>
          <Col xs={6}>
            <InputField
              label="Time"
              inputProps={{
                ...register('duration'),
                type: 'number',
              }}
              value={watch('duration')}
            />
          </Col>
          <Col xs={6}>
            <DropdownSelect
              classNameToggle={styles['duration-units']}
              label={validDurationUnits.find(op => op.value === watch('durationUnit'))?.label}
              value={validDurationUnits.find(op => op.value === watch('durationUnit'))?.value}
              options={validDurationUnits}
              onChange={(unit: string) => setValue('durationUnit', unit)}
            />
          </Col>
        </Row>
      ),
      type: [ASSIGNMENT_TYPE.text],
    },
    {
      id: 'youtube',
      icon: <YoutubeIcon />,
      title: 'Youtube URL',
      childComponent: (
        <>
          <InputField
            label="Youtube URL"
            inputProps={{ ...register('attachment') }}
            value={watch('attachment')}
            error={errors?.attachment?.message}
          />
          {getYoutubeId(watch('attachment')) && (
            <img
              alt="Preview"
              className={styles['thumbnail-youtube']}
              src={`https://img.youtube.com/vi/${getYoutubeId(watch('attachment'))}/mqdefault.jpg`}
            />
          )}
        </>
      ),
      type: [ASSIGNMENT_TYPE.video],
    },
    {
      id: 'piece',
      icon: <MusicIcon />,
      title: 'Piece',
      childComponent: (
        <AsyncMusicSelect
          value={
            watch('music') && {
              label: watch('music').label as string,
              value: watch('music').value as string,
            }
          }
          onChange={(music: any) => {
            setValue('music', {
              label: music?.label,
              value: music?.value,
            });
          }}
          placeholder="Select Piece ..."
        />
      ),
      type: [ASSIGNMENT_TYPE.text, ASSIGNMENT_TYPE.audio],
    },
    {
      id: 'subtask',
      icon: <TbSubtask size={22} />,
      title: 'Subtask',
      childComponent: (
        <>
          {subtaskList.map(subtask => (
            <div className={styles['subtask-wrapper']} key={subtask.index}>
              <InputField
                label="Subtask"
                className={styles['subtask-input']}
                inputClassName={styles['input']}
                value={subtask.value}
                inputProps={{
                  value: subtask.value,
                  onChange: ({ target }) => {
                    const { value } = target;
                    const index = subtaskList.findIndex(item => item.index === subtask.index);
                    setSubtaskList([
                      ...subtaskList.slice(0, index),
                      {
                        index: index,
                        id: subtask.id,
                        value,
                      },
                      ...subtaskList.slice(index + 1),
                    ]);
                  },
                }}
              />
              <MdOutlineDeleteOutline
                size={22}
                className={styles['icon-delete']}
                onClick={() => removeSubtask(subtask.index)}
              />
            </div>
          ))}
        </>
      ),
      type: [ASSIGNMENT_TYPE.text, ASSIGNMENT_TYPE.video, ASSIGNMENT_TYPE.audio],
    },
  ];

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={onCloseModal}
      title={`${assignmentEdit?.task_type ? 'Edit' : ''} Assignment`}
      customleftFooterComponent={
        assignmentEdit?.task_type ? (
          <>
            <Btn disabled={isLoading} onClick={() => handleDelete()} variant="danger">
              Delete
            </Btn>
          </>
        ) : (
          <>
            <Btn disabled={isLoading} onClick={() => onCloseModal()} variant="outline-secondary">
              Back
            </Btn>
          </>
        )
      }
      customRightFooterComponent={
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Btn isLoading={isLoading} variant="primary" type="submit">
              {assignmentEdit?.task_type ? 'Update ' : 'Create '}Assignment
            </Btn>
          </Form>
        </FormProvider>
      }
      className={styles['custom-modal-mb']}
      contentClassName={styles['content-modal-mb']}
    >
      <div className={styles['assignment-modal-body']}>
        <Row>
          <Col xs={6} className={styles['assignment-type-wrapper']}>
            <p className={styles['label']}>Select Assignment Type</p>
            <DropdownSelect
              classNameToggle={styles['assignment-type']}
              label={ASSIGNMENT_TYPE_OPTIONS.find(op => op.value === watch('task_type'))?.label}
              value={ASSIGNMENT_TYPE_OPTIONS.find(op => op.value === watch('task_type'))?.value}
              options={ASSIGNMENT_TYPE_OPTIONS}
              onChange={(type: string) => setValue('task_type', type)}
              disabled={!!assignmentEdit?.task_type}
            />
          </Col>
        </Row>
        <InputField
          label="Name"
          inputProps={{
            ...register('name'),
          }}
          value={watch('name')}
          error={errors.name?.message}
          autoComplete="off"
        />
        <AsyncStudentSelect
          value={studentsSelected}
          onChange={(students: MultiValue<OptionsType>) => {
            setStudentsSelected(students);
            setValue('contacts', map(map(students, 'value'), String));
          }}
          placeholder="Assigned to"
          error={errors.contacts?.message}
        />
        <InputField
          label="Description (Optional)"
          inputProps={{ as: 'textarea', ...register('description') }}
          inputClassName={styles['description-input']}
          value={watch('description')}
        />
        <Row>
          <Col xs={6}>
            <DateInput
              label="Select date"
              value={watch('due_date') as string}
              error={errors.due_date?.message}
              onChange={value => {
                if (value) setValue('due_date', moment(value).format());
                else setValue('due_date', '');
              }}
            />
          </Col>
        </Row>
        <div className={styles['sub-title']}>Add to your assignment</div>
        <div className={styles['assignment-list']}>
          {fieldList.map(
            item =>
              item.type.includes(watch('task_type')) && (
                <div
                  key={item.id}
                  className={cs(
                    styles['assignment-item'],
                    activeAssignments.find(i => i.id === item.id) || (item.id === 'subtask' && !!subtaskList.length)
                      ? styles['active']
                      : '',
                  )}
                  onClick={() => handleToggleActiveAssignment(item)}
                >
                  <div className={styles['icon']}>{item.icon}</div>
                  <div className={styles['title']}>{item.title}</div>
                </div>
              ),
          )}
        </div>
        <div className={styles['assignment-more-assignment']}>
          {fieldList.map(
            item =>
              (activeAssignments.find(i => i.id === item.id) || (item.id === 'subtask' && !!subtaskList.length)) && (
                <div key={item.id} className={styles['assignment-more-item-wrapper']}>
                  {item.childComponent}
                </div>
              ),
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default CreateAssignmentModal;
