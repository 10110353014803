import { Dialog, DialogBackdrop, DialogPanel, DialogProps, useClose } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';

export const ModalTitle = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  const close = useClose();

  return (
    <div
      {...props}
      className={classNames(
        'text-base font-semibold flex justify-between items-center py-1.5 pe-4 ps-6 border-b border-gray-200 min-h-12',
        props.className,
      )}
    >
      {children}
      <XMarkIcon className="cursor-pointer size-5 hover:text-dark/50" onClick={close} />
    </div>
  );
};

export const ModalBody = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div {...props} className={classNames('p-6', props.className)}>
      {children}
    </div>
  );
};

export const ModalFooter = ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div
      {...props}
      className={classNames(
        'px-6 py-3.5 sm:flex sm:flex-row-reverse sm:px-6 border-t border-gray-200 min-h-12',
        props.className,
      )}
    >
      {children}
    </div>
  );
};

export const Modal = ({ children, className, ...props }: DialogProps) => {
  return (
    <Dialog {...props} className={classNames('relative z-10', className)}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/80 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform rounded bg-white text-left shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-3xl data-closed:sm:translate-y-0 data-closed:sm:scale-95"
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
