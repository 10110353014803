import { TReduxFormData, TReduxPayload } from 'types';
export const GET_TEACHER = 'GET_TEACHER';
export const UPDATE_TEACHER = 'UPDATE_TEACHER';

export const GET_CLASSES = 'GET_CLASSES';
export const SET_CLASS = 'SET_CLASS';

export const SEND_EMAIL = 'AUTH/SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'AUTH/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'AUTH/SEND_EMAIL_FAIL';

export const SEND_FEEDBACK = 'USER/SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'USER/SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAIL = 'USER/SEND_FEEDBACK_FAIL';

export const GET_SCHOOL_ADDRESS = 'GET_SCHOOL_ADDRESS';
export const GET_SCHOOL_ADDRESS_SUCCESS = 'GET_SCHOOL_ADDRESS_SUCCESS';
export const GET_SCHOOL_ADDRESS_FAIL = 'GET_SCHOOL_ADDRESS_FAIL';

export const getTeacher = () => ({
  type: GET_TEACHER,
  payload: {
    request: {
      method: 'GET',
      url: '/study/teacher/',
      params: {
        embed: 'instruments,organization,groups',
      },
    },
  },
});

export const getClasses = () => ({
  type: GET_CLASSES,
  payload: {
    request: {
      method: 'GET',
      url: '/study/teacher/classes/',
    },
  },
});

export const setClass = (klass: any) => ({
  type: SET_CLASS,
  payload: klass,
});

export const updateTeacher = (data: TReduxFormData) => {
  const formData = new FormData();
  Object.keys(data).forEach(fieldName => {
    const fieldValue = data[fieldName];
    if (fieldName === 'picture' && (typeof fieldValue === 'string' || fieldValue === null)) {
      return;
    }
    formData.append(fieldName, fieldValue === null ? '' : fieldValue);
  });

  // TODO: wait to
  return {
    type: UPDATE_TEACHER,
    payload: {
      request: {
        method: 'PATCH',
        url: '/study/teacher/?embed=instruments,organization,groups',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      },
    },
  };
};

export const sendEmail = (data: TReduxPayload) => ({
  types: [SEND_EMAIL, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAIL],
  payload: {
    request: {
      method: 'POST',
      url: '/teacher-email/',
      data,
    },
  },
});

export const sendFeedback = (data: TReduxPayload) => ({
  type: SEND_FEEDBACK,
  payload: {
    request: {
      method: 'POST',
      url: '/feedback/',
      data,
    },
  },
});
