import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { GTAG_MESSAGE_STUDENT } from '../../constants/gtag';

import styles from './SendMessageModal.module.scss';

import { sendEmail } from 'actions/UserActions';
import Avatar from 'components/Avatar';
import Btn from 'components/Btn';
import CustomModal from 'components/CustomModal';
import InputField from 'components/InputField';
import NOTIFICATIONS from 'data/notifications/message';
import { Contact } from 'types';
import { gtag } from 'utils';

type SendMessageModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  className?: string;
  contact?: Contact;
  dispatch: Dispatch;
};

const SendMessageModal = (props: SendMessageModalProps) => {
  // eslint-disable-next-line
  const { isShowModal, handleCloseModal, className, contact, dispatch } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [messageContent, setMessageContent] = useState<string>('');

  useEffect(() => {
    setIsLoading(false);
  }, [isShowModal]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        sendEmail({
          message: messageContent,
          student: contact?.url,
        }) as any,
      );
      gtag(GTAG_MESSAGE_STUDENT);
      toast.success(
        <div>
          <div className="_toast-header">{NOTIFICATIONS.create.success}</div>
          <div className="_toast-content">
            <strong>To:</strong> {contact?.name}
          </div>
        </div>,
      );
      handleCloseModal();
    } catch (error) {
      toast.error(NOTIFICATIONS.create.error);
    }
    setIsLoading(false);
  };

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={handleCloseModal}
      title={
        <div className={styles['send-message-modal-user']}>
          <Avatar
            size={40}
            letter={contact?.name?.substring(0, 1) || ''}
            url={contact?.student?.picture}
            className={styles['student-avatar']}
          />
          <div className={styles['student-info']}>
            <div className={styles['first-title']}>{contact?.name}</div>
            <div className={styles['sub-title']}>{contact?.email}</div>
          </div>
        </div>
      }
      customRightFooterComponent={
        <Btn btnClassName="ms-2" isLoading={isLoading} variant="primary" onClick={() => handleSubmit()}>
          Send message
        </Btn>
      }
      className={styles['custom-modal-mb']}
      contentClassName={styles['content-modal-mb']}
    >
      <div className={styles['send-message-modal']}>
        <InputField
          label="Message"
          size="small"
          className={styles['send-message-input-wrapper']}
          inputClassName={styles['send-message-input-field']}
          inputProps={{
            placeholder: 'Message',
            as: 'textarea',
            value: messageContent,
            onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const value = e.target?.value;
              setMessageContent(value);
            },
          }}
        />
      </div>
    </CustomModal>
  );
};
export default connect(null)(SendMessageModal);
