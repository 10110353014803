import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import AssignmentsComponent from './AssignmentsComponent';
import BillingComponent from './BillingComponent';
import CalendarComponent from './CalendarComponent';
import styles from './Home.module.scss';
import StudentsComponent from './StudentsComponent';

import { fetchGroups } from 'actions/GroupActions';
import { createLesson, fetchLessons, fetchTeacherSchedule, updateLesson } from 'actions/ScheduleActions';
import { updateStudent } from 'actions/StudentActions';
import { CalendarType, Contact, GroupType, Lesson, LessonEvent, ScheduleType, User } from 'types';

type HomeProps = {
  students?: Contact[];
  user?: User;
  allApis: any;
  isFetchingStudent: boolean;
  groups: GroupType[];
  handleUpdateStudent: (id: number, groups: string[]) => void;
  fetchGroups: () => void;
  isFetchingSchedule: boolean;
  isFetchingLessons: boolean;
  calendar: CalendarType;
  schedule: ScheduleType;
  lessons: { results: Lesson[] };
  fetchTeacherSchedule: () => void;
  fetchLessons: () => void;
  dispatch: any;
  createLesson: (data: LessonEvent) => void;
  updateLesson: (id: string | number | undefined, data: LessonEvent) => void;
};

const Home = (props: HomeProps) => {
  const {
    students = [],
    isFetchingStudent,
    allApis: { getJson },
    groups,
    handleUpdateStudent,
    fetchGroups,
    isFetchingSchedule,
    isFetchingLessons,
    schedule,
    lessons,
    calendar,
    fetchLessons,
    fetchTeacherSchedule,
    dispatch,
    allApis,
    createLesson,
    updateLesson,
  } = props;
  const [practiceTimeChart, setPracticeTimeChart] = useState<{ [key: string]: number[] }>({});
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const handleGetStudentPractice = useCallback(async () => {
    const created_min = moment().subtract(30, 'days');
    const created_max = moment();
    const studentss = students.filter(i => i.status !== 'archived' && !!i.student);
    let studentIds = _.flatMap(studentss, contact => contact?.student?.id);

    const studentParams = studentIds.reduce((prev, curr) => {
      if (prev) return prev.concat(`&student=${curr}`);
      return `student=${curr}`;
    }, '');

    const studentsQueryString = studentParams ? studentParams + '&' : '';
    try {
      const queryString = [
        studentsQueryString,
        `created_min=${encodeURIComponent(created_min.utc().format())}`,
        `created_max=${encodeURIComponent(created_max.utc().format())}`,
      ];
      let result = await getJson('/practices/timechart/?' + queryString.join('&'));

      if (result) {
        const timeCharts: { [key: string]: number[] } = {};

        for (const [key, value] of Object.entries(result?.datasets) as any) {
          const timeChartKey = key.split('/')[3];
          timeCharts[timeChartKey] = value?.values || [];
        }
        setPracticeTimeChart(timeCharts);
      }
    } catch (error) {}
  }, [getJson, students]);

  useEffect(() => {
    if (students?.length > 0 && !isFetchingStudent) {
      handleGetStudentPractice();
    }
  }, [students, isFetchingStudent, handleGetStudentPractice]);

  const fetchDataCalendar = useCallback(async () => {
    await fetchLessons();
    await fetchTeacherSchedule();
    setIsRendered(true);
  }, [fetchLessons, fetchTeacherSchedule]);

  useEffect(() => {
    fetchDataCalendar();
    fetchGroups();
  }, [fetchDataCalendar, fetchGroups]);

  const filteredStudents = useMemo(() => {
    return students.filter(i => i.status !== 'archived');
  }, [students]);

  return (
    <div className={styles['home-wrapper']}>
      {isFetchingStudent ? (
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      ) : (
        <Row>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <BillingComponent students={students} />
            <StudentsComponent
              filteredStudents={filteredStudents}
              practiceTimeChart={practiceTimeChart}
              groups={groups}
              fetchGroups={fetchGroups}
              handleUpdateStudent={handleUpdateStudent}
            />
            <AssignmentsComponent />
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            {(isFetchingLessons || isFetchingSchedule) && !isRendered ? (
              <Spinner as="span" animation="border" role="status" className={styles['spinner-calendar']} />
            ) : (
              <CalendarComponent
                calendar={calendar}
                schedule={schedule}
                lessons={lessons}
                dispatch={dispatch}
                createLesson={createLesson}
                updateLesson={updateLesson}
                fetchLessons={fetchLessons}
                allApis={allApis}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user as User,
  students: Object.values(state.student.items) as Contact[],
  isFetchingStudent: state.student.isFetching as boolean,
  allApis: state.Api.allApis,
  groups: Object.values(state.group.items) as GroupType[],
  calendar: state.calendar,
  schedule: state.schedule.teacherSchedule,
  lessons: state.schedule.lessons,
  isFetchingSchedule: state.schedule.isFetchingSchedule,
  isFetchingLessons: state.schedule.isFetchingLessons,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchGroups: () => dispatch(fetchGroups()),
  handleUpdateStudent: (id: number, groups: string[]) => dispatch(updateStudent(id, { groups })),
  fetchTeacherSchedule: () => dispatch(fetchTeacherSchedule()),
  fetchLessons: () => dispatch(fetchLessons()),
  createLesson: (data: LessonEvent) => dispatch(createLesson(data)),
  updateLesson: (id: string | number | undefined, data: LessonEvent) => dispatch(updateLesson(id, data)),
  dispatch: (action: any) => dispatch(action),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
