import {
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  FETCH_GROUPS,
  FETCH_GROUPS_FAIL,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
} from '../actions/GroupActions';

import { GroupType, TReduxAction } from 'types';

export type TGroupReducerState = {
  isFetching: boolean;
  items: Record<string, Record<string, string>>;
};

export type TBillingReducerAction = TReduxAction & {
  payload: {
    data: {
      id: string;
      results: GroupType[];
    };
  };
  meta: {
    previousAction: Record<string, string | number>;
  };
};

const initialState: TGroupReducerState = {
  isFetching: false,
  items: {},
};

export default (state: TGroupReducerState = initialState, action: TBillingReducerAction) => {
  switch (action.type) {
    case FETCH_GROUPS: {
      return { ...state, isFetching: true };
    }

    case FETCH_GROUPS_FAIL: {
      return { ...state, isFetching: false };
    }

    case FETCH_GROUPS_SUCCESS: {
      const items = action.payload.data.results.reduce(
        (acc: Record<string | number, GroupType>, item: GroupType) => ({ ...acc, [item.id]: item }),
        {},
      );
      return { ...state, items: { ...state.items, ...items }, isFetching: false };
    }

    case FETCH_GROUP_SUCCESS: {
      const group = action.payload.data;
      return { ...state, items: { ...state.items, [group.id]: { ...state.items[group.id], ...group } } };
    }

    case CREATE_GROUP_SUCCESS: {
      const group = action.payload.data;
      return { ...state, items: { ...state.items, [group.id]: group } };
    }

    case UPDATE_GROUP_SUCCESS: {
      const group = action.payload.data;
      return { ...state, items: { ...state.items, [group.id]: { ...state.items[group.id], ...group } } };
    }

    case DELETE_GROUP_SUCCESS: {
      const id = action.meta.previousAction.id;
      const items = Object.entries(state.items).filter(o => o[0] != id);
      return { ...state, items: Object.fromEntries(items) };
    }

    default:
      return state;
  }
};
