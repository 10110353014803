import { default as cs } from 'classnames';
import { useEffect, useMemo } from 'react';
import { Form, FormControlProps, FormGroupProps, FormLabelProps, InputGroup } from 'react-bootstrap';

import styles from './InputField.module.scss';

type InputFieldProps = {
  inputClassName?: string;
  labelClassName?: string;
  label?: string;
  labelProps?: FormLabelProps;
  inputProps?: Partial<HTMLTextAreaElement> & FormControlProps;
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
  size?: string;
  value?: string | number;
  autoComplete?: string;
  addOn?: string;
  unit?: string;
};

const InputField = (props: InputFieldProps) => {
  const {
    className,
    inputClassName,
    labelClassName,
    label,
    inputProps,
    labelProps,
    groupProps,
    error,
    size,
    value,
    autoComplete,
    addOn,
    unit,
  } = props;
  let sizeClass = 'normal';
  const randomId = useMemo(() => (Math.random() + 1).toString(36).substring(2), []);
  switch (size) {
    case 'small':
      sizeClass = 'small';
      break;

    default:
      break;
  }
  useEffect(() => {
    const element = document.getElementById(inputProps?.id || randomId) as HTMLInputElement | null;
    if (!element?.value) {
      element?.classList?.remove(styles['has-value']);
    } else {
      element?.classList?.add(styles['has-value']);
    }
  }, [error, inputProps?.id, randomId]);

  const handleOnFocus = () => {
    const element = document.getElementById(inputProps?.id || randomId) as HTMLInputElement | null;
    element?.classList?.add(styles['has-value']);
  };

  const handleOnBlur = () => {
    const element = document.getElementById(inputProps?.id || randomId) as HTMLInputElement | null;
    if (!element?.value) {
      element?.classList?.remove(styles['has-value']);
    }
  };

  const renderInput = (
    <>
      <Form.Control
        {...inputProps}
        placeholder={size === 'small' ? label || inputProps?.placeholder : ''}
        autoComplete={autoComplete}
        className={cs(
          styles['input-field-input'],
          inputClassName,
          styles[sizeClass],
          value || inputProps?.value ? styles['has-value'] : '',
          addOn ? styles['has-add-on'] : '',
        )}
        isInvalid={!!error}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        id={inputProps?.id || randomId}
        value={value}
        data-1p-ignore
      />
      {label && (
        <label
          className={cs(styles['input-field-label'], labelClassName, styles[sizeClass])}
          {...labelProps}
          htmlFor={inputProps?.id || randomId}
        >
          {label}
        </label>
      )}
    </>
  );

  return (
    <Form.Group className={cs(styles['input-field-group'], className)} {...groupProps}>
      {addOn || unit ? (
        <InputGroup>
          {addOn && <InputGroup.Text className={styles['input-field-add-on']}>{addOn}</InputGroup.Text>}
          {renderInput}
          {unit && <InputGroup.Text className={styles['input-field-add-on']}>{unit}</InputGroup.Text>}
        </InputGroup>
      ) : (
        <>{renderInput}</>
      )}
      <Form.Control.Feedback type="invalid" className={styles['form-feedback']}>
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputField;
