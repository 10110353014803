import { useQueryClient } from '@tanstack/react-query';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { config } from './config';

import { UPDATE_ALL_APIS, UPDATE_EXPIRES_TIME, resetToken } from 'actions/AuthActions';
import { createFetcher } from 'common/api/fetcher';
import ErrorBoundary from 'features/common/ErrorBoundary';

type LoaderProps = {
  isAuthenticated: boolean;
  children: ReactElement;
  dispatch: Dispatch;
  token: string;
  expires: number;
};

const Loader = (props: LoaderProps) => {
  const { isAuthenticated, children, dispatch, token, expires } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const queryClient = useQueryClient();

  const loader = useCallback(async () => {
    let allApis: any = await createFetcher(token, () => {}, queryClient);
    dispatch({
      type: UPDATE_ALL_APIS,
      payload: { allApis: allApis },
    });

    return allApis;
  }, [dispatch, token, queryClient]);

  const initCheck = useCallback(async () => {
    const allApis = await loader();
    if (expires <= new Date().getTime() && token) {
      try {
        const resp = await allApis.postJson(`/auth/token/refresh/`, { token: token });
        dispatch({
          type: UPDATE_EXPIRES_TIME,
          payload: { data: { token: resp.token } },
        });

        setLoading(false);
      } catch (error) {
        dispatch(resetToken());
        window.location.replace(config.public_url as string);
      }
    } else {
      setLoading(false);
    }
  }, [dispatch, loader, token, expires]);

  useEffect(() => {
    initCheck();
  }, [initCheck]);

  useEffect(() => {
    if (isAuthenticated || token) {
      loader();
    }
  }, [isAuthenticated, loader, token]);

  if (loading) return <div />;
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default connect(({ auth: { isAuthenticated, token, expires }, Api: { allApis } }) => ({
  isAuthenticated,
  token,
  expires,
  allApis,
}))(Loader);
