import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { HiPlus } from 'react-icons/hi';
import { connect } from 'react-redux';

import styles from './GoalsComponent.module.scss';

import { useGetGoalByStudent } from 'common/api/apis';
import Btn from 'components/Btn';
import GoalModal from 'components/GoalModal';
import PageSection from 'components/PageSection';
import { Contact, IGoal } from 'types';

type GoalsComponentProps = {
  contact?: Contact;
};

const GoalsComponent = (props: GoalsComponentProps) => {
  const { contact } = props;
  const defaultGetGoalByStudentParams = useMemo(
    () => ({
      embed: 'piece',
      limit: '100',
    }),
    [],
  );
  const [goalLines, setGoalLines] = useState<IGoal[]>([]);
  const [finishLoadGoals, setFinishLoadGoals] = useState<boolean>(false);
  const [isShowGoalModal, setIsShowGoalModal] = useState<boolean>(false);
  const [goalEdit, setGoalEdit] = useState<IGoal>();
  const [getGoal, { isPending: loading }] = useGetGoalByStudent({
    contact: contact?.id,
    query_params: defaultGetGoalByStudentParams,
  });

  const handleEditGoal = useCallback(async (goal: IGoal) => {
    setGoalEdit(goal);
    setIsShowGoalModal(true);
  }, []);

  const loadGoals = useCallback(async () => {
    let resp = await getGoal({
      contact: contact?.id,
      query_params: defaultGetGoalByStudentParams,
    });
    if (resp) {
      setGoalLines(resp.results);
    }
    setFinishLoadGoals(true);
  }, [getGoal, contact, defaultGetGoalByStudentParams]);

  const renderProgress = (goalData: IGoal): number => Number(goalData.progress.slice(0, -1));
  const renderMinute = (goalData: IGoal): number => Math.round(goalData.duration / 60);
  const renderActual = (goalData: IGoal): number => {
    let progress = renderProgress(goalData);
    return (progress / 100) * renderMinute(goalData);
  };

  useEffect(() => {
    if (contact && !finishLoadGoals) {
      loadGoals();
    }
  }, [loadGoals, contact, finishLoadGoals]);

  return (
    <PageSection title="Goals" className="mb-4" wrapperClassName={styles['goal-wrapper']}>
      {loading || !finishLoadGoals ? (
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      ) : (
        <>
          {goalLines?.length === 0 ? (
            <div className={styles['no-goal']}>No goals found</div>
          ) : (
            <>
              {goalLines?.map((item: IGoal, id: number) => (
                <div key={id} className={styles['goal-container']} onClick={() => handleEditGoal(item)}>
                  <div data-id={id} key={`label_${id}`} className={styles['text-area']}>
                    <div className={styles['text-left']}>
                      Practice {renderMinute(item)} minute a {item.interval}
                    </div>
                    <div className={styles['text-right']}>
                      {renderActual(item)}/{renderMinute(item)} minutes
                    </div>
                  </div>
                  <ProgressBar key={`visual_${id}`} now={renderProgress(item)} className={styles['progress']} />
                </div>
              ))}
            </>
          )}
          <div className={styles['button-wrapper']}>
            <Btn variant="primary" btnClassName={styles['new-goal-btn']} onClick={() => setIsShowGoalModal(true)}>
              <HiPlus size={20} className={styles['plus-icon']} /> New goal
            </Btn>
          </div>
        </>
      )}
      <GoalModal
        isShowModal={isShowGoalModal}
        handleCloseModal={() => {
          setIsShowGoalModal(false);
          setGoalEdit(undefined);
        }}
        contact={contact}
        goalEdit={goalEdit}
        handleSubmitModal={loadGoals}
      />
    </PageSection>
  );
};

/* eslint-disable */
const mapStateToProps = (state: any) => ({});
/* eslint-enable */

export default connect(mapStateToProps)(GoalsComponent);
