import {
  CHANGE_EVENT_TYPE,
  CHANGE_LESSON,
  CHANGE_TEACHER_SCHEDULE,
  FETCH_LESSONS,
  FETCH_LESSONS_FAIL,
  FETCH_LESSONS_SUCCESS,
  FETCH_TEACHER_SCHEDULE,
  FETCH_TEACHER_SCHEDULE_FAIL,
  FETCH_TEACHER_SCHEDULE_SUCCESS,
} from '../actions/ScheduleActions';

import { Lesson, ScheduleType, TReduxAction } from 'types';

export type TScheduleReducerState = {
  isFetchingSchedule: boolean;
  isFetchingLessons: boolean;
  teacherSchedule: {
    results?: ScheduleType[];
  };
  lessons: {
    results?: Lesson[];
  };
};

const initialState = {
  isFetchingSchedule: false,
  isFetchingLessons: false,
  teacherSchedule: {},
  eventType: 'all',
  lessons: {},
};

export default (state: TScheduleReducerState = initialState, action: TReduxAction) => {
  switch (action.type) {
    case FETCH_LESSONS: {
      return {
        ...state,
        isFetchingLessons: true,
      };
    }

    case FETCH_LESSONS_SUCCESS: {
      const url = action.payload.request.responseURL;
      const params = new URLSearchParams((url as string).split('?')[1]);
      const event_type = params.get('event_type') || 'all';
      return {
        ...state,
        isFetchingLessons: false,
        eventType: event_type,
        lessons: action.payload.data,
      };
    }

    case FETCH_LESSONS_FAIL: {
      return {
        ...state,
        isFetchingLessons: false,
      };
    }

    case FETCH_TEACHER_SCHEDULE: {
      return {
        ...state,
        isFetchingSchedule: true,
      };
    }

    case FETCH_TEACHER_SCHEDULE_SUCCESS: {
      return {
        ...state,
        isFetchingSchedule: false,
        teacherSchedule: action.payload.data,
      };
    }

    case FETCH_TEACHER_SCHEDULE_FAIL: {
      return {
        ...state,
        isFetchingSchedule: false,
      };
    }

    case CHANGE_TEACHER_SCHEDULE: {
      return {
        ...state,
        teacherSchedule: {
          ...state.teacherSchedule,
          ...action.data,
        },
      };
    }

    case CHANGE_LESSON: {
      return {
        ...state,
        lessons: {
          ...state.lessons,
          results: state.lessons.results?.map((lesson: Lesson) => {
            if (lesson.url === action.data.url) {
              return { ...lesson, status: action.data.status };
            } else {
              return lesson;
            }
          }),
        },
      };
    }

    case CHANGE_EVENT_TYPE: {
      return {
        ...state,
        eventType: action.data.eventType,
      };
    }

    default:
      return state;
  }
};
