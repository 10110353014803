import _ from 'lodash';

import { REDUX_AXIOS_SUCCESS_SUFFIX } from 'data/common';
import { FeedType, TReduxAction } from 'types';

export const CREATE_COMMENT = 'UPDATES/CREATE_COMMENT';
export const GET_UPDATES = 'UPDATES/GET';
export const LIKE_POST = 'UPDATES/LIKE_POST';
export const UNLIKE_POST = 'UPDATES/UNLIKE_POST';
export const MARK_UPDATES_READ = 'UPDATES/MARK_READ';
export type TUpdatesSliceState = {
  isFetching: boolean;
  count: number;
  next: string | null;
  items: FeedType[];
  entities: Record<string | number, FeedType>;
  ids: (string | number)[];
};
export type TUpdatesSliceAction = TReduxAction & {
  payload: {
    data: {
      id: number;
      count: number;
      likes: number;
      next: string | null;
      results: FeedType[];
    };
  };
};
const initialState: TUpdatesSliceState = {
  isFetching: false,
  count: 0,
  next: null,
  items: [],
  entities: {},
  ids: [],
};

export default (state = initialState, action: TUpdatesSliceAction) => {
  switch (action.type) {
    case GET_UPDATES: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case GET_UPDATES + REDUX_AXIOS_SUCCESS_SUFFIX: {
      const { count, next, results } = action.payload.data;
      const items = _.uniqBy([...state.items, ...results], 'id');
      const entities = results.reduce((acc, item) => ({ ...acc, [item.id]: item }), state.entities);
      const ids = Object.keys(entities).sort((a, b) => {
        if (entities[b].datetime > entities[a].datetime) {
          return 1;
        } else if (entities[a].datetime > entities[b].datetime) {
          return -1;
        } else {
          return 0;
        }
      });

      return {
        ...state,
        isFetching: false,
        items,
        count,
        next,
        entities,
        ids,
      };
    }

    case LIKE_POST + REDUX_AXIOS_SUCCESS_SUFFIX:
    case UNLIKE_POST + REDUX_AXIOS_SUCCESS_SUFFIX: {
      const { id, likes } = action.payload.data;
      const items = state.items.map(item => (item.id === id ? { ...item, likes } : item));
      const entity = { ...state.entities[id], likes };
      return {
        ...state,
        items,
        entities: { ...state.entities, [id]: entity },
      };
    }

    case MARK_UPDATES_READ + REDUX_AXIOS_SUCCESS_SUFFIX: {
      const items = state.items.map(item => ({ ...item, unread: false }));
      const entities = Object.fromEntries(
        Object.values(state.entities).map(item => [item.id, { ...item, unread: true }]),
      );
      return {
        ...state,
        entities,
        items,
      };
    }

    default:
      return state;
  }
};

export const createComment = (postID: string | number, message: string) => ({
  type: CREATE_COMMENT,
  payload: {
    request: {
      method: 'POST',
      url: `/posts/${postID}/comments/`,
      data: { message },
    },
  },
});

export const getUpdates = (qs = '') => ({
  type: GET_UPDATES,
  payload: {
    request: {
      method: 'GET',
      url: `/posts/students/${qs}`,
    },
  },
});

export const likePost = (postID: string | number) => ({
  type: LIKE_POST,
  payload: {
    request: {
      method: 'POST',
      url: `/posts/${postID}/like/?embed=likes`,
    },
  },
});

export const unlikePost = (postID: string | number) => ({
  type: UNLIKE_POST,
  payload: {
    request: {
      method: 'DELETE',
      url: `/posts/${postID}/like/?embed=likes`,
    },
  },
});

export const markUpdatesAsRead = () => ({
  type: MARK_UPDATES_READ,
  payload: {
    request: {
      method: 'POST',
      url: '/posts/mark_read/',
    },
  },
});
