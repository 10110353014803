import { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styles from './Notes.module.scss';
import StudentList from './StudentList';
import StudentNoteDetail from './StudentNoteDetail';

import { addNote, deleteNote, getNotes, getPersonalNotes, updateNote } from 'actions/NotesActions';
import { Contact, NotesType, User } from 'types';

type NotesProps = {
  students: Contact[];
  user: User;
  allApis: any;
  isFetchingStudent: boolean;
  notes: NotesType;
  getNotes: (body: { contact: number; ordering: string; limit: number; offset: number; _: number }) => void;
  getPersonalNotes: (body: { ordering: string; limit: number; offset: number; _: number }) => void;
  deleteNote: (id: number) => void;
  updateNote: (id: number, data: { description: string }) => void;
  addNote: (data: { student?: string; description: string }) => void;
};

const Notes = (props: NotesProps) => {
  const {
    students = [],
    isFetchingStudent,
    user,
    notes,
    getNotes,
    getPersonalNotes,
    deleteNote,
    updateNote,
    addNote,
  } = props;
  const location = useLocation();
  const student = location.state?.student || ({ id: -1 } as Contact);
  const [studentSelected, setStudentSelected] = useState<Contact>(student);
  const [offset, setOffset] = useState<number>(0);
  const contactNotes = notes.data.filter(n => n.contact == studentSelected.url);

  const { ref } = useInView({
    threshold: 0,
    onChange: inView => {
      if (inView) {
        setOffset(contactNotes.length);
      }
    },
  });

  const changeContact = (contact: Contact) => {
    setOffset(0);
    setStudentSelected(contact);
  };

  useEffect(() => {
    const limit = 10;

    // The _ param is a "dirty hack" that prevents response caching.
    if (studentSelected.id !== -1) {
      getNotes({ contact: studentSelected.id, ordering: '-created', limit, offset, _: Date.now() });
    } else {
      getPersonalNotes({ ordering: '-created', limit, offset, _: Date.now() });
    }
  }, [studentSelected, getNotes, getPersonalNotes, offset]);

  if (isFetchingStudent)
    return (
      <div className={styles['spinner-wrapper']}>
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      </div>
    );

  return (
    <div className={styles['notes-wrapper']}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={3}>
          <StudentList students={students} studentSelected={studentSelected} setStudentSelected={changeContact} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={6}>
          {notes.loading && !notes.data ? (
            <div className={styles['spinner-wrapper']}>
              <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
            </div>
          ) : (
            <>
              <StudentNoteDetail
                studentSelected={studentSelected}
                user={user}
                students={students}
                notes={notes}
                deleteNote={deleteNote}
                updateNote={updateNote}
                addNote={addNote}
              />
              <div ref={ref} />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user as User,
  allApis: state.Api.allApis,
  students: Object.values(state.student.items) as Contact[],
  isFetchingStudent: state.student.isFetching as boolean,
  notes: state.notes,
});

const mapDispatchToProps = (dispatch: any) => ({
  getNotes: (body: { contact: number; limit: number }) => dispatch(getNotes(body)),
  getPersonalNotes: (body: { limit: number }) => dispatch(getPersonalNotes(body)),
  deleteNote: (id: number) => dispatch(deleteNote(id)),
  updateNote: (id: number, data: { description: string }) => dispatch(updateNote(id, data)),
  addNote: (data: { student?: string; description: string }) => dispatch(addNote(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
