import { default as cs } from 'classnames';
import { BiLock } from 'react-icons/bi';

import styles from './StudentList.module.scss';

import Avatar from 'components/Avatar';
import { Contact } from 'types';

type StudentListProps = {
  students: Contact[];
  studentSelected: Contact;
  setStudentSelected: (contact: Contact) => void;
};

const StudentList = (props: StudentListProps) => {
  const { students, setStudentSelected, studentSelected } = props;

  const onClickStudent = (contact: Contact) => {
    if (contact.id !== studentSelected.id) setStudentSelected(contact);
  };

  const renderStudentsComponent = () => {
    const teacher = { id: -1, name: 'My Private Notes' } as Contact;

    return (
      <ul className={styles['students-info-wrapper']}>
        {[teacher, ...students].map((contact: Contact) => {
          return (
            <li
              key={contact.id}
              className={cs(studentSelected.id === contact.id ? styles['active'] : '')}
              onClick={() => onClickStudent(contact)}
            >
              <span className={styles['student-info']} aria-hidden="true">
                <div className={styles['student-info__avatar']}>
                  {(contact.id === -1 && <BiLock size={22} />) || (
                    <Avatar
                      size={25}
                      letter={contact.name || contact.email || ''}
                      url={contact.student && contact.student.picture}
                    />
                  )}
                </div>
                <p className={styles['student-info__name']}>{contact.name}</p>
              </span>
            </li>
          );
        })}
      </ul>
    );
  };

  return <div className={styles['student-list-wrapper']}>{renderStudentsComponent()}</div>;
};

export default StudentList;
