import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';

import loadGMaps from '../../../common/scripts/loadGMaps';

export default ({ initialValues = {}, onSubmit }) => (
  <Formik
    initialValues={{
      name: '',
      address: '',
      ...initialValues,
    }}
    validate={values => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Required';
      }
      return errors;
    }}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, setFieldValue }) => {
      useEffect(() => {
        loadGMaps(() => {
          const addressAutocomplete = new window.google.maps.places.Autocomplete(document.getElementById('address'), {
            types: ['(cities)'],
          });
          addressAutocomplete.addListener('place_changed', () => {
            const place = addressAutocomplete.getPlace();
            setFieldValue('address', place.formatted_address);
          });
        });
      }, [setFieldValue]);

      return (
        <Form className="form-horizontal" id="organization-modal-form">
          <div className="form-group">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="name" className="d-none control-label">
              Name
            </label>
            <div>
              <Field name="name" className="form-control" id="name" disabled={isSubmitting} placeholder="Name" />
              <ErrorMessage name="name" className="text-danger" component="div" />
            </div>
          </div>
          <div className="form-group">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="address" className="d-none control-label">
              City, state
            </label>
            <div>
              <Field name="address" className="form-control" id="address" disabled={isSubmitting} />
              <ErrorMessage name="address" className="text-danger" component="div" />
            </div>
          </div>
        </Form>
      );
    }}
  </Formik>
);
