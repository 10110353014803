import moment from 'moment';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ToolbarCalendar.module.scss';

import { fetchLessons } from 'actions/ScheduleActions';
import DropdownSelect from 'components/DropdownSelect';

type ToolbarCalendarProps = {
  label?: string;
  onNavigate?: any;
};

const ToolbarCalendar = (props: ToolbarCalendarProps) => {
  const { label, onNavigate } = props;
  const dispatch = useDispatch() as any;
  const eventType = useSelector((state: any) => state.schedule.eventType);
  const calendarOptions = [
    {
      value: 'all',
      label: 'All',
      action: () => {
        dispatch(fetchLessons());
      },
    },
    {
      value: 'lesson',
      label: 'Lesson',
      action: () => {
        dispatch(fetchLessons({ event_type: 'lesson' }));
      },
    },
    {
      value: 'personal_appointment',
      label: 'Personal Appointment',
      action: () => {
        dispatch(fetchLessons({ event_type: 'personal_appointment' }));
      },
    },
    {
      value: 'event',
      label: 'Event',
      action: () => {
        dispatch(fetchLessons({ event_type: 'event' }));
      },
    },
  ];

  return (
    <div className={styles['wrapper-toolbar']}>
      <div className={styles['rbc-toolbar-label']}>
        <div className={styles['toolbar-icon']} onClick={() => onNavigate('PREV')}>
          <HiChevronLeft size={26} className={styles['toolbar-icon-left']} />
        </div>
        <div className={styles['toolbar-label']}>
          {moment(label).set('year', new Date().getFullYear()).format('MMM DD, ddd')}
        </div>
        <div className={styles['toolbar-icon']} onClick={() => onNavigate('NEXT')}>
          <HiChevronRight size={26} className={styles['toolbar-icon-right']} />
        </div>
      </div>
      <div>
        <DropdownSelect
          label={calendarOptions.filter(item => item.value === eventType)[0].label}
          options={calendarOptions}
        />
      </div>
    </div>
  );
};

export default ToolbarCalendar;
