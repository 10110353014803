import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { config } from '../../../utils/config';

import Form from './TeachersForm';

export default ({ show, onHide, org, dispatch, initialValues, setTab }) => {
  const { allApis } = useSelector(({ Api }) => Api);

  const handleCreate = values => {
    const { group, ...data } = values;
    const url = data.organization.replace(config.api_endpoint, '');
    if (group) {
      data.classes = [group];
    }
    return allApis.postJson(url + 'teachers/', data).then(response => {
      dispatch({
        type: 'update',
        org: {
          id: org.id,
          teachers: [...(org.teachers || []), response],
        },
      });
      onHide();
      return true;
    });
  };

  const handleUpdate = values => {
    const { id, user, organization, group, ...data } = values;
    data.groups = group ? [group] : [];
    return allApis.patchJson(`/studio/organizations/memberships/${id}/?embed=user`, data).then(membership => {
      dispatch({
        type: 'update',
        org: {
          id: org.id,
          teachers: org.teachers.map(t => (t.id === membership.id ? { ...t, ...membership } : t)),
        },
      });
      onHide();
      return true;
    });
  };

  const goToGroups = () => {
    onHide();
    setTab(0);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div className="modal-title">{initialValues.id ? 'Update' : 'Create'} teacher</div>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form
          initialValues={initialValues}
          groupOptions={org.groups?.map(g => ({
            value: g.url,
            label: g.name || 'Unnamed group',
          }))}
          onSubmit={initialValues?.id ? handleUpdate : handleCreate}
          goToGroups={goToGroups}
        />
      </Modal.Body>
      <Modal.Footer className="text-right">
        <button type="submit" form="teacher-modal-form" className="btn btn-primary">
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
