import { default as cs } from 'classnames';

import styles from './Avatar.module.scss';

type AvatarProps = {
  url?: string;
  size: number;
  letter: string;
  className?: string;
};

const Avatar = (props: AvatarProps) => {
  const { url, size = 40, letter, className } = props;
  const colors = [
    '#5A8770',
    '#B2B7BB',
    '#6FA9AB',
    '#F5AF29',
    '#0088B9',
    '#F18636',
    '#D93A37',
    '#A6B12E',
    '#5C9BBC',
    '#F5888D',
    '#9A89B5',
    '#407887',
    '#9A89B5',
    '#5A8770',
    '#D33F33',
    '#A2B01F',
    '#F0B126',
    '#0087BF',
    '#F18636',
    '#0087BF',
    '#B2B7BB',
    '#72ACAE',
    '#9C8AB4',
    '#5A8770',
    '#EEB424',
    '#407887',
  ];

  if (url) {
    return (
      <img
        src={url}
        alt=""
        style={{
          width: size,
          height: size,
          borderRadius: 50,
          objectFit: 'cover',
        }}
        className={className}
      />
    );
  }

  const color = colors[letter.charCodeAt(0) % colors.length];

  return (
    <div className={cs(styles['avatar'], className)}>
      <svg style={{ width: size, height: size }} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
        <circle fill={color} cx="50%" cy="50%" r={size / 2} />
        <text
          y={size * 0.64}
          x={size / 2}
          textAnchor="middle"
          fill="white"
          fontSize={`${size * 0.42}px`}
          fontWeight="normal"
          fontFamily="sans-serif"
        >
          {letter}
        </text>
      </svg>
    </div>
  );
};

export default Avatar;
