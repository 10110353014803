import { GET_CLASSES, SET_CLASS } from '../actions/UserActions';

export default (state = {}, action: any) => {
  switch (action.type) {
    case GET_CLASSES + '_SUCCESS': {
      return {
        ...state,
        ...action.payload.data,
      };
    }

    case SET_CLASS: {
      return {
        ...state,
        active: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
