export default (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="#0F1825"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2734 10.125L8.2151 12.1833C7.07344 13.325 7.07344 15.1667 8.2151 16.3083C9.35677 17.45 11.1984 17.45 12.3401 16.3083L15.5818 13.0667C17.8568 10.7917 17.8568 7.09166 15.5818 4.81666C13.3068 2.54166 9.60677 2.54166 7.33177 4.81666L3.79844 8.35C1.84844 10.3 1.84844 13.4667 3.79844 15.425"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
