import { ForwardedRef, forwardRef } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export default forwardRef((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <label
      htmlFor={props.id}
      className="h-11 has-[:disabled]:bg-gray-100 text-[0.8125rem] text-greySecondary relative block overflow-hidden rounded-md border !border-greyBorder !px-3 !pt-3 focus-within:border-primary focus-within:ring-1 focus-within:ring-primary"
    >
      <input
        {...props}
        ref={ref}
        placeholder={props.placeholder || props.label}
        className="text-gray-900 peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 disabled:text-gray-500"
      />
      <span className="absolute start-3 top-3 -translate-y-1/2 text-[0.5625rem] text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-[0.5625rem]">
        {props.label}
      </span>
    </label>
  );
});
