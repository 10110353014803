import { SET_CALENDAR } from '../actions/CalendarActions';

import { TReduxAction } from 'types';

const initialState = {
  showAvailableTime: false,
  showRequests: false,
  showSettings: false,
  height: 700,
};

export default (state = initialState, action: TReduxAction) => {
  switch (action.type) {
    case SET_CALENDAR:
      return action.data;
    default:
      return state;
  }
};
