export default (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    stroke="#0F1825"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66797 1.66666V4.16666"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.332 1.66666V4.16666"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91797 7.57501H17.0846"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0013 19.1667C16.8423 19.1667 18.3346 17.6743 18.3346 15.8333C18.3346 13.9924 16.8423 12.5 15.0013 12.5C13.1604 12.5 11.668 13.9924 11.668 15.8333C11.668 17.6743 13.1604 19.1667 15.0013 19.1667Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2412 15.875H13.7578"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 14.6583V17.15" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.5 7.08332V13.6333C16.8917 12.9416 16 12.5 15 12.5C13.1583 12.5 11.6667 13.9917 11.6667 15.8333C11.6667 16.4583 11.8417 17.05 12.15 17.55C12.325 17.85 12.55 18.1167 12.8083 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V7.08332C2.5 4.58332 3.75 2.91666 6.66667 2.91666H13.3333C16.25 2.91666 17.5 4.58332 17.5 7.08332Z"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.99803 11.4167H10.0055" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.91209 11.4167H6.91957" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.91209 13.9167H6.91957" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
