import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Img404 from 'theme/img/404.svg';

const Page404 = () => (
  <div className="page-404">
    <Col xs={12}>
      <Row>
        <div className="well text-center mt-5">
          <img className="my-3 d-inline" src={Img404} />
          <h2 className="my-3 h2">404 Error &mdash; Page not found</h2>
          <p>
            Oh no! The page you were looking for must of blown away on the wind. <br />
            Try searching for it or return to the{' '}
            <Link to="/" className="underline">
              homepage
            </Link>
            .
          </p>
          {/*
            <Col lg={4} lgOffset={4}>
              <div className="search text-left">
                <input type="text" placeholder="Search..." />
              </div>
            </Col>
          */}
        </div>
      </Row>
    </Col>
  </div>
);

export default Page404;
