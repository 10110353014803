import { ErrorMessage, Field, Form, Formik } from 'formik';

export default ({ initialValues = {}, onSubmit }) => (
  <Formik
    initialValues={{
      name: '',
      organization: '',
      ...initialValues,
    }}
    validate={values => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Required';
      }
      return errors;
    }}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form className="form-horizontal" id="group-modal-form">
        <Field name="organization" type="hidden" />
        <div className="form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name" className="d-none control-label">
            Name
          </label>
          <div>
            <Field name="name" className="form-control" id="name" disabled={isSubmitting} />
            <ErrorMessage name="name" className="text-danger" component="div" />
          </div>
        </div>
      </Form>
    )}
  </Formik>
);
