export default {
  create: {
    success: 'Student created succesfully.',
    error: 'Student created unsuccesfully. Please try again later.',
    limit: 'You have reached the limit of students you can add. Please consider upgrading your plan.',
  },
  update: {
    success: 'Student updated succesfully.',
    error: 'Student updated unsuccesfully. Please try again later.',
  },
  delete: {
    success: 'Student removed succesfully.',
    error: 'Student removed unsuccesfully. Please try again later.',
  },
  bulk: {
    success: 'Bulk add students succesfully.',
    error: 'Bulk add students unsuccesfully. Please try again later.',
  },
  archive: {
    success: 'Student archived  successfully.',
    error: 'Student archived unsuccessfully. Please try again later.',
  },
};
