export default {
  create: {
    success: 'Subtask created succesfully.',
    error: 'Subtask created unsuccesfully.',
  },
  update: {
    success: 'Subtask updated succesfully.',
    error: 'Subtask updated unsuccesfully.',
  },
  delete: {
    success: 'Subtask removed succesfully.',
    error: 'Subtask removed unsuccesfully.',
  },
};
