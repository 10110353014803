import _ from 'lodash';

import { ADD_NOTE, DELETE_NOTE, FETCH_NOTES, UPDATE_NOTE } from 'actions/NotesActions';
import { REDUX_AXIOS_ERROR_SUFFIX, REDUX_AXIOS_SUCCESS_SUFFIX } from 'data/common';
import { NoteDetail, NotesType, TReduxAction } from 'types';

export type TNotesReducerAction = TReduxAction & {
  payload: {
    config: Record<string, string | number>;
    data: {
      next: string | null;
      results: NoteDetail[];
    };
  };
};

const initialState: NotesType = {
  loading: false,
  data: [],
};

export default function reducer(state: NotesType = initialState, action: TNotesReducerAction) {
  switch (action.type) {
    case ADD_NOTE + REDUX_AXIOS_SUCCESS_SUFFIX: {
      return { ...state, data: [action.payload.data, ...state.data] };
    }

    case DELETE_NOTE + REDUX_AXIOS_SUCCESS_SUFFIX: {
      const data = state.data.filter(note => !note.url.endsWith(String(action.payload.config.url)));
      return { ...state, data };
    }

    case FETCH_NOTES: {
      return { ...state, loading: true };
    }

    case FETCH_NOTES + REDUX_AXIOS_ERROR_SUFFIX: {
      return { ...state, loading: false };
    }

    case FETCH_NOTES + REDUX_AXIOS_SUCCESS_SUFFIX: {
      const data = [...state.data, ...action.payload.data.results];
      return {
        ...state,
        data: _.orderBy(_.uniqBy(data, 'url'), 'created', 'desc'),
        loading: false,
      };
    }

    case UPDATE_NOTE + REDUX_AXIOS_SUCCESS_SUFFIX: {
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map(note => (note.id === data.id && data) || note),
      };
    }

    default:
      return state;
  }
}
