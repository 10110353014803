import { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import styles from './StudentsComponent.module.scss';

import Btn from 'components/Btn';
import { useToggle } from 'hooks';
import AddStudentModal from 'pages/Students/AddStudentModal';
import StudentItem from 'pages/Students/StudentItem';
import { Contact, GroupType } from 'types';

type StudentsComponentProps = {
  filteredStudents: Contact[];
  practiceTimeChart: { [key: string]: number[] };
  groups: GroupType[];
  handleUpdateStudent: (id: number, groups: string[]) => void;
  fetchGroups: () => void;
};

const StudentsComponent = (props: StudentsComponentProps) => {
  const { filteredStudents, practiceTimeChart, groups, handleUpdateStudent, fetchGroups } = props;
  const [isShowAddStudentModal, toggleIsShowAddStudentModal] = useToggle();
  const [isBulkAdd, setIsBulkAdd] = useState(false);

  const VISIBLE_ITEMS_NUM = 5;
  const HIDDEN_ITEMS_NUM = filteredStudents.length - VISIBLE_ITEMS_NUM;

  return (
    <>
      {!filteredStudents?.length ? (
        <div className={styles['students-wrapper']}>
          <p className={styles['students-header']}>Students</p>
          <div className={styles['no-student']}>
            <div className={styles['no-student-body']}>
              <p className={styles['no-student__title']}>You do not have any students yet.</p>
              <p className={styles['no-student__desc']}>
                When you add a student, they will receive an email asking them to download the Practice app.
              </p>
              <Btn variant="primary" className="mx-auto" onClick={toggleIsShowAddStudentModal}>
                <HiPlus size={18} /> Add your first students
              </Btn>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles['students-wrapper']}>
          <p className={styles['students-header']}>Students</p>
          <div className={styles['students-body']}>
            {filteredStudents
              ?.slice(0, VISIBLE_ITEMS_NUM)
              ?.map(item => (
                <StudentItem
                  hideCheckbox
                  key={item.id}
                  contact={item}
                  timeChart={practiceTimeChart?.[String(item?.student?.id)]}
                  groups={groups}
                  handleUpdateStudent={handleUpdateStudent}
                  fetchGroups={fetchGroups}
                />
              ))}
          </div>
          {HIDDEN_ITEMS_NUM > 0 && (
            <Link className={styles['btn-view-students']} to={'/students'}>
              +{HIDDEN_ITEMS_NUM} Student{HIDDEN_ITEMS_NUM > 1 && 's'}
            </Link>
          )}
        </div>
      )}
      <AddStudentModal
        setIsBulkAdd={(value: boolean) => setIsBulkAdd(value)}
        isShowModal={isShowAddStudentModal}
        handleCloseModal={toggleIsShowAddStudentModal}
        isBulkAdd={isBulkAdd}
      />
    </>
  );
};

export default StudentsComponent;
