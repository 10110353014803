import { default as cs } from 'classnames';
import { useState } from 'react';

import styles from './AvatarField.module.scss';

import AvatarImg from 'theme/img/avatar-upload_41x41.svg';
import { User } from 'types';

type AvatarFieldProps = {
  meta?: any;
  subtext?: string;
  onUpdateNewImageFile?: any;
  value?: any;
  user: User;
  error?: string;
};

const AvatarField = (props: AvatarFieldProps) => {
  const { meta, subtext, onUpdateNewImageFile, value, user, error } = props;
  const [image, setImage] = useState<any>(value);
  const imageSrc = !meta?.error && typeof image === 'string' ? image : image?.src;

  const onAvatarChange = (e: any) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    onUpdateNewImageFile(file);

    reader.onloadend = () => {
      file.src = reader.result;
      setImage(file);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className={styles['avatar-field-wrapper']}>
      <label htmlFor={`${user.id}`}>
        {imageSrc ? (
          <div
            className={styles['avatar-wrapper']}
            style={{
              background: `url(${imageSrc}) center / cover`,
            }}
          ></div>
        ) : (
          <img src={AvatarImg} className={styles['avatar-img']} />
        )}
        <div className={cs(styles['avatar-button'], error && ' is-invalid')}>Change Photo</div>
        {error && <div className="invalid-feedback">{error}</div>}
        <input
          id={`${user.id}`}
          type="file"
          accept="image/gif, image/jpeg, image/png"
          className={styles['avatar-input']}
          onChange={onAvatarChange}
        />
      </label>
      {Boolean(subtext) && <div className="file-upload-text color-gray">{subtext}</div>}
      {meta?.error && (
        <div>
          <p className="text-danger">{meta.error}</p>
        </div>
      )}
    </div>
  );
};

export default AvatarField;
