import React, { useEffect, useReducer, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import './styles.module.scss';
import GroupsTab from './GroupsTab';
import StudentsTab from './StudentsTab';
import TeachersTab from './TeachersTab';
import { GroupsModal, OrganizationsModal, TeachersModal } from './components';

import AddStudentModal from 'pages/Students/AddStudentModal';

const initialState = {
  isLoading: true,
  orgs: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'get':
      return {
        ...state,
        isLoading: false,
        orgs: action.orgs,
      };
    case 'create':
      return {
        ...state,
        orgs: [...state.orgs, action.org],
      };
    case 'delete': {
      return {
        ...state,
        orgs: state.orgs.filter(({ id }) => id !== action.org.id),
      };
    }
    case 'update':
      return {
        ...state,
        orgs: state.orgs.map(o => (o.id === action.org.id ? { ...o, ...action.org } : o)),
      };
    default:
      return state;
  }
}

export default () => {
  const url = '/studio/organizations';
  const [state, dispatch] = useReducer(reducer, initialState);
  const { allApis } = useSelector(({ Api }) => Api);
  const user = useSelector(state => state.user);
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState();

  useEffect(() => {
    allApis.getJson(url, { limit: 1000, embed: 'groups' }).then(({ results }) => {
      dispatch({ type: 'get', orgs: results });
    });
  }, [allApis]);

  const handleClickOrgAdd = () => {
    setModal('orgs');
  };

  const onModalHide = () => {
    setModal(false);
  };

  const handleTabSelect = key => {
    setTab(key);
  };

  if (state.isLoading) {
    return (
      <div className="py-5 my-5 text-center">
        <div className="spinner-border big" />
      </div>
    );
  }

  const org = state.orgs[0];
  return (
    <div className="organizations container mb-5">
      <div className="py-4 px-5">
        <div className="px-4">
          {(org &&
            (((org.creator.url ?? org.creator === user.url) && (
              <>
                {/*
                <div>
                  <span
                    className="pull-right material-icons-outlined btn btn-pill font-regular"
                    onClick={() => handleClickOrgEdit(org)}
                  >
                    edit
                  </span>
                </div>
                */}
                <Tab.Container id="organizations" activeKey={tab} onSelect={handleTabSelect}>
                  <div className="d-flex align-items-center justify-content-between">
                    <Nav variant="underline">
                      <Nav.Item>
                        <Nav.Link eventKey={0}>
                          <span className="material-icons-outlined">home</span>
                          Classes
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={1}>
                          <span className="material-icons-outlined">person</span>
                          Teachers
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={2}>
                          <span className="material-icons-outlined">school</span>
                          Students
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey={0}>
                        <button className="btn btn-primary" onClick={() => setModal('groups')}>
                          <span className="material-icons-outlined">add</span>
                          Create class
                        </button>
                      </Tab.Pane>
                      <Tab.Pane eventKey={1}>
                        <button className="btn btn-primary" onClick={() => setModal('teachers')}>
                          <span className="material-icons-outlined">add</span>
                          Add teacher
                        </button>
                      </Tab.Pane>
                      <Tab.Pane eventKey={2}>
                        <button className="btn btn-primary" onClick={() => setModal('students')}>
                          <span className="material-icons-outlined">add</span>
                          Add students
                        </button>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                  <Tab.Content className="py-4">
                    <Tab.Pane eventKey={0}>{<GroupsTab org={org} dispatch={dispatch} />}</Tab.Pane>
                    <Tab.Pane eventKey={1}>
                      <TeachersTab org={org} dispatch={dispatch} setTab={setTab} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                      <StudentsTab org={org} dispatch={dispatch} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </>
            )) || (
              <div className="text-center h4 mt-5 mb-5">
                You are a member of <b>{org.name}</b>.
              </div>
            ))) || (
            <div className="text-center mt-5 mb-5">
              <p>You are not a member of any organizations.</p>
              <button type="button" className="btn btn-primary d-inline-block" onClick={handleClickOrgAdd}>
                Create new organization
              </button>
            </div>
          )}
        </div>
      </div>

      <OrganizationsModal show={modal === 'orgs'} onHide={onModalHide} org={org} orgDispatch={dispatch} />

      {state.orgs.length > 0 && (
        <>
          <GroupsModal
            show={modal === 'groups'}
            onHide={() => setModal()}
            org={org}
            dispatch={dispatch}
            initialValues={{ organization: org.url }}
          />
          <TeachersModal
            show={modal === 'teachers'}
            onHide={() => setModal()}
            org={org}
            dispatch={dispatch}
            initialValues={{
              organization: org.url,
              group: org.groups?.reduce((acc, g) => acc || g.url, ''),
              role: 'teacher',
            }}
            setTab={setTab}
          />
          <AddStudentModal
            initialValues={{ owner: org.url }}
            isShowModal={modal === 'students'}
            handleCloseModal={() => {
              const url = `/studio/organizations/${org.id}/memberships/`;
              allApis
                .getJson(url, { limit: 1000, role: 'student', embed: 'user', _: Date.now() })
                .then(({ results: students }) =>
                  dispatch({
                    type: 'update',
                    org: { id: org.id, students },
                  }),
                );
              setModal();
            }}
          />
        </>
      )}
    </div>
  );
};
