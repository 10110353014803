import moment from 'moment';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import styles from './Header.module.scss';

import Avatar from 'components/Avatar';
import { useStudentByUserID } from 'hooks';
import { FeedType } from 'types';

type HeaderProps = {
  data: FeedType;
};

const Header = ({ data }: HeaderProps) => {
  const { user, datetime } = data;
  const navigate = useNavigate();
  const student = useStudentByUserID(user.id);
  const location = useLocation();
  const from = location.state?.from ?? location.pathname;
  return (
    <div className={styles['wrapper-header']}>
      <div className={styles['header-left']}>
        <div className={styles['header-avatar']}>
          <Avatar url={user.picture} size={42} letter={user?.full_name?.substring(0, 1)} />
        </div>
        <div className={styles['header-info']}>
          <p className={styles['info__name']} onClick={() => navigate(`/students/${student?.id}`)}>
            {user?.full_name}
          </p>
          <Link to={`/post/${data?.id}`} state={{ from }} className={styles.link}>
            <p className={styles['info__time']}>{datetime && `${moment(datetime).format('MMM D, YYYY [at] LT')}`}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
