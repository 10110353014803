export const CREATE_GOAL = 'students/createGoal';
export const DELETE_GOAL = 'students/deleteGoal';
export const GET_GOALS = 'students/getGoals';
export const UPDATE_GOAL = 'students/updateGoal';

export type TGoalDataValue =
  | string
  | number
  | null
  | undefined
  | Record<string | number, string | number | undefined>[]
  | (string | undefined)[];
export type TGoalData = Record<string, TGoalDataValue>;
export type TGoalParams = Record<string, string | number>;

export const createGoal = (data: TGoalData, params: TGoalParams) => ({
  type: CREATE_GOAL,
  payload: {
    request: {
      method: 'POST',
      url: '/teacher/goals/',
      data,
      params,
    },
  },
});

export const deleteGoal = (id: string | number) => ({
  type: DELETE_GOAL,
  payload: {
    request: {
      method: 'DELETE',
      url: `/teacher/goals/${id}/`,
    },
  },
});

export const getGoals = (params: TGoalParams) => ({
  type: GET_GOALS,
  payload: {
    request: {
      method: 'GET',
      url: '/teacher/goals/',
      params,
    },
  },
});

export const updateGoal = (id: string | number, data: TGoalData, params: TGoalParams) => ({
  type: UPDATE_GOAL,
  payload: {
    request: {
      method: 'PATCH',
      url: `/teacher/goals/${id}/`,
      data,
      params,
    },
  },
});
