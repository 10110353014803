import { default as cs } from 'classnames';
import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import { TfiMoreAlt } from 'react-icons/tfi';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import AddGroupModal from '../AddGroupModal';
import EditStudentModal from '../EditStudentModal';

import PracticeChart from './PracticeChart';
import styles from './StudentItem.module.scss';

import { sendInvite } from 'actions/StudentActions';
import { DateUtils } from 'common/utils/date-utils';
import Avatar from 'components/Avatar';
import Btn from 'components/Btn';
import Checkbox from 'components/Checkbox';
import CreateAssignmentModal from 'components/CreateAssignmentModal';
import DropdownSelect from 'components/DropdownSelect';
import SendMessageModal from 'components/SendMessageModal';
import NOTIFICATIONS from 'data/notifications/email';
import { ReactComponent as SendMailIcon } from 'theme/svg/send-mail.svg';
import { Contact, GroupType } from 'types';

type StudentItemProps = {
  hideCheckbox?: boolean;
  contact: Contact;
  dispatch: Dispatch;
  timeChart?: number[];
  groups: GroupType[];
  handleUpdateStudent: (id: number, groups: string[]) => void;
  fetchGroups: () => void;
  handleToggleCheck?: any;
  checkIsChecked?: any;
};

const StudentItem = (props: StudentItemProps) => {
  const {
    hideCheckbox,
    contact,
    dispatch,
    timeChart,
    groups,
    handleUpdateStudent,
    fetchGroups,
    handleToggleCheck,
    checkIsChecked,
  } = props;
  const [isShowSendMessageModal, setIsShowSendMessageModal] = useState(false);
  const [isShowAssignmentModal, setIsShowAssignmentModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [isShowAddGroupModal, setIsShowAddGroupModal] = useState(false);
  const [isInviteResent, setIsInviteResent] = useState(false);
  const [isReSendingInvite, setIsReSendingInvite] = useState(false);
  const router = useNavigate();

  /* eslint-disable */
  const generateFakeData = () => {
    const created_min = moment().startOf('month');
    const daysNum = Math.min(moment().daysInMonth(), moment().diff(created_min, 'days'));
    const values = Array.from(Array(moment().daysInMonth()), (_, i) =>
      i < daysNum ? Math.ceil(Math.random() * 1000) : 0,
    );
    return values;
  };
  /* eslint-enable */

  const resendInvite = async (id: number) => {
    setIsReSendingInvite(true);
    try {
      await dispatch(sendInvite(id));
      setIsInviteResent(true);
    } catch (error) {
      toast.error(NOTIFICATIONS.create.error);
    }
    setIsReSendingInvite(false);
  };

  const dropdownOptions = [
    {
      label: 'Send message',
      action: () => setIsShowSendMessageModal(true),
    },
    { label: 'Assign homework', action: () => setIsShowAssignmentModal(true) },
    { label: 'Add to group', action: () => setIsShowAddGroupModal(true) },
    { label: 'Add note', action: () => router('/notes', { state: { student: contact } }) },
    { label: 'Edit student', action: () => setIsShowEditModal(true) },
  ];

  const handleClickName = (contact: Contact) => {
    router(`/students/${contact.id}`);
  };

  const renderTimePracticed = () => {
    if (!timeChart) return `0 s`;
    let total_time = timeChart.reduce(function (a, b) {
      return a + b;
    });

    return total_time < 60 ? `${total_time} s` : DateUtils.countdown()(total_time);
  };

  return (
    <div className={styles['student-item']}>
      <SendMessageModal
        isShowModal={isShowSendMessageModal}
        handleCloseModal={() => setIsShowSendMessageModal(false)}
        contact={contact}
      />
      <CreateAssignmentModal
        isShowModal={isShowAssignmentModal}
        handleCloseModal={() => setIsShowAssignmentModal(false)}
      />
      <EditStudentModal
        isShowModal={isShowEditModal}
        handleCloseModal={() => setIsShowEditModal(false)}
        contact={contact}
      />
      <AddGroupModal
        isShowModal={isShowAddGroupModal}
        handleCloseModal={() => setIsShowAddGroupModal(false)}
        groups={groups}
        contact={contact}
        handleUpdateStudent={handleUpdateStudent}
        fetchGroups={fetchGroups}
      />
      <div className={styles['left-wrapper']}>
        {!hideCheckbox && (
          <div className={styles['item-checkbox']}>
            <Checkbox
              checked={checkIsChecked(contact)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleToggleCheck(contact, e.target.checked)}
            />
          </div>
        )}
        <Avatar
          size={31}
          letter={contact?.name?.substring(0, 1) || ''}
          url={contact?.student?.picture}
          className={styles['student-avatar']}
        />
        <div className={styles['student-info']}>
          <div
            className={cs(styles['first-title'], styles['first-title__allow-click'])}
            onClick={() => handleClickName(contact)}
          >
            {contact?.name}
          </div>
          <div className={styles['student-time-practiced-mb']}>{renderTimePracticed()}</div>
          {/* <div className={styles['sub-title']}>{contact?.lastActive}</div> */}
        </div>
        <div className={styles['student-time-practiced']}>{renderTimePracticed()}</div>
        <div className={styles['timechart-wrapper']}>
          <PracticeChart values={timeChart || []} />
        </div>
      </div>
      <div className={styles['timechart-wrapper-mb']}>
        {isInviteResent ? (
          <div className={styles['invite-resent-text']}>Invite re-sent</div>
        ) : (
          <Btn
            isLoading={isReSendingInvite}
            variant="link"
            btnClassName={styles['resend-btn']}
            onClick={() => resendInvite(contact?.id)}
          >
            <SendMailIcon className={styles['icon']} />
          </Btn>
        )}
      </div>
      <DropdownSelect
        customToggleComponent={
          <div className={styles['right-wrapper']}>
            <TfiMoreAlt className={styles['more-icon']} />
          </div>
        }
        options={dropdownOptions}
      />
    </div>
  );
};

export default connect(null)(StudentItem);
