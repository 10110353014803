import _findIndex from 'lodash/findIndex';
import { useCallback, useEffect, useMemo, useState } from 'react';

type UseCheckType = {
  items: any[];
  key?: string;
};

const useCheck = ({ items, key = 'id' }: UseCheckType) => {
  const [checkedItems, setCheckItems] = useState<any>([]);
  const [defaultCheckedItems, setDefaultCheckedItems] = useState<any>([]);

  useEffect(() => {
    if (defaultCheckedItems.length > 0) {
      setCheckItems(defaultCheckedItems);
      setDefaultCheckedItems([]);
      return;
    }
    setCheckItems((currItems: any) =>
      currItems.map((crrItem: any) => {
        const updatedIndex = _findIndex(items, (item: any) => item.id === crrItem.id);
        if (updatedIndex >= 0) {
          return items[updatedIndex];
        }

        return crrItem;
      }),
    );
  }, [items, defaultCheckedItems]);

  const isCheckedAll = useMemo(() => items?.length === checkedItems?.length, [items, checkedItems]);

  const handleDefaultCheckedItems = useCallback(
    (items: any) => {
      setDefaultCheckedItems(items);
    },
    // eslint-disable-next-line
    [defaultCheckedItems],
  );

  const handleToggleCheckAll = useCallback(() => {
    if (!isCheckedAll) {
      setCheckItems(items);
    } else {
      setCheckItems([]);
    }
    // eslint-disable-next-line
  }, [isCheckedAll, checkedItems, items]);

  const handleToggleCheck = useCallback(
    (targetItem: any, checked: boolean) => {
      if (checked) {
        setCheckItems([...checkedItems, targetItem]);
      } else {
        const newCheckedItems = checkedItems?.filter((item: any) => item.id !== targetItem.id);
        setCheckItems(newCheckedItems);
      }
    },
    [checkedItems],
  );

  const handleClearCheck = useCallback(() => {
    setCheckItems([]);
  }, []);

  const checkIsChecked = useCallback(
    (item: any) => _findIndex(checkedItems, (checkedItem: any) => checkedItem[key] === item[key]) !== -1,
    [checkedItems, key],
  );

  return {
    checkedItems,
    setCheckItems,
    checkIsChecked,
    handleToggleCheck,
    handleClearCheck,
    isCheckedAll,
    handleToggleCheckAll,
    handleDefaultCheckedItems,
  };
};

export default useCheck;
