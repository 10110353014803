import { uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { HiChevronLeft, HiChevronRight, HiPlus } from 'react-icons/hi';
import { connect } from 'react-redux';

import styles from './Assignments.module.scss';
import TableAssignments from './TableAssignments';

import { fetchGroups } from 'actions/GroupActions';
import { contactsApi } from 'common/api/contacts-api';
import Btn from 'components/Btn';
import CreateAssignmentModal from 'components/CreateAssignmentModal';
import DropdownSelect from 'components/DropdownSelect';
import InputField from 'components/InputField';
import { useToggle } from 'hooks';
import { AssignmentType, AssignmentsStudentType, GroupType, OptionsType, TaskType } from 'types';

type DateRange = {
  from: moment.Moment;
  to: moment.Moment;
};

type AssignmentsProps = {
  groups: GroupType[];
  fetchGroups: () => void;
  allApis: any;
};

export type TAssignmentContext = {
  students: AssignmentsStudentType[];
};

export const AssignmentContext = createContext<TAssignmentContext>({
  students: [],
});

export const useAssignment = () => {
  const context = useContext(AssignmentContext);
  return context;
};

const Assignments = (props: AssignmentsProps) => {
  const {
    groups,
    fetchGroups,
    allApis: { getJson },
  } = props;
  const [dateRange, setDateRange] = useState<DateRange>({
    from: moment().startOf('month'),
    to: moment().endOf('month'),
  });
  const [search, setSearch] = useState('');
  const [filterGroup, setFilterGroup] = useState<string | number>('');
  const [isLoading, toggleIsLoading] = useToggle();
  const [respAssignments, setRespAssignments] = useState<AssignmentsStudentType[]>([]);
  const [isShowAssignmentModal, toggleIsShowAssignmentModal] = useToggle();
  const [assignmentEdit, setAssignmentEdit] = useState<TaskType>();

  const getData = useCallback(
    async (date_range: DateRange, filter_group: string | number) => {
      toggleIsLoading();
      const resp = await contactsApi.getList(getJson, {
        ...(filter_group ? { groups: filter_group } : {}),
        embed: 'task,assignments,music,student',
        assignments_date_min: date_range.from.format(),
        assignments_date_max: date_range.to.format(),
      });
      setRespAssignments(resp.results);
      toggleIsLoading();
    },
    [getJson, toggleIsLoading],
  );

  const getAssignments = (data: AssignmentsStudentType[]) => {
    if (!data) return [];
    if (data) {
      let ret = data.reduce(
        (s: AssignmentType[], item: AssignmentsStudentType) => (item.assignments ? [...s, ...item.assignments] : s),
        [],
      );

      let assignments = uniqBy(ret, (t: any) => t.id);

      return assignments.sort(
        (t1: AssignmentType, t2: AssignmentType) =>
          new Date(t1.task.created).getTime() - new Date(t2.task.created).getTime(),
      );
    }
  };

  useEffect(() => {
    getData(dateRange, filterGroup);
    fetchGroups();
  }, [dateRange, filterGroup, fetchGroups, getData]);

  const onSelect = useCallback(
    (option: OptionsType) => {
      getData(dateRange, option.value);
      setFilterGroup(option.value);
    },
    [dateRange, getData],
  );

  const optionsGroup = useMemo(
    () => [
      {
        label: 'All groups',
        value: '',
        action: () => {
          onSelect({
            label: 'All groups',
            value: '',
          });
        },
      },
      ...groups?.map(group => ({
        id: group.id,
        label: group.name,
        value: `${group.id}`,
        action: () => {
          onSelect({
            label: group.name,
            value: `${group.id}`,
          });
        },
      })),
    ],
    [groups, onSelect],
  );

  const previous = () => {
    const fromValue = dateRange?.from.clone();
    const toValue = dateRange?.to.clone();

    const newFromValue = fromValue.subtract(1, 'months');
    const newToValue = toValue.subtract(1, 'months').endOf('month');
    const newDateRange = {
      from: newFromValue,
      to: newToValue,
    };
    setDateRange(newDateRange);
    getData(newDateRange, filterGroup);
  };

  const next = () => {
    const fromValue = dateRange?.from.clone();
    const toValue = dateRange?.to.clone();

    const newFromValue = fromValue.add(1, 'months');
    const newToValue = toValue.add(1, 'months').endOf('month');

    if (moment().isAfter(newFromValue)) {
      const newDateRange = {
        from: newFromValue,
        to: newToValue,
      };
      setDateRange(newDateRange);
      getData(newDateRange, filterGroup);
    }
  };

  const handleOpenModal = (assignment: TaskType) => {
    setAssignmentEdit(assignment);
    toggleIsShowAssignmentModal();
  };

  const handleSubmitModal = () => {
    getData(dateRange, filterGroup);
  };

  const handleCloseModal = () => {
    setAssignmentEdit(undefined);
    toggleIsShowAssignmentModal();
  };

  return (
    <AssignmentContext.Provider value={{ students: respAssignments }}>
      <div className={styles['assignments-wrapper']}>
        <div className={styles['header']}>
          <div className={styles['left-wrapper']}>
            <InputField
              className={styles['search-input']}
              label="Search"
              size="small"
              inputProps={{ value: search, onChange: e => setSearch(e.target.value) }}
            />
          </div>
          <div className={styles['right-wrapper']}>
            <div className={styles['date-wrapper']}>
              <div className={styles['date-icon']}>
                <HiChevronLeft size={26} className={styles['date-icon-left']} onClick={previous} />
              </div>
              <div className={styles['date-label']}>{dateRange.from?.format('MMMM YYYY')}</div>
              <div className={styles['date-icon']}>
                <HiChevronRight size={26} className={styles['date-icon-right']} onClick={next} />
              </div>
            </div>
            <Btn variant="primary" btnClassName={styles['new-assignment-btn']} onClick={toggleIsShowAssignmentModal}>
              <HiPlus className={styles['plus-btn-icon']} size={18} /> New assignment
            </Btn>
            <DropdownSelect
              label={optionsGroup.find(option => option.value === filterGroup)?.label}
              options={optionsGroup}
              className={styles['group-dropdown']}
            />
          </div>
        </div>
        {isLoading ? (
          <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
        ) : (
          <TableAssignments
            onViewAssignmentDetail={handleOpenModal}
            students={respAssignments}
            assignments={getAssignments(respAssignments) as AssignmentType[]}
          />
        )}

        <CreateAssignmentModal
          isShowModal={isShowAssignmentModal}
          handleCloseModal={handleCloseModal}
          assignmentEdit={assignmentEdit}
          handleSubmitModal={handleSubmitModal}
        />
      </div>
    </AssignmentContext.Provider>
  );
};

const mapStateToProps = (state: any) => ({
  groups: Object.values(state.group.items) as GroupType[],
  allApis: state.Api.allApis,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchGroups: () => dispatch(fetchGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assignments);
