import React from 'react';

import Body from './Body';
import styles from './FeedCard.module.scss';
import Footer from './Footer';
import Header from './Header';

import { FeedType, User } from 'types';

type FeedCardProps = {
  allApis: any; // TODO: add type
  data: FeedType;
  user?: User;
  keyTab: string;
};

const FeedCard = (props: FeedCardProps) => {
  const { data, user, keyTab } = props;
  return (
    <div className={styles['wrapper-feed']}>
      <Header data={data} />
      <Body data={data} />
      <Footer data={data} user={user} keyTab={keyTab} />
    </div>
  );
};

export default FeedCard;
