// https://tailwindui.com/components/application-ui/forms/select-menus

import { Listbox, ListboxButton, ListboxOption, ListboxOptions, ListboxProps } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/16/solid';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ForwardedRef, forwardRef } from 'react';

import { setClass } from '../actions/UserActions';
import { useAppDispatch, useAppSelector } from '../hooks';

import Avatar from './Avatar';

export default forwardRef((props: ListboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  const user = useAppSelector(state => state.user);
  const classes = useAppSelector(state => state.classes);
  const dispatch = useAppDispatch();

  if (!classes.results) {
    return null;
  }

  const value = classes.active || classes.results[0];

  const onChange = (v: any) => {
    dispatch(setClass(v));
  };

  return (
    <Listbox ref={ref} value={value} onChange={onChange}>
      <div className="relative mt-2">
        <ListboxButton className="shadow-sm grid w-full cursor-pointer grid-cols-1 rounded-xl bg-white py-1.5 pr-2 pl-3 text-left text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
          <span className="col-start-1 row-start-1 flex items-center gap-3 pr-6">
            <Avatar size={32} letter={value.name.substring(0, 1)} url={!value.id && user.picture} />
            <span className="leading-normal">
              <span className="block truncate text-[.75rem] font-semibold">{value.name}</span>
              <span className="block truncate text-[.625rem] text-gray-500">{value.owner.name}</span>
            </span>
          </span>
          <ChevronUpDownIcon
            aria-hidden="true"
            className="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
          />
        </ListboxButton>
        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-sm ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {classes.results.map((klass: any) => (
            <ListboxOption
              key={klass.url}
              value={klass}
              className="group relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-grey data-[focus]:outline-none"
            >
              <div className="flex items-center">
                <Avatar size={32} letter={klass.name.substring(0, 1)} url={!klass.id && user.picture} />
                <span className="ml-3 leading-normal truncate">
                  <span className="block truncate font-semibold">{klass.name}</span>
                  <span className="block truncate text-[.625rem] text-gray-500">{klass.owner.name}</span>
                </span>
              </div>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 group-[&:not([data-selected])]:hidden">
                <CheckIcon aria-hidden="true" className="size-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
});
