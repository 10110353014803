import { HTMLProps, MouseEvent, ReactNode, forwardRef } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { ImNotification } from 'react-icons/im';
import { MdOutlineClear } from 'react-icons/md';

import styles from './DropdownSelect.module.scss';

type CustomToggleProps = HTMLProps<HTMLDivElement> & {
  label?: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  customToggleComponent?: ReactNode;
  icon?: ReactNode; // TODO: add type
  classNameToggle?: string;
  value?: string;
  isClearable?: boolean;
  onClear?: Function;
  error?: string;
};

const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  (
    // eslint-disable-next-line
    { onClick, label, customToggleComponent, icon, classNameToggle, value, isClearable, onClear, error, disabled },
    ref,
  ) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {customToggleComponent ? (
        customToggleComponent
      ) : (
        <div className={`${styles['dropdown-toggle']} ${classNameToggle} ${error && styles['dropdown-toggle__error']}`}>
          <>
            <span>
              {icon || ''}
              {label}
            </span>
            {!error ? (
              <>
                {value && isClearable ? (
                  <MdOutlineClear
                    size={14}
                    onClick={() => {
                      if (onClear) onClear();
                    }}
                    className={styles['icon']}
                  />
                ) : (
                  <HiOutlineChevronDown className={styles['icon']} />
                )}
              </>
            ) : (
              <ImNotification className={styles['icon-error']} />
            )}
          </>
        </div>
      )}
    </div>
  ),
);

type DropdownSelectProps = {
  id?: string | number;
  label?: string;
  className?: string;
  classNameToggle?: string;
  value?: string;
  options?: Array<{
    value?: string;
    action?: Function;
    label?: string;
    icon?: ReactNode; // TODO: add type
  }>;
  customToggleComponent?: ReactNode;
  icon?: ReactNode;
  error?: string;
  isClearable?: boolean;
  onClear?: Function;
  onChange?: Function;
  disabled?: boolean;
  required?: boolean;
};

const DropdownSelect = (props: DropdownSelectProps) => {
  const {
    id,
    label,
    className,
    customToggleComponent,
    options,
    icon,
    value,
    classNameToggle,
    isClearable,
    onClear,
    error,
    onChange,
    disabled,
    required,
  } = props;
  let isRequired = required === null ? true : required;
  return (
    <>
      <Dropdown aria-required={isRequired} className={className}>
        <Dropdown.Toggle
          as={CustomToggle}
          icon={icon}
          label={label}
          customToggleComponent={customToggleComponent}
          classNameToggle={classNameToggle}
          value={value}
          isClearable={isClearable}
          onClear={onClear}
          error={error}
          disabled={disabled}
          required={isRequired}
        ></Dropdown.Toggle>
        <Dropdown.Menu className={styles['dropdown-menu']}>
          {options?.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => (item?.action ? item?.action(id) : onChange && onChange(item.value))}
              className={styles['dropdown-item']}
              active={!!item?.value && value === item?.value}
            >
              {item?.icon}
              {item?.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Form.Control.Feedback type="invalid" className={error && styles['block']}>
        {error}
      </Form.Control.Feedback>
    </>
  );
};

export default DropdownSelect;
