import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import EditStudentModal from '../../pages/Students/EditStudentModal';
import { config } from '../../utils/config';

import Avatar from 'components/Avatar';

const StudentsTab = ({ org, dispatch }) => {
  const { allApis } = useSelector(({ Api }) => Api);
  const [showModal, setShowModal] = useState(false);
  const [student, setStudent] = useState();

  useEffect(() => {
    if (!showModal) {
      const url = `/studio/organizations/${org.id}/memberships`;
      allApis.getJson(url, { limit: 1000, role: 'student', embed: 'user' }).then(({ results: students }) =>
        dispatch({
          type: 'update',
          org: { id: org.id, students },
        }),
      );
    }
  }, [showModal, allApis, dispatch, org.id]);

  const onClickEdit = membership => {
    setStudent(membership);
    setShowModal(true);
  };

  const handleDelete = obj => {
    const name = obj.user?.full_name || obj.name;
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(`Are you sure you want to remove ${name}?`);

    if (confirm) {
      const url = obj.url.replace(config.api_endpoint, '');
      allApis.delJson(url).then(() => {
        dispatch({
          type: 'update',
          org: {
            id: org.id,
            students: org.students.filter(i => obj.url !== i.url),
          },
        });
      });
    }
  };

  if (!org.students) {
    return <div className="spinner-border big mt50 mx-auto d-block" />;
  }

  return (
    <div>
      {org.students?.length === 0 && <p className="text-center py-5">There are no students in the organization yet.</p>}

      {org.students?.length > 0 && (
        <ul>
          {org.students.map(membership => (
            <li key={membership.url} className="d-flex justify-content-between align-items-center border-bottom py-3">
              <span className="d-flex align-items-center" style={{ gap: '16px', flexBasis: '250px' }}>
                <Avatar size={40} letter={membership.name.substring(0, 1) || ''} url={membership.user?.picture} />
                <span style={{ fontSize: '14px', fontWeight: 500 }}>
                  {membership.user?.full_name || membership.name}
                </span>
              </span>
              <span style={{ flexBasis: '250px' }}>{membership.email}</span>
              <span className="text-muted">{membership.status !== 'accepted' && membership.status}</span>
              <Dropdown>
                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                  <span className="material-icons-outlined">more_horiz</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => onClickEdit(membership)}>
                    <span className="material-icons-outlined">edit</span>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDelete(membership)}>
                    <span className="material-icons-outlined">delete_forever</span>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ))}
        </ul>
      )}

      <EditStudentModal isShowModal={showModal} handleCloseModal={() => setShowModal(false)} contact={student} />
    </div>
  );
};

StudentsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  org: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StudentsTab;
