import { TReduxPayload, TStudentPayload } from 'types';
export const DELETE_STUDENT = 'STUDENT/DELETE_STUDENT';

export const FETCH_STUDENTS = 'STUDENT/FETCH_STUDENTS';
export const FETCH_STUDENTS_SUCCESS = 'STUDENT/FETCH_STUDENTS_SUCCESS';
export const FETCH_STUDENTS_FAIL = 'STUDENT/FETCH_STUDENTS_FAIL';

export const FETCH_STUDENT = 'STUDENT/FETCH_STUDENT';
export const FETCH_STUDENT_SUCCESS = 'STUDENT/FETCH_STUDENT_SUCCESS';
export const FETCH_STUDENT_FAIL = 'STUDENT/FETCH_STUDENT_FAIL';

export const CREATE_STUDENT = 'STUDENT/CREATE_STUDENT';
export const CREATE_STUDENT_SUCCESS = 'STUDENT/CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAIL = 'STUDENT/CREATE_STUDENT_FAIL';

export const SEND_INVITE = 'STUDENT/SEND_INVITE';

export const UPDATE_STUDENT = 'STUDENT/UPDATE_STUDENT';
export const UPDATE_STUDENT_SUCCESS = 'STUDENT/UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAIL = 'STUDENT/UPDATE_STUDENT_FAIL';

export const FILTER_STUDENTS = 'STUDENT/FILTER_STUDENTS';

export const fetchStudents = (params: TReduxPayload = {}) => ({
  type: FETCH_STUDENTS,
  payload: {
    request: {
      method: 'GET',
      url: '/contacts/',
      params: {
        ordering: 'name',
        embed: 'student',
        limit: 1000,
        ...params,
      },
    },
  },
});

export const fetchStudent = (id: string) => ({
  types: [FETCH_STUDENT, FETCH_STUDENT_SUCCESS, FETCH_STUDENT_FAIL],
  payload: {
    request: {
      method: 'GET',
      url: `/contacts/${id}/`,
    },
  },
});

export const createStudent = (data: TReduxPayload) => ({
  type: CREATE_STUDENT,
  payload: {
    request: {
      method: 'POST',
      url: '/contacts/',
      data,
    },
  },
});

export const sendInvite = (studentID: string | number) => ({
  type: SEND_INVITE,
  payload: {
    request: {
      method: 'POST',
      url: `/contacts/${studentID}/invite/`,
    },
  },
});

export const updateStudent = (id: string | number | undefined, data: TStudentPayload) => ({
  types: [UPDATE_STUDENT, UPDATE_STUDENT_SUCCESS, UPDATE_STUDENT_FAIL],
  payload: {
    request: {
      method: 'PATCH',
      url: `/contacts/${id}/`,
      data,
    },
  },
});

export const deleteStudent = (id: string | number) => ({
  type: DELETE_STUDENT,
  payload: {
    request: {
      method: 'DELETE',
      url: `/contacts/${id}/`,
      data: { student: { id: id } },
    },
  },
});

export const filterStudents = (searchQuery: TReduxPayload) => ({
  type: FILTER_STUDENTS,
  searchQuery,
});
