import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

type BarChartProps = {
  className: string;
  labels: string[];
  dataset: any[];
  prefix?: string;
  suffix?: string;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = (props: BarChartProps) => {
  const [maxValue, setMaxValue] = useState<number>(0);
  const { className, labels, dataset, prefix = '', suffix = '' } = props;

  useEffect(() => {
    if (dataset) {
      let max_val = Math.max(...dataset);
      const length_number = `${max_val}`.length;

      if (length_number) {
        let divisor = '1';
        for (let i = 0; i < length_number - 1; i++) {
          divisor += '0';
        }
        max_val = Math.ceil(+max_val / +divisor) * +divisor;
      }

      if (max_val) setMaxValue(+max_val);
    }
  }, [dataset]);

  const data = {
    labels,
    datasets: [
      {
        data: dataset,
        backgroundColor: '#2264F2',
        borderRadius: 100,
        maxBarThickness: 14,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        // ticks: {
        //   //Change color label
        //   color: (c: any) => {
        //     return c.index == 6 ? '#2264F2' : '#7B8698';
        //   },
        // },
      },
      y: {
        ticks: {
          callback: (value: number | string) => prefix + value + suffix,
          stepSize: maxValue / 10,
          color: '#7B8698',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Bar data={data} options={options} className={className} />;
};

export default BarChart;
