import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router';

import { useAPI } from '../../../../hooks';

import styles from './OverviewComponent.module.scss';

import DropdownSelect from 'components/DropdownSelect';
import PageSection from 'components/PageSection';
import { Contact } from 'types';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const options = [
  {
    label: 'Last 7 days',
    value: 7,
  },
  {
    label: 'Last 30 days',
    value: 30,
  },
];

export default ({ contact }: { contact?: Contact }) => {
  const { id } = useParams();
  const [period, setPeriod] = useState<number>(options[0].value);
  const { data, isLoading } = useAPI(
    '/practices/timechart/',
    {
      student_id: id,
      created_min: dayjs().subtract(30, 'day').startOf('d').toISOString(),
      created_max: dayjs().endOf('d').toISOString(),
    },
    {
      enabled: !!contact?.student,
      placeholderData: [],
      select: (data: Record<string, any>) => {
        if (!data.datasets) return [];
        const o = Object.values(data?.datasets)[0] as Record<string, any>;
        return o.values as number[];
      },
    },
  );

  const labels = (() => {
    const format = period < 30 ? 'ddd' : 'MMM D';
    return data.slice(-period).map((v: number, i: number, a: number[]) =>
      dayjs()
        .add(i - a.length + 1, 'day')
        .format(format),
    );
  })();

  const total = (() => {
    const sum = data.slice(-period).reduce((a: number, b: number) => a + b, 0);
    return (sum && dayjs.duration(sum * 1000).humanize()) || 'absent';
  })();

  const barProps = {
    data: {
      labels,
      datasets: [
        {
          backgroundColor: '#2264F2',
          borderRadius: 3,
          data: data.slice(-period).map((v: number) => Math.ceil(v / 60)),
          minBarLength: 1,
        },
      ],
    },
    options: {
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          max: 180,
          ticks: {
            callback: (value: string | number) => `${Number(value) / 60}h`,
            stepSize: 30,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  return (
    <PageSection className="mb-4" wrapperClassName={styles['overview-wrapper']}>
      {isLoading ? (
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      ) : (
        <>
          <div className={styles['top-area']}>
            <div className={styles['left-item']}>
              <div className={styles['item-item']}>
                <div className={styles['item-title']}>Practice time</div>
                <div className={styles['item-value']}>{total}</div>
              </div>
            </div>
            <div>
              <DropdownSelect
                label={options.find(o => o.value == period)?.label}
                options={options.map(o => ({ ...o, value: String(o.value) }))}
                onChange={setPeriod}
              />
            </div>
          </div>
          <div className={styles['wrapper-chart']}>
            <Bar {...barProps} />
          </div>
        </>
      )}
    </PageSection>
  );
};
