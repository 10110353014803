import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { HiPlus } from 'react-icons/hi';
import { connect } from 'react-redux';

import styles from './AssignmentsComponent.module.scss';

import { useGetAssignmentByStudent } from 'common/api/apis';
import Btn from 'components/Btn';
import CreateAssignmentModal from 'components/CreateAssignmentModal';
import PageSection from 'components/PageSection';
import { Assignment, AssignmentsContext, AssignmentsDispatchContext } from 'features/assignments';
import { Contact } from 'types';

type AssignmentComponentProps = {
  contact?: Contact;
};

const AssignmentComponent = (props: AssignmentComponentProps) => {
  const [isShowCreateAssignmentModal, setIsShowCreateAssignmentModal] = useState<boolean>(false);
  const { contact } = props;
  const { status, items: assignments } = useContext(AssignmentsContext);
  const dispatch = useContext(AssignmentsDispatchContext);
  const [getAssignmentByStudent, {}] = useGetAssignmentByStudent({
    contact: contact?.id,
    query_params: {},
  });

  useEffect(() => {
    if (contact?.id && status === 'pending') {
      getAssignmentByStudent({
        contact: contact.id,
        query_params: {
          embed: 'task',
          ordering: '-task__created',
          offset: '0',
          limit: '10',
        },
      }).then((resp: any) => {
        dispatch({ type: 'set', payload: { status: 'fulfilled', items: resp.results } });
      });
    }
  }, [status, contact?.id, dispatch, getAssignmentByStudent]);

  const renderProgress = (item: Assignment) => {
    const progress_value = item.progress ? Number(item.progress.slice(0, -1)) : 0;
    const progress_level = (value: number) => {
      if (value <= 20) return 'low';
      if (value <= 40) return 'medium';
      return 'high';
    };
    return <div className={styles[`time-${progress_level(progress_value)}`]}>{progress_value}%</div>;
  };

  return (
    <PageSection title="Assignments" className="mb-4" wrapperClassName={styles['assignment-wrapper']}>
      {status !== 'fulfilled' ? (
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      ) : (
        <>
          <div className={styles['table-wrapper']}>
            <table>
              <thead>
                <tr>
                  <th className={styles['header-1']}>Name</th>
                  <th className={styles['header-2']}>Due Date</th>
                  <th className={styles['header-3']}>Status</th>
                </tr>
              </thead>
              <tbody>
                {assignments.length === 0 ? (
                  <tr>
                    <td colSpan={4}>
                      <div className={styles['no-assignment']}>No assignment yet.</div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {assignments.map((item: Assignment, id: number) => (
                      <tr key={id}>
                        <td>
                          <div className={styles['colume-1']}>{item.task.name}</div>
                        </td>
                        <td>
                          {item.task.due_date ? (
                            <div className={styles['colume-2']}>{moment(item.task.due_date).format('ll')}</div>
                          ) : (
                            <div className={styles['colume-2']}>-</div>
                          )}
                        </td>
                        <td>
                          <div className={styles['colume-3']}>{renderProgress(item)}</div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                <tr>
                  <td>
                    <Btn
                      variant="primary"
                      btnClassName={styles['new-assignment-btn']}
                      onClick={() => setIsShowCreateAssignmentModal(true)}
                    >
                      <HiPlus size={20} className={styles['plus-icon']} /> New assignment
                    </Btn>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles['table-wrapper-mb']}>
            {assignments.length === 0 ? (
              <div className={styles['no-assignment']}>No assignment yet.</div>
            ) : (
              <>
                {assignments.map((item: Assignment, id: number) => (
                  <div key={id} className={styles['practice-item']}>
                    <div className={styles['practice-item-header']}>
                      <div className={styles['header-title']}>{item.task.name}</div>
                    </div>
                    <div className={styles['wrapper-field']}>
                      <p className={styles['field__label']}>Due Date</p>
                      <p className={styles['field__value-date']}>
                        {item.task.due_date ? moment(item.task.due_date).format('ll') : '-'}
                      </p>
                    </div>
                    <div className={styles['wrapper-field']}>
                      <p className={styles['field__label']}>Status</p>
                      <div className={styles['field__value-time']}>{renderProgress(item)}</div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
      <CreateAssignmentModal
        isShowModal={isShowCreateAssignmentModal}
        handleCloseModal={() => setIsShowCreateAssignmentModal(false)}
        contact={contact}
        handleSubmitModal={() => dispatch({ type: 'reset' })}
      />
    </PageSection>
  );
};

/* eslint-disable */
const mapStateToProps = (state: any) => ({});
/* eslint-enable */

export default connect(mapStateToProps)(AssignmentComponent);
