import { yupResolver } from '@hookform/resolvers/yup';
import { default as cs } from 'classnames';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import styles from './EditStudentModal.module.scss';

import { fetchStudents, updateStudent } from 'actions/StudentActions';
import Btn from 'components/Btn';
import CustomModal from 'components/CustomModal';
import InputField from 'components/InputField';
import NOTIFICATIONS from 'data/notifications/student';
import { ReactComponent as AddUserIcon } from 'theme/svg/add-user.svg';
import { Contact } from 'types';

type EditStudentModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  contact?: Contact;
  dispatch: any;
};

const EditStudentModal = (props: EditStudentModalProps) => {
  const { isShowModal, handleCloseModal, contact, dispatch } = props;
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);
  const schema = yup.object().shape({
    first_name: yup.string().required('Fisrt name is required'),
    last_name: yup.string(),
    email: yup.string().email('Email is invalid').required('Email address is required'),
    cost_per_lesson: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .moreThan(-0.01, 'Must be a positive number')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    parent_phone: yup.string(),
    street: yup.string(),
    parent_name_1: yup.string(),
    parent_email: yup.string().email('Email is invalid'),
  });

  const formMethods = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  type TStudentValues = yup.InferType<typeof schema>;

  const onSubmit = async (values?: TStudentValues) => {
    const payload = {
      ...values,
      name: [values?.first_name, values?.last_name].join(' ').trim(),
    };
    try {
      await dispatch(updateStudent(contact?.id, payload));
      await dispatch(fetchStudents());
      handleCloseModal();
      reset();
      toast.success(NOTIFICATIONS.update.success);
    } catch (error) {
      toast.error(NOTIFICATIONS.update.error);
    }
  };

  const handleArchiveStudent = async () => {
    setIsLoadingArchive(true);
    try {
      const status = 'archived';
      await dispatch(updateStudent(contact?.id, { status }));
      await dispatch(fetchStudents());
      handleCloseModal();
      reset();
      toast.success(NOTIFICATIONS.archive.success);
    } catch (error) {
      toast.error(NOTIFICATIONS.archive.error);
    }
    setIsLoadingArchive(false);
  };

  useEffect(() => {
    if (!isShowModal) {
      reset();
    }
  }, [isShowModal, reset]);

  useEffect(() => {
    if (contact) {
      setValue('first_name', contact?.name?.split(' ')?.[0] || '');
      setValue('last_name', contact?.name?.split(' ')?.[1] || '');
      setValue('email', contact?.email || '');
      setValue('cost_per_lesson', Number(contact?.cost_per_lesson));
      setValue('parent_phone', contact?.parent_phone);
      setValue('street', contact?.street);
      setValue('parent_name_1', contact?.parent_name_1);
      setValue('parent_email', contact?.parent_email);
    }
  }, [contact, setValue]);

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={handleCloseModal}
      title="Edit student"
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
      className={cs(styles['add-student-modal'], styles['custom-modal-mb'])}
      customleftFooterComponent={
        <Btn variant="outline-secondary" onClick={() => handleArchiveStudent()} isLoading={isLoadingArchive}>
          <AddUserIcon className={styles['add-student-btn-icon']} /> Archive student
        </Btn>
      }
      contentClassName={styles['content-modal-mb']}
    >
      <div className={styles['add-student-modal-body']}>
        <div className={styles['required-section']}>
          <Row>
            <Col xs={6}>
              <InputField
                label="First name"
                inputProps={{ ...register('first_name') }}
                error={errors.first_name?.message}
              />
            </Col>
            <Col xs={6}>
              <InputField
                label="Last name"
                inputProps={{ ...register('last_name') }}
                error={errors.last_name?.message}
              />
            </Col>
            <Col xs={12}>
              <InputField
                label="email@website.com"
                inputProps={{ ...register('email') }}
                className="mb-0"
                error={errors.email?.message}
              />
            </Col>
          </Row>
        </div>

        <div className={styles['optional-section']}>
          <div className={styles['section-header']}>Optional Information</div>
          <Row>
            <Col xs={6}>
              <InputField
                label="Lesson rate $"
                inputProps={{ ...register('cost_per_lesson') }}
                error={errors.cost_per_lesson?.message}
              />
            </Col>
            <Col xs={6}>
              <InputField
                label="Phone number"
                inputProps={{ ...register('parent_phone') }}
                error={errors.parent_phone?.message}
              />
            </Col>
            <Col xs={12}>
              <InputField label="Address" inputProps={{ ...register('street') }} error={errors.street?.message} />
            </Col>
            <Col xs={6}>
              <InputField
                label="Parent name"
                inputProps={{ ...register('parent_name_1') }}
                error={errors.parent_name_1?.message}
              />
            </Col>
            <Col xs={6}>
              <InputField
                label="Email"
                inputProps={{ ...register('parent_email') }}
                error={errors.parent_email?.message}
              />
            </Col>
          </Row>
        </div>
      </div>
    </CustomModal>
  );
};

export default connect(null)(EditStudentModal);
