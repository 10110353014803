import { default as cs } from 'classnames';
import { BiChevronRight } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './UserGuide.module.scss';

import { hideUserGuide, modifySteps } from 'actions/UserGuideActions';
import Btn from 'components/Btn';
import partyIcon from 'theme/img/party.png';
import { UserGuideState } from 'types';

type UserGuideProps = {
  userGuide: UserGuideState;
  dispatch: any;
};

const toDoList = [
  {
    key: '1',
    label: 'Add students to class',
    link: '/students',
    external: false,
  },
  {
    key: '2',
    label: 'Add yourself to test in-app experience',
    link: 'https://musopen.org/apps/practice/',
    external: true,
  },
  {
    key: '3',
    label: 'Create test assignment',
    subLabel: '(for you and/or students)',
    link: '/assignments',
    external: false,
  },
  {
    key: '4',
    label: 'Complete bio info to make finding you easier',
    link: 'dashboard/settings',
    external: false,
  },
  {
    key: '5',
    label: 'Join our Facebook group to ask questions, share feedback on the app',
    link: 'https://www.facebook.com/musopenorg',
    external: true,
  },
];

const UserGuide = (props: UserGuideProps) => {
  const { userGuide, dispatch } = props;

  const history = useNavigate();

  const redirectPage = (key: string, link: string, external: boolean) => {
    if (external) window.open(link, '_blank');
    else history(link);
  };

  const handleHideUserGuide = () => {
    dispatch(hideUserGuide());
  };

  const getPercentComplete = () => {
    return (userGuide?.completedSteps?.length / 5) * 100;
  };

  const handleSetStep = (checked: boolean, stepId: string) => {
    let steps: string[] = userGuide?.completedSteps || [];

    if (checked) {
      steps.push(stepId);
    } else {
      steps = steps.filter(i => i !== stepId);
    }
    dispatch(modifySteps(steps));
  };

  return (
    <>
      {!userGuide?.isHidden && (
        <>
          {userGuide?.completedSteps?.length === 5 ? (
            <div className={styles['user-guide-wrapper']}>
              <div className={styles['user-guide-complete']}>
                <img src={partyIcon} alt="" />
                <div className={styles['user-guide-complete-heading']}>Congrats!</div>
                <div className={styles['user-guide-complete-description']}>
                  You should be all set to get started. If you need help please write us or join our{' '}
                  <a href="https://www.facebook.com/musopenorg" target="_blank" rel="noreferrer">
                    FB group
                  </a>{' '}
                  for help.
                </div>
              </div>
              <div className={styles['footer']}>
                <Btn variant="primary" onClick={handleHideUserGuide}>
                  Complete
                </Btn>
              </div>
            </div>
          ) : (
            <div className={styles['user-guide-wrapper']}>
              <div className={styles['header']}>Your profile is {getPercentComplete()}% complete</div>
              <div className={styles['progress-wrapper']}>
                {toDoList.map((_, index) => (
                  <div
                    key={index}
                    className={cs(
                      styles['progress'],
                      userGuide?.completedSteps?.length >= index + 1
                        ? styles['progress--actived']
                        : styles['progress--inactive'],
                    )}
                  ></div>
                ))}
              </div>
              <div className={styles['to-do-list-wrapper']}>
                {toDoList.map(todo => (
                  <div key={todo.key} className={styles['to-do']}>
                    <div
                      className={cs(
                        styles['to-do-radio'],
                        userGuide?.completedSteps?.includes(todo.key)
                          ? styles['to-do-radio--actived']
                          : styles['to-do-radio--inactive'],
                      )}
                      onClick={() => handleSetStep(!userGuide?.completedSteps?.includes(todo.key), todo.key)}
                    >
                      {userGuide?.completedSteps?.includes(todo.key) && <BsCheck size={16} />}
                    </div>
                    <div
                      className={styles['to-do-content']}
                      onClick={() => redirectPage(todo.key, todo.link, todo.external)}
                    >
                      <p
                        className={cs(
                          userGuide?.completedSteps?.includes(todo.key) ? styles['label--actived'] : styles['label'],
                        )}
                      >
                        {todo.label}
                      </p>
                      <p className={styles['sub-label']}>{todo.subLabel}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles['footer']}>
                <Btn variant="outline-primary" btnClassName={styles['btn-skip']} onClick={handleHideUserGuide}>
                  Skip this <BiChevronRight className={styles['skip-btn-icon']} size={20} />
                </Btn>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userGuide: state.userGuide as UserGuideState,
});

export default connect(mapStateToProps)(UserGuide);
