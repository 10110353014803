import { useState } from 'react';
import { useSelector } from 'react-redux';

type UseApiPropType = {
  method?: string;
  endpoint?: string;
  params?: string[][] | Record<string, string>;
  body?: any;
};

type ReduxStoreState = {
  Api: {
    allApis: {
      getJson: Function;
      postJson: Function;
      delJson: Function;
      patchJson: Function;
      putJson: Function;
    };
  };
};

export type UseApiResultType = {
  data: any | null;
  error: object | Error | unknown | null;
  isPending: boolean | null;
};

export type UseApiReturnType = [Function, UseApiResultType];

// Make api request from redux method
export const useApi = (props: UseApiPropType): UseApiReturnType => {
  const allApis = useSelector((state: ReduxStoreState) => state.Api.allApis);
  const { getJson, postJson, delJson, patchJson, putJson } = allApis;
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<object | Error | unknown | null>(null);
  const [isPending, setIsPending] = useState<boolean | null>(null);

  const process_request = (passedHookConfig: UseApiPropType) => {
    let { method, endpoint, params, body } = passedHookConfig;
    let caseMethod = method == null ? 'get' : method;
    let requestMethod = getJson;
    switch (caseMethod) {
      case 'get':
        requestMethod = getJson;
        break;
      case 'post':
        requestMethod = postJson;
        break;
      case 'put':
        requestMethod = putJson;
        break;
      case 'patch':
        requestMethod = patchJson;
        break;
      case 'del':
        requestMethod = delJson;
        break;
      default:
        requestMethod = getJson;
    }
    let queryParams = params != null ? new URLSearchParams(params).toString() : null;
    let url = queryParams == null ? endpoint : `${endpoint}?${queryParams}`;
    let req = body == null ? requestMethod(url) : requestMethod(url, body);
    return req;
  };

  const makeRequest = async (passedRequestConfig: UseApiPropType = {}) => {
    setIsPending(true);
    setError(null);
    let config = { ...props, ...passedRequestConfig };
    let req = process_request(config);

    return req
      .then((data: object) => {
        setData(data);
        return data;
      })
      .catch((error: object) => {
        setError(error);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return [
    makeRequest,
    {
      data,
      error,
      isPending,
    },
  ];
};
