import { yupResolver } from '@hookform/resolvers/yup';
import { default as cs } from 'classnames';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { GTAG_ADD_MULTIPLE_STUDENTS, GTAG_ADD_STUDENT } from '../../../constants/gtag';

import styles from './AddStudentModal.module.scss';

import { createStudent, fetchStudents } from 'actions/StudentActions';
import Btn from 'components/Btn';
import CustomModal from 'components/CustomModal';
import CustomTagInput from 'components/CustomTagInput';
import InputField from 'components/InputField';
import NOTIFICATIONS from 'data/notifications/student';
import { ReactComponent as AddUserIcon } from 'theme/svg/add-user.svg';
import { gtag } from 'utils';

type AddStudentModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  isBulkAdd: boolean;
  setIsBulkAdd: (value: boolean) => void;
  dispatch: any; //TODO: add type
  allApis: any; // TODO: add type
  initialValues?: { groups: string[] };
};

const AddStudentModal = (props: AddStudentModalProps) => {
  const {
    isShowModal,
    handleCloseModal,
    isBulkAdd,
    setIsBulkAdd,
    dispatch,
    allApis: { postJson },
    initialValues = {},
  } = props;
  const [bulkEmails, setBulkEmails] = useState<string[]>([]);
  const [bulkError, setBulkError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    first_name: yup.string().required('Fisrt name is required'),
    last_name: yup.string(),
    email: yup.string().email('Email is invalid').required('Email address is required'),
    cost_per_lesson: yup
      .number()
      .typeError('Must be a number')
      .nullable()
      .moreThan(0, 'Must be greater than 0')
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    parent_phone: yup.string(),
    street: yup.string(),
    parent_name_1: yup.string(),
    parent_email: yup.string().email('Email is invalid'),
  });

  const formMethods = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  type TStudentValues = yup.InferType<typeof schema>;

  const onSubmit = async (values?: TStudentValues) => {
    if (isBulkAdd) {
      setBulkError('');
      setIsLoading(true);
      let isBulkError = !(bulkEmails.length > 0);
      const payload = bulkEmails.map(i => {
        const isValidEmail = yup.string().email().isValidSync(i);
        if (!isValidEmail) {
          isBulkError = true;
        }
        return {
          ...initialValues,
          name: i,
          email: i,
        };
      });
      if (isBulkError) {
        setBulkError('One or more email(s) is invalid');
        return;
      }
      try {
        await postJson('/contacts/bulk/', payload);
        await dispatch(fetchStudents());
        handleCloseModal();
        toast.success(NOTIFICATIONS.bulk.success);
        gtag(GTAG_ADD_MULTIPLE_STUDENTS);
      } catch (error: any) {
        if (error.error?.response?.status === 402) {
          toast.error(NOTIFICATIONS.create.limit);
        } else {
          const errorText = ((await error) as any)?.[0]?.non_field_errors?.[0];
          toast.error(errorText || NOTIFICATIONS.bulk.error);
        }
      }
      setIsLoading(false);
    } else {
      const payload = {
        ...initialValues,
        ...values,
        name: [values?.first_name, values?.last_name].join(' ').trim(),
      };
      try {
        await dispatch(createStudent(payload));
        gtag(GTAG_ADD_STUDENT);
        reset();
        handleCloseModal();
        toast.success(NOTIFICATIONS.create.success);
      } catch (error: any) {
        if (error.error?.response?.status === 402) {
          toast.error(NOTIFICATIONS.create.limit);
        } else {
          toast.error(NOTIFICATIONS.create.error);
        }
      }
    }
  };

  useEffect(() => {
    setBulkEmails([]);
    setBulkError('');
    setIsLoading(false);
    reset();
  }, [isShowModal, reset]);

  return (
    <CustomModal
      isShowModal={isShowModal}
      handleCloseModal={handleCloseModal}
      title="Add new student"
      className={cs(styles['add-student-modal'], styles['custom-modal-mb'])}
      onSubmit={isBulkAdd ? () => onSubmit() : handleSubmit(onSubmit)}
      isSubmitting={isSubmitting || isLoading}
      customleftFooterComponent={
        <>
          {isBulkAdd ? (
            <Btn variant="link" onClick={() => setIsBulkAdd(false)}>
              <AddUserIcon className={styles['add-student-btn-icon']} /> Add single student
            </Btn>
          ) : (
            <Btn variant="link" onClick={() => setIsBulkAdd(true)}>
              <AddUserIcon className={styles['add-student-btn-icon']} /> Or bulk add students
            </Btn>
          )}
        </>
      }
      contentClassName={styles['content-modal-mb']}
    >
      <div className={styles['add-student-modal-body']}>
        {isBulkAdd ? (
          <div className={styles['required-section']}>
            <CustomTagInput
              inputClassName={cs('mb-0', styles['input-bulk'])}
              inputProps={{
                separators: [' ', 'Enter'],
                value: bulkEmails,
                placeHolder: 'email@website.com',
                onChange: (values: string[]) => {
                  setBulkError('');
                  setBulkEmails(values);
                },
              }}
              error={bulkError}
            />
          </div>
        ) : (
          <>
            <FormProvider {...formMethods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles['required-section']}>
                  <Row>
                    <Col xs={6}>
                      <InputField
                        label="First name"
                        inputProps={{ ...register('first_name') }}
                        error={errors.first_name?.message}
                      />
                    </Col>
                    <Col xs={6}>
                      <InputField
                        label="Last name"
                        inputProps={{ ...register('last_name') }}
                        error={errors.last_name?.message}
                      />
                    </Col>
                    <Col xs={12}>
                      <InputField
                        label="email@website.com"
                        inputProps={{ ...register('email') }}
                        className="mb-0"
                        error={errors.email?.message}
                      />
                    </Col>
                  </Row>
                </div>
                <div className={styles['optional-section']}>
                  <div className={styles['section-header']}>Optional Information</div>
                  <Row>
                    <Col xs={6}>
                      <InputField
                        label="Lesson rate $"
                        inputProps={{ ...register('cost_per_lesson') }}
                        error={errors.cost_per_lesson?.message}
                      />
                    </Col>
                    <Col xs={6}>
                      <InputField
                        label="Phone number"
                        inputProps={{ ...register('parent_phone') }}
                        error={errors.parent_phone?.message}
                      />
                    </Col>
                    <Col xs={12}>
                      <InputField
                        label="Address"
                        inputProps={{ ...register('street') }}
                        error={errors.street?.message}
                      />
                    </Col>
                    <Col xs={6}>
                      <InputField
                        label="Parent name"
                        inputProps={{ ...register('parent_name_1') }}
                        error={errors.parent_name_1?.message}
                      />
                    </Col>
                    <Col xs={6}>
                      <InputField
                        label="Email"
                        inputProps={{ ...register('parent_email') }}
                        error={errors.parent_email?.message}
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </FormProvider>
          </>
        )}
      </div>
    </CustomModal>
  );
};

const mapStateToProps = (state: any) => ({
  allApis: state.Api.allApis,
});

export default connect(mapStateToProps)(AddStudentModal);
