import { configureStore } from '@reduxjs/toolkit';
import { QueryClient } from '@tanstack/react-query';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';

import rootReducer from '../reducers/index';

import apiMiddleware from './apiMiddleware';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

const cacheApiMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  if (action.payload?.request?.method === 'GET') {
    return queryClient.fetchQuery({
      queryKey: [action.payload.request.url, action.payload.request.params],
      queryFn: () => apiMiddleware(store)(next)(action),
    });
  }

  return apiMiddleware(store)(next)(action);
};

export const store = configureStore({
  reducer: persistReducer(
    {
      key: 'primary',
      whitelist: ['auth', 'user', 'userGuide'],
      storage,
    },
    rootReducer,
  ),
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    reduxThunk,
    cacheApiMiddleware,
  ],
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

// https://redux.js.org/usage/usage-with-typescript#define-root-state-and-dispatch-types
// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ReturnType<AppStore['dispatch']>;
