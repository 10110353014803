import { Dispatch, ReactNode, createContext, useReducer } from 'react';

import { Assignment } from './types';

interface State {
  status: 'pending' | 'fulfilled';
  items: Assignment[];
}

interface Action {
  type: string;
  payload?: Partial<State>;
}

const initialState: State = {
  status: 'pending',
  items: [],
};

function assignmentsReducer(state: State, action: Action) {
  switch (action.type) {
    case 'set': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'reset': {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export const AssignmentsContext = createContext<State>(initialState);
export const AssignmentsDispatchContext = createContext<Dispatch<Action>>(() => {});

export function AssignmentsProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(assignmentsReducer, initialState);
  return (
    <AssignmentsContext.Provider value={state}>
      <AssignmentsDispatchContext.Provider value={dispatch}>{children}</AssignmentsDispatchContext.Provider>
    </AssignmentsContext.Provider>
  );
}
