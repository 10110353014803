import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { useGetPost } from '../../common/api/apis';
import { FeedType, User } from '../../types';
import FeedCard from '../StudentDetails/FeedComponent/FeedCard';

import styles from './PostDetails.module.scss';

type PostDetailsProps = {
  allApis: any;
  user: User;
};

const PostDetails = (props: PostDetailsProps) => {
  const { allApis, user } = props;
  const [post, setPost] = useState<FeedType | null>(null);

  let { id } = useParams() as { id: string };
  const [fetchPost, { isPending }] = useGetPost({ post_id: id });
  const location = useLocation();

  const handleFetchPost = async () => {
    let resp = await fetchPost();
    if (resp) {
      setPost(resp);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className={styles['wrapper']}>
      {location.state?.from && location.state?.from !== location.pathname && (
        <Link
          to={location.state.from}
          state={{ feed: true }}
          className="flex items-center gap-2 font-semibold text-base text-dark no-underline hover:!opacity-60"
        >
          <ArrowLeftIcon className="size-6" />
          Back to feed
        </Link>
      )}

      {isPending ? (
        <div className={styles['spinner-wrapper']}>
          <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
        </div>
      ) : (
        post && <FeedCard allApis={allApis} data={post} user={user} keyTab="feed" />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  allApis: state.Api.allApis,
  user: state.user as User,
});

export default connect(mapStateToProps)(PostDetails);
