import {
  LOGOUT,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  RESET_TOKEN,
  SET_TOKEN,
  UPDATE_EXPIRES_TIME,
} from '../actions/AuthActions';

import { TReduxAction } from 'types';

const initialState = {
  isAuthenticated: false,
  token: null,
  logout: false,
};

export default (state = initialState, action: TReduxAction) => {
  switch (action.type) {
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload.data.token,
        expires: new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
        isAuthenticated: true,
      };
    }

    case LOGOUT: {
      return {
        ...initialState,
        logout: true,
      };
    }

    case RESET_TOKEN:
    case LOGOUT_FAIL:
    case LOGOUT_SUCCESS: {
      return initialState;
    }

    case UPDATE_EXPIRES_TIME: {
      return {
        ...state,
        token: action.payload.data.token,
        expires: new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
      };
    }

    default:
      return state;
  }
};
