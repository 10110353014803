import { GET_TEACHER, UPDATE_TEACHER } from '../actions/UserActions';

import { REDUX_AXIOS_SUCCESS_SUFFIX } from 'data/common';
import { TReduxAction, User } from 'types';

export type TUserReducerAction = TReduxAction & {
  payload: {
    data: {
      user: User;
    };
  };
};

const initialState = {};

export default (state = initialState, action: TUserReducerAction) => {
  switch (action.type) {
    case GET_TEACHER + REDUX_AXIOS_SUCCESS_SUFFIX:
    case UPDATE_TEACHER + REDUX_AXIOS_SUCCESS_SUFFIX: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    default:
      return state;
  }
};
