import { default as cs } from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import styles from './AllStudent.module.scss';

import Avatar from 'components/Avatar';
import { Contact } from 'types';

type AllStudentProps = {
  students: Contact[];
  studentSelected?: Contact;
  setStudentSelected: Dispatch<SetStateAction<Contact | undefined>>;
};

const AllStudent = (props: AllStudentProps) => {
  const { students, studentSelected, setStudentSelected } = props;
  return (
    <div className={styles['all-student-wrapper']}>
      {students.map((student: Contact) => (
        <div
          key={student.id}
          className={cs(
            styles['student-item'],
            studentSelected?.id === student.id ? styles['student-item--active'] : '',
          )}
          onClick={() => setStudentSelected(student)}
        >
          <div className={styles['left-wrapper']}>
            <div className={styles['avatar-wrapper']}>
              <Avatar
                size={40}
                letter={student?.name?.substring(0, 1) || ''}
                url={student?.student?.picture}
                className={styles['avatar']}
              />
            </div>
            <div className={styles['info-wrapper']}>
              <p className={styles['info__name']}>{student.name}</p>
              <p className={styles['info__subname']}>4 days left to pract ice beth...</p>
            </div>
          </div>
          <div className={styles['time-wrapper']}>2h</div>
        </div>
      ))}
    </div>
  );
};

export default AllStudent;
