import { TFetcherMethodKwargs } from 'types';

export const delJson = (url: string, { authToken }: TFetcherMethodKwargs) => {
  return fetch(`${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...(authToken && { Authorization: `JWT ${authToken}` }),
    },
  });
};
