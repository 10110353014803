import { config } from '../../utils/config';

const loadGMaps = (callback: () => void) => {
  const existingScript = document.getElementById('gmaps');

  if (existingScript && callback) callback();

  if (!existingScript) {
    const script = document.createElement('script');

    script.src = `//maps.googleapis.com/maps/api/js?key=${config.places_api_key}&language=en-US&libraries=places`;
    script.id = 'gmaps';

    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }
};

export default loadGMaps;
