export const FETCH_ASSIGNMENTS = 'ASSIGNMENT/FETCH_ASSIGNMENTS';
export const FETCH_ASSIGNMENTS_SUCCESS = 'ASSIGNMENT/FETCH_ASSIGNMENTS_SUCCESS';
export const FETCH_ASSIGNMENTS_FAIL = 'ASSIGNMENT/FETCH_ASSIGNMENTS_FAIL';

export const fetchAssignments = (page: number = 1, limit: number = 10) => ({
  types: [FETCH_ASSIGNMENTS, FETCH_ASSIGNMENTS_SUCCESS, FETCH_ASSIGNMENTS_FAIL],
  payload: {
    client: 'fake',
    request: {
      method: 'GET',
      url: `/assignments/?_page=${page}&_limit=${limit}`,
    },
  },
});
