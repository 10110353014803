import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { config } from '../../utils/config';

import { TeachersModal } from './components';

import Avatar from 'components/Avatar';

const TeachersTab = ({ org, dispatch, setTab }) => {
  const { allApis } = useSelector(({ Api }) => Api);
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const url = org.url.replace(config.api_endpoint, '') + 'teachers/';
    allApis.getJson(url, { limit: 1000, embed: 'user' }).then(({ results: teachers }) =>
      dispatch({
        type: 'update',
        org: { id: org.id, teachers },
      }),
    );
  }, [allApis, dispatch, org.id, org.url]);

  /*
  const onClickEdit = membership => {
    const values = {
      url: membership.url,
      email: membership.email,
      group: membership.classes[0],
    };
    setInitialValues(values);
    setShowModal(true);
  };
  */

  const onModalHide = () => {
    setInitialValues({});
    setShowModal(false);
  };

  /*
  const handleDelete = obj => {
    const url = obj.url.replace(config.api_endpoint, '');
    allApis.delJson(url).then(() => {
      dispatch({
        type: 'update',
        org: {
          id: org.id,
          teachers: org.teachers.filter(i => obj.url !== i.url),
        },
      });
    });
  };
  */

  if (!org.teachers) {
    return <div className="spinner-border big mx-auto d-block" />;
  }

  return (
    <div>
      <TeachersModal
        show={showModal}
        onHide={onModalHide}
        org={org}
        dispatch={dispatch}
        initialValues={initialValues}
        setTab={setTab}
      />
      {org.teachers?.length === 0 && <p className="text-center py-5">There are no teachers in the organization yet.</p>}

      {org.teachers?.length > 0 && (
        <ul>
          {org.teachers.map(membership => (
            <li key={membership.url} className="d-flex justify-content-between align-items-center border-bottom py-3">
              <span className="d-flex align-items-center" style={{ gap: '16px', flexBasis: '250px' }}>
                <Avatar size={40} letter={membership.full_name.substring(0, 1) || ''} url={membership.user?.picture} />
                <span style={{ fontSize: '14px', fontWeight: 500 }}>
                  {membership.user?.full_name || membership.name}
                </span>
              </span>
              <span style={{ flexBasis: '250px' }}>{membership.email}</span>
              {/*
              <span className="text-muted">{!membership.user && 'pending'}</span>
              <Dropdown>
                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                  <span className="material-icons-outlined">more_horiz</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => onClickEdit(membership)}>
                    <span className="material-icons-outlined">edit</span>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleDelete(membership)}>
                    <span className="material-icons-outlined">delete_forever</span>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

TeachersTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  org: PropTypes.objectOf(PropTypes.any).isRequired,
  setTab: PropTypes.func.isRequired,
};

export default TeachersTab;
