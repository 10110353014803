import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setClass } from '../../actions/UserActions';

import { GroupsModal } from './components';

const GroupsTab = ({ org, dispatch }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { allApis } = useSelector(({ Api }) => Api);
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    const params = { limit: 1000, embed: 'memberships' };
    allApis.getJson(`/studio/organizations/${org.id}/classes/`, params).then(({ results: groups }) =>
      dispatch({
        type: 'update',
        org: { id: org.id, groups },
      }),
    );
  }, [org.id, allApis, dispatch]);

  const onClickEdit = group => {
    const { id, name, organization } = group;
    setInitialValues({ id, name, organization });
    setShowModal(true);
  };

  const onModalHide = () => {
    setInitialValues(null);
    setShowModal(false);
  };

  const onClickView = value => {
    reduxDispatch(setClass(value));
    navigate('/students');
  };

  const handleDelete = group => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(`Are you sure you want to remove ${group.name}?`);
    if (confirm) {
      allApis.delJson(`/studio/groups/${group.id}/`).then(() => {
        dispatch({
          type: 'update',
          org: {
            id: org.id,
            groups: org.groups.filter(({ id }) => group.id !== id),
          },
        });
      });
    }
  };

  if (!org.groups) {
    return <div className="spinner-border big mt50 mx-auto d-block" />;
  }

  const teachers = group => group.memberships.filter(m => m.role === 'teacher');

  const renderMetadata = group => {
    return (
      <>
        {group.memberships.length} student{group.memberships.length != 1 && 's'}
      </>
    );
  };

  return (
    <div className="mt16">
      <GroupsModal show={showModal} onHide={onModalHide} org={org} dispatch={dispatch} initialValues={initialValues} />
      {(org.groups.length && (
        <div className="d-flex" style={{ gap: '16px' }}>
          {org.groups.map(group => (
            <div className="card" key={group.url} style={{ width: '33.333%' }}>
              <div className="card-bg">
                <Dropdown>
                  <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                    <span className="material-icons-outlined">more_horiz</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onClickEdit(group)}>
                      <span className="material-icons-outlined">edit</span>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDelete(group)}>
                      <span className="material-icons-outlined">delete_forever</span>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="card-body">
                <div className="card-title">
                  <div
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    role="button"
                    onClick={() => onClickView(group)}
                  >
                    {group.name || <i>Unnamed class</i>}
                  </div>
                  <div style={{ fontSize: '14px' }}>{(teachers(group).length && teachers(group)[0].name) || '-'}</div>
                </div>
                <div className="card-text border-top">{renderMetadata(group)}</div>
              </div>
            </div>
          ))}
        </div>
      )) || <p className="text-center py-5">There are no classes in the organization yet.</p>}
    </div>
  );
};

GroupsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  org: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GroupsTab;
