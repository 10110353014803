import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import NoContentImg from '../../../theme/img/feed/no-content.svg';

import FeedCard from './FeedCard';
import styles from './FeedComponent.module.scss';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useGetFeed } from 'common/api/apis';
import { Contact, FeedType, User } from 'types';
type FeedComponentProps = {
  allApis: any; // TODO: add type
  contact?: Contact | null;
  user?: User;
  keyTab: string;
  action?: string | null;
};

const FeedComponent = (props: FeedComponentProps) => {
  const { allApis, contact, user, keyTab, action } = props;
  const [isPending, setIsPending] = useState(true);
  const [next, setNext] = useState<string | null>(null);
  const actions = ['practice session', 'assignment completion'];
  const [posts, setPosts] = useState<FeedType[]>([]);

  const [fetchFeeds] = useGetFeed({});

  const fetchUpdates = async (pagination: string | null = '') => {
    if (contact && !contact.student) {
      setIsPending(false);
      return;
    }
    // Handle next only if match user id
    let fetch_params = {
      user_id: contact?.student?.id.toString(),
      next: pagination,
    };

    // Make request
    let resp = await fetchFeeds(fetch_params);
    if (resp) {
      let responsePosts = resp.results.filter((post: FeedType) =>
        action && action?.length > 0 ? post.action === action : actions.includes(post.action),
      );
      let currentPosts = pagination ? posts : [];
      setPosts([...currentPosts, ...responsePosts]);
      setNext(resp.next);
    }
    setIsPending(false);
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: inViewport => {
      if (inViewport && next) {
        fetchUpdates(next);
      }
    },
  });

  useEffect(() => {
    if (keyTab === 'feed') {
      fetchUpdates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyTab, contact, action]);

  return (
    <div className={styles['feed-container']}>
      {posts.map((item: FeedType, index: number) => (
        <div className="w-100" key={item.id} ref={index === posts.length - 5 ? ref : null}>
          <FeedCard allApis={allApis} data={item} user={user} keyTab={keyTab} />
        </div>
      ))}
      {!posts.length && !isPending && (
        <div className="border border-gray-200 rounded-lg p-11 flex justify-center w-full">
          <div className="w-[344px] text-center">
            <img className="w-[138px] h-[114px] mb-3 inline-block" src={NoContentImg} />
            <p className="font-semibold text-base text-gray-900">No updates yet</p>
            <p className="text-sm text-gray-600">
              You will see updates here once your student starts practicing using the app. Here is the{' '}
              <Link to={'/'} className="text-blue-500 no-underline">
                link
              </Link>
            </p>
          </div>
        </div>
      )}
      {isPending && <Spinner as="span" animation="border" role="status" className={styles['spinner']} />}
    </div>
  );
};

export default FeedComponent;
