export const DateUtils = {
  formatSecsToMins: (seconds: number) => {
    return `${Math.floor(seconds / 60)}m${seconds % 60}s`;
  },
  countdown: () => {
    return (s: number) => {
      if (s < 60) return `${s}s`;

      const h = Math.floor(s / 3600);
      s -= h * 3600;

      const m = Math.floor(s / 60);
      s -= m * 60;

      const tmp = [];

      if (h) {
        tmp.push(`${h}h`);
      }
      if (h || m) {
        tmp.push(`${m}m`);
      }

      return tmp.join(' ');
    };
  },
};
