import { default as cs } from 'classnames';
import { isEmpty, uniqBy } from 'lodash';
import { FiCheck, FiClock } from 'react-icons/fi';
import { HiPlus } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import styles from './TableAssignments.module.scss';

import Avatar from 'components/Avatar';
import { AssignmentType, AssignmentsStudentType, TaskType } from 'types';

type TableAssignmentsProps = {
  assignments: AssignmentType[];
  students: AssignmentsStudentType[];
  onViewAssignmentDetail: (data: TaskType) => void;
};

const TableAssignments = (props: TableAssignmentsProps) => {
  const { assignments, students, onViewAssignmentDetail } = props;
  const history = useNavigate();
  let tasks = uniqBy(
    assignments.map((a: AssignmentType) => a.task),
    (t: TaskType) => t.id,
  );

  return (
    <div className={styles['table-assignments-wrapper']}>
      <div className={styles['header']}>
        {isEmpty(students) && isEmpty(assignments) ? (
          <div className={styles['no-content']}>No Assignments</div>
        ) : (
          <>
            <div>
              {students.length > 0 && <div className={styles['student-name']} />}

              {students.map((s: AssignmentsStudentType, idx: number) => (
                <div
                  className={styles['student-info']}
                  key={idx}
                  onClick={() => history(`../students/${s.id}`, { relative: 'path' })}
                >
                  <Avatar url={s?.student?.picture} letter={s.name.substring(0, 1)} size={31} /> &nbsp;&nbsp;
                  {s.name}
                </div>
              ))}
            </div>

            <div className={styles['wrapper-task']}>
              {tasks.map((task: TaskType, i) => (
                <div className={styles['assignment-col']} key={i}>
                  <div className={styles['assignment-info']} onClick={() => onViewAssignmentDetail(task)}>
                    <div className={styles['assignment-title']}>{task.name}</div>
                  </div>

                  {students.map((s: AssignmentsStudentType, idx: number) => {
                    const assignmentR = s.assignments
                      ? s.assignments.find((sa: AssignmentType) => sa.task.id === task.id)
                      : null;
                    if (!assignmentR)
                      return (
                        <div
                          className={cs(styles['assignment-progress'], styles['assignment-progress-none'])}
                          onClick={() => onViewAssignmentDetail(task)}
                          key={idx}
                        >
                          <span className={cs(styles['assignment-status'], styles['empty'])}>
                            <HiPlus size={18} className={styles['add-icon']} />
                          </span>
                        </div>
                      );

                    return (
                      <div
                        className={cs(
                          styles['assignment-progress'],
                          styles[`${!assignmentR.is_finished ? 'in-progress' : 'finished'}`],
                        )}
                        key={idx}
                      >
                        <span className={styles['assignment-status']}>
                          {assignmentR.is_finished ? (
                            <FiCheck size={18} className={styles['checked-icon']} />
                          ) : (
                            <FiClock size={18} className={styles['clock-icon']} />
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            {students.length > 0 && (
              <div className={styles['assignment-col-f']}>
                <div className={styles['assignment-info-f']} />
                {[...Array(students.length)].map((_, index) => (
                  <div className={styles['assignment-progress-f']} key={index} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TableAssignments;
