import { ErrorMessage, Field, Form, Formik } from 'formik';

export default ({ initialValues = {}, onSubmit, groupOptions = [], goToGroups }) => (
  <Formik
    initialValues={{
      email: '',
      organization: '',
      group: '',
      ...initialValues,
    }}
    validate={values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Required';
      }
      if (!values.group) {
        errors.group = 'Required';
      }
      return errors;
    }}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form className="form-horizontal" id="teacher-modal-form">
        <Field name="organization" type="hidden" />
        <div className="form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="email" className="d-none control-label">
            Email
          </label>
          <div>
            <Field
              name="email"
              className="form-control"
              id="email"
              type="email"
              disabled={isSubmitting || initialValues?.user}
              placeholder="Email"
            />
            <ErrorMessage name="email" className="text-danger" component="div" />
          </div>
        </div>
        <div className="form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="group" className="d-none control-label">
            Class
          </label>
          <div>
            {(groupOptions.length && (
              <Field name="group" className="form-control" id="group" component="select" disabled={isSubmitting}>
                {groupOptions.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Field>
            )) || (
              <p className="form-control-static" style={{ fontSize: '13px' }}>
                No classes,{' '}
                <a type="button" onClick={goToGroups} className="text-primary">
                  click here to add one
                </a>
              </p>
            )}
            <ErrorMessage name="group" className="text-danger" component="div" />
          </div>
        </div>
      </Form>
    )}
  </Formik>
);
