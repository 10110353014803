import { default as cs } from 'classnames';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';

import AllStudent from './AllStudent';
import MessageArea from './MessageArea';
import styles from './Messages.module.scss';

import { fetchStudents } from 'actions/StudentActions';
import { Contact, User } from 'types';

type MessagesProps = {
  allApis: any;
  fetchStudents: () => void;
  students: Contact[];
  isFetchingStudent: boolean;
  user: User;
};

const Messages = (props: MessagesProps) => {
  /* eslint-disable */
  const {
    allApis: { getJson, postJson },
    students = [],
    fetchStudents,
    isFetchingStudent,
    user,
  } = props;
  /* eslint-enable */

  const studentss = Object.values(students).filter(student => ['new', 'accepted'].indexOf(student.status) > -1);
  const [keyTab, setKeyTab] = useState<string>('all');
  const [studentSelected, setStudentSelected] = useState<Contact>();

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const classTabActive = (tab: string) => (keyTab === tab ? styles['tab-item--active'] : '');

  if (isFetchingStudent)
    return (
      <div className={styles['spinner-wrapper']}>
        <Spinner as="span" animation="border" role="status" className={styles['spinner']} />
      </div>
    );

  return (
    <div className={styles['container']}>
      <Row className={styles['messages-wrapper']}>
        <Col md={4} lg={4} xl={4} xxl={4}>
          <div className={styles['left-wrapper']}>
            <Tab.Container activeKey={keyTab}>
              <div className={styles['tabs']}>
                <p className={cs(styles['tab-item'], classTabActive('all'))} onClick={() => setKeyTab('all')}>
                  All
                </p>
                <p className={cs(styles['tab-item'], classTabActive('student'))} onClick={() => setKeyTab('student')}>
                  Student
                </p>
                <p className={cs(styles['tab-item'], classTabActive('feed'))} onClick={() => setKeyTab('feed')}>
                  Feed
                </p>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="all">
                  <AllStudent
                    students={studentss}
                    studentSelected={studentSelected || studentss[0]}
                    setStudentSelected={setStudentSelected}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="student">Student</Tab.Pane>
                <Tab.Pane eventKey="feed">Feed</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>
        <Col md={8} lg={8} xl={8} xxl={8} className={styles['right-wrapper']}>
          <MessageArea studentSelected={studentSelected || studentss[0]} user={user} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  allApis: state.Api.allApis,
  students: Object.values(state.student.items) as Contact[],
  isFetchingStudent: state.student.isFetching as boolean,
  user: state.user as User,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchStudents: () => dispatch(fetchStudents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
