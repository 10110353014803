import classnames from 'classnames/dedupe';
import { ForwardedRef, forwardRef } from 'react';

import './Btn.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnClassName?: string;
  variant?: string;
  isLoading?: boolean;
}

export default forwardRef(
  (
    { children, btnClassName, variant = 'primary', isLoading, ...props }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const className = classnames('btn', `btn-${variant}`, props.className, btnClassName, {
      busy: isLoading,
    });
    const mergedProps = { ...props, className };

    return (
      <button ref={ref} {...mergedProps}>
        {children}
      </button>
    );
  },
);
