export default {
  create: {
    success: 'Goal created succesfully.',
    error: 'Goal created unsuccesfully.',
  },
  update: {
    success: 'Goal updated succesfully.',
    error: 'Goal updated unsuccesfully.',
  },
  delete: {
    success: 'Goal removed succesfully.',
    error: 'Goal removed unsuccesfully.',
  },
};
