import { GroupType } from 'types';
export const FETCH_GROUPS = 'GROUP/FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESS = 'GROUP/FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAIL = 'GROUP/FETCH_GROUPS_FAIL';

export const FETCH_GROUP = 'GROUP/FETCH_GROUP';
export const FETCH_GROUP_SUCCESS = 'GROUP/FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_FAIL = 'GROUP/FETCH_GROUP_FAIL';

export const CREATE_GROUP = 'GROUP/CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'GROUP/CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = 'GROUP/CREATE_GROUP_FAIL';

export const UPDATE_GROUP = 'GROUP/UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'GROUP/UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAIL = 'GROUP/UPDATE_GROUP_FAIL';

export const DELETE_GROUP = 'GROUP/DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'GROUP/DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'GROUP/DELETE_GROUP_FAIL';

export const fetchGroups = () => ({
  type: FETCH_GROUPS,
  payload: {
    request: {
      method: 'GET',
      url: '/groups/',
    },
  },
});

export const fetchGroup = (id: string) => ({
  type: FETCH_GROUP,
  payload: {
    request: {
      method: 'GET',
      url: `/groups/${id}/`,
    },
  },
});

export const createGroup = (data: Pick<GroupType, 'name'>) => ({
  type: CREATE_GROUP,
  payload: {
    request: {
      method: 'POST',
      url: '/groups/',
      data,
    },
  },
});

export const updateGroup = ({ id, ...data }: GroupType) => ({
  type: UPDATE_GROUP,
  payload: {
    request: {
      method: 'PATCH',
      url: `/groups/${id}/`,
      data,
    },
  },
});

export const deleteGroup = (id: string | number) => ({
  type: DELETE_GROUP,
  payload: {
    request: {
      method: 'DELETE',
      url: `/groups/${id}/`,
    },
  },
  id,
});
