import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { config } from '../../../utils/config';

import GroupsForm from './GroupsForm';

const TeacherRow = ({ teacher, onDelete, onSave }) => {
  const [edit, setEdit] = useState(false);
  const [hidden, setHidden] = useState();

  const handleSave = values => {
    onSave(values).then(() => {
      if (values.id) {
        setEdit(false);
      } else {
        setHidden(true);
      }
    });
  };

  if (hidden) {
    return null;
  }

  if (teacher.id && !edit) {
    return (
      <div className="form-control-static clearfix" style={{ fontSize: '13px' }}>
        <span type="button" className="material-icons-outlined float-end text-muted" onClick={() => onDelete(teacher)}>
          delete_forever
        </span>
        {!teacher.user && (
          <span
            type="button"
            className="material-icons-outlined float-end text-muted"
            onClick={() => {
              setEdit(true);
            }}
          >
            edit
          </span>
        )}
        {teacher.name}
        {!teacher.user && <span className="text-muted"> (pending)</span>}
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        id: teacher?.id,
        email: teacher.email || '',
      }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        }
        return errors;
      }}
      onSubmit={handleSave}
    >
      {({ isSubmitting }) => (
        <Form className="d-flex align-items-start justify-content-between pb4">
          <div className="flex-grow-1">
            <Field
              name="email"
              className="form-control input-sm"
              placeholder="Email"
              type="email"
              disabled={isSubmitting}
            />
            <ErrorMessage name="email" className="text-danger" component="div" />
          </div>
          &nbsp;
          <button type="button" className="btn btn-sm btn-danger" title="Cancel" onClick={() => setHidden(true)}>
            <span className="material-icons-outlined">cancel</span>
          </button>
          &nbsp;
          <button type="submit" className="btn btn-sm btn-success" title="Save">
            <span className="material-icons-outlined">done</span>
          </button>
        </Form>
      )}
    </Formik>
  );
};

const Teachers = ({ dispatch, org, groupId }) => {
  const { allApis } = useSelector(state => state.Api);
  const group = org.groups.find(g => g.id === groupId);
  const teachers = (org.teachers || []).filter(t => t.groups.includes(group.url));
  const [emptyRows, setEmptyRows] = useState([]);
  const rows = [...teachers, ...emptyRows];

  const handleDelete = teacher => {
    const url = teacher.url.replace(config.api_endpoint, '');
    allApis.delJson(url).then(() => {
      dispatch({
        type: 'update',
        org: {
          id: org.id,
          teachers: org.teachers.filter(i => teacher.url !== i.url),
        },
      });
    });
  };

  const handleSave = values => {
    const url = '/studio/organizations/memberships/';
    const { id, ...data } = values;
    if (id) {
      return allApis.patchJson(`${url}${id}/`, data).then(response => {
        dispatch({
          type: 'update',
          org: {
            id: org.id,
            teachers: org.teachers.map(t => (t.id === response.id ? { ...t, ...response } : t)),
          },
        });
        return true;
      });
    }

    data.organization = org.url;
    data.groups = [group.url];
    data.role = 'teacher';
    return allApis.postJson(url, data).then(response => {
      dispatch({
        type: 'update',
        org: {
          id: org.id,
          teachers: [...(org.teachers || []), response],
        },
      });
      return true;
    });
  };

  const appendTeacherRow = () => {
    setEmptyRows([...emptyRows, { url: Date.now() }]);
  };

  return (
    <div>
      {rows.map(teacher => (
        <TeacherRow key={teacher.url} teacher={teacher} onDelete={handleDelete} onSave={handleSave} />
      ))}
      <div type="button" className="form-control-static py-3" style={{ fontSize: '13px' }}>
        <a onClick={() => appendTeacherRow()}>+ Add teacher</a>
      </div>
    </div>
  );
};

export default ({ show, onHide, org, dispatch, initialValues }) => {
  const { allApis } = useSelector(({ Api }) => Api);

  const handleCreate = values =>
    allApis.postJson('/studio/groups/', values).then(group => {
      dispatch({
        type: 'update',
        org: { id: org.id, groups: [...(org.groups || []), group] },
      });
      onHide();
      return true;
    });

  const handleUpdate = values =>
    allApis.patchJson(`/studio/groups/${values.id}/?embed=memberships`, values).then(group => {
      dispatch({
        type: 'update',
        org: {
          id: org.id,
          groups: org.groups.map(g => (g.id === group.id ? group : g)),
        },
      });
      onHide();
      return true;
    });

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div className="modal-title">{initialValues?.id ? 'Update' : 'Create'} class</div>
      </Modal.Header>
      <Modal.Body className="p-4">
        <GroupsForm initialValues={initialValues} onSubmit={initialValues?.id ? handleUpdate : handleCreate} />
        {false && (
          <div className="form-horizontal">
            <div className="form-group mb0">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="name" className="d-none control-label">
                Teachers
              </label>
              <Teachers dispatch={dispatch} org={org} groupId={initialValues.id} />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="text-right">
        <button type="submit" form="group-modal-form" className="btn btn-primary">
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
