import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';
import Form from 'react-bootstrap/Form';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
  id?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  className?: string;
  label?: string;
}

const Checkbox = ({
  disabled = false,
  defaultChecked,
  className,
  id,
  checked,
  onChange,
  label,
}: CheckboxProps): any => {
  return (
    <>
      <Form.Check
        type="checkbox"
        id={id}
        className={`${styles['check-box']} ${className}`}
        checked={checked}
        onChange={onChange}
        defaultChecked={defaultChecked}
        label={label}
        disabled={disabled}
      />
    </>
  );
};

export default Checkbox;
