import axios, { AxiosRequestConfig } from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';

import { TResetTokenAction, resetToken } from '../actions/AuthActions';
import { CREATE_STUDENT, FETCH_STUDENTS } from '../actions/StudentActions';
import { config } from '../utils/config';

type AuthState = {
  token: string | null;
  isAuthenticated: boolean;
};

const clients = {
  default: {
    client: axios.create({
      baseURL: config.api_endpoint,
    }),
  },
};

const axiosMiddlewareOptions = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: ({ getState }: { getState: () => { auth: AuthState } }, req: AxiosRequestConfig) => {
          const {
            auth: { token },
          } = getState();

          if (token && req?.headers) {
            req.headers.Authorization = `JWT ${token}`;
          }

          return req;
        },
      },
      {
        success: ({ getState }: { getState: () => { classes: any } }, req: any) => {
          const { classes } = getState();
          const types = [CREATE_STUDENT, FETCH_STUDENTS];

          if (types.includes(req.reduxSourceAction.type)) {
            const klass = classes.active || (classes.results && classes.results[0]);
            if (klass) {
              const url = klass.students.replace(config.api_endpoint, '');
              req.url = url;
            }
          }

          return req;
        },
      },
    ],
    response: [
      {
        error: (
          {
            getSourceAction,
          }: {
            getSourceAction: (config: AxiosRequestConfig) => any;
          },
          error: any,
        ) => {
          if (error.config && error.response?.status === 500) {
            const request = getSourceAction(error.config).payload.request;
            if (request.url === '/billing/paypal/invoices/') {
              const msg = 'Can not read an invoice list: paypal_merchant_id is empty.';
              if (error.response.data.detail === msg) {
                return Promise.resolve({ status: 204, data: [] });
              }
            }
          }

          return Promise.reject(error);
        },
      },
      {
        error: (
          {
            getState,
            dispatch,
          }: {
            getState: () => { auth: AuthState };
            dispatch: (action: TResetTokenAction) => void;
          },
          req: any,
        ) => {
          const { response = { status: 0 } } = req;
          const {
            auth: { isAuthenticated },
          } = getState();

          if (response.status === 401 && isAuthenticated) {
            dispatch(resetToken());
            window.location.replace(process.env.PUBLIC_URL || '/');
          }

          return Promise.reject(req);
        },
      },
    ],
  },
};

export default multiClientMiddleware(clients, axiosMiddlewareOptions);
