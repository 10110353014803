import moment from 'moment';
import { useState } from 'react';
import { Calendar as CalendarContainer, SlotInfo, ToolbarProps, Views, momentLocalizer } from 'react-big-calendar';
import { Dispatch } from 'redux';
import { RRule, RRuleSet } from 'rrule';

import styles from './CalendarComponent.module.scss';
import EventWrapperCalendar from './EventWrapperCalendar';
import ToolbarCalendar from './ToolbarCalendar';

import ModalLesson from 'pages/Calendar/ModalLesson';
import { CalendarType, Lesson, LessonEvent, ScheduleType } from 'types';
import { REGISTRATION_TYPES } from 'utils/config';

type CalendarComponentProps = {
  calendar: CalendarType;
  schedule: ScheduleType;
  lessons: { results: Lesson[] };
  dispatch: Dispatch;
  createLesson: (data: LessonEvent) => void;
  updateLesson: (id: string | number | undefined, data: LessonEvent) => void;
  fetchLessons: () => void;
  allApis: any;
};

const TIME_60MIN = 60;

const CalendarComponent = (props: CalendarComponentProps) => {
  const { calendar, lessons, schedule, dispatch, createLesson, updateLesson, fetchLessons, allApis } = props;
  const localizer = momentLocalizer(moment);
  /* eslint-disable */
  const [defaultDate, setDefaultDate] = useState<any>(new Date());
  /* eslint-enable */
  const [initializeDataModal, setInitializeDataModal] = useState<LessonEvent>();
  const [showModalLessonDateInput, setShowModalLessonDateInput] = useState<boolean>(false);
  const [displayModalLesson, setDisplayModalLesson] = useState<string>('');
  const getDays = () => ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const getUnitOfTime = () => {
    return {
      value: schedule.time_increment,
      unit: 'minutes',
    };
  };

  const unit = getUnitOfTime().value || 60;
  const step = 60;

  const eventStyleGetter = (event: any) => {
    if (event.event_type === 'lesson') {
      return {
        style: {
          backgroundColor: event.status === 'pending' ? '#E9F0FE' : '#2264F2',
          borderColor: '#2264F2',
          color: event.status === 'pending' ? '#2264F2' : '#ffffff',
          zIndex: '11',
        },
      };
    } else if (event.event_type === 'event') {
      return {
        style: {
          backgroundColor: event.status === 'pending' ? '#bee8c3' : '#4BA356',
          borderColor: '#4BA356',
          color: event.status === 'pending' ? '#4BA356' : '#FFFFFF',
          zIndex: '11',
        },
      };
    } else if (event.event_type === 'personal_appointment') {
      return {
        style: {
          backgroundColor: event.status === 'pending' ? '#ffc4ab' : '#C83C00',
          borderColor: '#C83C00',
          color: event.status === 'pending' ? '#C83C00' : '#FFFFFF',
          zIndex: '11',
        },
      };
    }
  };

  const dayPropGetter = () => ({
    style: {
      backgroundColor: '#ffffff',
    },
    className: 'rbc-timeslot-group-wrapper',
  });

  const openLessonModal = (isNew = false) => {
    setDisplayModalLesson(isNew ? 'New' : 'Edit');
  };

  const handleSelectEvent = (event: LessonEvent) => {
    setShowModalLessonDateInput(true);
    if (typeof event === 'undefined') {
      const currDate = new Date();
      const unitOfTime = getUnitOfTime();
      setInitializeDataModal({
        date: currDate,
        start: currDate,
        end: moment(currDate).add(unitOfTime.value, 'm').toDate(),
        event_type: 'lesson',
      });
      openLessonModal(true);
    } else {
      const isNew = displayModalLesson === 'New';
      const reg = !isNew && Object.keys(REGISTRATION_TYPES).find(key => key === event.registration);

      setInitializeDataModal({
        status: event.status,
        date: new Date(event.start),
        start: new Date(event.start),
        end: new Date(event.end),
        event_type: event.event_type,
        url: event.url,
        fee: event.fee,
        student: event.contacts?.length ? event.contacts[0] : '',
        summary: event.summary,
        description: event.description,
        location: event.location,
        registration: reg || '',
        recurrence_type: event.recurrence_type,
        recurrence_end: event.recurrence_end,
        visibility: event.visibility,
        name: event.name,
      });
      openLessonModal(false);
    }
  };

  const handleSelectSlot = ({ start, end }: SlotInfo) => {
    setInitializeDataModal({
      date: start,
      start: start,
      end: end,
      event_type: 'lesson',
    });

    setShowModalLessonDateInput(true);
    openLessonModal(true);
  };

  const wrapperToolBar = (props: ToolbarProps) => {
    return <ToolbarCalendar {...props} />;
  };

  const getLessonEvents = (lessons: any[]) => {
    const data: any[] = [];

    const getLessonTitle = (lesson: any): string => {
      if (lesson.summary) {
        return lesson.summary;
      }

      let title = 'Lesson';
      if (lesson.student) {
        const name = lesson.student_contact_name || lesson.student.first_name + ' ' + lesson.student.last_name;
        title += `: ${name}`;
      }

      return title;
    };

    lessons.forEach((lesson: any) => {
      if (lesson.status === 'pending' || lesson.status === 'approved') {
        const name = lesson?.student_contact_name || lesson?.student?.first_name + ' ' + lesson?.student?.last_name;
        data.push({
          url: lesson.url,
          title: (lesson && getLessonTitle(lesson)) || '',
          contacts: lesson.contacts,
          start: (lesson?.start && new Date(lesson?.start)) || '',
          end: (lesson?.end && new Date(lesson?.end)) || '',
          allDay: false,
          desc: lesson.description,
          fee: lesson.fee,
          userUrl: lesson.students && lesson.students[0],
          location: lesson.location,
          summary: lesson.summary,
          description: lesson.description,
          event_type: lesson.event_type,
          registration: lesson.registration,
          visibility: lesson.visibility,
          status: lesson.status,
          name: name,
          recurrence_end: lesson.recurrence_end,
          recurrence_type: lesson.recurrence_type,
        });
      }
    });

    return data;
  };

  const getAvailableTimeEvents = (date: any, schedule: any) => {
    const data = [];

    const rruleSet = new RRuleSet();
    const startDate = moment(date).subtract(1, 'week').startOf('week');

    getDays().forEach((day, index) => {
      if (index !== 0) startDate.add(1, 'day');

      if (schedule[day] === true) {
        const start = moment(schedule[day + '_start'], 'HH:mm:ss');
        const end = moment(schedule[day + '_end'], 'HH:mm:ss');

        rruleSet.rrule(
          new RRule({
            freq: RRule.WEEKLY,
            count: 3,
            dtstart: startDate.clone().hours(start.hours()).minutes(start.minutes()).toDate(),
          }),
        );

        rruleSet.rrule(
          new RRule({
            freq: RRule.WEEKLY,
            count: 3,
            dtstart: startDate.clone().hours(end.hours()).minutes(end.minutes()).toDate(),
          }),
        );
      }
    });

    // every 2 items is a pair (start time and end time of a period)
    const timePeriods = rruleSet.all();

    for (let index = 0; index < timePeriods.length; index += 2) {
      const unitOfTime = getUnitOfTime();

      let start = moment(timePeriods[index]);
      let end = moment(timePeriods[index + 1]);

      do {
        let e = null;
        const s = start.clone();

        if (unitOfTime.value === TIME_60MIN && s.minutes() !== 0) {
          start.minutes(0);
        }

        start.add(unitOfTime.value as any, unitOfTime.unit);

        if (start.isAfter(end)) {
          e = end.clone();
        } else {
          e = start.clone();
        }

        data.push({
          title: 'Available',
          status: 'available',
          start: s.toDate(),
          end: e.toDate(),
          allDay: false,
        });
      } while (start.isBefore(end));
    }

    return data;
  };

  const isRender = () => {
    const data: any = {};

    if (lessons.results !== undefined) {
      const lessonEventsResult = getLessonEvents(lessons.results);
      data.lessonEventsResult = [...lessonEventsResult];
    }

    if (Object.keys(schedule).length > 0) {
      const availableTimeEventsResult = getAvailableTimeEvents(defaultDate, schedule);
      data.availableTimeEventsResult = [...availableTimeEventsResult];
    }

    const getEvents =
      calendar.showAvailableTime === true
        ? [...data.availableTimeEventsResult, ...data.lessonEventsResult]
        : data.lessonEventsResult;

    return (
      <CalendarContainer
        selectable
        localizer={localizer}
        dayLayoutAlgorithm="overlap"
        events={getEvents}
        defaultView={Views.DAY}
        eventPropGetter={eventStyleGetter as any}
        dayPropGetter={dayPropGetter as any}
        step={step}
        timeslots={unit / step}
        components={{
          toolbar: wrapperToolBar,
          event: EventWrapperCalendar,
        }}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        popup={true}
      />
    );
  };

  const handleCloseModal = () => {
    setShowModalLessonDateInput(false);
    openLessonModal(false);
  };

  return (
    <div className={styles['calendar-wrapper']}>
      {isRender()}

      <ModalLesson
        isShowModal={showModalLessonDateInput}
        handleCloseModal={handleCloseModal}
        fetchLessons={fetchLessons}
        dispatch={dispatch}
        allApis={allApis}
        displayModalLesson={displayModalLesson}
        updateCalendarEvents={() => {}}
        createLesson={createLesson}
        initializeDataModal={initializeDataModal}
        updateLesson={updateLesson}
      />
    </div>
  );
};

export default CalendarComponent;
