import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import { Modal } from '../../components/Modal';

import './index.css';

export default () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <main className="pt-[84px]">
      <section className="py-12 lg:pt-[60px] lg:pb-[144px]">
        <div className="container">
          <div className="text-center">
            <h2 className="font-Jakarta text-[32px] md:text-5xl leading-10 md:leading-[56px] text-dark mb-3 sm:mb-4 font-medium tracking-tight">
              Find the right plan to grow and
              <br className="hidden md:block" />
              inspire your music community
            </h2>
            <p className="text-base sm:text-lg">
              We are a registered 501(c)3 non-profit based in California.
              <br className="hidden md:block" />
              100% of your monthly subscription is tax deductible.
            </p>
          </div>

          <div className="mt-11 sm:mt-[84px] grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-7 text-sm text-[#07101F]">
            <div className="bg-white border rounded-[16px]">
              <div className="p-6 border-b border-b-[#07101F] border-opacity-10">
                <p className="text-xl font-Jakarta font-semibold mb-2">Free Plan</p>
                <p className="text-sm">Perfect for getting started with essential tools.</p>

                <div className="mt-[60px]">
                  <p className="text-gray-600 mb-4">
                    <span className="font-Jakarta text-dark text-[40px] leading-[40px] font-semibold mr-1.5">$0</span>{' '}
                    USD/mo
                  </p>
                  <a
                    href="signup"
                    className="py-3 px-6 font-medium text-base text-white bg-primary rounded-[40px] w-full inline-block text-center"
                  >
                    Get started
                  </a>
                </div>
              </div>

              <ul className="flex flex-col gap-2 p-6 text-sm">
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Track practice time</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Calendar</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Lesson scheduler</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Assign homework</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Set goals</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>See class feed</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Manage billing</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Free website</span>
                </li>
              </ul>
            </div>

            <div className="bg-white border rounded-[16px]">
              <div className="p-6 border-b border-b-[#07101F] border-opacity-10">
                <p className="text-xl font-Jakarta font-semibold mb-2">Pro</p>
                <p className="text-sm">Perfect for getting started with essential tools.</p>

                <div className="mt-[60px]">
                  <p className="text-gray-600 mb-4">
                    <span className="font-Jakarta text-dark text-[40px] leading-[40px] font-semibold mr-1.5">$5</span>{' '}
                    USD/mo
                  </p>
                  <a
                    href="signup"
                    className="py-3 px-6 font-medium text-base text-white bg-primary rounded-[40px] w-full inline-block text-center"
                  >
                    Get started
                  </a>
                </div>
              </div>

              <ul className="flex flex-col gap-2 p-6 text-sm">
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Track practice time</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Calendar</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Lesson scheduler</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Assign homework</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Set goals</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>See class feed</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Manage billing</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Free website</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Automated billing and invoices</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Custom domain support</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Newsletters</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Listen to audio from practice sessions</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Free Musopen Pro membership</span>
                </li>
              </ul>
            </div>

            <div className="bg-white border rounded-[16px]">
              <div className="p-6 border-b border-b-[#07101F] border-opacity-10">
                <p className="text-xl font-Jakarta font-semibold mb-2">Organization</p>
                <p className="text-sm">Unlimited access & premium features.</p>

                <div className="mt-[60px]">
                  <p className="text-gray-600 mb-4">
                    <span className="font-Jakarta text-dark text-[40px] leading-[40px] font-semibold mr-1.5">$10</span>{' '}
                    USD/mo
                  </p>
                  <a
                    href="signup"
                    className="py-3 px-6 font-medium text-base text-white bg-primary rounded-[40px] w-full inline-block text-center"
                  >
                    Get started
                  </a>
                </div>
              </div>

              <ul className="flex flex-col gap-2 p-6 text-sm">
                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Track practice time</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Calendar</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Lesson scheduler</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Assign homework</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Set goals</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>See class feed</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Manage billing</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Free website</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Automated billing and invoices</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Custom domain support</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Newsletters</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Listen to audio from practice sessions</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Free Musopen Pro membership</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Unlimited classes</span>
                </li>

                <li className="flex items-center gap-2.5">
                  <CheckIcon className="icon check" />
                  <span>Administrative control</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-[1560px] mx-auto py-[84px] rounded-3xl bg-fade-gradient hidden md:block">
        <div className="container">
          <h3 className="text-5xl leading-[56px] mb-[84px]">Compare plans and features</h3>
          <div className="rounded-2xl bg-white border border-gray-200">
            <table className="w-full text-lg leading-[26px] pricing-table text-[#07101F]">
              <thead>
                <tr className="text-center font-Jakarta border-b border-b-gray-200 text-base">
                  <th className="[&:not(:last-child)]:border-r border-r-gray-200 p-6 font-semibold"></th>
                  <th className="[&:not(:last-child)]:border-r border-r-gray-200 p-6 font-semibold whitespace-nowrap">
                    <span className="block mb-4">Free Plan</span>$<span className="text-4xl align-text-top">0</span>
                  </th>
                  <th className="[&:not(:last-child)]:border-r border-r-gray-200 p-6 font-semibold whitespace-nowrap">
                    <span className="block mb-4">Pro</span>$<span className="text-4xl align-text-top">5</span>
                    <span className="text-gray-600 font-sans font-normal inline-block text-left align-text-top">
                      per/
                      <br />
                      month
                    </span>
                  </th>
                  <th className="[&:not(:last-child)]:border-r border-r-gray-200 p-6 font-semibold whitespace-nowrap">
                    <span className="block mb-4">Organization</span>$<span className="text-4xl align-text-top">10</span>
                    <span className="text-gray-600 font-sans font-normal inline-block text-left align-text-top">
                      per/
                      <br />
                      month
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Track practice time</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Calendar</td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Lesson scheduler</td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Assign homework</td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Set goals</td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>See class feed</td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Manage billing</td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Free website</td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Automated billing and invoices</td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Custom domain support</td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Newsletters</td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Listen to audio from practice sessions</td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Free Musopen Pro membership</td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Unlimited classes</td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td>Administrative control</td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <span className="w-[14px] bg-gray-400 h-0.5 mx-auto block"></span>
                  </td>
                  <td>
                    <CheckIcon className="stroke-primary size-8 mx-auto" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className="py-12 lg:py-[144px]">
        <div className="container">
          <div className="flex flex-col lg:flex-row">
            <h2 className="font-Jakarta text-[28px] sm:text-[48px] leading-9 sm:leading-[56px] mb-11 font-medium tracking-tight max-w-[410px]">
              Questions & answers
            </h2>

            <div className="lg:max-w-[735px] w-full ml-auto">
              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">How much usage is included with the Adagio add-on plan?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">Is there a free trial for Adagio?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">How does Adagio use my data?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">How do I get charged when I add members to my classroom?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">Where can I find my invoices?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">What are your accepted payment methods?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">
                    What happens when I go over the block storage limit on a Free Plan?
                  </h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">What is a block?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden border-b">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">Do you offer student discounts?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between py-5 hover:text-gray-600">
                  <h4 className="text-lg leading-6">How is pricing calculated for the paid plans?</h4>
                  <PlusIcon className="size-6 text-gray-600" />
                </summary>
                <p className="pb-6 text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non soluta vero aliquid obcaecati neque
                  voluptas vel, perspiciatis pariatur quaerat enim animi quod odio consectetur saepe, a minima corrupti
                  doloremque recusandae!
                </p>
              </details>
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-[1560px] mx-auto mb-11 rounded-3xl bg-promotion-section bg-no-repeat bg-left-top overflow-hidden py-16 max-md:mx-4 text-center hidden lg:block">
        <div className="container flex justify-center gap-11 flex-col items-center">
          <div className="rounded-full py-1 px-2.5 inline-block text-xs uppercase border border-[#07101F1A]/10">
            Music teaching with Adagio
          </div>
          <div className="text-base max-w-[800px]">
            <h3 className="font-Jakarta text-[44px] leading-[52px] mb-6">
              Teach, inspire, and grow alongside 10,000+ music educators.
            </h3>
            <p>
              A platform designed to help you focus on what matters most—teaching and inspiring
              <br className="hidden sm:block" />
              your students—while it handles organization, tracking, and simplification.
            </p>
          </div>

          <div className="gap-2 md:gap-4 flex justify-center max-md:flex-col max-md:items-center">
            <a href="signup" className="py-4 px-8 font-medium text-base bg-primary text-white rounded-[40px]">
              Get started
            </a>

            <a
              role="button"
              className="py-4 px-8 font-medium text-base bg-[#3066B0] bg-opacity-10 text-gray-800 rounded-[40px] flex items-center gap-2"
              onClick={() => setShowModal(true)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="fill-gray-800"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.7817 8.78296C10.4498 8.55666 10 8.79436 10 9.19607V14.8039C10 15.2056 10.4498 15.4433 10.7817 15.217L14.8941 12.4131C15.1852 12.2146 15.1852 11.7854 14.8941 11.5869L10.7817 8.78296Z"
                />
              </svg>
              Watch demo
            </a>
          </div>
        </div>
      </section>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="aspect-video size-full min-w-3xl z-20">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/cGINBVsGBIA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </main>
  );
};
