import { HTMLProps, MouseEvent, ReactNode, forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AiFillSetting } from 'react-icons/ai';
import { HiOutlineChevronDown } from 'react-icons/hi';

import styles from './DropdownFilterGroups.module.scss';

import { OptionsType } from 'types';

type CustomToggleProps = HTMLProps<HTMLDivElement> & {
  label?: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  customToggleComponent?: ReactNode;
  icon?: ReactNode; // TODO: add type
  classNameToggle?: string;
  value?: string;
};

const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  // eslint-disable-next-line
  ({ onClick, label, customToggleComponent, icon, classNameToggle, value }, ref) => (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {customToggleComponent ? (
        customToggleComponent
      ) : (
        <div className={`${styles['dropdown-toggle']} ${classNameToggle}}`}>
          <>
            <span>
              {icon || ''}
              {label}
            </span>
            <HiOutlineChevronDown className={styles['icon']} />
          </>
        </div>
      )}
    </div>
  ),
);

type DropdownFilterGroupsProps = {
  label?: string;
  className?: string;
  classNameToggle?: string;
  value?: string;
  options?: OptionsType[];
  icon?: ReactNode;
  onSelect?: (data: OptionsType) => void;
  onEditGroup?: () => void;
};

const DropdownFilterGroups = (props: DropdownFilterGroupsProps) => {
  const { label, className, options, icon, classNameToggle, value, onSelect, onEditGroup } = props;
  return (
    <>
      <Dropdown className={className} align={{ lg: 'end' }}>
        <Dropdown.Toggle
          as={CustomToggle}
          icon={icon}
          label={label}
          classNameToggle={classNameToggle}
        ></Dropdown.Toggle>
        <Dropdown.Menu className={styles['dropdown-menu']}>
          <div className={styles['dropdown-item-body']}>
            {options?.map((item: any, index: number) => (
              <Dropdown.Item
                key={index}
                onClick={() => onSelect && onSelect(item)}
                className={styles['dropdown-item']}
                active={value === item?.value}
              >
                {item?.icon}
                <span className={styles['text']}>{item?.label}</span>
              </Dropdown.Item>
            ))}
          </div>
          <div className={styles['dropdown-item-bottom']}>
            <Dropdown.Item onClick={onEditGroup}>
              <AiFillSetting size={22} className={styles['icon']} />
              <span className={styles['text']}>Edit group</span>
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropdownFilterGroups;
