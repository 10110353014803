import {
  FETCH_TIMECHARTS_FOR_STUDENTS,
  FETCH_TIMECHARTS_FOR_STUDENTS_FAIL,
  FETCH_TIMECHARTS_FOR_STUDENTS_SUCCESS,
} from '../actions/PracticesActions';

import { TReduxAction } from 'types';

const initialState = {
  isFetching: false,
  timeCharts: {},
};

export default (state = initialState, action: TReduxAction) => {
  switch (action.type) {
    case FETCH_TIMECHARTS_FOR_STUDENTS: {
      return { ...state, isFetching: true };
    }
    case FETCH_TIMECHARTS_FOR_STUDENTS_FAIL: {
      return { ...state, isFetching: false };
    }
    case FETCH_TIMECHARTS_FOR_STUDENTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        timeCharts: action.payload.data.datasets,
      };
    }
    default:
      return state;
  }
};
