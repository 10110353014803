export default {
  create: {
    success: 'Assignment created succesfully.',
    error: 'Assignment created unsuccesfully.',
  },
  update: {
    success: 'Assignment updated succesfully.',
    error: 'Assignment updated unsuccesfully.',
  },
  delete: {
    success: 'Assignment removed succesfully.',
    error: 'Assignment removed unsuccesfully.',
  },
};
