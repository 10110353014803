import { CalendarIcon } from '@heroicons/react/24/outline';
import { default as cs } from 'classnames';
import { MouseEvent, SyntheticEvent, forwardRef, useMemo } from 'react';
import { Form, FormGroupProps, FormLabelProps, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { MdOutlineClear } from 'react-icons/md';

import styles from './DateInput.module.scss';

type CustomInputProps = {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  value?: string;
  error?: string;
  handleReset: (e: SyntheticEvent<any, Event>) => void;
};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(({ value, onClick, error, handleReset }, ref) => {
  const randomId = useMemo(() => (Math.random() + 1).toString(36).substring(2), []);

  return (
    <>
      <InputGroup>
        <Form.Control
          placeholder={'Select date'}
          className={cs(styles['input-field-input'])}
          isInvalid={!!error}
          onClick={onClick}
          ref={ref}
          id={randomId}
          value={value}
          readOnly
        />
        <InputGroup.Text>
          {(value && <MdOutlineClear size={14} className={styles['clear-calendar-icon']} onClick={handleReset} />) || (
            <CalendarIcon className={styles['calendar-icon']} />
          )}
        </InputGroup.Text>
      </InputGroup>

      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  );
});

type DateInputProps = {
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
  label?: string;
  labelProps?: FormLabelProps;
  labelClassName?: string;
  value: string | number;
  onChange: (date: Date | null, event: SyntheticEvent<any, Event> | undefined) => void;
};

const DateInput = (props: DateInputProps) => {
  const { className, groupProps, error, value, onChange } = props;
  return (
    <Form.Group className={cs(styles['input-field-group'], className)} {...groupProps}>
      <DatePicker
        selected={(value && new Date(value)) || null}
        onChange={onChange}
        showPopperArrow={false}
        customInput={<CustomInput error={error} handleReset={e => onChange(null, e)} />}
        calendarClassName={styles['calendar']}
      />
    </Form.Group>
  );
};

export default DateInput;
