import { CreditCardIcon } from '@heroicons/react/24/outline';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Dropdown, InputGroup } from 'react-bootstrap';

import CustomModal from '../../../../components/CustomModal';

import styles from './ChargeLesson.module.scss';

import { useAPI } from 'hooks';

type ChargeLessonProps = {
  options: any;
  value: {
    auto_invoicing?: boolean;
    default_rate?: boolean;
    fee?: number;
  };
  onChange: (arg: any) => void;
};

const ChargeLesson = (props: ChargeLessonProps) => {
  const {
    options,
    value: { fee = '', auto_invoicing = false, default_rate = false },
    onChange,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const items = Array.from(new Map(options.map((i: any) => [i.value, i])).values());
  const { data: teacher } = useAPI('/study/teacher/');

  return (
    <>
      <div className={styles.wrapper}>
        <InputGroup className="w-100">
          <InputGroup.Text className="bg-transparent p-0 border-0 text-secondary py-1">
            <CreditCardIcon />
          </InputGroup.Text>
          <Dropdown onSelect={fee => onChange({ fee })}>
            <Dropdown.Toggle className="border-0 form-control text-start" variant="transparent">
              {fee || <span className="text-secondary">Add charge</span>}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {items.map((option: any, index: number) => (
                <Dropdown.Item key={index} eventKey={option.value} active={fee == option.value}>
                  <span style={{ fontWeight: 500 }}>{option.label}</span> /{' '}
                  <span className="text-secondary">Per Lesson</span>
                </Dropdown.Item>
              ))}
              <Dropdown.Item className="border-top mt-1 pt-2" onClick={() => setOpenModal(true)}>
                <Cog6ToothIcon className="text-primary" /> Settings
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </div>

      <CustomModal
        isShowModal={openModal}
        title="Charge settings"
        handleCloseModal={() => setOpenModal(false)}
        onSubmit={() => setOpenModal(false)}
      >
        <div className={styles['modal-container']}>
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              value={fee}
              onChange={e => {
                onChange({ fee: e.target.value });
              }}
            />
          </div>
          <div className={styles['checkbox-container']}>
            <div className={styles['checkbox-wrapper']}>
              <span>Set as default rate</span>
              <div className="form-check form-switch">
                <input
                  defaultChecked={default_rate}
                  onChange={e => {
                    onChange({ default_rate: e.target.checked });
                  }}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                />
              </div>
            </div>
            <div className={styles['checkbox-wrapper']}>
              <span>Auto-invoice</span>
              <div className="form-check form-switch">
                <input
                  defaultChecked={auto_invoicing}
                  onChange={e => {
                    onChange({ auto_invoicing: e.target.checked });
                  }}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  disabled={!Boolean(teacher?.subscription)}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ChargeLesson;
