import {
  CREATE_STUDENT_SUCCESS,
  DELETE_STUDENT,
  FETCH_STUDENTS,
  FETCH_STUDENTS_FAIL,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENT_SUCCESS,
  FILTER_STUDENTS,
  UPDATE_STUDENT_SUCCESS,
} from '../actions/StudentActions';
import { CREATE_GOAL, GET_GOALS, UPDATE_GOAL } from '../features/students/studentsSlice';

import { Contact, IGoal, Student, TReduxAction } from 'types';

export type TStudentReducerAction = TReduxAction & {
  payload: {
    data: {
      id: number;
      results: Contact[] | IGoal[];
    };
  };
  meta: {
    previousAction: {
      payload: TReduxAction['payload'] & {
        request: {
          params: Record<string, string>;
          data: {
            contact: string;
            student: Student;
          };
        };
      };
    };
  };
  searchQuery: string;
};

export type TExtendContact = Contact & {
  goals: IGoal[];
};

export type TStudentReducerState = {
  isFetching: boolean;
  items: Record<string | number, TExtendContact>;
  filteredItems: Record<string | number, TExtendContact>;
  searchQuery: string;
};

const initialState: TStudentReducerState = {
  isFetching: false,
  items: {},
  filteredItems: {},
  searchQuery: '',
};

export default (state = initialState, action: TStudentReducerAction) => {
  switch (action.type) {
    case FETCH_STUDENTS: {
      return { ...state, isFetching: true };
    }

    case FETCH_STUDENTS_FAIL: {
      return { ...state, isFetching: false };
    }

    case FETCH_STUDENTS_SUCCESS: {
      const items = action.payload.data.results?.reduce(
        (acc: Record<string | number, Student>, item: Student) => ({ ...acc, [item.id]: item }),
        {},
      );
      return {
        ...state,
        // items: { ...state.items, ...items },
        items,
        isFetching: false,
      };
    }

    case FETCH_STUDENT_SUCCESS: {
      return {
        ...state,
        items: {
          ...state.items,
        },
      };
    }

    case CREATE_STUDENT_SUCCESS: {
      const { data: student } = action.payload;
      return { ...state, items: { ...state.items, [student.id]: student } };
    }

    case DELETE_STUDENT + '_SUCCESS': {
      const { data } = action.meta.previousAction.payload.request;
      const {
        items: { [data.student.id]: _, ...items },
      } = state;
      return { ...state, items };
    }

    case UPDATE_STUDENT_SUCCESS: {
      const { data: student } = action.payload;
      return {
        ...state,
        items: {
          ...state.items,
          [student.id]: { ...student, student: state.items[student.id]?.student },
        },
      };
    }

    case FILTER_STUDENTS: {
      const { searchQuery } = action;
      if (!Object.keys(state.items).length || !searchQuery) {
        return { ...state, filteredItems: [], searchQuery };
      }

      const searchQueryLower = searchQuery.toLowerCase();
      const { items: students } = state;

      const filteredItems = Object.keys(students).reduce((acc, id) => {
        const studentNameLower = students[id].name.toLowerCase();
        if (studentNameLower.indexOf(searchQueryLower) !== -1) {
          return { ...acc, [id]: students[id] };
        }
        return acc;
      }, {});

      return { ...state, filteredItems, searchQuery };
    }

    case CREATE_GOAL + '_SUCCESS': {
      const url = action.meta.previousAction.payload.request.data.contact;
      const contact = Object.values(state.items).find(contact => contact.url === url);

      if (contact) {
        const goals: IGoal[] = state.items[contact.id].goals || [];
        goals.concat(action.payload.data.results as IGoal[]);

        return {
          ...state,
          items: {
            ...state.items,
            [contact.id]: {
              ...state.items[contact.id],
              goals,
            },
          },
        };
      }
      return state;
    }

    case GET_GOALS + '_SUCCESS': {
      const id = action.meta.previousAction.payload.request.params.student;
      const contact = Object.values(state.items).find(contact => contact.student?.id.toString() === id);

      if (contact) {
        return {
          ...state,
          items: {
            ...state.items,
            [contact.id]: {
              ...state.items[contact.id],
              goals: action.payload.data.results,
            },
          },
        };
      }
      return state;
    }

    case UPDATE_GOAL + '_SUCCESS': {
      const url = action.meta.previousAction.payload.request.data.contact;
      const contact = Object.values(state.items).find(contact => contact.url === url);

      if (contact) {
        const goals: IGoal[] = state.items[contact.id].goals || [];
        goals.push(action.payload.data as any);
        return {
          ...state,
          items: {
            ...state.items,
            [contact.id]: {
              ...state.items[contact.id],
              goals,
            },
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};
