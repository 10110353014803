import { useState } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { Col, Row } from 'react-bootstrap';
import { HiChevronLeft, HiChevronRight, HiPlus, HiUsers } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';

import { changeEventType } from '../../../actions/ScheduleActions';

import styles from './ToolbarCalendar.module.scss';

// import { HiPlus, HiChevronRight, HiChevronLeft, HiUsers, {/* HiOutlineBell */} } from 'react-icons/hi';
import Btn from 'components/Btn';
import DropdownSelect from 'components/DropdownSelect';
// import DropdownRequestPending from 'pages/Calendar/ToolbarCalendar/DropdownRequestPending';

export interface ToolbarCalendarProps extends ToolbarProps {
  handleSelectEvent: Function;
}

const ToolbarCalendar = (props: ToolbarCalendarProps) => {
  const [viewSelected, setViewSelected] = useState<string>('Week');
  const dispatch = useDispatch() as any;
  const { label, onNavigate, onView, handleSelectEvent } = props;
  const eventType = useSelector((state: any) => state.schedule.eventType);
  const calendarOptions = [
    {
      value: 'all',
      label: 'All',
      action: () => {
        dispatch(
          changeEventType({
            eventType: 'all',
          }),
        );
      },
    },
    {
      value: 'lesson',
      label: 'Lesson',
      action: () => {
        dispatch(
          changeEventType({
            eventType: 'lesson',
          }),
        );
      },
    },
    {
      value: 'personal_appointment',
      label: 'Personal Appointment',
      action: () => {
        dispatch(changeEventType({ eventType: 'personal_appointment' }));
      },
    },
    {
      value: 'event',
      label: 'Event',
      action: () => {
        dispatch(changeEventType({ eventType: 'event' }));
      },
    },
  ];

  const optionsViewCalendar = [
    {
      value: 'Month',
      label: 'Month',
      action: () => {
        onView('month');
        setViewSelected('Month');
      },
    },
    {
      label: 'Week',
      value: 'Week',
      action: () => {
        setViewSelected('Week');
        onView('week');
      },
    },
    {
      label: 'Day',
      value: 'Day',
      action: () => {
        setViewSelected('Day');
        onView('day');
      },
    },
  ];

  return (
    <Row className={styles['wrapper-toolbar']}>
      <Col xs={5} className={styles['rbc-toolbar-label']}>
        <div className={styles['toolbar-icon']} onClick={() => onNavigate('PREV')}>
          <HiChevronLeft size={26} className={styles['toolbar-icon-left']} />
        </div>
        <div className={styles['toolbar-label']}>{label}</div>
        <div className={styles['toolbar-icon']}>
          <HiChevronRight size={26} className={styles['toolbar-icon-right']} onClick={() => onNavigate('NEXT')} />
        </div>
        <Btn variant="link" btnClassName={styles['toolbar-today-btn']} onClick={() => onNavigate('TODAY')}>
          Today
        </Btn>
      </Col>
      <Col xs={7} className={styles['rbc-btn-group']}>
        {/*<DropdownRequestPending
          className={styles['pending-requests-wrapper']}
          classNameToggle={styles['pending-requests-toggle']}
          label={'3 Requests Pending'}
          icon={<HiOutlineBell size={18} className={styles['request-pending-icon']} />}
          options={Array(5).fill({})}
        /> */}
        <Btn variant="primary" btnClassName={styles['add-calendar-btn']} onClick={() => handleSelectEvent()}>
          <HiPlus className={styles['plus-btn-icon']} size={18} /> Create event
        </Btn>
        <DropdownSelect label={viewSelected} options={optionsViewCalendar} className={styles['select-view-calendar']} />
        <DropdownSelect
          label={
            calendarOptions.filter(item => {
              return item.value === eventType;
            })[0].label
          }
          icon={<HiUsers size={15} className={styles['icon-dropdown']} />}
          options={calendarOptions}
        />
      </Col>
    </Row>
  );
};

export default ToolbarCalendar;
