import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getJson } from 'common/api/method/get-json';
import { config } from 'utils/config';

export default (url: string, params: object = {}, options: object = {}) => {
  url = new URL(config.api_endpoint + url).toString();
  const token = useSelector((store: any) => store.auth.token);
  return useQuery({
    queryKey: [url, params],
    queryFn: () => getJson(url, { data: params, authToken: token }).then(res => res.json()),
    ...options,
  });
};
