import React from 'react';

import AssignmentsComponent from './AssignmentsComponent';
import GoalsComponent from './GoalsComponent';
import InvoicesComponent from './InvoicesComponent';
import LessonsComponent from './LessonsComponent';
import OverviewComponent from './OverviewComponent';
import PracticeComponent from './PracticeComponent';

import { Contact, User } from 'types';

type AnalyticsComponentProps = {
  contact?: Contact;
  user: User;
};

const AnalyticsComponent = (props: AnalyticsComponentProps) => {
  const { user, contact } = props;

  return (
    <>
      <OverviewComponent contact={contact} />

      <GoalsComponent contact={contact} />

      <AssignmentsComponent contact={contact} />

      <PracticeComponent contact={contact} />

      <InvoicesComponent />

      <LessonsComponent user={user} contact={contact} />
    </>
  );
};

export default AnalyticsComponent;
