import { HIDE_USERGUIDE, MODIFY_STEPS } from '../actions/UserGuideActions';

import { TReduxAction } from 'types';

type TUserGuideReducerAction = TReduxAction & {
  completedSteps: string[];
};

const initialState = {
  completedSteps: [],
  isHidden: false,
};

export default (state = initialState, action: TUserGuideReducerAction) => {
  switch (action.type) {
    case HIDE_USERGUIDE: {
      return { ...state, isHidden: true };
    }

    case MODIFY_STEPS: {
      return { ...state, completedSteps: action.completedSteps };
    }

    default:
      return state;
  }
};
