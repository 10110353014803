import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';

import styles from './CheckboxCustom.module.scss';

export interface CheckboxCustomProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
  id?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  className?: string;
}

const CheckboxCustom = ({
  disabled = false,
  defaultChecked,
  className,
  id,
  checked,
  onChange,
}: CheckboxCustomProps): any => {
  return (
    <input
      type="checkbox"
      id={id}
      className={`${styles['check-box-custom']} ${className}`}
      checked={checked}
      onChange={onChange}
      defaultChecked={defaultChecked}
      disabled={disabled}
    />
  );
};

export default CheckboxCustom;
