import { default as cs } from 'classnames';
import { ReactNode } from 'react';

import styles from './PageSection.module.scss';

type PageSectionProps = {
  noPadding?: boolean;
  title?: string;
  className?: string;
  children?: ReactNode;
  wrapperClassName?: string;
};

const PageSection = (props: PageSectionProps) => {
  const { noPadding, title, className, children, wrapperClassName } = props;
  return (
    <div className={cs(styles['page-section'], className)}>
      <div className={styles['title']}>{title}</div>
      <div className={cs(styles['section-wrapper'], wrapperClassName, noPadding ? styles['no-padding'] : '')}>
        {children}
      </div>
    </div>
  );
};

export default PageSection;
