import { forIn } from 'lodash';

import { TFetcherData } from 'types';

export const withPayloadJson =
  (method: string) =>
  (url: string, { data, authToken }: TFetcherData): Promise<Response> => {
    return fetch(`${url}`, {
      method: method,
      body: data == null ? undefined : JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `JWT ${authToken}` }),
      },
    });
  };

export const withMultipartPayload =
  (method: string) =>
  (url: string, { data, authToken }: TFetcherData) => {
    let formData = new FormData();
    forIn(data, (value, key) => {
      if (value != null) {
        let nKey = key.replace(/\d+$/g, '');
        formData.append(nKey, value);
      }
    });

    return fetch(url, {
      // url: url,
      // credentials: 'include',
      method: method,
      body: formData,
      headers: {
        // 'Content-Type': 'multipart/form-data',
        ...(authToken && { Authorization: `JWT ${authToken}` }),
      },
    });
  };
