import { TReduxPayload } from 'types';
export const ADD_NOTE = 'notes/addNote';
export const DELETE_NOTE = 'notes/deleteNote';
export const FETCH_NOTES = 'notes/fetchNotes';
export const UPDATE_NOTE = 'notes/updateNote';

export const addNote = (data: TReduxPayload) => ({
  type: ADD_NOTE,
  payload: {
    request: {
      method: 'POST',
      url: '/notes/',
      data,
    },
  },
});

export const deleteNote = (noteId: string | number) => ({
  type: DELETE_NOTE,
  payload: {
    request: {
      method: 'DELETE',
      url: `/notes/${noteId}/`,
    },
  },
});

export const getNotes = (params = {}) => ({
  type: FETCH_NOTES,
  payload: {
    request: {
      method: 'GET',
      url: '/notes/',
      params,
    },
  },
});

export const getPersonalNotes = (params: TReduxPayload) => ({
  type: FETCH_NOTES,
  payload: {
    request: {
      method: 'GET',
      url: '/notes/personal/',
      params,
    },
  },
});

export const updateNote = (noteId: string | number, data: TReduxPayload) => ({
  type: UPDATE_NOTE,
  payload: {
    request: {
      method: 'PATCH',
      url: `/notes/${noteId}/`,
      data,
    },
  },
});
